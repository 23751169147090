import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers
import {
  productListReducer,
  productDetailsReducer,
} from "./reducers/productReducers";
import {
  headrailListReducer,
  headrailDetailsReducer,
} from "./reducers/headrailReducers";
import {
  motorListReducer,
  motorDetailsReducer,
} from "./reducers/motorReducers";
import {
  deliveryListReducer,
  deliveryDetailsReducer,
} from "./reducers/deliveryReducers";
import {
    bracketListReducer,
    bracketDetailsReducer,
} from "./reducers/bracketReducers";
import {
    remoteListReducer,
    remoteDetailsReducer,
} from "./reducers/remoteReducers";
import {
  tasselListReducer,
  tasselDetailsReducer,
} from "./reducers/tasselReducers";
import {
  fittingListReducer,
  fittingDetailsReducer,
} from "./reducers/fittingReducers";
import {
  chainListReducer,
  chainDetailsReducer,
} from "./reducers/chainReducers";
import {
    cordListReducer,
    cordDetailsReducer,
} from "./reducers/cordReducers";
import {
    wandListReducer,
    wandDetailsReducer,
} from "./reducers/wandReducers";
import {
  addressListReducer,
  addressGetReducer,
  addressDetailsReducer,
  addAddressReducer,
} from "./reducers/addressReducers";
import { cartListReducer, cartReducer, userCartReducer } from "./reducers/cartReducers";
import { discountListReducer } from "./reducers/discountReducer"
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userListReducer,
  userUpdateProfileReducer,
} from "./reducers/userReducers";
import {
    createOrderReducer,
    orderDetailsReducer,
    orderListUserReducer,
    orderListReducer,
    orderListCompletedReducer,
    orderPayReducer, orderListAllReducer,
} from './reducers/orderReducers'
import {
    messageReducer,
} from './reducers/messageReducer'
import {
    errorReducer,
} from './reducers/errorReducer'
import {
    notificationReducer,
} from './reducers/notificationReducer'


const reducer = combineReducers({
    productList: productListReducer,
    productDetails: productDetailsReducer,
    headrailList: headrailListReducer,
    headrailDetails: headrailDetailsReducer,
    motorList: motorListReducer,
    motorDetails: motorDetailsReducer,
    deliveryList: deliveryListReducer,
    deliveryDetails: deliveryDetailsReducer,
    bracketList: bracketListReducer,
    bracketDetails: bracketDetailsReducer,
    remoteList: remoteListReducer,
    remoteDetails: remoteDetailsReducer,
    tasselList: tasselListReducer,
    tasselDetails: tasselDetailsReducer,
    fittingList: fittingListReducer,
    fittingDetails: fittingDetailsReducer,
    chainList: chainListReducer,
    chainDetails: chainDetailsReducer,
    cordList: cordListReducer,
    cordDetails: cordDetailsReducer,
    wandList: wandListReducer,
    wandDetails: wandDetailsReducer,
    addressList: addressListReducer,
    addressDetails: addressDetailsReducer,
    cartList: cartListReducer,
    carts: cartReducer,
    discountList: discountListReducer,
    userCarts: userCartReducer,
    userAddresses: addressGetReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userList: userListReducer,
    userUpdateProfile: userUpdateProfileReducer,
    createOrder: createOrderReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderListAll: orderListAllReducer,
    orderListUser: orderListUserReducer,
    orderList: orderListReducer,
    orderListCompleted: orderListCompletedReducer,
    addAddress: addAddressReducer,
    notificationList: notificationReducer,
    message: messageReducer,
    error: errorReducer,
})

const cartItemsFromStorage = localStorage.getItem("selectedCart")
  ? JSON.parse(localStorage.getItem("selectedCart"))
  : {};

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

// Load initial state when the application is loaded
const initialState = {
  carts: {
    carts: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
