import {
    BRACKET_LIST_REQUEST,
    BRACKET_LIST_SUCCESS,
    BRACKET_LIST_FAIL,
    BRACKET_DETAILS_REQUEST,
    BRACKET_DETAILS_SUCCESS,
    BRACKET_DETAILS_FAIL,
} from "../constants/bracketConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting brackets from server
export const bracketListReducer = (state = { brackets: [] }, action) => {
    switch (action.type) {
        case BRACKET_LIST_REQUEST:
            return {
                loading: true,
                brackets: [],
            };
        case BRACKET_LIST_SUCCESS:
            return {
                loading: false,
                brackets: action.payload,
            };
        case BRACKET_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case USER_LOGOUT:
            return state;
        default:
            return state;
    }
};

// Get brackets details
export const bracketDetailsReducer = (state = { bracket: { reviews: [] } }, action) => {
    switch (action.type) {
        case BRACKET_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case BRACKET_DETAILS_SUCCESS:
            return {
                loading: false,
                bracket: action.payload,
            };
        case BRACKET_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
