import {
    DISCOUNT_LIST_FAIL,
    DISCOUNT_LIST_REQUEST,
    DISCOUNT_LIST_RESET,
    DISCOUNT_LIST_SUCCESS
} from "../constants/discountConstants"

export const discountListReducer = (state = { discounts: [] }, action) => {
    switch (action.type) {
        case DISCOUNT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DISCOUNT_LIST_SUCCESS:
            return {
                loading: false,
                discounts: action.payload,
            }
        case DISCOUNT_LIST_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case DISCOUNT_LIST_RESET:
            return {
                cartsAll: [],
            }
        default:
            return state
    }
}
