import axios from "axios";
import {
  CART_GET_REQUEST,
  CART_GET_SUCCESS,
  CART_GET_FAIL,
  CART_ADD_ITEM,
  CART_ADD_FAIL,
  CART_ADD_SUCCESS,
  CART_REMOVE_ITEM,
  CART_REMOVE_FAIL,
  CART_REMOVE_SUCCESS,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_UPDATE_REQUEST,
  CART_UPDATE_SUCCESS,
  CART_UPDATE_FAIL,
} from "../constants/cartConstants";
import { API_BASE_URL } from "../constants/apiBase";

export const getUserCarts = () => async (dispatch, getState) => {
  let result;
  const {userLogin: { userInfo },} = getState();
  if(userInfo){
    try {
      dispatch({
        type: CART_GET_REQUEST,
    });
    // Get user login and user info
    // Header to send with the request
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    };
    //Make request to server and get the response data
    const { data } = await axios.get(`${API_BASE_URL}/api/cart/user`, config);
    result = data;
    dispatch({
      type: CART_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CART_GET_FAIL,
      payload: error.response && error.response.data.msg
    });
  }
}
return result;
};

export const addToCart = (cartItem) => async (dispatch, getState) => {
  //console.log("add to Cart çalıştı");
  let result = "";
  try {
    // Get user login and user info
    const {
      userLogin: { userInfo },
    } = getState();
    // Header to send with the request
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    };
    //Make request to server and get the response cartItem
    const  {data}  = await axios.post(
      `${API_BASE_URL}/api/cart/user`,
      cartItem,
      config
    );
    //console.log(data)
    result = data
    dispatch({
      type: CART_ADD_ITEM,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CART_ADD_FAIL,
      payload: error.response && error.response.data.message
    });
  }
  return result;
};

export const addNewCart = (cartItem) => async (dispatch, getState) => {
  //console.log("add to Cart çalıştı");
  let result = "";
  try {
    // Get user login and user info
    const {
      userLogin: { userInfo },
    } = getState();
    // Header to send with the request
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    };
    //Make request to server and get the response cartItem
    const {data}  = await axios.post(
      `${API_BASE_URL}/api/cart`,
      cartItem,
      config
    );
    //console.log(data)
    result = data
    dispatch({
      type: CART_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CART_ADD_FAIL,
      payload: error.response && error.response.data.msg
    });
  }
  return result;
};

export const updateCart = (state) => async (dispatch, getState) => {
  let msg = ""

  try {
    dispatch({
      type: CART_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    }

    const { cart_id, ...restOfState } = state

    const {data}  = await axios.post(
        `${API_BASE_URL}/api/cart/${cart_id}`,
        restOfState,
        config
    )

    msg = data

    dispatch({
      type: CART_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CART_UPDATE_FAIL,
      payload:
          error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
    })
  }

  return msg
}

export const removeFromCart = (state) => async (dispatch, getState) => {
  let msg = "";
  //console.log("remove from Cart çalıştı");
  try {
    // Get user login and user info
    const {
      userLogin: { userInfo },
    } = getState();
    // Header to send with the request
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    };
    //Make request to server and get the response data
    const {data}  = await axios.post(
      `${API_BASE_URL}/api/cart/user/${state.cart_id}`,
      state,
      config
    );
    //console.log(data)
      msg=data;
      dispatch({
        type: CART_REMOVE_ITEM,
        payload: data,
      });

  } catch (error) {
    dispatch({
      type: CART_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
  return msg;
};

export const removeCart = (id) => async (dispatch, getState) => {
  let result;
  //console.log("remove from Cart çalıştı", id);
  try {
    // Get user login and user info
    const {
      userLogin: { userInfo },
    } = getState();
    // Header to send with the request
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    };
    //Make request to server and get the response data
    // const {data} = await axios.delete(
    //   `${API_BASE_URL}/api/cart/${id}`,
    //   config
    // );
    // Changed DELETE request to a POST request because Mod Security prevents them
    const { data } = await axios.post(
        // `${API_BASE_URL}/api/cart/${id}`,
        `${API_BASE_URL}/api/cart/delete/${id}`,
        {},
        config
    );

    result=data;
    localStorage.removeItem("selectedCart");
    dispatch({
      type: CART_REMOVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CART_REMOVE_FAIL,
      payload: error.response && error.response.data.msg
    });
  }
  return result;
  };



export const listAllCarts = (state) => async (dispatch, getState) => {
  //console.log("list all carts çalıştı", state)
  let result;
  const {
    userLogin: { userInfo },
  } = getState();
  if (userInfo) {
    try {
      dispatch({
        type: `CART_LIST_REQUEST`,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      };

      //Make request to server and get the response data
      const { data } = await axios.get(
        `${API_BASE_URL}/api/cart?page=${state.currentPage}`,
        config
      );
      //console.log(data);
      dispatch({
        type: `CART_LIST_SUCCESS`,
        payload: data,
      });
      result = data;
    } catch (error) {
      // //console.log(error)
      dispatch({
        type: `CART_LIST_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
  return result;
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  // //console.log(data);
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });
  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
