import React from "react";
// Bootstrap UI Components
import { Navbar, Nav, Container, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
// React Redux
import { useDispatch, useSelector } from "react-redux";
// Redux User Actions
import { logout } from "../actions/userActions";

const Header = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const notifications = useSelector((store) => store.notificationList);
  // const userDetails = useSelector((state) => state.userDetails);
  const active =
    notifications && notifications.filter((item) => item.isActive === true);
  const logoutHandler = () => {
    dispatch(logout());
  };

  //console.log(userInfo);
  //console.log(userDetails);
  return (
    // <div>
      <header id="header">
        <Navbar
          bg="light"
          expand="lg"
          className="bg-zebra"
          variant="dark"
          collapseOnSelect
        >
          <Container>
            {/*<Navbar.Brand href="/" className="bg-light pt-0 pb-1 ps-1 pe-1">*/}
            {/*  <img*/}
            {/*    src="zebra.png"*/}
            {/*    alt="zebrablind"*/}
            {/*    // style={{ height: "50px", width: "250px" }}*/}
            {/*    style={{height: "45px", width: "225px"}}*/}
            {/*  />*/}
            {/*</Navbar.Brand>*/}
            <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
            >
              {/*<img*/}
              {/*    className="accredited-logo"*/}
              {/*    src="BBSA_accredited.png"*/}
              {/*    alt="BBSA_accredited"*/}
              {/*/>*/}
              {/*<img*/}
              {/*    className="accredited-logo"*/}
              {/*    src="blinds_make_better.png"*/}
              {/*    alt="blinds_make_better"*/}
              {/*/>*/}
              {/*<img*/}
              {/*    className="accredited-logo"*/}
              {/*    src="make_it_safe.png"*/}
              {/*    alt="blinds_make_better"*/}
              {/*/>*/}
              <Navbar.Brand href="/" className="bg-light pt-0 pb-1 ps-1 pe-1">
                <img
                    className="zebra-logo"
                    src="zebra.png"
                    alt="zebrablind"
                    // style={{ height: "50px", width: "250px" }}
                    // style={{height: "45px", width: "225px"}}
                />
            </Navbar.Brand>
          </div>
            {userInfo ? (
              <div>
                {/* {console.log("User role=> ", userInfo.user.role)} */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                  <Nav className="me-auto">
                    <div className="container-fluid">
                      <div className="float-end">
                        <Navbar.Brand>
                          Welcome {userInfo.user.company}
                        </Navbar.Brand>
                      </div>
                      <br />
                      <div className="mt-2 d-xs-d-flex d-sm-d-flex d-md-d-flex d-lg-inline-flex">
                        {userInfo.user.role === 0 && (
                          <LinkContainer to="/">
                            <Nav.Link className="text-light d-flex">
                              <i className="fa fa-home mr-2  me-1 mt-1 ms-1"></i>
                              <div className="d-block">Home</div>
                            </Nav.Link>
                          </LinkContainer>
                        )}
                        {userInfo.user.role === 1 ? (
                          <>
                            <LinkContainer to="/">
                              <Nav.Link className="text-light d-flex">
                                <i className="fa fa-home mr-2  me-1 mt-1 ms-1"></i>
                                <div className="d-block">Home</div>
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/notifications">
                              <Nav.Link className="text-light d-flex">
                                <i className="fa fa-bell mr-2  me-1 mt-1 ms-1"></i>
                                <div className="">
                                  Notifications{" "}
                                  {active && active.length > 0 && (
                                    <Badge bg="info">
                                      {active && active.length}
                                    </Badge>
                                  )}
                                </div>
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/users">
                              <Nav.Link className="text-light d-flex">
                                <i className="fa fa-users mr-2  me-1 mt-1 ms-1"></i>
                                <div className="">Users</div>
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/products">
                              <Nav.Link className="text-light d-flex">
                                <i className="fa fa-boxes mr-2  me-1 mt-1 ms-1"></i>
                                <div className="">Products</div>
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/cartsAll">
                              <Nav.Link className="text-light d-flex">
                                <i className="fa fa-cart-arrow-down mr-2  me-1 mt-1 ms-1"></i>
                                <div className="">Carts</div>
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/ordersAll">
                              <Nav.Link className="text-light d-flex">
                                <i className="fas fa-clipboard-list mr-2  me-1 mt-1 ms-1"></i>
                                <div className="">Orders</div>
                              </Nav.Link>
                            </LinkContainer>
                          </>
                        ) : null}

                          <LinkContainer to="/profile">
                            <Nav.Link className="text-light d-flex">
                              <i className="fa fa-user mr-2  me-1 mt-1 ms-1"></i>
                              <div className="">Profile</div>
                            </Nav.Link>
                          </LinkContainer>

                        {userInfo.user.role === 0 ? (
                          <>
                            <LinkContainer to="/orders">
                              <Nav.Link className="text-light d-flex">
                                <i className="fa fa-list mr-2  me-1 mt-1 ms-1"></i>
                                <div className="">Orders</div>
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/cart">
                              <Nav.Link className="text-light d-flex">
                                <i className="fa fa-shopping-cart mr-2 me-1 mt-1 ms-1"></i>
                                <div className="">Carts</div>
                              </Nav.Link>
                            </LinkContainer>
                          </>
                        ) : null}
                        <LinkContainer to="/">
                          <Nav.Link className="d-flex d-inline-block">
                            <i
                              className="fa fa-times-circle mr-2  me-1 mt-1 ms-1"
                              onClick={logoutHandler}
                            ></i>
                            <div onClick={logoutHandler} className="">
                              Logout
                            </div>
                          </Nav.Link>
                        </LinkContainer>
                      </div>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </div>
            ) : null}
          </Container>
        </Navbar>
      </header>
    // </div>
  );
};

export default Header;
