export const GET_BLIND_REQUEST = 'GET_BLIND_REQUEST'
export const GET_BLIND_SUCCESS = 'GET_BLIND_SUCCESS'
export const GET_BLIND_FAIL = 'GET_BLIND_FAIL'

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL'

export const INTEGRATE_CUSTOMER_REQUEST = 'INTEGRATE_CUSTOMER_REQUEST'
export const INTEGRATE_CUSTOMER_SUCCESS = 'INTEGRATE_CUSTOMER_SUCCESS'
export const INTEGRATE_CUSTOMER_FAIL = 'INTEGRATE_CUSTOMER_FAIL'

export const INTEGRATE_ORDER_REQUEST = 'INTEGRATE_ORDER_REQUEST'
export const INTEGRATE_ORDER_SUCCESS = 'INTEGRATE_ORDER_SUCCESS'
export const INTEGRATE_ORDER_FAIL = 'INTEGRATE_ORDER_FAIL'

export const DISINTEGRATE_ORDER_REQUEST = 'DISINTEGRATE_ORDER_REQUEST'
export const DISINTEGRATE_ORDER_SUCCESS = 'DISINTEGRATE_ORDER_SUCCESS'
export const DISINTEGRATE_ORDER_FAIL = 'DISINTEGRATE_ORDER_FAIL'

export const SEND_ORDER_RECEIVED_MAIL_REQUEST = 'SEND_ORDER_RECEIVED_MAIL_REQUEST'
export const SEND_ORDER_RECEIVED_MAIL_SUCCESS = 'SEND_ORDER_RECEIVED_MAIL_SUCCESS'
export const SEND_ORDER_RECEIVED_MAIL_FAIL = 'SEND_ORDER_RECEIVED_MAIL_FAIL'
