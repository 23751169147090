import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_DETAILS_RESET,
} from "../constants/userConstants"

import { USER_LIST_USER_RESET } from "../constants/userConstants"
import { API_BASE_URL } from "../constants/apiBase"
import axios from "axios"

export const login = (email, password) => async (dispatch) => {
  let result
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    const { data } = await axios.post(
      `${API_BASE_URL}/user/login`,
      { email, password },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })
    result = data

    localStorage.setItem("userInfo", JSON.stringify({ accesstoken: data.accesstoken, user: { company: data.user.company, email: data.user.email, role : data.user.role } }))
    localStorage.setItem("firstLogin", true)
  } catch (error) {
    //console.log(error.response.data.msg)
    dispatch(
      {
        type: USER_LOGIN_FAIL,
        payload:error.response.data.msg,
      }
    )
    result = error.response.data
  }

  return result
}

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo")
  localStorage.removeItem("cartItems")
  localStorage.removeItem("shippingAddress")
  localStorage.removeItem("paymentMethod")
  localStorage.removeItem("firstLogin")
  localStorage.removeItem("selectedCart")

  dispatch({
    type: USER_LOGOUT,
  })

  dispatch({
    type: USER_DETAILS_RESET,
  })

  dispatch({
    type: USER_LIST_USER_RESET,
  })
}

export const register =
  (firstname, lastname, email, company, phone, password) =>
  async (dispatch) => {
   let result
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      }

      const { data } = await axios.post(
        `${API_BASE_URL}/user/register`,
        { firstname, lastname, email, company, phone, password },
        config
      )

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      })

     result = data
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error.response.data.msg,
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,
      })
      result = error.response.data
    }

    return result
}

export const getUserDetails = () => async (dispatch, getState) => {
  let result
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
      },
    }

    const { data } = await axios.get(`${API_BASE_URL}/user/info`, config)
    result = data

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error && error.response.data.msg
    })
    result = error.response.data
  }

  return result
}

export const getUserDetailsAdmin = (email) => async (dispatch, getState) => {
  let result
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
      },
    }

    const { data } = await axios.post(`${API_BASE_URL}/user/user/info`, {email}, config)
    result = data

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response.data.msg && error.response && error.response.data.message
          ? error.response.data.msg
          : error.message,
    })
  }

  return result
}

export const updateUserProfile = (stateProfile) => async (dispatch, getState) => {
  let result
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    }

    const { data } = await axios.post(
      `${API_BASE_URL}/user/update`,
      stateProfile,
      config
    )
    result = data

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: error.response.data.msg,
    })
    result = error.response.data
  }

  return result
}

export const listAllUsers = (page, state) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `USER_LIST_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      const { data } = await axios.get(
        `${API_BASE_URL}/user/allUsers?isDeleted=false&isActive=${state.isActive}&company[regex]=${state.company}&email[regex]=${state.email}&page=${page}`,
        config
      )
      result = data

      dispatch({
        type: `USER_LIST_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: `USER_LIST_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  return result
}

export const addUserDiscount = (state) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `USER_PUT_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      const { data } = await axios.post(
          `${API_BASE_URL}/user/user/${state._id}/discount`,
          state,
          config
      )
      result = data

      dispatch({
        type: `USER_PUT_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: `USER_PUT_FAIL`,
        payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
      })
    }
  }

  return result
}

export const deleteUserDiscount = (state) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `USER_PUT_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      const { data } = await axios.post(
          `${API_BASE_URL}/user/user/${state.user_id}/discount/delete/${state.discount_id}`,
          {},
          config
      )
      result = data

      dispatch({
        type: `USER_PUT_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: `USER_PUT_FAIL`,
        payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
      })
    }
  }

  return result
}

export const updateUser = (state) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `USER_PUT_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      const { data } = await axios.post(
        `${API_BASE_URL}/user/user/${state._id}`,
        state,
        config
      )
      result = data

      dispatch({
        type: `USER_PUT_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: `USER_PUT_FAIL`,
        payload:
        error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
      })
    }
  }

  return result
}

export const resetPassword = (id) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      const { data } = await axios.post(
        `${API_BASE_URL}/user/reset/${id}`,
        {},
        config
      )

      result = data
    } catch (error) {
      result = error
    }
  }

  return result
}

export const autVerify = () => async (dispatch, getState) => {
    const user = JSON.parse(localStorage.getItem("userInfo"))

    if (user) {
      const { exp } = JSON.parse(atob(user.accesstoken.split('.')[1]))
      //console.log(new Date(exp * 1000))  //expire time
      if ((exp * 1000) < Date.now()) {
        dispatch(logout())
      }
    }
}
