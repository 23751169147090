import {
  CART_GET_REQUEST,
  CART_GET_SUCCESS,
  CART_GET_FAIL,
  CART_ADD_FAIL,
  CART_ADD_SUCCESS,
  CART_REMOVE_FAIL,
  CART_REMOVE_SUCCESS,
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_LIST_FAIL,
  CART_LIST_REQUEST,
  CART_LIST_RESET,
  CART_LIST_SUCCESS,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_UPDATE_REQUEST,
  CART_UPDATE_FAIL,
  CART_UPDATE_SUCCESS,
} from "../constants/cartConstants";

export const cartReducer = (state = { userCart: {} }, action) => {
  //console.log(action.type)
 // console.log(action.payload)
  switch (action.type) {
    case CART_ADD_ITEM:
      return {
        loading: false,
        userCart: action.payload.userCart,
      };
    case CART_REMOVE_ITEM:
      return {
        loading: false,
        cartItems: action.payload.userCart,
      };

    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    default:
      return state;
  }
};

export const userCartReducer = (state = { userCarts: [] }, action) => {
  //console.log(action.payload)
  switch (action.type) {
    case CART_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CART_GET_SUCCESS:
      return {
        loading: false,
        userCarts: action.payload.userCarts,
      };
    case CART_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CART_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CART_ADD_SUCCESS:
      return {
        loading: false,
        userCarts:[...state, action.payload.newCart],
        msg:action.payload.msg
      };
    case CART_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CART_UPDATE_SUCCESS:
      return {
        loading: false,
        cartItems: action.payload.userCart,
        msg: action.payload.msg,
      };
    case CART_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CART_REMOVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CART_REMOVE_SUCCESS:
      return {
        loading: false,
        msg: action.payload.msg,
        userCarts: state.filter(item=>item._id !== action.payload.deletedCart._id),
      };
    default:
      return state;
  }
};

export const cartListReducer = (state = { cartsAll: [] }, action) => {
  switch (action.type) {
    case CART_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CART_LIST_SUCCESS:
      return {
        loading: false,
        cartsAll: action.payload,
      };
    case CART_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CART_LIST_RESET:
      return {
        cartsAll: [],
      };
    default:
      return state;
  }
};
