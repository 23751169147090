import React, { useEffect } from "react";
// Redux
import { useDispatch } from "react-redux";
// Bootstrap Components
import {
    Tabs, Tab,
} from "react-bootstrap";
import { listItems } from "../actions/sameActions";

import { NotificationList, NotificationListP } from "../components/NotificationList";

const Notification = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Dispatch the list products action and fill our state
            dispatch(listItems("notification"));
      }, [dispatch]);

  return ( 
    <div style={{ maxWidth: "900px", margin: "0 auto" }}>
      <Tabs defaultActiveKey="notification" id="uncontrolled-tab-example" className="mb-3">
  
  <Tab eventKey="notification" title="InProgress">
    <NotificationList />
  </Tab>
  <Tab eventKey="notification_passive" title="Completed">
    <NotificationListP />
  </Tab>
</Tabs>
    </div>
  );
};

export default Notification;
