import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="text-center mb-0 mt-2">
        <div
          className="container-fluid bg-zebra py-1 mb-0 mx-auto text-light mt-4"
          style={{ fontSize: "12px" }}
        >
          {/*By <a href="https://www.linkedin.com/company/eturnsoft/" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>EturnSoft</a>*/}
          By <a href="https://www.zebrablind.co.uk/" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>Zebra Blind</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
