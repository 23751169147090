import {
    WAND_LIST_REQUEST,
    WAND_LIST_SUCCESS,
    WAND_LIST_FAIL,
    WAND_DETAILS_REQUEST,
    WAND_DETAILS_SUCCESS,
    WAND_DETAILS_FAIL,
} from "../constants/wandConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting wands from server
export const wandListReducer = (state = { wands: [] }, action) => {
    //console.log(action.payload)
    switch (action.type) {
        case WAND_LIST_REQUEST:
            return {
                loading: true,
                wands: [],
            };
        case WAND_LIST_SUCCESS:
            return {
                loading: false,
                wands: action.payload,
            };
        case WAND_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case USER_LOGOUT:
            return state;
        default:
            return state;
    }
};

// Get wands details
export const wandDetailsReducer = (
    state = { wand: { reviews: [] } },
    action
) => {
    switch (action.type) {
        case WAND_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case WAND_DETAILS_SUCCESS:
            return {
                loading: false,
                wand: action.payload,
            };
        case WAND_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
