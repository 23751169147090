import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-bs-datatable"
import {
    Button,
    Modal,
    Form,
    Badge,
    InputGroup,
    FormControl,
} from "react-bootstrap"
import {
    updateItems,
    addNewItems,
    listItems,
    deleteItems,
} from "../actions/sameActions"

const getBadge = (status) => {
    switch (status) {
        case true:
            return "success"
        case false:
            return "secondary"
        default:
            return "primary"
    }
}

const getActive = (status) => {
    switch (status) {
        case true:
            return "Active"
        case false:
            return "Inactive"
        default:
            return "Active"
    }
}

const classes = {
    table: "table-striped table-hover table-responsive table-sm",
    theadCol: `
      .table-datatable__root & {
        &.sortable:hover {
          background: pink;
        }
      }
    `,
    tbodyRow: `
      &:nth-of-type(even) {
        background: #eaeaea;
      }
    `,
}

const initialState = {
    blindType: "DayAndNight",
    name: "",
    price: "",
    isActive: true,
}

export const BracketList = () => {
    const dispatch = useDispatch()
    const bracketList = useSelector((store) => store.bracketList.brackets)
    const [state, setState] = useState(bracketList)
    const [selected, setSelected] = useState(initialState)
    const [show, setShow] = useState(false)
    const [isAdding, setIsAdding] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")

    const handleClose = () => setShow(false)
    const handleCloseX = () => {
        setMessage("")
        setError("")
    }

    useEffect(() => {
        setState(bracketList)
    }, [bracketList])

    useEffect(() => {
        dispatch(listItems("bracket"))
    }, [dispatch, isLoading])

    const header = [
        { title: "Blind Type", prop: "blindType" },
        { title: "Name", prop: "name" },
        { title: "Price", prop: "price" },
        { title: "Status", prop: "isActive" },
    ]

    const body =
        state &&
        state.length > 0 &&
        state.map((item) => {
            return {
                blindType: item.blindType.split(/(?=[A-Z])/).join(' '),
                name: item.name,
                price: "£" + item.price,
                isActive: (
                    <Badge bg={getBadge(item.isActive)}>{getActive(item.isActive)}</Badge>
                ),
                id: item._id,
            }
        })

    function onRowClick(data) {
        setIsAdding(false)
        setIsDeleting(false)
        const selected = bracketList && bracketList.find((item) => item._id === data.id)
        setSelected(selected)
        setShow(true)
    }

    function addNewItem() {
        setSelected(initialState)
        setIsAdding(true)
        setShow(true)
    }
    function handleDeleteItem() {
        setIsDeleting(true)
    }

    const handleInput = (e) => {
        if (e.target.name === "isActive") {
            setSelected({ ...selected, [e.target.name]: e.target.checked })
        } else {
            setSelected({ ...selected, [e.target.name]: e.target.value })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!selected.name || !selected.price) {
            setMessage("")
            setError("Name and price fields can not be empty")
            setTimeout(() => {
                setError("")
            }, 3000)
        } else {
            setIsLoading(true)
            dispatch(updateItems("bracket", selected)).then((res) => {
                if (res.status === 200) {
                    setError("")
                    setMessage(res.msg)
                    setShow(false)
                    setTimeout(() => {
                        setMessage("")
                        setIsLoading(false)
                    }, 3000)
                }
                else {
                    setMessage("")
                    setError(res.msg)
                    setTimeout(() => {
                        setError("")
                        setIsLoading(false)
                    }, 3000)
                }
            })
        }
    }

    const handleSubmitNew = (e) => {
        e.preventDefault()
        if (!selected.name || !selected.price) {
            setMessage("")
            setError("Name and price fields can not be empty")
            setTimeout(() => {
                setError("")
            }, 3000)
        } else {
            setIsLoading(true)
            dispatch(addNewItems("bracket", selected)).then((res) => {
                if (res.status === 200) {
                    setError("")
                    setMessage(res.msg)
                    setShow(false)
                    setIsAdding(false)
                    setTimeout(() => {
                        setMessage("")
                        setIsLoading(false)
                    }, 3000)
                }
                else {
                    setMessage("")
                    setError(res.msg)
                    setTimeout(() => {
                        setError("")
                        setIsLoading(false)
                    }, 3000)
                }
            })
        }
    }

    const handleSubmitDelete = (e) => {
        e.preventDefault()
        setIsLoading(true)
        dispatch(deleteItems("bracket", selected._id)).then((res) => {
            if (res.status === 200) {
                setError("")
                setMessage(res.msg)
                setShow(false)
                setIsDeleting(false)
                setTimeout(() => {
                    setMessage("")
                    setIsLoading(false)
                }, 2000)
            }
            else {
                setMessage("")
                setError(res.msg)
                setIsDeleting(false)
                setTimeout(() => {
                    setError("")
                    setIsLoading(false)
                }, 3000)
            }
        })
    }

    return (
        <div style={{ overflowX: "auto" }}>
            <div className="d-flex text-center">
                <Button
                    variant="primary"
                    type="button"
                    onClick={addNewItem}
                >
                    Add New Bracket
                </Button>
            </div>
            {state && state.length > 0 && (
                <Datatable
                    tableHeaders={header}
                    tableBody={body}
                    classes={classes}
                    onRowClick={(item) => onRowClick(item)}
                />
            )}
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    {isAdding ? (
                        <Modal.Title>Add New Bracket</Modal.Title>
                    ) : (
                        <Modal.Title>Bracket Update</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-bracket-blindType-sm"
                            >
                                Blind Type
                            </InputGroup.Text>
                            <Form.Select
                                className="select"
                                size="sm"
                                defaultValue={selected.blindType}
                                name="blindType"
                                id="blindType"
                                onChange={handleInput}
                            >
                                <option value="DayAndNight">Day And Night</option>
                                <option value="Vellision">Vellision</option>
                                <option value="Venetian">Venetian</option>
                                <option value="Vertical">Vertical</option>
                            </Form.Select>
                        </InputGroup>

                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-bracket-price-sm"
                            >
                                Bracket Name
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                placeholder="Enter Name"
                                name="name"
                                defaultValue={selected.name}
                                onChange={handleInput}
                            />
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-bracket-price-sm"
                            >
                                Price
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                placeholder="Enter Price"
                                name="price"
                                min="0.00"
                                max="10000.00"
                                step="0.01"
                                defaultValue={selected.price}
                                onChange={handleInput}
                            />
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <Form.Check
                                type="switch"
                                id="isActive"
                                label="Status"
                                name="isActive"
                                defaultChecked={selected.isActive}
                                onChange={handleInput}
                            />
                        </InputGroup>
                    </Form>
                    {error && (
                        <div className="alert alert-warning mt-2" role="alert">
                            {error}
                        </div>
                    )}
                </Modal.Body>
                {!isAdding && isDeleting && (
                    <Modal.Footer className="d-flex justify-content-center">
                        <div>
                            <Button size="sm" variant="danger" onClick={handleSubmitDelete}>
                                I'm sure I want to delete the item
                            </Button>
                        </div>
                    </Modal.Footer>
                )}
                <Modal.Footer className="d-flex justify-content-between">
                    <div>
                        {!isAdding && (
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={handleDeleteItem}
                                disabled={message || error}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button size="sm" variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        {isAdding ? (
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={handleSubmitNew}
                                disabled={message || error}
                            >
                                Save Bracket
                            </Button>
                        ) : (
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={message || error}
                            >
                                Update Bracket
                            </Button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={!!message} onHide={handleCloseX} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message && (
                        <div className="alert alert-success mt-2" role="alert">
                            {message}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}
