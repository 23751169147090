import axios from "axios"
import { API_BASE_URL } from "../constants/apiBase"

export const deactivateDiscount = (state) => async (dispatch, getState) => {
    let result
    const {
        userLogin: { userInfo },
    } = getState()
    if (userInfo) {
        try {
            dispatch({
                type: `DISCOUNT_PUT_REQUEST`,
            })
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${userInfo.accesstoken}`,
                },
            }

            //Make request to server and get the response data
            const { data } = await axios.post(
                `${API_BASE_URL}/api/discount/deactivate/${state._id}`,
                state,
                config
            )

            result = data
            dispatch({
                type: `DISCOUNT_PUT_SUCCESS`,
                payload: data,
            })
        } catch (error) {
            //console.log(error)

            result = error.response && error.response.data ? error.response.data : { status: 500, msg: "Something went wrong!" }

            dispatch({
                type: `DISCOUNT_PUT_FAIL`,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
    return result
}

export const listAllDiscounts = (state) => async (dispatch, getState) => {
    let result
    const {
        userLogin: { userInfo },
    } = getState()
    if (userInfo) {
        try {
            dispatch({
                type: `DISCOUNT_LIST_REQUEST`,
            })
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${userInfo.accesstoken}`,
                },
            }

            const { data } = await axios.get(
                // `${API_BASE_URL}/api/discount?page=${state.currentPage}`,
                `${API_BASE_URL}/api/discount?isDeleted=false&page=${state.currentPage}`,
                config
            )
            //console.log(data)
            dispatch({
                type: `DISCOUNT_LIST_SUCCESS`,
                payload: data,
            })
            result = data
        } catch (error) {
            dispatch({
                type: `DISCOUNT_LIST_FAIL`,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
    return result
}
