import { SET_MESSAGE, CLEAR_MESSAGE } from "../constants/messageConstants";

const initialState = '';

export const messageReducer = (state = initialState, action) => {
  // const { type, payload } = action;
//console.log(action.payload)
  switch (action.type) {
    case SET_MESSAGE:
      return action.payload;

    case CLEAR_MESSAGE:
      return null;

    default:
      return state;
  }
};
