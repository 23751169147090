import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"

// Redux
import { useDispatch, useSelector } from "react-redux"
// Bootstrap Components
import {
    Badge,
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    ListGroup,
    Modal,
    Row,
    Spinner,
    Table,
} from "react-bootstrap"
import {
    listAllUsers,
    updateUser,
    resetPassword,
} from "../actions/userActions"
import { getAddresses } from "../actions/addressAction"

import Pagination from "../components/Pagination"
import { listCompanyNames } from "../actions/orderActions"

import { getCustomer, integrateCustomer } from "../actions/apiActions"
import { listItems } from "../actions/sameActions"

import { capitalizeFirstLettersInString, isValidEmail } from "../utils/common"

const getBadge = (status) => {
    switch (status) {
        case true:
            return "success"
        case false:
            return "secondary"
        default:
            return "primary"
    }
}
const getActive = (status) => {
    switch (status) {
        case true:
            return "Active"
        case false:
            return "Inactive"
        default:
            return "Active"
    }
}

export default function UserDetails() {
    const dispatch = useDispatch()
    const { users } = useSelector((store) => store.userList)
    const [state, setstate] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [selected, setSelected] = useState({})
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isIntegrating, setIsIntegrating] = useState(false)
    const [filteredValue, setFilteredValue] = useState({
        company: "",
        email: "",
        isActive: true,
    })
    const { addresses } = useSelector((store) => store.userAddresses)
    const [message, setMessage] = useState("")
    const [messageType, setMessageType] = useState("info")
    const [messageDetail, setMessageDetail] = useState("")
    const [error, setError] = useState("")
    //const isTablet = useMediaQuery({ query: "(min-width: 768px)" })
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
    const [companyNames, setCompanyNames] = useState([])


    useEffect(() => {
        dispatch(getAddresses())
    }, [dispatch])


    useEffect(() => {
        dispatch(listCompanyNames()).then((res) => {
            if (res.status === 200) {
                setCompanyNames(res.users)
            }
        })
    }, [dispatch])

    const handleClose = () => setShow(false)
    const handleCloseX = () => setMessage("")

    useEffect(() => {
        dispatch(listAllUsers(currentPage, filteredValue))
    }, [
        dispatch,
        currentPage,
        isLoading,
        filteredValue,
        filteredValue.company,
        filteredValue.email,
        filteredValue.isActive,
    ])

    useEffect(() => {
        if (users && users.status === "success") {
            setstate(users.users)
            setTotalItems(users.total)
        }
    }, [users])

    function findAddress(id) {
        const selectedAddress =
            addresses &&
            addresses
                .filter((item) => item.user_id === id)
                .map((elem) => {
                    return `${elem.address_name} : ${elem.address_line1} ${
                        elem.address_line2
                    } ${elem.city} ${elem.postal_code} ${elem.country.toUpperCase()}`
                })
        if (selectedAddress) {
            return selectedAddress
        }
    }

    const onChangePage = (pageNum) => {
        setCurrentPage(pageNum)
    }

    const onFilterPageClear = (e) => {
        setFilteredValue({ ...filteredValue, company: "", email: "" })
        document.querySelector("#emailSelectControl").value = ""
        document.querySelector("#companySelectControl").value = ""
    }

    function onFilterValue(e) {
        if (e.target.name === "company") {
            setFilteredValue({
                ...filteredValue,
                company: e.target.value,
                email: "",
            })
            document.querySelector("#emailSelectControl").value = ""
        } else if (e.target.name === "email") {
            setFilteredValue({
                ...filteredValue,
                email: e.target.value,
                company: "",
            })
            document.querySelector("#companySelectControl").value = ""
        } else {
            setFilteredValue({ ...filteredValue, isActive: e.target.value })
        }
        setCurrentPage(1)
    }

    function onRowClick(id) {
        const selected =
            users && users.users && users.users.find((item) => item._id === id)
        setSelected(selected)
        setShow(true)
    }

    const handleInput = (e) => {
        if (e.target.name === "isActive") {
            setSelected({ ...selected, [e.target.name]: e.target.checked })
        } else {
            setSelected({ ...selected, [e.target.name]: e.target.value })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        dispatch(updateUser(selected)).then((res) => {
            if (res.status === 200) {
                setShow(false)
                setMessage(res.msg)
                setTimeout(() => {
                    setMessage("")
                    setIsLoading(false)
                }, 2000)
            }
        })
    }

    const handleResetPassword = () => {
        setIsLoading(true)
        dispatch(resetPassword(selected._id)).then((res) => {
            if (res.status === 200) {
                setShow(false)
                setMessage(res.msg)
                setTimeout(() => {
                    setMessage("")
                    setIsLoading(false)
                }, 2000)
            }
        })
    }

    const handleIntegrateUser = async () => {
        try {
            setIsIntegrating(true)
            setIsLoading(true)

            if (!isValidEmail(selected.email))
                throw new Error(`${selected.email} is not a valid email address`)

            let customer_id

            const getCustomerResponse = await dispatch(getCustomer(selected.email))

            if (getCustomerResponse.status === 200)
                customer_id = getCustomerResponse.data.id.toString()

            if (customer_id) {
                const updateUserResponse = await dispatch(updateUser({ ...selected, isIntegrated: true }))

                if (updateUserResponse.status === 200) {
                    setError("")
                    setMessage("The customer has been integrated successfully")
                    setMessageDetail(getCustomerResponse.data ? "User: " + getCustomerResponse.data.id : "")
                    setTimeout(() => {
                        setShow(false)
                        setMessage("")
                        setMessageDetail("")
                        setIsIntegrating(false)
                        setIsLoading(false)
                    }, 3000)
                }
                else {
                    setMessage("")
                    setError(updateUserResponse.msg)
                    setTimeout(() => {
                        setError("")
                        setIsIntegrating(false)
                        setIsLoading(false)
                    }, 3000)
                }
            }
            else {
                const listItemsResponse = await dispatch(listItems("address", { user_id: selected._id, isActive: true, isDeleted: false }))

                let requestBody = {
                    name: capitalizeFirstLettersInString(selected.company),
                    email: selected.email,
                    country: listItemsResponse.status === "success" && listItemsResponse.addresses.length ? listItemsResponse.addresses[0].country : null,
                    city: listItemsResponse.status === "success" && listItemsResponse.addresses.length ? listItemsResponse.addresses[0].city : null,
                    postcode: listItemsResponse.status === "success" && listItemsResponse.addresses.length ? listItemsResponse.addresses[0].postal_code : null,
                    number: selected.phone ? String(selected.phone).padStart(11, '0') : null,
                    integration_id: selected._id,
                    integrated_from: "Portal",
                }

                const integrateCustomerResponse = await dispatch(integrateCustomer(requestBody))

                if (integrateCustomerResponse.status === 201) {
                    const updateUserResponse = await dispatch(updateUser({ ...selected, isIntegrated: true }))

                    if (updateUserResponse.status === 200) {
                        setError("")
                        setMessage(integrateCustomerResponse.message)
                        setMessageDetail(integrateCustomerResponse.data ? "User: " + integrateCustomerResponse.data.id : "")
                        setTimeout(() => {
                            setShow(false)
                            setMessage("")
                            setMessageDetail("")
                            setIsIntegrating(false)
                            setIsLoading(false)
                        }, 3000)
                    }
                    else {
                        setMessage("")
                        setError(updateUserResponse.msg)
                        setTimeout(() => {
                            setError("")
                            setIsIntegrating(false)
                            setIsLoading(false)
                        }, 3000)
                    }
                }
                else {
                    throw new Error("ERROR: " + integrateCustomerResponse.message)
                }
            }
        }
        catch (error) {
            console.log('error', error)
            setError("")
            setMessage(error.message)
            setMessageType("error")
            setTimeout(() => {
                setMessage("")
                setMessageType("info")
                setIsIntegrating(false)
                setIsLoading(false)
            }, 5000)
        }
    }

    return (
        <div style={{ maxWidth: "850px", margin: "0 auto" }}>
            <Col lg={6}>
                <InputGroup className="mb-2">
                    <FormControl
                        id="companySelectControl"
                        type="text"
                        placeholder="Select Company"
                        name="company"
                        list="companySelect"
                        size="sm"
                        onChange={onFilterValue}
                    />
                    <datalist id="companySelect">
                        {companyNames &&
                        companyNames.map((item, index) => {
                            return (
                                <option key={index} value={item.company}>
                                    {item.company}
                                </option>
                            )
                        })}
                    </datalist>
                    <FormControl
                        id="emailSelectControl"
                        type="text"
                        placeholder="Select Email"
                        name="email"
                        list="emailSelect"
                        onChange={onFilterValue}
                        size="sm"
                    />
                    <datalist id="emailSelect">
                        {companyNames &&
                        companyNames.map((item, index) => {
                            return (
                                <option key={index} value={item.email}>
                                    {item.email}
                                </option>
                            )
                        })}
                    </datalist>
                    <InputGroup.Text
                        onClick={onFilterPageClear}
                        style={{ cursor: "pointer" }}
                        title="Clear"
                    >
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </InputGroup.Text>
                </InputGroup>
            </Col>
            <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                onChangePage={onChangePage}
            />
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th>Company</th>
                    <th>Email</th>
                    {!isMobile && (
                        <>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Register</th>
                        </>
                    )}
                    <th>
                        <select
                            name="isActive"
                            style={{ fontWeight: "700", border: "none" }}
                            onChange={onFilterValue}
                        >
                            <option value="true">Active</option>
                            <option value="false">Passive</option>
                        </select>
                    </th>
                </tr>
                </thead>
                <tbody>
                {state ? (
                    state.map((item, index) => {
                        return (
                            <tr key={item._id} onClick={() => onRowClick(item._id)}>
                                <td>{item.company}</td>
                                <td>{item.email}</td>
                                {!isMobile && (
                                    <>
                                        <td>{item.firstname}</td>
                                        <td>{item.firstname}</td>
                                        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                    </>
                                )}
                                <td>
                                    <Badge bg={getBadge(item.isActive)}>
                                        {getActive(item.isActive)}
                                    </Badge>
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <></>
                )}
                </tbody>
            </Table>
            <Modal
                size={isMobile ? "sm" : "lg"}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{selected.company} Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Name</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    {selected.firstname} {selected.lastname}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Contact</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Phone: {selected.phone}</ListGroup.Item>
                                <ListGroup.Item>Email: {selected.email}</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Shipping Address</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush">
                                {findAddress(selected._id).map((item, index) => {
                                    return <ListGroup.Item key={index}>{item}</ListGroup.Item>
                                })}
                            </ListGroup>
                        </Col>
                        <Col xs={6} sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Discount</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col xs={6} sm={9}>
                            <InputGroup>
                                <InputGroup.Text id="inputGroup-pelmeted-sm">%</InputGroup.Text>
                                <FormControl
                                    type="number"
                                    placeholder="Enter discount"
                                    name="user_discount"
                                    min="0.0"
                                    max="100.0"
                                    step="0.1"
                                    defaultValue={selected.user_discount}
                                    onChange={handleInput}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs={6} sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>is Active</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col xs={6} sm={9} className="align-self-center">
                            <Form.Switch>
                                <Form.Check
                                    size="lg"
                                    type="switch"
                                    id="isActive"
                                    name="isActive"
                                    defaultChecked={selected.isActive}
                                    style={{ cursor: "pointer" }}
                                    onChange={handleInput}
                                />
                            </Form.Switch>
                        </Col>
                    </Row>
                    {error && (
                        <div className="alert alert-warning mt-2" role="alert">
                            {error}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <Button
                            className="p-1"
                            size={isMobile && "sm"}
                            variant="danger"
                            onClick={handleResetPassword}
                            disabled={message}
                        >
                            Reset Password
                        </Button>
                        {!selected.isIntegrated ? (
                            <Button
                                size={isMobile && "sm"}
                                variant="info"
                                onClick={handleIntegrateUser}
                                className="ms-2"
                                disabled={message || error || isLoading}
                            >
                                {isIntegrating && (
                                    <Spinner animation="border" size="sm" variant="dark" />
                                )}
                                Integrate
                            </Button>
                        ) : null}
                    </div>
                    <div>
                        <Button
                            className="p-1"
                            size={isMobile && "sm"}
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="p-1"
                            size={isMobile && "sm"}
                            variant="primary"
                            onClick={handleSubmit}
                            disabled={message}
                        >
                            Update User
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={!!message} onHide={handleCloseX} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{messageType === "info" ? "Info" : "Error"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message && (
                        <div className={`alert alert-${messageType === "info" ? `success` : `danger`} mt-2`} role="alert">
                            {message}
                            {messageDetail && <><br /><span>{messageDetail}</span></>}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}
