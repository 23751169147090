import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { listCompanyNames } from "../actions/orderActions"
import { Button, Col, Form, FormControl, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap"
import { getUserDetailsAdmin, addUserDiscount, deleteUserDiscount } from "../actions/userActions"
import { useMediaQuery } from "react-responsive"
import { listItems } from "../actions/sameActions"
import { removeDuplicatesFromArrayOfObjects } from "../utils/common"

const types = Object.freeze({
    String: 'string',
    Number: 'number',
})

const conditions = [
    {
        type: types.String,
        values: [
            {
                id: 'oneOf',
                name: 'One Of',
            },
        ],
    },
    {
        type: types.Number,
        values: [
            {
                id: 'equals',
                name: 'Equals',
            },
            {
                id: 'greaterThan',
                name: 'Greater Than',
            },
            {
                id: 'greaterThanOrEqual',
                name: 'Greater Than or Equal',
            },
            {
                id: 'smallerThan',
                name: 'Smaller Than',
            },
            {
                id: 'smallerThanOrEqual',
                name: 'Smaller Than or Equal',
            },
        ],
    },
]

const discounts = [
    {
        id: 'blindType',
        name: 'Blind Type',
        type: types.String,
        conditions: conditions.find(c => c.type === types.String).values,
        values: [
            {
                id: 'DayAndNight',
                name: 'Day And Night',
            },
            {
                id: 'Vellision',
                name: 'Vellision',
            },
            {
                id: 'Pleated',
                name: 'Pleated',
            },
            {
                id: 'Venetian',
                name: 'Venetian',
            },
            {
                id: 'Vertical',
                name: 'Vertical',
            },
        ],
        disabled: false,
    },
    {
        id: 'headrailType',
        name: 'Headrail Type',
        type: types.String,
        conditions: conditions.find(c => c.type === types.String).values,
        values: [
            {
                id: 'Single',
                name: 'Single',
            },
            {
                id: 'Double',
                name: 'Double',
            },
        ],
        disabled: false,
    },
    {
        id: 'width',
        name: 'Width',
        type: types.Number,
        conditions: conditions.find(c => c.type === types.Number).values,
        values: [],
        disabled: false,
    },
    {
        id: 'drop',
        name: 'Drop',
        type: types.Number,
        conditions: conditions.find(c => c.type === types.Number).values,
        values: [],
        disabled: false,
    },
    {
        id: 'fabric',
        name: 'Fabric',
        type: types.String,
        conditions: conditions.find(c => c.type === types.String).values,
        values: [],
        disabled: false,
    },
    {
        id: 'tassel',
        name: 'Tassel',
        type: types.String,
        conditions: conditions.find(c => c.type === types.String).values,
        values: [],
        disabled: false,
    },
    {
        id: 'headrail',
        name: 'Headrail',
        type: types.String,
        conditions: conditions.find(c => c.type === types.String).values,
        values: [],
        disabled: false,
    },
    {
        id: 'fitting',
        name: 'Fitting',
        type: types.String,
        conditions: conditions.find(c => c.type === types.String).values,
        values: [],
        disabled: false,
    },
    {
        id: 'motor',
        name: 'Motor',
        type: types.String,
        conditions: conditions.find(c => c.type === types.String).values,
        values: [],
        disabled: false,
    },
]

const initialState = {
    id: discounts[0].id,
    type: discounts[0].type,
    condition: discounts[0].conditions[0].id,
    values: [],
    discount: "",
    isNegative: false,
}

export default function UserDiscounts() {
    const dispatch = useDispatch()

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState({})

    const [userDiscounts, setUserDiscounts] = useState([])

    const [selected, setSelected] = useState(initialState)

    const [isLoading, setIsLoading] = useState(false)

    const [message, setMessage] = useState("")
    const [error, setError] = useState("")

    const [warnValues, setWarnValues] = useState("")
    const [warnDiscount, setWarnDiscount] = useState("")

    const [show, setShow] = useState(false)

    const isMobile = useMediaQuery({ query: "(max-width: 767px)" })

    useEffect(() => {
        dispatch(listItems("product")).then((res) => { if (res.products) discounts.find(d => d.id === "fabric").values = res.products.filter(i => i.isActive === true && i.isDeleted === false).sort((a, b) => a.fabric.localeCompare(b.fabric)).map(i => ({ name: i.fabric, value: i.fabric })) })

        dispatch(listItems("tassel")).then(tassels => { if (tassels) discounts.find(d => d.id === "tassel").values = tassels.filter(i => i.isActive === true && i.isDeleted === false).map(i => ({ name: i.name, value: i.name })) })
        dispatch(listItems("headrail")).then(headrails => { if (headrails) discounts.find(d => d.id === "headrail").values = removeDuplicatesFromArrayOfObjects(headrails, 'name').filter(i => i.isActive === true && i.isDeleted === false).map(i => ({ name: i.name, value: i.name })) })
        dispatch(listItems("fitting")).then(fittings => { if (fittings) discounts.find(d => d.id === "fitting").values = fittings.filter(i => i.isActive === true && i.isDeleted === false).map(i => ({ name: i.name, value: i.name })) })
        dispatch(listItems("motor")).then(motors => { if (motors) discounts.find(d => d.id === "motor").values = motors.filter(i => i.isActive === true && i.isDeleted === false).map(i => ({ name: i.name, value: i.name })) })

        dispatch(listCompanyNames()).then((res) => {
            if (res.status === 200) {
                const users = res.users.sort((a, b) => a.company.localeCompare(b.company))

                setUsers(users)

                setSelectedUser(users[0])
            }
        })
    }, [dispatch])

    useEffect(() => {
        if (selectedUser && selectedUser._id) {
            dispatch(getUserDetailsAdmin(selectedUser.email)).then((res) => {
                if (res.status === 200) {
                    const userDiscounts = res.user.userDiscounts

                    setUserDiscounts(userDiscounts)
                }
            })
        }
    }, [dispatch, selectedUser, isLoading])

    useEffect(() => {
        let defaultDiscountIndex = NaN
        if (userDiscounts.length) {
            for (let i = 0; i < discounts.length; i++) {

                if (userDiscounts.findIndex(ud => ud.id === discounts[i].id) > -1) {
                    discounts[i].disabled = true
                }
                else {
                    discounts[i].disabled = false
                    if (isNaN(defaultDiscountIndex)) {
                        defaultDiscountIndex = i
                    }
                }
            }

            initialState.id = discounts[defaultDiscountIndex].id
            initialState.type = discounts[defaultDiscountIndex].type
            initialState.condition = discounts[defaultDiscountIndex].conditions[0].id
        }
        else {
            for (let i = 0; i < discounts.length; i++) {
                discounts[i].disabled = false
            }
            initialState.id = discounts[0].id
            initialState.type = discounts[0].type
            initialState.condition = discounts[0].conditions[0].id
        }
    }, [userDiscounts])

    const handleUserChange = e => {
        const user = users.find(c => c._id === e.target.value)

        setSelectedUser(user)
    }

    const handleOpenDiscountModal = () => {
        setSelected(initialState)
        setWarnValues("")
        setWarnDiscount("")
        setMessage("")
        setError("")
        setShow(true)
    }

    const handleCloseDiscountModal = () => setShow(false)

    const handleCloseInfoModal = () => {
        setMessage("")
        setError("")
    }

    const handleNumberPaste = e => e.preventDefault()

    const handleNumberInput = (e, max = 100.00) => {
        if (Array.from(e.target.value.toString()).length > 1 && Array.from(e.target.value.toString())[0] === "0") {
            if (Array.from(e.target.value.toString().substring(1))[0] === '.')
                e.target.value = "0" + e.target.value.toString().substring(1)
            else
                e.target.value = e.target.value.toString().substring(1)
        }

        if (e.target.value.toString().indexOf('.') > -1) {
            if (e.target.value.toString().split('.')[1].length > 2) {
                // e.target.value = e.target.value.toString().substring(0, e.target.value.toString().length - 1)
                const wholeNumberPart = e.target.value.toString().split('.')[0]
                const decimalPart = e.target.value.toString().split('.')[1].substring(0, 2)

                e.target.value = wholeNumberPart.padStart(1, '0') + '.' + decimalPart
            }
            else if (Array.from(e.target.value.toString())[0] === '.')
                e.target.value = "0" + e.target.value.toString()
        }

        if (isNaN(parseFloat(e.target.value)) === false && parseFloat(e.target.value) > max)
            e.target.value = max
    }

    const handleNumberKeyPress = e => {
        if (!/[0-9.]/.test(e.key))
            e.preventDefault()

        if (e.target.value.toString() === "" && e.key === '.')
            e.preventDefault()
    }

    const handleInput = e => {
        if (e.target.name === "id") {
            const discount = discounts.find(d => d.id === e.target.value)

            setSelected({
                id: discount.id,
                type: discount.type,
                condition: discount.conditions[0].id,
                values: [],
                discount: "",
                isNegative: false,
            })
        }
        else if (e.target.name === "condition") {
            setSelected({
                ...selected,
                condition: e.target.value,
            })
        }
        else if (e.target.name === "values") {
            let values

            if (discounts.find(d => d.id === selected.id).type === types.String)
                values = [].slice.call(e.target.selectedOptions).map(item => item.value)
            else if (discounts.find(d => d.id === selected.id).type === types.Number)
                values = [e.target.value]

            if (values.length === 0 || (values.length === 1 && !values[0])) {
                if (discounts.find(d => d.id === selected.id).type === types.String)
                    setWarnValues("Select at least one value.")
                else if (discounts.find(d => d.id === selected.id).type === types.Number)
                    setWarnValues("Enter value between 0 and 10000.")
            }
            else setWarnValues("")

            setSelected({
                ...selected,
                values: values,
            })
        }
        else if (e.target.name === "discount") {
            if (!e.target.value)
                setWarnDiscount("Enter value between 0 and 100.")
            else setWarnDiscount("")

            setSelected({
                ...selected,
                discount: e.target.value,
            })
        }
        else if (e.target.name === "isNegative") {
            setSelected({
                ...selected,
                isNegative: e.target.checked
            })
        }
    }

    const handleAddDiscount = e => {
        e.preventDefault()

        if ((selected.values.length === 0 || (selected.values.length === 1 && !selected.values[0])) || !selected.discount) {
            if (selected.values.length === 0 || (selected.values.length === 1 && !selected.values[0])) {
                if (discounts.find(d => d.id === selected.id).type === types.String)
                    setWarnValues("Select at least one value.")
                else if (discounts.find(d => d.id === selected.id).type === types.Number)
                    setWarnValues("Enter value between 0 and 10000.")
            }
            else
                setWarnValues("")

            if (!selected.discount)
                setWarnDiscount("Enter value between 0 and 100.")
            else
                setWarnDiscount("")

            setMessage("")
            setError("Values and discount fields can not be empty")
            setTimeout(() => {
                setError("")
            }, 2000)
        }
        else {
            setIsLoading(true)

            if (selected.isNegative) {
                selected.discount = (parseFloat(selected.discount) * -1).toString()
            }

            let state = {
                _id: selectedUser._id,
                userDiscounts: selected,
            }

            dispatch(addUserDiscount(state))
                .then(res => {
                    if (res.status === 200) {
                        setMessage(res.msg)
                        setError("")
                        setShow(false)

                        setTimeout(() => {
                            setMessage("")
                            setIsLoading(false)
                        }, 2000)
                    }
                })
        }
    }

    const handleDeleteDiscount = (e, id) => {
        let obj = {
            user_id: selectedUser._id,
            discount_id: id,
        }

        setIsLoading(true)
        dispatch(deleteUserDiscount(obj))
            .then(res => {
                if (res.status === 200) {
                    setMessage(res.msg)
                    setError("")

                    setTimeout(() => {
                        setMessage("")
                        setIsLoading(false)
                    }, 2000)
                }
            })
    }

    return (
        <div style={{ maxWidth: "850px", margin: "0 auto" }}>
            <Modal
                show={show}
                onHide={handleCloseDiscountModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-user-discount-id-sm"
                            >
                                Name
                            </InputGroup.Text>
                            <Form.Select
                                className="select"
                                size="sm"
                                style={{ minWidth: "180px" }}
                                value={selected.id}
                                name="id"
                                id="id"
                                onChange={handleInput}
                            >
                                {discounts.map((item, index) => (
                                    <option key={index} value={item.id} disabled={item.disabled}>{item.name}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-user-discount-condition-sm"
                            >
                                Condition
                            </InputGroup.Text>
                            <Form.Select
                                className="select"
                                size="sm"
                                style={{ minWidth: "180px" }}
                                value={selected.condition}
                                name="condition"
                                id="condition"
                                onChange={handleInput}
                            >
                                {discounts.find(d => d.id === selected.id).conditions.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                        {discounts.find(d => d.id === selected.id).type === types.Number && (
                            <div className="mb-3">
                                <InputGroup size="sm">
                                    <InputGroup.Text
                                        style={{ width: "120px" }}
                                        id="inputGroup-user-discount-values-sm"
                                    >
                                        Values
                                    </InputGroup.Text>
                                    <FormControl
                                        type="number"
                                        placeholder="Enter Value"
                                        style={{ minWidth: "180px", borderColor: `${warnValues && "red"}` }}
                                        name="values"
                                        id="values"
                                        min="0.00"
                                        max="10000.00"
                                        step="0.01"
                                        value={selected.values[0] || ""}
                                        onChange={handleInput}
                                        onPaste={handleNumberPaste}
                                        onInput={(e) => handleNumberInput(e, 10000.00)}
                                        onKeyPress={handleNumberKeyPress}
                                        pattern="[0-9.]+"
                                    />
                                </InputGroup>
                                {warnValues && (
                                    <Form.Text type="invalid" style={{ color: "red" }}>
                                        {warnValues}
                                    </Form.Text>
                                )}
                            </div>
                        )}

                        {discounts.find(d => d.id === selected.id).type === types.String && (
                            <div className="mb-3 ">
                                <InputGroup size="sm">
                                    <InputGroup.Text
                                        style={{ width: "120px" }}
                                        id="inputGroup-user-discount-values-sm"
                                    >
                                        Values
                                    </InputGroup.Text>
                                    <Form.Control
                                        name="values"
                                        id="values"
                                        as="select"
                                        style={{ minWidth: "180px", borderColor: `${warnValues && "red"}` }}
                                        multiple
                                        value={selected.values}
                                        onChange={handleInput}
                                    >
                                        {discounts.find(d => d.id === selected.id).values.map((item, index) => (
                                            <option key={item.name} value={item.value}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                                {warnValues && (
                                    <Form.Text type="invalid" style={{ color: "red" }}>
                                        {warnValues}
                                    </Form.Text>
                                )}
                            </div>
                        )}

                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-user-discount-discount-sm"
                            >
                                Discount
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                placeholder="Enter Value"
                                style={{ minWidth: "180px", borderColor: `${warnDiscount && "red"}` }}
                                name="discount"
                                id="discount"
                                min="0.00"
                                max="100.00"
                                step="0.25"
                                value={selected.discount}
                                onChange={handleInput}
                                onPaste={handleNumberPaste}
                                onInput={handleNumberInput}
                                onKeyPress={handleNumberKeyPress}
                                pattern="[0-9.]+"
                            />
                            {warnDiscount && (
                                <Form.Text type="invalid" style={{ color: "red" }}>
                                    {warnDiscount}
                                </Form.Text>
                            )}
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3 justify-content-end">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="button-tooltip-2">
                                        There will be a negative discount (markup) if this option is checked
                                    </Tooltip>
                                }
                            >
                                {({ ref, ...triggerHandler }) => (
                                    <Form.Check
                                        type="switch"
                                        {...triggerHandler}
                                        ref={ref}
                                        id="isNegative"
                                        label="Is Negative"
                                        name="isNegative"
                                        checked={selected.isNegative}
                                        onChange={handleInput}
                                    />
                                )}
                            </OverlayTrigger>
                        </InputGroup>

                    </Form>
                    {error && (
                        <div className="alert alert-warning mt-2" role="alert">
                            {error}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button
                        size="sm"
                        variant="primary"
                        onClick={handleAddDiscount}
                        disabled={message || error}
                    >
                        Save Discount
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={!!message} onHide={handleCloseInfoModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message && (
                        <div className="alert alert-success mt-2" role="alert">
                            {message}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <Row className="mb-2">
                <Col className="align-self-end" sm={6}>
                    <Form.Group
                        className="mb-2 mb-sm-0 col-sm-12"
                        controlId="formGridUser"
                    >
                        <Form.Select
                            className="select"
                            size="sm"
                            value={selectedUser._id}
                            name="user"
                            id="user"
                            onChange={handleUserChange}
                            disabled={isLoading}
                        >
                            {
                                users &&
                                users.map((item, index) => (
                                        <option key={index} value={item._id}>
                                            {item.company}
                                        </option>
                                    )
                                )
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="align-self-end text-center text-sm-end" sm={6}>
                    <Button variant="primary" type="button" disabled={isLoading} onClick={handleOpenDiscountModal}>
                        Add New Discount
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    {isMobile ? (
                        <th>Match</th>
                    ) : (
                        <>
                            <th>Name</th>
                            <th>Condition</th>
                            <th>Value</th>
                        </>
                    )}
                    <th>Discount</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    userDiscounts.length ? (
                        userDiscounts.map((item, index) => (
                            <tr key={index}>
                                {isMobile ? (
                                    <td><span className="text-info">if</span> <span className="text-primary">{item.id}</span> <span className="text-success">{item.condition}</span> <span className="fw-bold">{item.values.join(',')}</span></td>
                                ) : (
                                    <>
                                        <td>{item.id}</td>
                                        <td>{item.condition}</td>
                                        <td>{item.values.join(',')}</td>
                                    </>
                                )}
                                <td>{item.discount}</td>
                                <td className="text-center">
                                    <i style={{ cursor: 'pointer' }} className="fa fa-lg fa-minus-circle text-danger" onClick={(e) => handleDeleteDiscount(e, item._id)} />
                                </td>
                            </tr>
                        ))
                    ) : null
                }
                </tbody>
            </Table>
        </div>
    )
}
