export const ORDER_READY_REQUEST = 'ORDER_READY_REQUEST'

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_LIST_USER_REQUEST = 'ORDER_LIST_USER_REQUEST'
export const ORDER_LIST_USER_SUCCESS = 'ORDER_LIST_USER_SUCCESS'
export const ORDER_LIST_USER_FAIL = 'ORDER_LIST_USER_FAIL'
export const ORDER_LIST_USER_RESET = 'ORDER_LIST_USER_REST'

export const ORDER_LIST_COMPLETED_REQUEST = 'ORDER_LIST_COMPLETED_REQUEST'
export const ORDER_LIST_COMPLETED_SUCCESS = 'ORDER_LIST_COMPLETED_SUCCESS'
export const ORDER_LIST_COMPLETED_FAIL = 'ORDER_LIST_COMPLETED_FAIL'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'
export const ORDER_LIST_RESET = 'ORDER_LIST_REST'

export const ORDER_LIST_ALL_REQUEST = 'ORDER_LIST_ALL_REQUEST'
export const ORDER_LIST_ALL_SUCCESS = 'ORDER_LIST_ALL_SUCCESS'
export const ORDER_LIST_ALL_FAIL = 'ORDER_LIST_ALL_FAIL'
