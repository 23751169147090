import React, { useEffect } from "react"
// Redux
import { useDispatch } from "react-redux"
// Bootstrap Components
import {
    Tabs, Tab,
} from "react-bootstrap"
import { listItems } from "../actions/sameActions"

import { BlindList } from "../components/ProductList"
import { HeadrailList } from "../components/HeadRailList"
import { MotorList } from "../components/MotorList"
import { DeliveryList } from "../components/DeliveryList"
import { BracketList } from "../components/BracketList"
import { RemoteList } from "../components/RemoteList"
import { TasselList } from "../components/TasselList"
// import { FittingList } from "../components/FittingList"
import { ChainList } from "../components/ChainList"
import { CordList } from "../components/CordList"
import { WandList } from "../components/WandList"

const ProductScreen = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        // Dispatch the list products action and fill our state
            dispatch(listItems("product"))
            dispatch(listItems("motor"))
            dispatch(listItems("delivery"))
            dispatch(listItems("bracket"))
            dispatch(listItems("remote"))
            dispatch(listItems("tassel"))
            dispatch(listItems("fitting"))
            dispatch(listItems("chain"))
            dispatch(listItems("cord"))
            dispatch(listItems("wand"))
            dispatch(listItems("headrail"))
      }, [dispatch])

  return (
    <div style={{ margin: "0 auto" }}>
      <Tabs defaultActiveKey="blind" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="blind" title="Blinds">
          <BlindList />
        </Tab>
        <Tab eventKey="headrail" title="HeadRails">
          <HeadrailList />
        </Tab>
        <Tab eventKey="motor" title="Motors">
          <MotorList />
        </Tab>
        <Tab eventKey="tassel" title="Tassels">
          <TasselList />
        </Tab>
        {/*<Tab eventKey="fitting" title="Fittings">*/}
        {/*  <FittingList />*/}
        {/*</Tab>*/}
        <Tab eventKey="chain" title="Chains" >
          <ChainList />
        </Tab>
        <Tab eventKey="cord" title="Cords" >
          <CordList />
        </Tab>
        <Tab eventKey="wand" title="Wands" >
          <WandList />
        </Tab>
        <Tab eventKey="delivery" title="Delivery">
          <DeliveryList />
        </Tab>
        <Tab eventKey="bracket" title="Ext. Bracket">
          <BracketList />
        </Tab>
        <Tab eventKey="remote" title="Remote">
          <RemoteList />
        </Tab>
      </Tabs>
    </div>
  )
}

export default ProductScreen
