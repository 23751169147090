import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Row,
  InputGroup,
  Col,
  Form,
  Button,
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Image,
  Modal,
  Alert
} from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { listItems } from "../actions/sameActions"
import HomeCart from "../components/HomeCart"
import HomeList from "../components/HomeList"
import {
  getUserCarts,
  addToCart,
  addNewCart,
  removeFromCart,
} from "../actions/cartActions"
import { handleCalc } from "../actions/calculateAction"
import { getUserDetails } from "../actions/userActions"
import { getRandomColor, isBlindIncomplete } from "../utils/common"

const initialState = {
  blindType: "DayAndNight",
  headrailType: "Single",
  doubleGroupCode: "",
  product_id: "",
  window: "",
  continued_blind: "None",
  width: "",
  drop: "",
  headrail: "Open",
  controlOption: "Chain",
  tassel: "None",
  control: "Left",
  motor: "None",
  chain: "White",
  cord: "None",
  wand: "None",
  fabric: "Select...",
  notes: "",
  charger: 0,
  remote: "None",
  fitting: "Top Fix",
  bracket: "None",
  bracketCount: 0,
}

const initialPrices = {
  unitPrice: "",
  discountedPrice: "",
  suggestedPrice: "",
  extraUserDiscount: "",
  discountedPriceWithoutExtraUserDiscount: "",
}

const Home = () => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((store) => store.userLogin)
  const productList = useSelector((store) => store.productList.products)
  const headrailList = useSelector((store) => store.headrailList.headrails)
  const tasselList = useSelector((store) => store.tasselList.tassels)
  const motorList = useSelector((store) => store.motorList.motors)
  const remoteList = useSelector((store) => store.remoteList.remotes)
  const chainList = useSelector((store) => store.chainList.chains)
  const fittingList = useSelector((store) => store.fittingList.fittings)
  const bracketList = useSelector((store) => store.bracketList.brackets)
  const cordList = useSelector((store) => store.cordList.cords)
  const wandList = useSelector((store) => store.wandList.wands)
  const { userCarts } = useSelector((store) => store.userCarts)
  const [blinds, setBlinds] = useState(productList)

  const [doubleBlinds, setDoubleBlinds] = useState([productList, productList])

  const [state, setState] = useState(initialState)

  const color = getRandomColor()
  const [doubleState, setDoubleState] = useState([
    {
      ...initialState,
      headrailType: "Double",
      doubleGroupCode: color,
    },
    {
      ...initialState,
      headrailType: "Double",
      doubleGroupCode: color,
    },
  ])

  const [userCart, setUserCart] = useState({})
  const [prices, setPrices] = useState(initialPrices)

  const [doublePrices, setDoublePrices] = useState([initialPrices, initialPrices])

  const [warnWindow, setWarnWindow] = useState('Enter an unused window name.')
  const [doubleWarnWindow, setDoubleWarnWindow] = useState(['Enter an unused window name.', 'Enter an unused window name.'])

  const [warnWidth, setWarnWidth] = useState('Enter value "1" to "2500" mm.')
  const [warnDrop, setWarnDrop] = useState('Enter value "1" to "3000" mm.')

  const [warnWidthBiggerThanTwoThousand, setWarnWidthBiggerThanTwoThousand] = useState(false)

  const [doubleWarnWidth, setDoubleWarnWidth] = useState(['Enter value "1" to "2500" mm.', 'Enter value "1" to "2500" mm.'])
  const [doubleWarnDrop, setDoubleWarnDrop] = useState(['Enter value "1" to "3000" mm.', 'Enter value "1" to "3000" mm.'])

  const [doubleWarnWidthBiggerThanTwoThousand, setDoubleWarnWidthBiggerThanTwoThousand] = useState(false)

  const [cartName, setCartName] = useState("")
  const [show, setShow] = useState(false)

  const [blindType, setBlindType] = useState("DayAndNight")

  const [headrailType, setHeadrailType] = useState("Single")

  const [clearFormFieldsAfterAddingItem, setClearFormFieldsAfterAddingItem] = useState(true)

  const handleClose = () => setShow(false)

  const handleClear = useCallback((includingHeadrailType = true) => {
    setState({
      ...initialState,
      blindType: blindType,
      headrail: blindType === "DayAndNight" ? "Open" : (blindType === "Vellision" ? "White" : (blindType === "Pleated" ? "P-White" : (blindType === "Venetian" ? "FauxWood" : "VER-White"))),
      ...(blindType === "Venetian") && {
        chain: chainList.find(c => c.blindType === "Venetian")?.name ?? "",
        fitting: "Normal Fix",
      },
    })

    const color = getRandomColor()

    setDoubleState([
      {
        ...initialState,
        blindType: blindType,
        headrailType: "Double",
        headrail: blindType === "DayAndNight" ? "Open" : (blindType === "Vellision" ? "White" : (blindType === "Pleated" ? "P-White" : (blindType === "Venetian" ? "FauxWood" : "VER-White"))),
        doubleGroupCode: color,
      },
      {
        ...initialState,
        blindType: blindType,
        headrailType: "Double",
        headrail: blindType === "DayAndNight" ? "Open" : (blindType === "Vellision" ? "White" : (blindType === "Pleated" ? "P-White" : (blindType === "Venetian" ? "FauxWood" : "VER-White"))),
        doubleGroupCode: color,
      },
    ])

    if (includingHeadrailType === true)
      setHeadrailType("Single")

    setBlinds(productList)
    setDoubleBlinds([productList, productList])

    setPrices(initialPrices)
    setDoublePrices([initialPrices, initialPrices])

    setWarnWindow('Enter an unused window name.')

    setWarnWidthBiggerThanTwoThousand(false)
    setDoubleWarnWidthBiggerThanTwoThousand(false)

    if (blindType === "DayAndNight") {
      setDoubleWarnWindow(['Enter an unused window name.', 'Enter an unused window name.'])

      setWarnWidth('Enter value "1" to "2500" mm.')
      setDoubleWarnWidth(["Enter value \"1\" to \"2500\" mm.", "Enter value \"1\" to \"2500\" mm."])
    }
    else if (blindType === "Vellision") {
      setWarnWidth('Enter value "1" to "5000" mm.')
    }
    else if (blindType === "Pleated") {
      setDoubleWarnWindow(['Enter an unused window name.', 'Enter an unused window name.'])

      setWarnWidth('Enter value "200" to "1400" mm.')
      setDoubleWarnWidth(["Enter value \"200\" to \"1400\" mm.", "Enter value \"200\" to \"1400\" mm."])
    }
    else if (blindType === "Venetian") {
      setWarnWidth('Enter value "250" to "2700" mm.')
    }
    else if (blindType === "Vertical") {
      setWarnWidth('Enter value "100" to "4000" mm.')
    }

    if (blindType === "DayAndNight") {
      setWarnDrop('Enter value "1" to "3000" mm.')
      setDoubleWarnDrop(["Enter value \"1\" to \"3000\" mm.", "Enter value \"1\" to \"3000\" mm."])
    }
    else if (blindType === "Vellision") {
      setWarnDrop('Enter value "1" to "4000" mm.')
    }
    else if (blindType === "Pleated") {
      setWarnDrop('Enter value "200" to "2000" mm.')
      setDoubleWarnDrop(["Enter value \"200\" to \"2000\" mm.", "Enter value \"200\" to \"2000\" mm."])
    }
    else if (blindType === "Venetian") {
      setWarnDrop('Enter value "250" to "2200" mm.')
    }
    else if (blindType === "Vertical") {
      setWarnDrop('Enter value "100" to "3000" mm.')
    }

    document.querySelectorAll(".select").forEach((element) => {
      element.value = initialState[element.name]
    })
  }, [blindType, chainList, productList])

  useEffect(() => {
    dispatch(getUserCarts())
    dispatch(getUserDetails())
    dispatch(listItems("product"))
    dispatch(listItems("motor"))
    dispatch(listItems("remote"))
    dispatch(listItems("chain"))
    dispatch(listItems("cord"))
    dispatch(listItems("wand"))
    dispatch(listItems("fitting"))
    dispatch(listItems("bracket"))
    dispatch(listItems("headrail"))
    dispatch(listItems("tassel"))
  }, [dispatch, userInfo])

  useEffect(() => {
    setBlinds(productList)

    setDoubleBlinds([productList, productList])
  }, [productList])

  useEffect(() => {
    handleClear()
  }, [blindType, handleClear])

  useEffect(() => {
    handleClear(false)
  }, [headrailType, handleClear])

  useEffect(() => {
    setTimeout(() => {
      const selected_cart = JSON.parse(localStorage.getItem("selectedCart"))
      if (selected_cart) {
        const selectedCart =
            userCarts &&
            userCarts.length > 0 &&
            userCarts.find((item) => item._id === selected_cart._id)
        if (selectedCart) {
          setUserCart(selectedCart)
          localStorage.setItem("selectedCart", JSON.stringify(selectedCart))
        } else {
          if (userCarts && userCarts.length > 0) {
            setUserCart(userCarts[0])
            localStorage.setItem("selectedCart", JSON.stringify(userCarts[0]))
          } else {
            setUserCart({})
          }
        }
      } else {
        if (userCarts && userCarts.length > 0) {
          setUserCart(userCarts[0])
          localStorage.setItem("selectedCart", JSON.stringify(userCarts[0]))
        } else {
          setUserCart({})
          setShow(true)
        }
      }
    }, 250)
  }, [userCarts])

  const handleSelectCart = (e) => {
    const selectedCart =
      userCarts.length > 0 &&
      userCarts.find((item) => item._id === e.target.value)

    localStorage.setItem("selectedCart", JSON.stringify(selectedCart))

    setUserCart(selectedCart)

    handleClear()
  }

  const handleDoubleInput = (e, index) => {
    const clonedDoubleState = [...doubleState]

    const state = {...clonedDoubleState[index]}
    const otherState = {...clonedDoubleState[Math.abs(index - 1)]}

    if (e.target.name === "window") {
      const clonedDoubleWarnWindow = [...doubleWarnWindow]

      if (!e.target.value.trim() || (userCart.cartItems && userCart.cartItems.filter(i => i.window === e.target.value.trim()).length > 0)) {
        clonedDoubleWarnWindow[index] = "Enter an unused window name."
        clonedDoubleWarnWindow[Math.abs(index - 1)] = "Enter an unused window name."
      }
      else {
        clonedDoubleWarnWindow[index] = ""
        clonedDoubleWarnWindow[Math.abs(index - 1)] = ""
      }

      setDoubleWarnWindow(clonedDoubleWarnWindow)
    }

    if (e.target.name === "motor" && e.target.value !== "None") {
      const clonedDoubleWarnWidth = [...doubleWarnWidth]

      if (state.width < 470 || state.width > 2500) {
        clonedDoubleWarnWidth[index] = "Enter value \"470\" to \"2500\" mm."
        clonedDoubleWarnWidth[Math.abs(index - 1)] = "Enter value \"470\" to \"2500\" mm."
      } else {
        clonedDoubleWarnWidth[index] = ""
        clonedDoubleWarnWidth[Math.abs(index - 1)] = ""
      }

      setDoubleWarnWidth(clonedDoubleWarnWidth)
    }

    if (e.target.name === "motor" && e.target.value === "None") {
      const clonedDoubleWarnWidth = [...doubleWarnWidth]

      if (!state.width || state.width < 0 || state.width > 2500) {
        clonedDoubleWarnWidth[index] = "Enter value \"1\" to \"2500\" mm."
        clonedDoubleWarnWidth[Math.abs(index - 1)] = "Enter value \"1\" to \"2500\" mm."
      } else {
        clonedDoubleWarnWidth[index] = ""
        clonedDoubleWarnWidth[Math.abs(index - 1)] = ""
      }

      setDoubleWarnWidth(clonedDoubleWarnWidth)
    }

    const value = e.target.name === "charger" || e.target.name === "bracketCount" ? Number(e.target.value) : e.target.value
    if (e.target.name === "motor" && e.target.value === "None") {
      state.charger = 0
      state.remote = "None"

      otherState.charger = 0
      otherState.remote = "None"
    }
    if (e.target.name === "motor" && e.target.value === "Rechargeable") {
      state.chain = "White"
      state.cord = "None"

      otherState.chain = "White"
      otherState.cord = "None"
    }
    if (e.target.name === "fitting" && e.target.value === "Top Fix") {
      state.bracket = "None"
      state.bracketCount = 0

      otherState.bracket = "None"
      otherState.bracketCount = 0
    }

    state[e.target.name] = value
    clonedDoubleState[index] = state

    if (e.target.name !== "fabric" && e.target.name !== "tassel") {
      otherState[e.target.name] = value
      clonedDoubleState[Math.abs(index - 1)] = otherState
    }

    setDoubleState(clonedDoubleState)
  }

  const handleInput = (e) => {
    if (e.target.name === "window") {
      if (!e.target.value.trim() || (userCart.cartItems && userCart.cartItems.filter(i => i.window === e.target.value.trim()).length > 0)) {
        setWarnWindow("Enter an unused window name.")
      }
      else {
        setWarnWindow("")
      }
    }

    if (e.target.name === "motor" && e.target.value !== "None") {
      if (blindType === "DayAndNight") {
        if (state.width < 470 || state.width > 2500) {
          setWarnWidth('Enter value "470" to "2500" mm.')
        } else {
          setWarnWidth("")
        }
      }
    }

    if (e.target.name === "motor" && e.target.value === "None") {
      if (blindType === "DayAndNight") {
        if (!state.width || state.width < 1 || state.width > 2500) {
          setWarnWidth('Enter value "1" to "2500" mm.')
        } else {
          setWarnWidth("")
        }
      }
    }

    let headrail
    if (blindType === "DayAndNight") {
      if (e.target.name === "fabric") {
        if (productList) {
          const productIndex = productList.findIndex(p => p.blindType === blindType && p.fabric === e.target.value)
          if (productIndex > -1) {

            if (state.headrail === "AnthraciteCovered") {
              const isVStick = productList[productIndex].isVStick
              if (isVStick === false) {
                headrail = "WhiteCovered"
              }
            }
            else if (state.headrail === "Pelmeted") {
              const fabric = productList[productIndex].fabric
              if (fabric.startsWith('4')) {
                headrail = "WhiteCovered"
              }
            }

          }
        }
      }
    }
    if (blindType === "Venetian") {
      if (e.target.name === "fabric") {
        if (productList) {
          const productIndex = productList.findIndex(p => p.blindType === blindType && p.fabric === e.target.value)
          if (productIndex > -1) {
            const isFaux = productList[productIndex].isFaux
            const isTape = productList[productIndex].isTape
            if (isFaux) {
              if ((state.headrail === "Wood" || state.headrail === "WoodTape") || (!isTape && state.headrail === "FauxWoodTape")) {
                headrail = "FauxWood"
              }
            }
            else {
              if ((state.headrail === "FauxWood" || state.headrail === "FauxWoodTape") || (!isTape && state.headrail === "WoodTape")) {
                headrail = "Wood"
              }
            }
          }
        }
      }
    }

    if (e.target.name === "headrail" || headrail) {
      if (e.target.value === "FauxWood" || e.target.value === "FauxWoodTape" || headrail === "FauxWood") {
        if (!state.width || state.width < 250 || state.width > 2700) {
          setWarnWidth('Enter value "250" to "2700" mm.')
        }
        else {
          setWarnWidth("")
        }
      }
      else if (e.target.value === "Wood" || e.target.value === "WoodTape" || headrail === "Wood") {
        if (!state.width || state.width < 250 || state.width > 2400) {
          setWarnWidth('Enter value "250" to "2400" mm.')
        }
        else {
          setWarnWidth("")
        }
      }
    }

    let tassel
    if (blindType === "DayAndNight") {
      if (e.target.name === "headrail") {
        if (e.target.value === "AnthraciteCovered") {
          if (state.tassel === "No-Beaded" || state.tassel === "Beaded") {
            tassel = "None"
          }
        }
      }
    }

    // TODO: FOR WHAT???
    if (e.target.name === "cord") {
      console.log('e.target.value', e.target.value)
    }
    //

    const value = e.target.name === "charger" || e.target.name === "bracketCount" ? Number(e.target.value) : e.target.value
    setState({
      ...state,
      ...(e.target.name === "motor" && e.target.value === "None") && { charger: 0, remote: "None" },

      ...(e.target.name === "motor" && e.target.value === "Rechargeable") && { chain: blindType === "DayAndNight" ? "White" : (chainList.find(c => c.blindType === "Venetian")?.name ?? ""), cord: "None" },

      ...(e.target.name === "fitting" && e.target.value === "Top Fix") && { bracket: "None", bracketCount: 0 },

      ...(e.target.name === "fitting" && e.target.value === "Normal Fix") && { bracket: "None", bracketCount: 0 },

      ...(e.target.name === "controlOption" && e.target.value === "Chain") && { wand: "None" },

      ...(e.target.name === "controlOption" && e.target.value === "Wand") && { chain: "White", cord: "None" },

      ...(headrail) && { headrail },

      ...(tassel) && { tassel },

      [e.target.name]: value
    })
  }

  const handleDoubleWidth = (e, index) => {
    const input = Number(e.target.value)

    const clonedDoubleState = [...doubleState]

    const state = {...clonedDoubleState[index]}
    const otherState = {...clonedDoubleState[Math.abs(index - 1)]}

    const clonedDoubleWarnWidth = [...doubleWarnWidth]

    if (blindType === "DayAndNight") {
      if (!input || input < 1 || input > 2500) {
        clonedDoubleWarnWidth[index] = "Enter value \"1\" to \"2500\" mm."
        clonedDoubleWarnWidth[Math.abs(index - 1)] = "Enter value \"1\" to \"2500\" mm."
      } else if (state.motor !== "None" && input < 470) {
        clonedDoubleWarnWidth[index] = "Enter value \"470\" to \"2500\" mm."
        clonedDoubleWarnWidth[Math.abs(index - 1)] = "Enter value \"470\" to \"2500\" mm."
      } else {
        clonedDoubleWarnWidth[index] = ""
        clonedDoubleWarnWidth[Math.abs(index - 1)] = ""
      }

      if (input && input > 2000) {
        setDoubleWarnWidthBiggerThanTwoThousand(true)
      }
      else {
        setDoubleWarnWidthBiggerThanTwoThousand(false)
      }
    }
    else if (blindType === "Pleated") {
      if (!input || input < 200 || input > 1400) {
        clonedDoubleWarnWidth[index] = "Enter value \"200\" to \"1400\" mm."
        clonedDoubleWarnWidth[Math.abs(index - 1)] = "Enter value \"200\" to \"1400\" mm."
      } else {
        clonedDoubleWarnWidth[index] = ""
        clonedDoubleWarnWidth[Math.abs(index - 1)] = ""
      }

      setDoubleWarnWidthBiggerThanTwoThousand(false)
    }

    setDoubleWarnWidth(clonedDoubleWarnWidth)

    state[e.target.name] = e.target.value
    otherState[e.target.name] = e.target.value

    clonedDoubleState[index] = state
    clonedDoubleState[Math.abs(index - 1)] = otherState

    setDoubleState(clonedDoubleState)
  }

  const handleDoubleDrop = (e, index) => {
    const input = Number(e.target.value)

    const clonedDoubleState = [...doubleState]

    const state = {...clonedDoubleState[index]}
    const otherState = {...clonedDoubleState[Math.abs(index - 1)]}

    const clonedDoubleWarnDrop = [...doubleWarnDrop]

    if (blindType === "DayAndNight") {
      if (!input || input < 1 || input > 3000) {
        clonedDoubleWarnDrop[index] = "Enter value \"1\" to \"3000\" mm."
        clonedDoubleWarnDrop[Math.abs(index - 1)] = "Enter value \"1\" to \"3000\" mm."
      } else {
        clonedDoubleWarnDrop[index] = ""
        clonedDoubleWarnDrop[Math.abs(index - 1)] = ""
      }
    } else if (blindType === "Pleated") {
      if (!input || input < 200 || input > 2000) {
        clonedDoubleWarnDrop[index] = "Enter value \"200\" to \"2000\" mm."
        clonedDoubleWarnDrop[Math.abs(index - 1)] = "Enter value \"200\" to \"2000\" mm."
      } else {
        clonedDoubleWarnDrop[index] = ""
        clonedDoubleWarnDrop[Math.abs(index - 1)] = ""
      }
    }

    setDoubleWarnDrop(clonedDoubleWarnDrop)

    state[e.target.name] = e.target.value
    otherState[e.target.name] = e.target.value

    clonedDoubleState[index] = state
    clonedDoubleState[Math.abs(index - 1)] = otherState

    setDoubleState(clonedDoubleState)
  }

  const handleWidth = (e) => {
    const input = Number(e.target.value)

    if (blindType === "DayAndNight") {
      if (!input || input < 1 || input > 2500) {
        setWarnWidth('Enter value "1" to "2500" mm.')
      } else if (state.motor !== "None" && input < 470) {
        setWarnWidth('Enter value "470" to "2500" mm.')
      } else {
        setWarnWidth("")
      }

      if (input && input > 2000) {
        setWarnWidthBiggerThanTwoThousand(true)
      }
      else {
        setWarnWidthBiggerThanTwoThousand(false)
      }
    }
    else if (blindType === "Vellision") {
      if (!input || input < 1 || input > 5000) {
        setWarnWidth('Enter value "1" to "5000" mm.')
      } else {
        setWarnWidth("")
      }

      setWarnWidthBiggerThanTwoThousand(false)
    }
    else if (blindType === "Pleated") {
      if (!input || input < 200 || input > 1400) {
        setWarnWidth('Enter value "200" to "1400" mm.')
      } else {
        setWarnWidth("")
      }

      setWarnWidthBiggerThanTwoThousand(false)
    }
    else if (blindType === "Venetian") {
      if (state.headrail === "FauxWood" || state.headrail === "FauxWoodTape") {
        if (!input || input < 250 || input > 2700) {
          setWarnWidth('Enter value "250" to "2700" mm.')
        } else {
          setWarnWidth("")
        }
      }
      else if (state.headrail === "Wood" || state.headrail === "WoodTape") {
        if (!input || input < 250 || input > 2400) {
          setWarnWidth('Enter value "250" to "2400" mm.')
        } else {
          setWarnWidth("")
        }
      }

      setWarnWidthBiggerThanTwoThousand(false)
    }
    else if (blindType === "Vertical") {
      if (!input || input < 100 || input > 4000) {
        setWarnWidth('Enter value "100" to "4000" mm.')
      } else {
        setWarnWidth("")
      }

      setWarnWidthBiggerThanTwoThousand(false)
    }

    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleDrop = (e) => {
    const input = Number(e.target.value)

    if (blindType === "DayAndNight") {
      if (!input || input < 1 || input > 3000) {
        setWarnDrop('Enter value "1" to "3000" mm.')
      } else {
        setWarnDrop("")
      }
    }
    else if (blindType === "Vellision") {
      if (!input || input < 1 || input > 4000) {
        setWarnDrop('Enter value "1" to "4000" mm.')
      } else {
        setWarnDrop("")
      }
    }
    else if (blindType === "Pleated") {
      if (!input || input < 200 || input > 2000) {
        setWarnDrop('Enter value "200" to "2000" mm.')
      } else {
        setWarnDrop("")
      }
    }
    else if (blindType === "Venetian") {
      if (!input || input < 250 || input > 2200) {
        setWarnDrop('Enter value "250" to "2200" mm.')
      } else {
        setWarnDrop("")
      }
    }
    else if (blindType === "Vertical") {
      if (!input || input < 100 || input > 3000) {
        setWarnDrop('Enter value "100" to "3000" mm.')
      } else {
        setWarnDrop("")
      }
    }

    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleCalculate = useCallback(() => {
    if (headrailType === "Single") {
      const result = dispatch(handleCalc(state))
      setState({ ...state, product_id: result.product_id })
      setPrices(result.prices)
    }
    else if (headrailType === "Double") {
      const clonedDoubleState = [...doubleState]
      const state = {...clonedDoubleState[0]}
      const otherState = {...clonedDoubleState[1]}

      const result = dispatch(handleCalc(state))
      const otherResult = dispatch(handleCalc(otherState))

      state['product_id'] = result.product_id
      otherState['product_id'] = otherResult.product_id

      clonedDoubleState[0] = state
      clonedDoubleState[1] = otherState

      setDoubleState(clonedDoubleState)

      const clonedDoublePrices = [...doublePrices]

      clonedDoublePrices[0] = result.prices
      clonedDoublePrices[1] = otherResult.prices

      setDoublePrices(clonedDoublePrices)
    }
  }, [dispatch, doublePrices, doubleState, headrailType, state])

  const handleCart = useCallback((e) => {
    e.preventDefault()

    let data
    if (headrailType === "Single") {
      data = {
        cart_id: userCart._id,
        cartType: "Single",
        cartItems: state,
      }
    }
    else if (headrailType === "Double") {
      data = {
        cart_id: userCart._id,
        cartType: "Double",
        cartItems: doubleState,
      }
    }

    dispatch(addToCart(data)).then((response) => {
      if (response.status === 200) {
        if (clearFormFieldsAfterAddingItem) handleClear()
        else {
          if (headrailType === "Single") {
            setWarnWindow('Enter an unused window name')

            const clonedState = {...state}
            clonedState['window'] = ""
            setState(clonedState)
          }

          if (headrailType === "Double") {
            const clonedDoubleWarnWindow = [...doubleWarnWindow]

            clonedDoubleWarnWindow[0] = 'Enter an unused window name'
            clonedDoubleWarnWindow[1] = 'Enter an unused window name'

            setDoubleWarnWindow(clonedDoubleWarnWindow)

            const clonedDoubleState = [...doubleState]
            const state = {...clonedDoubleState[0]}
            const otherState = {...clonedDoubleState[1]}

            const color = getRandomColor()

            state['window'] = ""
            state['doubleGroupCode'] = color
            otherState['window'] = ""
            otherState['doubleGroupCode'] = color

            clonedDoubleState[0] = state
            clonedDoubleState[1] = otherState

            setDoubleState(clonedDoubleState)
          }
        }
        dispatch(getUserCarts())
      }
    })
  }, [dispatch, clearFormFieldsAfterAddingItem, doubleWarnWindow, doubleState, handleClear, headrailType, state, userCart])

  const handleCartName = (e) => {
    e.preventDefault()
    setCartName(e.target.value)
  }

  const handleNewCart = (e) => {
    e.preventDefault()
    const data = {
      cart_name: cartName,
    }
    dispatch(addNewCart(data)).then((response) => {
      if (response.status === 200) {
        setCartName("")

        handleClear()

        dispatch(getUserCarts())
      }
    })
  }

  const deleteFromCart = (cartProduct) => {
    let obj = {
      cart_id: userCart._id,
    }

    if (cartProduct.headrailType === "Double") {
      obj['cartType'] = "Double"

      const item_id_array = [cartProduct._id]

      const otherCartProduct = userCart.cartItems.filter(i => i._id !== cartProduct._id && i.doubleGroupCode === cartProduct.doubleGroupCode)

      if (otherCartProduct[0])
        item_id_array.push(otherCartProduct[0])

      obj['item_id'] = item_id_array
    }
    else {
      obj['cartType'] = "Single"
      obj['item_id'] = cartProduct._id
    }

    dispatch(removeFromCart(obj)).then(
      (response) => {
        if (response.status === 200) {
          dispatch(getUserCarts())
        }
      }
    )
  }

  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <Row>
        <Form.Group
          // className="mb-3 col-sm-6 col-lg-6"
          className="mb-3 col-md-6"
          controlId="formGridCarts"
          style={{ alignSelf: "center" }}
        >
          {userCarts && userCarts.length > 0 && (
            <InputGroup className="mb-1">
              <InputGroup.Text id="basic-addon3">
                <strong>Selected Cart</strong>
              </InputGroup.Text>
              <Form.Select
                defaultValue="none"
                name="cart_id"
                id="cart_id"
                onChange={handleSelectCart}
              >
                {userCart && (
                  <option value={userCart._id}>{userCart.cart_name}</option>
                )}
                {userCarts &&
                  userCarts.length > 0 &&
                  userCarts.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={item._id}
                        style={{
                          display: `${userCart && userCart._id === item._id ? "none" : ""
                            }`,
                        }}
                      >
                        {item.cart_name}
                      </option>
                    )
                  })}
              </Form.Select>
            </InputGroup>
          )}
        </Form.Group>
        <Form.Group
          // className="mb-3 col-sm-6"
          className="mb-3 col-md-6"
          controlId="formGridCarts"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <InputGroup
              className="mb-1 pe-2"
              // style={{ maxWidth: "300px" }}
          >
            <Form.Control
              placeholder="New Cart Name"
              value={cartName}
              onChange={handleCartName}
            />
            <Button
              variant="outline-dark"
              type="button"
              style={{ fontSize: "12px" }}
              onClick={handleNewCart}
            >
              <i className="fa fa-cart-plus" aria-hidden="true"/> New
              Cart
            </Button>
          </InputGroup>
          <img
              className="accredited-logo"
              src="BBSA_accredited.png"
              alt="BBSA_accredited"
          />
        </Form.Group>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered
      >
          <Alert className="m-0" variant="warning">Before submit your product selection, make sure that you have a selected cart above. <br></br>If not; you can create a new cart (with a name you wish) from the "New Cart" button! <br></br>Also you can create multiply carts for every customer.</Alert>
      </Modal>
      <hr/>
      <Form size="sm">
        <Row>
          <Form.Group
              className="mb-3 col-sm-6 col-lg-3"
              controlId="formGridBlindType"
          >
            <Form.Label className="mb-0">BLIND TYPE</Form.Label>
            <Form.Select
                className="select"
                size="sm"
                value={blindType}
                name="blindType"
                id="blindType"
                onChange={(e) => setBlindType(e.target.value)}
            >
              <option value="DayAndNight">Day And Night</option>
              <option value="Vellision">Vellision</option>
              <option value="Pleated">Pleated</option>
              <option value="Venetian">Venetian</option>
              {/*<option value="Vertical" disabled={userInfo && userInfo.user && userInfo.user.email !== "ismail@ismail.com" && userInfo.user.email !== "yavuztiryaki@hotmail.com"}>Vertical {userInfo && userInfo.user && userInfo.user.email !== "ismail@ismail.com" && userInfo.user.email !== "yavuztiryaki@hotmail.com" ? "(Soon)" : ""}</option>*/}
              <option value="Vertical">Vertical</option>
            </Form.Select>
          </Form.Group>
          <Form.Group
              className="mb-3 col-sm-6 col-lg-3"
              controlId="formGridHeadrailType"
          >
            <Form.Label className="mb-0">HEADRAIL TYPE</Form.Label>
            <Form.Select
                className="select"
                size="sm"
                value={headrailType}
                name="headrailType"
                id="headrailType"
                onChange={(e) => setHeadrailType(e.target.value)}
            >
              <option value="Single">Single</option>
              {(blindType === "DayAndNight" || blindType === "Pleated") && <option value="Double">Double</option>}
            </Form.Select>
          </Form.Group>
          <Form.Group
              className="mb-3 col-sm-12 col-lg-6"
              controlId="formGridClearFormFieldsAfterAddingItem"
          >
            <Form.Label className="mb-0"/>
            <Form.Check
                type="switch"
                id="clearFormFieldsAfterAddingItem"
                label="Clear Form Fields After Adding Item"
                name="clearFormFieldsAfterAddingItem"
                checked={clearFormFieldsAfterAddingItem}
                onChange={(e) => setClearFormFieldsAfterAddingItem(e.target.checked)}
            />
          </Form.Group>
        </Row>
        {headrailType === "Double" ? (
            doubleState.map((state, index) => (
                <React.Fragment key={index}>
                  <Row>
                    <Form.Group
                        className={`mb-1 ${blindType === `DayAndNight` ? `col-sm-6 col-lg-3` : `col-sm-12 col-lg-6`}`}
                        controlId="formGridWindow"
                    >
                      <Form.Label className="mb-0 text-muted">Window Name</Form.Label>
                      <Form.Control
                          size="sm"
                          placeholder="Enter window name."
                          name="window"
                          value={state.window}
                          onChange={(e) => handleDoubleInput(e, index)}
                          style={{ borderColor: `${doubleWarnWindow[index] && "red"}` }}
                      />
                      {doubleWarnWindow[index] && (
                          <Form.Text type="invalid" style={{ color: "red" }}>
                            {doubleWarnWindow[index]}
                          </Form.Text>
                      )}
                    </Form.Group>
                    {blindType === "DayAndNight" ? (
                        <Form.Group
                            className="mb-1 col-sm-6 col-lg-3"
                            controlId="formGridContinued"
                        >
                          <Form.Label className="mb-0 text-muted">
                            Continued Blind{" "}
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="button-tooltip-2">
                                    If this blind just next to one before blind on same window.
                                    An example bay window, patio or french door etc.
                                  </Tooltip>
                                }
                            >
                              {({ ref, ...triggerHandler }) => (
                                  <i
                                      {...triggerHandler}
                                      ref={ref}
                                      className="fa fa-question-circle"
                                      aria-hidden="true"
                                  />
                              )}
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Select
                              className="select"
                              size="sm"
                              value={state.continued_blind}
                              name="continued_blind"
                              id="continued_blind"
                              onChange={(e) => handleDoubleInput(e, index)}
                          >
                            <option value="None">None</option>
                            {
                              userCart &&
                              userCart.cartItems &&
                              userCart.cartItems.length > 0 &&
                              userCart.cartItems.filter((value, index, self) =>
                                      index === self.findIndex(
                                          t => (
                                              t.window === value.window && t.doubleGroupCode === value.doubleGroupCode
                                          )
                                      )
                              ).map((item) => {
                                return (
                                    <option key={item._id} value={item.window} disabled={item.blindType !== "DayAndNight"}>
                                      {item.window}
                                    </option>
                                )
                              })
                            }
                          </Form.Select>
                        </Form.Group>
                    ) : null}
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridWidth"
                    >
                      <Form.Label className="mb-0 text-muted">Width (mm)</Form.Label>
                      <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              Your blind will be made in “blind size” (exactly same size)
                              you fill the form.
                            </Tooltip>
                          }
                      >
                        {({ ref, ...triggerHandler }) => (
                            <Form.Control
                                size="sm"
                                {...triggerHandler}
                                ref={ref}
                                placeholder="Enter width in 'mm'."
                                type="number"
                                min={blindType === "DayAndNight" ? 1 : 200}
                                max={blindType === "DayAndNight" ? 2500 : 1400}
                                name="width"
                                onChange={(e) => handleDoubleWidth(e, index)}
                                value={state.width}
                                pattern="[0-9]+"
                                className="no-arrows"
                                style={{ borderColor: `${doubleWarnWidth[index] && "red"}` }}
                            />
                        )}
                      </OverlayTrigger>
                      {doubleWarnWidth[index] && (
                          <Form.Text type="invalid" style={{ color: "red" }}>
                            {doubleWarnWidth[index]}
                          </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridDrop"
                    >
                      <Form.Label className="mb-0 text-muted">Drop (mm)</Form.Label>
                      <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              Your blind will be made in “blind size” (exactly same) size
                              you fill the form.
                            </Tooltip>
                          }
                      >
                        {({ ref, ...triggerHandler }) => (
                            <Form.Control
                                size="sm"
                                {...triggerHandler}
                                ref={ref}
                                placeholder="Enter drop in 'mm'."
                                type="number"
                                min={blindType === "DayAndNight" ? 1 : 200}
                                max={blindType === "DayAndNight" ? 3000 : 2000}
                                name="drop"
                                value={state.drop}
                                onChange={(e) => handleDoubleDrop(e, index)}
                                pattern="[0-9]+"
                                className="no-arrows"
                                style={{ borderColor: `${doubleWarnDrop[index] && "red"}` }}
                            />
                        )}
                      </OverlayTrigger>
                      {doubleWarnDrop[index] && (
                          <Form.Text type="invalid" style={{ color: "red" }}>
                            {doubleWarnDrop[index]}
                          </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridFabric"
                    >
                      <Form.Label className="mb-0 text-muted">
                        Fabric{" "}
                        <span
                            style={{
                              color: `${state.fabric === "Select..." ? "red" : ""}`,
                              display: `${state.fabric === "Select..." ? "" : "none"}`,
                            }}
                        >
                          *
                        </span>
                      </Form.Label>
                      <Form.Select
                          style={{
                            borderColor: `${state.fabric === "Select..." ? "red" : ""}`,
                          }}
                          className="select"
                          value={state.fabric}
                          size="sm"
                          name="fabric"
                          id="fabric"
                          onChange={(e) => handleDoubleInput(e, index)}
                      >
                        <option value="Select...">Select...</option>
                        {doubleBlinds[index] && doubleBlinds[index].length > 0 &&
                            doubleBlinds[index]
                                .filter((item) => item.isActive === true && item.blindType === blindType)
                                .sort((a, b) => a.fabric.substring(0, 3) - b.fabric.substring(0, 3))
                                .map((item, index) => (
                                    <option id={item._id} key={index} value={item.fabric}>
                                      {item.fabric}
                                    </option>
                                ))
                        }
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridFabric"
                    >
                      <Image src={`${state.fabric !== "Select..." ? (state.blindType === "DayAndNight" ? `/blinds/${state.fabric.toLowerCase().substring(0,3)}.jpg` : `/blinds/${state.fabric.toLowerCase()}.jpg`) : `no-image.png`}`} onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }} rounded fluid />
                    </Form.Group>
                    {blindType === "DayAndNight" ? (
                        <>
                          <Form.Group
                              className="mb-1 col-sm-6 col-lg-3"
                              controlId="formGridTassel"
                          >
                            <Form.Label className="mb-0 text-muted">Tassel</Form.Label>
                            <Form.Select
                                className="select"
                                size="sm"
                                defaultValue="None"
                                name="tassel"
                                id="tassel"
                                onChange={(e) => handleDoubleInput(e, index)}
                            >
                              <option value="None">None</option>
                              {
                                tasselList &&
                                tasselList.length > 0 &&
                                tasselList
                                    .filter((item) => item.isActive === true)
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group
                              className="mb-1 col-sm-6 col-lg-3"
                              controlId="formGridTassel"
                          >
                            <Image src={`/tassels/${state.tassel.toLowerCase()}.jpg`} onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }} rounded fluid />
                          </Form.Group>
                        </>
                    ) : (
                        <Form.Group
                            className="mb-1 col-sm-12 col-lg-6"
                        >
                        </Form.Group>
                    )}
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridHeadrail"
                    >
                      <Form.Label className="mb-0 text-muted">Headrail</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          value={state.headrail}
                          name="headrail"
                          id="headrail"
                          onChange={(e) => handleDoubleInput(e, index)}
                      >
                        {
                          headrailList &&
                          headrailList.length > 0 &&
                          headrailList
                              .filter((item) => item.isActive === true && item.blindType === blindType)
                              .map((item, index) => {
                                return (
                                    blindType !== "DayAndNight" || (blindType === "DayAndNight" && item.name === "Open") ? (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                    ) : null
                                )
                              })
                        }
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridHeadrail"
                    >
                      <Image src={`/headrails/double/${state.headrail.toLowerCase()}.png`} onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }} rounded fluid />
                    </Form.Group>
                    {blindType === "DayAndNight" && state.motor === "None" ? (
                        <>
                          <Form.Group
                              className="mb-1 col-sm-6 col-lg-3"
                              controlId="formGridChains"
                          >
                            <Form.Label className="mb-0 text-muted">Chains</Form.Label>
                            <Form.Select
                                className="select"
                                size="sm"
                                value={state.chain}
                                name="chain"
                                id="chain"
                                onChange={(e) => handleDoubleInput(e, index)}
                            >
                              {
                                chainList &&
                                chainList.length > 0 &&
                                chainList
                                    .filter((item) => item.blindType === blindType && item.isActive === true)
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name} {item.blindType === "DayAndNight" && item.name === "Silver" ? ' / Metal (+£1)' : ''}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                          <Form.Group
                              className={`mb-1 col-sm-6 col-lg-3`}
                              controlId="formGridCords"
                          >
                            <Form.Label className="mb-0 text-muted">Chain Length</Form.Label>
                            <Form.Select
                                className="select"
                                size="sm"
                                value={state.cord}
                                name="cord"
                                id="cord"
                                onChange={(e) => handleDoubleInput(e, index)}
                                style={{ borderColor: `${state.cord === "None" ? "red" : ""}` }}
                            >
                              <option value="None">None</option>
                              {
                                cordList &&
                                cordList.length > 0 &&
                                cordList
                                    .filter((item) => item.isActive === true && item.blindType === blindType)
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                        </>
                    ) : (
                        <Form.Group
                            className="mb-1 col-sm-12 col-lg-6"
                        >
                        </Form.Group>
                    )}
                  </Row>
                  {blindType === "DayAndNight" ? (
                      <>
                        <Row className="mb-1">
                          <Form.Group
                              className="mb-1 col-sm-6 col-lg-3"
                              controlId="formGridFitting"
                          >
                            <Form.Label className="mb-0 text-muted">Fitting</Form.Label>
                            <Form.Select
                                className="select"
                                size="sm"
                                value={state.fitting}
                                name="fitting"
                                id="fitting"
                                onChange={(e) => handleDoubleInput(e, index)}
                            >
                              {
                                fittingList &&
                                fittingList.length > 0 &&
                                fittingList
                                    .filter((item) => item.isActive === true && ((blindType !== "Venetian" && item.name !== "Normal Fix") || (blindType === "Venetian" && item.name !== "Top Fix")))
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                          {state.fitting === "Face Fix" ? (
                              <Form.Group
                                  className="mb-1 col-sm-6 col-lg-3"
                                  controlId="formGridBracketCount"
                              >
                                <Form.Label className="mb-0 text-muted">Ext. Bracket Quantity</Form.Label>
                                <Form.Control
                                    size="sm"
                                    placeholder="Enter bracket quantity."
                                    type="number"
                                    min="0"
                                    name="bracketCount"
                                    value={state.bracketCount}
                                    onChange={(e) => handleDoubleInput(e, index)}
                                    onPaste={(event) => event.preventDefault()}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key))
                                        event.preventDefault()

                                      if (event.target.value.toString() === "0")
                                        event.target.value = ""
                                    }}
                                    pattern="[0-9]+"
                                    style={{ borderColor: `${(state.fitting !== "Top Fix" && state.bracketCount === 0) ? "red" : ""}` }}
                                />
                              </Form.Group>
                          ) : null}
                          {state.fitting === "Face Fix" ? (
                              <Form.Group
                                  className="mb-1 col-sm-12 col-lg-6"
                                  controlId="formGridBracket"
                              >
                                <Form.Label className="mb-0 text-muted">Ext. Bracket</Form.Label>
                                <Form.Select
                                    className="select"
                                    size="sm"
                                    value={state.bracket}
                                    name="bracket"
                                    id="bracket"
                                    onChange={(e) => handleDoubleInput(e, index)}
                                    style={{ borderColor: `${(state.fitting !== "Top Fix" && state.bracket === "None") ? "red" : ""}` }}
                                >
                                  <option value="None">None</option>
                                  {
                                    bracketList &&
                                    bracketList.length > 0 &&
                                    bracketList
                                        .filter((item) => item.isActive === true && item.blindType === blindType)
                                        .map((item, index) => {
                                          return (
                                              <option key={index} value={item.name}>
                                                {item.name} (£ {item.price})
                                              </option>
                                          )
                                        })
                                  }
                                </Form.Select>
                              </Form.Group>
                          ) : null}
                        </Row>
                      </>
                  ) : null}
                  <Row className="mb-1">
                    {blindType === "DayAndNight" ? (
                        <Form.Group
                            className="mb-1 col-sm-12 col-lg-6"
                            controlId="formGridControl"
                        >
                          <Form.Label className="mb-0 text-muted">Control Side</Form.Label>
                          <Form.Select
                              className="select"
                              size="sm"
                              value={state.control}
                              name="control"
                              id="control"
                              onChange={(e) => handleDoubleInput(e, index)}
                          >
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                          </Form.Select>
                        </Form.Group>
                    ) : null}
                    <Form.Group
                        className="mb-1 col-sm-12 col-lg-6 d-flex flex-column"
                    >
                      <Form.Label className="mb-0 text-muted">Comments</Form.Label>
                      <FloatingLabel
                          controlId="floatingTextarea2"
                          label="Comments"
                          size="sm"
                      >
                        <Form.Control
                            size="sm"
                            as="textarea"
                            placeholder="Leave your comment here"
                            name="notes"
                            value={state.notes}
                            style={{ height: "75px" }}
                            onChange={(e) => handleDoubleInput(e, index)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Row>
                </React.Fragment>
            ))
        ) : (
            <>
              <Row>
                <Form.Group
                    className={`mb-1 ${blindType === `DayAndNight` ? `col-sm-6 col-lg-3` : `col-sm-12 col-lg-6`}`}
                    controlId="formGridWindow"
                >
                  <Form.Label className="mb-0 text-muted">Window Name</Form.Label>
                  <Form.Control
                      size="sm"
                      placeholder="Enter window name."
                      name="window"
                      value={state.window}
                      onChange={handleInput}
                      style={{ borderColor: `${warnWindow && "red"}` }}
                  />
                  {warnWindow && (
                      <Form.Text type="invalid" style={{ color: "red" }}>
                        {warnWindow}
                      </Form.Text>
                  )}
                </Form.Group>
                {blindType === "DayAndNight" ? (
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridContinued"
                    >
                      <Form.Label className="mb-0 text-muted">
                        Continued Blind{" "}
                        <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                If this blind just next to one before blind on same window.
                                An example bay window, patio or french door etc.
                              </Tooltip>
                            }
                        >
                          {({ ref, ...triggerHandler }) => (
                              <i
                                  {...triggerHandler}
                                  ref={ref}
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                              />
                          )}
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          defaultValue="None"
                          name="continued_blind"
                          id="continued_blind"
                          onChange={handleInput}
                      >
                        <option value="None">None</option>
                        {
                            userCart &&
                            userCart.cartItems &&
                            userCart.cartItems.length > 0 &&
                            userCart.cartItems.filter((value, index, self) =>
                                index === self.findIndex(
                                    t => (
                                        t.window === value.window && t.doubleGroupCode === value.doubleGroupCode
                                    )
                                )
                            ).map((item) => {
                                return (
                                    <option key={item._id} value={item.window} disabled={item.blindType !== "DayAndNight"}>
                                      {item.window}
                                    </option>
                                )
                            })
                        }
                      </Form.Select>
                    </Form.Group>
                ) : null}
                <Form.Group
                    className="mb-1 col-sm-6 col-lg-3"
                    controlId="formGridWidth"
                >
                  <Form.Label className="mb-0 text-muted">Width (mm)</Form.Label>
                  <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Your blind will be made in “blind size” (exactly same size)
                          you fill the form.
                        </Tooltip>
                      }
                  >
                    {({ ref, ...triggerHandler }) => (
                        <Form.Control
                            size="sm"
                            {...triggerHandler}
                            ref={ref}
                            placeholder="Enter width in 'mm'."
                            type="number"
                            min={blindType === "Venetian" ? 250 : (blindType === "Pleated" ? 200 : (blindType === "Vertical" ? 100 : 1))}
                            max={blindType === "Venetian" ? (state.headrail === "FauxWood" || state.headrail === "FauxWoodTape" ? 2700 : 2400) : (blindType === "Pleated" ? 1400 : (blindType === "Vellision" ? 5000 : (blindType === "Vertical" ? 4000 : 2500)))}
                            name="width"
                            onChange={handleWidth}
                            value={state.width}
                            pattern="[0-9]+"
                            className="no-arrows"
                            style={{ borderColor: `${warnWidth && "red"}` }}
                        />
                    )}
                  </OverlayTrigger>
                  {warnWidth && (
                      <Form.Text type="invalid" style={{ color: "red" }}>
                        {warnWidth}
                      </Form.Text>
                  )}
                </Form.Group>
                <Form.Group
                    className="mb-1 col-sm-6 col-lg-3"
                    controlId="formGridDrop"
                >
                  <Form.Label className="mb-0 text-muted">Drop (mm)</Form.Label>
                  <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          Your blind will be made in “blind size” (exactly same) size
                          you fill the form.
                        </Tooltip>
                      }
                  >
                    {({ ref, ...triggerHandler }) => (
                        <Form.Control
                            size="sm"
                            {...triggerHandler}
                            ref={ref}
                            placeholder="Enter drop in 'mm'."
                            type="number"
                            min={blindType === "Venetian" ? 250 : (blindType === "Pleated" ? 200 : (blindType === "Vertical" ? 100 : 1))}
                            max={blindType === "Venetian" ? 2200 : (blindType === "Pleated" ? 2000 : (blindType === "Vellision" ? 4000 : 3000))}
                            name="drop"
                            value={state.drop}
                            onChange={handleDrop}
                            pattern="[0-9]+"
                            className="no-arrows"
                            style={{ borderColor: `${warnDrop && "red"}` }}
                        />
                    )}
                  </OverlayTrigger>
                  {warnDrop && (
                      <Form.Text type="invalid" style={{ color: "red" }}>
                        {warnDrop}
                      </Form.Text>
                  )}
                </Form.Group>
                <Form.Group
                    className="mb-1 col-sm-6 col-lg-3"
                    controlId="formGridFabric"
                >
                  <Form.Label
                      className="mb-0 text-muted"
                  >
                    Fabric{" "}
                    <span
                        style={{
                          color: `${state.fabric === "Select..." ? "red" : ""}`,
                          display: `${state.fabric === "Select..." ? "" : "none"}`,
                        }}
                    >
                      *
                    </span>
                  </Form.Label>
                  <Form.Select
                      style={{
                        borderColor: `${state.fabric === "Select..." ? "red" : ""}`,
                      }}
                      className="select"
                      value={state.fabric}
                      size="sm"
                      name="fabric"
                      id="fabric"
                      onChange={handleInput}
                  >
                    <option value="Select...">Select...</option>
                    {
                      blinds && blinds.length > 0 &&
                      blinds
                          .filter((item) => item.isActive === true && item.blindType === blindType)
                          .sort((a, b) => a.fabric.substring(0, 3) - b.fabric.substring(0, 3))
                          .map((item, index) => (
                              <option id={item._id} key={index} value={item.fabric}>
                                {item.fabric}
                              </option>
                          ))
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group
                    className="mb-1 col-sm-6 col-lg-3"
                    controlId="formGridFabric"
                >
                  <Image
                      src={`${state.fabric !== "Select..." ? (state.blindType === "DayAndNight" ? `/blinds/${state.fabric.toLowerCase().substring(0,3)}.jpg` : `/blinds/${state.fabric.toLowerCase()}.jpg`) : `no-image.png`}`}
                      onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }}
                      rounded
                      fluid
                  />
                </Form.Group>
                {blindType === "DayAndNight" ? (
                    <>
                      <Form.Group
                          className="mb-1 col-sm-6 col-lg-3"
                          controlId="formGridTassel"
                      >
                        <Form.Label className="mb-0 text-muted">Tassel</Form.Label>
                        <Form.Select
                            className="select"
                            size="sm"
                            value={state.tassel}
                            name="tassel"
                            id="tassel"
                            onChange={handleInput}
                        >
                          <option value="None">None</option>
                          {
                            tasselList &&
                            tasselList.length > 0 &&
                            tasselList
                                .filter((item) => item.isActive === true)
                                .map((item, index) => {

                                  let isAnthraciteCovered = false
                                  if (blindType === "DayAndNight") {
                                    if (state.headrail === "AnthraciteCovered") {
                                      isAnthraciteCovered = true
                                    }
                                  }

                                  return (
                                      <option key={index} value={item.name} disabled={(item.name === "No-Beaded" || item.name === "Beaded") && isAnthraciteCovered}>
                                        {item.name}
                                      </option>
                                  )
                                })
                          }
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                          className="mb-1 col-sm-6 col-lg-3"
                          controlId="formGridTassel"
                      >
                        <Image src={`/tassels/${state.tassel.toLowerCase()}.jpg`} onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }} rounded fluid />
                      </Form.Group>
                    </>
                ) : (
                    <Form.Group
                        className="mb-1 col-sm-12 col-lg-6"
                    >
                    </Form.Group>
                )}
                <Form.Group
                    className="mb-1 col-sm-6 col-lg-3"
                    controlId="formGridHeadrail"
                >
                  <Form.Label className="mb-0 text-muted">Headrail</Form.Label>
                  {/*<Form.Select*/}
                  {/*    className="select"*/}
                  {/*    size="sm"*/}
                  {/*    value={state.headrail}*/}
                  {/*    name="headrail"*/}
                  {/*    id="headrail"*/}
                  {/*    onChange={handleInput}*/}
                  {/*>*/}
                  {/*  {*/}
                  {/*    headrailList &&*/}
                  {/*    headrailList.length > 0 &&*/}
                  {/*    headrailList*/}
                  {/*        .filter((item) => item.isActive === true && item.blindType === blindType)*/}
                  {/*        .map((item, index) => {*/}
                  {/*          return (*/}
                  {/*              <option key={index} value={item.name}>*/}
                  {/*                {item.name}*/}
                  {/*              </option>*/}
                  {/*          )*/}
                  {/*        })*/}
                  {/*  }*/}
                  {/*</Form.Select>*/}
                  {blindType === "Venetian" ? (
                      <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              Your tape will be matching color of your slat selection.
                            </Tooltip>
                          }
                      >
                        {({ ref, ...triggerHandler }) => (
                            <Form.Select
                                className="select"
                                size="sm"
                                {...triggerHandler}
                                ref={ref}
                                value={state.headrail}
                                name="headrail"
                                id="headrail"
                                onChange={handleInput}
                            >
                              {
                                headrailList &&
                                headrailList.length > 0 &&
                                headrailList
                                    .filter((item) => item.isActive === true && item.blindType === blindType)
                                    .map((item, index) => {

                                      let disabled = false
                                      if (productList) {
                                        const productIndex = productList.findIndex(p => p.blindType === blindType && p.fabric === state.fabric)
                                        if (productIndex > -1) {
                                          const isFaux = productList[productIndex].isFaux
                                          const isTape = productList[productIndex].isTape
                                          if (isFaux) {
                                            if ((item.name === "Wood" || item.name === "WoodTape") || (!isTape && item.name === "FauxWoodTape")) {
                                              disabled = true
                                            }
                                          }
                                          else {
                                            if ((item.name === "FauxWood" || item.name === "FauxWoodTape") || (!isTape && item.name === "WoodTape")) {
                                              disabled = true
                                            }
                                          }
                                        }
                                      }

                                      return (
                                          <option key={index} value={item.name} disabled={disabled}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                        )}
                      </OverlayTrigger>
                  ) : (
                      <Form.Select
                          className="select"
                          size="sm"
                          value={state.headrail}
                          name="headrail"
                          id="headrail"
                          onChange={handleInput}
                      >
                        {
                          headrailList &&
                          headrailList.length > 0 &&
                          headrailList
                              .filter((item) => item.isActive === true && item.blindType === blindType)
                              .map((item, index) => {
                                let isVStick = true
                                if (blindType === "DayAndNight") {
                                  if (productList) {
                                    const productIndex = productList.findIndex(p => p.blindType === blindType && p.fabric === state.fabric)
                                    if (productIndex > -1) {
                                      isVStick = productList[productIndex].isVStick
                                    }
                                  }
                                }

                                let is400 = false
                                if (blindType === "DayAndNight") {
                                  if (state.fabric.startsWith('4')) {
                                    is400 = true
                                  }
                                }

                                return (
                                    <option key={index} value={item.name} disabled={(blindType === "DayAndNight" && item.name === "AnthraciteCovered" && isVStick === false) || (blindType === "DayAndNight" && item.name === "Pelmeted" && is400 === true)}>
                                      {item.name}
                                    </option>
                                )
                              })
                        }
                      </Form.Select>
                  )}
                </Form.Group>
                <Form.Group
                    className="mb-1 col-sm-6 col-lg-3"
                    controlId="formGridHeadrail"
                >
                  <Image src={`/headrails/${state.headrail.toLowerCase()}.png`} onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }} rounded fluid />
                </Form.Group>
                {(blindType === "DayAndNight" || blindType === "Venetian") && state.motor === "None" ? (
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridChains"
                    >
                      <Form.Label className="mb-0 text-muted">Chains</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          value={state.chain}
                          name="chain"
                          id="chain"
                          onChange={handleInput}
                      >
                        {
                          chainList &&
                          chainList.length > 0 &&
                          chainList
                              .filter((item) => item.blindType === blindType && item.isActive === true)
                              .map((item, index) => {
                                return (
                                    <option key={index} value={item.name}>
                                      {/*{item.name} {item.blindType === "DayAndNight" && item.name === "Silver" ? ' / Metal (+£1)' : ''}*/}
                                      {item.name} {item.blindType === "DayAndNight" && item.name === "Silver" ? ' / Metal (+£1)' : (item.blindType === "Venetian" && (item.name === "Brushed Aluminium" || item.name === "Chrome" || item.name === "Brass" ? ` (+£4)` : ''))}
                                    </option>
                                )
                              })
                        }
                      </Form.Select>
                    </Form.Group>
                ) : null}
                {blindType === "Vellision" || blindType === "Vertical" ? (
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridControlOption"
                    >
                      <Form.Label className="mb-0 text-muted">Control Option</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          value={state.controlOption}
                          name="controlOption"
                          id="controlOption"
                          onChange={handleInput}
                      >
                        <option value="Chain">Chain</option>
                        <option value="Wand">Wand</option>
                      </Form.Select>
                    </Form.Group>
                ) : null}
                {((blindType === "DayAndNight" || blindType === "Venetian") && state.motor === "None") || ((blindType === "Vellision" || blindType === "Vertical") && state.controlOption === "Chain") ? (
                    <Form.Group
                        className={`mb-1 col-sm-6 col-lg-3`}
                        controlId="formGridCords"
                    >
                      <Form.Label className="mb-0 text-muted">Chain Length</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          value={state.cord}
                          name="cord"
                          id="cord"
                          onChange={handleInput}
                          style={{ borderColor: `${state.cord === "None" ? "red" : ""}` }}
                      >
                        <option value="None">None</option>
                        {
                          cordList &&
                          cordList.length > 0 &&
                          cordList
                              .filter((item) => item.isActive === true && item.blindType === blindType)
                              .map((item, index) => {
                                return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                )
                              })
                        }
                      </Form.Select>
                    </Form.Group>
                ) : null}
                {(blindType === "Vellision" || blindType === "Vertical") && state.controlOption === "Wand" ? (
                    <Form.Group
                        className={`mb-1 col-sm-6 col-lg-3`}
                        controlId="formGridWands"
                    >
                      <Form.Label className="mb-0 text-muted">Wand Length</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          value={state.wand}
                          name="wand"
                          id="wand"
                          onChange={handleInput}
                          style={{ borderColor: `${state.wand === "None" ? "red" : ""}` }}
                      >
                        <option value="None">None</option>
                        {
                          wandList &&
                          wandList.length > 0 &&
                          wandList
                              .filter((item) => item.isActive === true && item.blindType === blindType)
                              .map((item, index) => {
                                return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                )
                              })
                        }
                      </Form.Select>
                    </Form.Group>
                ) : null}
              </Row>
              {blindType !== "Pleated" ? (
                  <Row className="mb-1">
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridFitting"
                    >
                      <Form.Label className="mb-0 text-muted">Fitting</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          value={state.fitting}
                          name="fitting"
                          id="fitting"
                          onChange={handleInput}
                      >
                        {
                          fittingList &&
                          fittingList.length > 0 &&
                          fittingList
                              .filter((item) => item.isActive === true && ((blindType !== "Venetian" && item.name !== "Normal Fix") || (blindType === "Venetian" && item.name !== "Top Fix")))
                              .map((item, index) => {
                                return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                )
                              })
                        }
                      </Form.Select>
                    </Form.Group>
                    {state.fitting === "Face Fix" ? (
                        <Form.Group
                            className="mb-1 col-sm-6 col-lg-3"
                            controlId="formGridBracketCount"
                        >
                          <Form.Label className="mb-0 text-muted">Ext. Bracket Quantity</Form.Label>
                          <Form.Control
                              size="sm"
                              placeholder="Enter bracket quantity."
                              type="number"
                              min="0"
                              name="bracketCount"
                              value={state.bracketCount}
                              onChange={handleInput}
                              onPaste={(event) => event.preventDefault()}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key))
                                  event.preventDefault()

                                if (event.target.value.toString() === "0")
                                  event.target.value = ""
                              }}
                              pattern="[0-9]+"
                              style={{ borderColor: `${(state.fitting !== "Top Fix" && state.fitting !== "Normal Fix" && state.bracketCount === 0) ? "red" : ""}` }}
                          />
                        </Form.Group>
                    ) : null}
                    {state.fitting === "Face Fix" ? (
                        <Form.Group
                            className="mb-1 col-sm-12 col-lg-6"
                            controlId="formGridBracket"
                        >
                          <Form.Label className="mb-0 text-muted">Ext. Bracket</Form.Label>
                          <Form.Select
                              className="select"
                              size="sm"
                              defaultValue="None"
                              name="bracket"
                              id="bracket"
                              onChange={handleInput}
                              style={{ borderColor: `${(state.fitting !== "Top Fix" && state.fitting !== "Normal Fix" && state.bracket === "None") ? "red" : ""}` }}
                          >
                            <option value="None">None</option>
                            {
                              bracketList &&
                              bracketList.length > 0 &&
                              bracketList
                                  .filter((item) => item.isActive === true && item.blindType === blindType)
                                  .map((item, index) => {
                                    return (
                                        <option key={index} value={item.name}>
                                          {item.name} (£ {item.price})
                                        </option>
                                    )
                                  })
                            }
                          </Form.Select>
                        </Form.Group>
                    ) : null}
                  </Row>
              ) : null}
              {(blindType === "DayAndNight" || blindType === "Venetian") ? (
                  <Row className="mb-1">
                    <Form.Group
                        className="mb-1 col-sm-6 col-lg-3"
                        controlId="formGridMotor"
                    >
                      <Form.Label className="mb-0 text-muted">Motor</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          defaultValue="None"
                          defaultChecked="None"
                          name="motor"
                          id="motor"
                          onChange={handleInput}
                      >
                        <option value="None">None</option>
                        {
                          motorList &&
                          motorList.length > 0 &&
                          motorList
                              .filter((item) => item.isActive === true)
                              .map((item, index) => {
                                return (
                                    <option key={index} value={item.name}>
                                      {item.name} {blindType === "Venetian" && item.name === "Rechargeable" ? "(Tilt Only)" : ""}
                                    </option>
                                )
                              })
                        }
                      </Form.Select>
                    </Form.Group>
                    {state.motor !== "None" ? (
                        <>
                          <Form.Group
                              className="mb-1 col-sm-6 col-lg-3"
                              controlId="formGridCharger"
                          >
                            <Form.Label className="mb-0 text-muted">Charger Count</Form.Label>
                            <Form.Control
                                size="sm"
                                placeholder="Enter charger count."
                                type="number"
                                min="0"
                                name="charger"
                                value={state.charger}
                                onChange={handleInput}
                                onPaste={(event) => event.preventDefault()}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key))
                                    event.preventDefault()

                                  if (event.target.value.toString() === "0")
                                    event.target.value = ""
                                }}
                                pattern="[0-9]+"
                                style={{ borderColor: `${(state.charger === 0 && state.remote !== "None") ? "red" : ""}` }}
                            />
                          </Form.Group>
                          <Form.Group
                              className="mb-1 col-sm-12 col-lg-6"
                              controlId="formGridRemote"
                          >
                            <Form.Label className="mb-0 text-muted">Remote</Form.Label>
                            <Form.Select
                                className="select"
                                size="sm"
                                defaultValue="None"
                                name="remote"
                                id="remote"
                                onChange={handleInput}
                                style={{ borderColor: `${(state.charger !== 0 && state.remote === "None") ? "red" : ""}` }}
                            >
                              <option value="None">None</option>
                              {
                                remoteList &&
                                remoteList.length > 0 &&
                                remoteList
                                    .filter((item) => item.blindType === blindType && item.isActive === true)
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                        </>
                    ) : null}
                  </Row>
              ) : null}
              <Row className="mb-1">
                {(blindType !== "Pleated" && blindType !== "Venetian") ? (
                    <Form.Group
                        className="mb-1 col-sm-12 col-lg-6"
                        controlId="formGridControl"
                    >
                      <Form.Label className="mb-0 text-muted">Control Side</Form.Label>
                      <Form.Select
                          className="select"
                          size="sm"
                          defaultValue="Left"
                          name="control"
                          id="control"
                          onChange={handleInput}
                      >
                        <option value="Left">Left</option>
                        <option value="Right">Right</option>
                      </Form.Select>
                    </Form.Group>
                ) : null}
                <Form.Group className="mb-1 col-sm-12 col-lg-6 d-flex flex-column ">
                  <Form.Label className="mb-0 text-muted">Comments</Form.Label>
                  <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Comments"
                      size="sm"
                  >
                    <Form.Control
                        size="sm"
                        as="textarea"
                        placeholder="Leave your comment here"
                        name="notes"
                        value={state.notes}
                        style={{ height: "75px" }}
                        onChange={handleInput}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </>
        )}
        <Row className="mb-1">
          <Form.Group
              className="mb-1 col-sm-6 col-lg-3 d-flex flex-column "
              controlId="formGridCalculate"
          >
            <Button
                onClick={handleClear}
                variant="outline-zebra"
                size="sm"
                type="button"
                style={{ marginBottom: "5px" }}
            >
              Clear Form
            </Button>
          </Form.Group>
          <Form.Group
              className="mb-1 col-sm-6 col-lg-3 d-flex flex-column "
              controlId="formGridCalculate"
          >
            <Button
                onClick={handleCalculate}
                variant="secondary"
                size="sm"
                type="button"
                style={{ maxWidth: "200px" }}
                disabled={
                  isBlindIncomplete(productList, headrailType, state, doubleState, warnWindow, doubleWarnWindow, warnWidth, doubleWarnWidth, warnDrop, doubleWarnDrop)
                }
            >
              Calculate Price
            </Button>
          </Form.Group>
          <Form.Group
              className="mb-1 col-sm-12 col-lg-6 d-flex justify-content-end "
              controlId="formGridCalculate"
          >
            {(
                isBlindIncomplete(productList, headrailType, state, doubleState, warnWindow, doubleWarnWindow, warnWidth, doubleWarnWidth, warnDrop, doubleWarnDrop)
            ) && (
                <div className="d-flex justify-content-center mt-2 align-items-center">
                  <Form.Text className="me-2" type="invalid" style={{ color: "red" }}>
                    Please fill in the form correctly
                  </Form.Text>
                  {
                    (userCarts && userCarts.length === 0) || isBlindIncomplete(productList, headrailType, state, doubleState, warnWindow, doubleWarnWindow, warnWidth, doubleWarnWidth, warnDrop, doubleWarnDrop) ?
                        <OverlayTrigger
                            notes={state.notes}
                            overlay={
                              <Tooltip id="tooltip-right">
                                {userCarts && userCarts.length === 0
                                    ? "Please add a new cart"
                                    : (headrailType === "Single" && state.window === "") || (headrailType === "Double" && doubleState.map(state => state.window).some(i => i === ""))
                                        ? "Please enter window name"
                                        : (headrailType === "Single" && warnWindow !== "") || (headrailType === "Double" && doubleWarnWindow.some(i => i !== ""))
                                            ? "Please check your window name"
                                            : (headrailType === "Single" && !state.width) || (headrailType === "Double" && doubleState.map(p => p.width).some(i => !i))
                                                ? "Please enter width"
                                                : (headrailType === "Single" && !state.drop) || (headrailType === "Double" && doubleState.map(p => p.drop).some(i => !i))
                                                    ? "Please enter drop"
                                                    : (headrailType === "Single" && warnWidth !== "") || (headrailType === "Double" && doubleWarnWidth.some(i => i !== ""))
                                                        ? "Please check your dimensions"
                                                        : (headrailType === "Single" && warnDrop !== "") || (headrailType === "Double" && doubleWarnDrop.some(i => i !== ""))
                                                            ? "Please check your dimensions"
                                                            : (headrailType === "Single" && state.fabric === "Select...") || (headrailType === "Double" && doubleState.map(state => state.fabric).some(i => i === "Select..."))
                                                                ? "Please select fabric"
                                                                : (headrailType === "Double" && state.blindType === "DayAndNight" && doubleState.map(state => state.fabric).every(i => !productList.filter(p => p.isRStick === true).map(product => product.fabric).includes(i)))
                                                                    ? "Please select one roller fabric at least"
                                                                    : (headrailType === "Double" && state.blindType === "Pleated" && doubleState.map(state => state.fabric).every(i => !productList.filter(p => p.isNet === true).map(product => product.fabric).includes(i)))
                                                                        ? "Please select one fabric with net at least"
                                                                        : (headrailType === "Double" && state.blindType === "Pleated" && doubleState.map(state => state.fabric).every(i => productList.filter(p => p.isNet === true).map(product => product.fabric).includes(i)))
                                                                            ? "Please select one fabric without net at least"
                                                                            : (headrailType === "Single" && (((state.blindType === "DayAndNight" || state.blindType === "Venetian") && (state.motor === "None" && state.cord === "None")) || ((state.blindType === "Vellision" || state.blindType === "Vertical") && state.controlOption === "Chain" && state.cord === "None"))) || (headrailType === "Double" && state.blindType === "DayAndNight" && doubleState.map(state => state.cord).some(i => i === "None"))
                                                                                ? "Please select cord"
                                                                                : (headrailType === "Single" && (state.blindType === "Vellision" || state.blindType === "Vertical") && state.controlOption === "Wand" && state.wand === "None")
                                                                                    ? "Please select wand"
                                                                                    : (headrailType === "Single" && state.fitting !== "Top Fix" && state.fitting !== "Normal Fix" && (state.bracketCount === 0 || state.bracket === "None")) || (headrailType === "Double" && doubleState.map(state => ({ fitting: state.fitting, bracketCount: state.bracketCount, bracket: state.bracket })).some(i => i.fitting !== "Top Fix" && (i.bracketCount === 0 || i.bracket === "None")))
                                                                                        ? "Please select a bracket and enter bracket count"
                                                                                        : (headrailType === "Single" && (state.blindType === "DayAndNight" || state.blindType === "Venetian") && state.motor !== "None" && ((state.charger !== 0 && state.remote === "None") || (state.charger === 0 && state.remote !== "None")))
                                                                                            ? "Please select a remote and enter charger count"
                                                                                            : "Please fill in the form correctly"
                                }
                              </Tooltip>
                            }
                        >
                          <i className="fa fa-lg fa-question-circle text-muted"/>
                        </OverlayTrigger> :
                        ""
                  }
                </div>
            )}
          </Form.Group>
        </Row>
      </Form>
      {((headrailType === "Single" && prices.unitPrice) || (headrailType === "Double" && doublePrices.map(p => p.unitPrice).every(x => !!x))) && (
        <Row>
          <HomeList
            headrailType={headrailType}
            productList={productList}
            product={state}
            doubleProduct={doubleState}
            prices={prices}
            doublePrices={doublePrices}
            warnWindow={warnWindow}
            doubleWarnWindow={doubleWarnWindow}
            warnWidth={warnWidth}
            doubleWarnWidth={doubleWarnWidth}
            warnDrop={warnDrop}
            doubleWarnDrop={doubleWarnDrop}
            // handleCart={handleCart}
            handleCart={(e) =>{
              if (warnWidthBiggerThanTwoThousand || doubleWarnWidthBiggerThanTwoThousand) {
                const Swal = require('sweetalert2')

                Swal.fire({
                  title: "WARNING",
                  text: "Your width size is bigger than 2000 mm. Are you sure to proceed?",
                  showDenyButton: true,
                  confirmButtonText: `<i class="fa fa-thumbs-up"></i> Yes`,
                  denyButtonText: `<i class="fa fa-thumbs-down"></i> No`,
                  confirmButtonColor: "#3085d6",
                  denyButtonColor: "#777",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleCart(e)
                  }
                })
              }
              else {
                handleCart(e)
              }
            }}
            handleCalculate={handleCalculate}
          />
        </Row>
      )}
      <hr/>
      {userCarts && userCarts.length > 0 && (
        <>
          <Row>
            <h5 className="mb-1 col-sm-6 col-lg-3">Cart Items</h5>
          </Row>
          <Row>
            {
              userCart &&
              userCart.cartItems &&
              userCart.cartItems.length > 0 &&
              userCart.cartItems.map((item, index) => {
                return (
                  <Col key={index} xs={6} sm={6} md={3} lg={3} xl={3} className="d-flex">
                    <HomeCart
                      productList={productList}
                      cartProduct={item}
                      deleteFromCart={deleteFromCart}
                    />
                  </Col>
                )
              })
            }
          </Row>
          <Row
            className="d-flex text-center"
            style={{ justifyContent: "center" }}
          >
            <LinkContainer to="/cart" style={{ maxWidth: "200px" }}>
              <Button
                title="Go to carts to proceed order"
                variant="primary"
                type="button"
                disabled={
                  userCart &&
                  userCart.cartItems &&
                  userCart.cartItems.length === 0
                }
              >
                Go to Carts
              </Button>
            </LinkContainer>
          </Row>
        </>
      )}
    </div>
  )
}

export default Home
