import React from "react";
// Bootstrap Components
import { Tabs, Tab } from "react-bootstrap";

import ProfileDetails from "../components/ProfileDetails";
import AddressDetails from "../components/AddressDetails";

const Profile = () => {
  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      <Tabs
        defaultActiveKey="profile"
        id="tab-example"
        className="mb-3"
      >
        <Tab eventKey="profile" title="My Profile">
          <ProfileDetails />
        </Tab>
        <Tab eventKey="address" title="Addresses">
          <AddressDetails />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Profile;
