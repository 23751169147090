import React, { useState, useEffect } from "react";
// Bootstrap Components
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
// Redux
import { useDispatch, useSelector } from "react-redux";
// UI Components
import Message from "./Message";
import Loader from "./Loader";
// Redux Actions
import { getUserDetails } from "../actions/userActions";
import { getOneItem } from "../actions/sameActions";
import { addAddress, deleteAddress } from "../actions/addressAction";
import { useMediaQuery } from "react-responsive";

export default function AddressDetails() {
  const [isAddressAdd, setIsAddressAdd] = useState(false);

  const initialState = {
    address_name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    mobile: "",
    telephone: "",
    postal_code: "",
  };

  const emptyState = {
    address_name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    mobile: "",
    telephone: "",
    postal_code: "",
  };

  // const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isLarge = useMediaQuery({ query: "(max-width: 768px)" });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [state, setState] = useState(initialState);
  // console.log(state);

  const dispatch = useDispatch();
  const { addresses } = useSelector((store) => store.userAddresses);
  //console.log(addresses);
  const [userAddressesState, setUserAddressesState] = useState([]);

  // Get user details from Redux store
  const userDetails = useSelector((state) => state.userDetails);
  const { loading } = userDetails;

  // Get update status on user info
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(initialState);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getOneItem("address"));
    // dispatch(listUserOrders());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    setUserAddressesState(addresses);
  }, [addresses]);

  const handleInput = (e) => {
    if (e.target.name === "postal_code") {
      setState({ ...state, [e.target.name]: e.target.value.toUpperCase() });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  // Handler for address
  const submitAddressHandler = (e) => {
    e.preventDefault();
    // console.log("Add address button worked.");

    if (
      (state.telephone === "" && state.mobile === "") ||
      (state.telephone !== "" && state.telephone.length !== 10) ||
      (state.mobile !== "" && state.mobile.length !== 10)
    ) {
      setError(
        "You have to type minimum one phone number. Phone number must be  10 characters. (Don't type +44)"
      );
      setTimeout(() => {
        setError("");
      }, 5000);
    } else {
      dispatch(addAddress(state)).then((response, error) => {
        //console.log("address details ==>", response);

        if (response && response.status === 200) {
          setError("");
          setMessage(response.msg);
          setIsAddressAdd(false);
          setState(emptyState);
          dispatch(getOneItem("address"));
          setTimeout(() => {
            setMessage("");
          }, 5000);
        } else if (response && response.status === 400) {
          setMessage("");
          setError(response.msg);
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      });
    }
  };

  const handleDelete = (item) => {
    setSelected(item);
    handleShow();
  };
  const handledeleteAdrress = () => {
    dispatch(deleteAddress(selected._id)).then((response) => {
      if (response.status === 200) {
        handleClose();
        setMessage(response.msg);
        dispatch(getOneItem("address"));
        setSelected(initialState);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    });
  };
  //console.log(userAddressesState);

  return (
    <div>
      <div className="container mt-3">
        <div className="d-flex justify-content-between">
          <h2>My Addresses</h2>
          <Button
            type="button"
            variant="warning"
            className="mt-2 mb-3 bg-zebra"
            onClick={() => setIsAddressAdd(true)}
          >
            Add New Address
          </Button>
        </div>
        <Table striped bordered hover className="border-zebra">
          <thead>
            <tr>
              <th>
                <img src="favicon.ico" alt="favicon" />
              </th>
              <th>Address Name</th>

              {isTablet && <th>Address Line 1</th>}
              {isTablet && <th>Address Line 2</th>}
              {isTablet && <th>Postal Code</th>}
              {isTablet && <th>City</th>}
              {isTablet && <th>Country</th>}
              {isTablet && <th>Mobile</th>}
              {isTablet && <th>Telephone</th>}
              {isLarge && <th>Address</th>}

              <th>
                <span className="text-danger">Del</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {userAddressesState &&
              userAddressesState.map((item, index) => {
                return (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.address_name}</td>

                    {isTablet && <td>{item.address_line1}</td>}
                    {isTablet && <td>{item.address_line2}</td>}
                    {isTablet && <td>{item.postal_code}</td>}
                    {isTablet && <td>{item.city}</td>}
                    {isTablet && <td>{item.country}</td>}
                    {isTablet && <td>{item.mobile}</td>}
                    {isTablet && <td>{item.telephone}</td>}
                    {isLarge && (
                      <td>
                        {item.address_line1 +
                          " " +
                          item.address_line2 +
                          " " +
                          item.postal_code +
                          " " +
                          item.city +
                          " " +
                          item.country}
                      </td>
                    )}
                    {/* <td>{item._id}</td> */}
                    <td>
                      <button
                        type="button"
                        onClick={() => handleDelete(item)}
                        className="btn btn-sm mx-auto"
                      >
                        <i className="fa fa-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are u sure deleting selected address?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handledeleteAdrress}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        {error && <Message variant="danger">{error}</Message>}
        {message && <Message variant="success">{message}</Message>}
        {loading && <Loader />}
        {isAddressAdd ? (
          <Row>
            <Col className="text-center mx-auto mt-2" xs={12} sm={8} md={6}>
              <div className="text-center mx-auto mt-2" xs={12} sm={8} md={6}>
                <h2>Add New Address</h2>

                <Form onSubmit={submitAddressHandler}>
                  <Form.Group controlId="address_name">
                    <Form.Label className="mt-2 text-secondary">
                      Adrress Name{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            This field required.
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=" Enter Adress Name"
                      name="address_name"
                      value={state.address_name}
                      onChange={handleInput}
                      required
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="address_line1">
                    <Form.Label className="mt-2 text-secondary">
                      Address Line 1{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            This field required.
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your address..."
                      name="address_line1"
                      value={state.address_line1}
                      onChange={handleInput}
                      required
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="address_line2">
                    <Form.Label className="mt-2 text-secondary">
                      Address Line 2
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your address..."
                      name="address_line2"
                      value={state.address_line2}
                      onChange={handleInput}
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="city">
                    <Form.Label className="mt-2 text-secondary">
                      City{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            This field required.
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={state.city}
                      onChange={handleInput}
                      required
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="country">
                    <Form.Label className="mt-2 text-secondary">
                      Country
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      placeholder="Country"
                      value={state.country}
                      onChange={handleInput}
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="mobile">
                    <Form.Label className="mt-2 me-2 text-secondary">
                      GSM Number{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            You have to type at least one phone number. Phone
                            number can be 10 characters, please enter without
                            (+44).
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your GSM number"
                      name="mobile"
                      value={state.mobile}
                      onChange={handleInput}
                      pattern="[0-9]{9,13}"
                      maxLength="13"
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="telephone">
                    <Form.Label className="mt-2 text-secondary">
                      Phone{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            You have to type at least one phone number. Phone
                            number can be 10 characters, please enter without
                            (+44).
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Phone Number"
                      name="telephone"
                      value={state.telephone}
                      onChange={handleInput}
                      // pattern="[0-9]{9,13}"
                      pattern="[0-9]{10,11}"
                      // maxLength="13"
                      maxLength="11"
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="postal_code">
                    <Form.Label className="mt-2 text-secondary">
                      Postal Code{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            This field required. Postal Codes Example Types:{" "}
                            <br />
                            AA9A 9AA <br />
                            A9A 9AA <br />
                            A9 9AA <br />
                            A99 9AA <br />
                            AA9 9AA <br />
                            AA99 9AA
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <i
                            {...triggerHandler}
                            ref={ref}
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i>
                        )}
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Postal Code"
                      name="postal_code"
                      value={state.postal_code}
                      onChange={handleInput}
                      pattern="^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$"
                      required
                      className="border-zebra"
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="warning mt-3 mx-auto d-flex"
                    className="mt-3 mb-5 bg-zebra"
                  >
                    Add Address
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  );
}
