import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_COMPLETED_REQUEST,
  ORDER_LIST_COMPLETED_SUCCESS,
  ORDER_LIST_COMPLETED_FAIL,
  ORDER_LIST_USER_REQUEST,
  ORDER_LIST_USER_SUCCESS,
  ORDER_LIST_USER_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_ALL_REQUEST,
  ORDER_LIST_ALL_SUCCESS,
  ORDER_LIST_ALL_FAIL,
} from "../constants/orderConstants";
import { API_BASE_URL } from "../constants/apiBase";

import axios from "axios"
import { serializeQuery } from "../utils/common"

export const createOrder = (order) => async (dispatch, getState) => {
  let result

  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const userIP = localStorage.getItem("userIP")

    const config = {
      headers: {
        "Content-Type": "application/json",
        'user-ip': userIP,
        Authorization: `${userInfo.accesstoken}`,
      },
    }

    const { data } = await axios.post(`${API_BASE_URL}/api/order`, order, config)
    result = data

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    result = error.response.data

    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: error.response.data.msg,
      // error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message,
    })
  }

  return result
}

export const getOrderDetails = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    // Get user login to get the bearertoken
    const {
      userLogin: { userInfo },
    } = getState();

    const userIP = localStorage.getItem("userIP");
    // Header to send with the request
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'user-ip': userIP,
      },
    };
    // Make request to server and get the response data
    const { data } = await axios.get(
      `${API_BASE_URL}/api/orders/${orderId}`,
      config
    );
    // console.log(data)
    // Dispatch user order success after making the request
    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const payOrder =
  (orderId, paymentResult) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_DETAILS_REQUEST,
      });

      // Get user login to get the bearertoken
      const {
        userLogin: { userInfo },
      } = getState();
      const userIP = localStorage.getItem("userIP");
      // Header to send with the request
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'user-ip': userIP,
        },
      };

      // Make request to server and get the response data
      const { data } = await axios.get(
        `${API_BASE_URL}/api/orders/${orderId}`,
        config
      );

      // Dispatch user order success after making the request
      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listAllOrders = (queryObject = null) => async (dispatch, getState) => {
  let result

  try {
    dispatch({
      type: ORDER_LIST_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const userIP = localStorage.getItem("userIP")

    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
        'user-ip': userIP,
      },
    }

    let url = ""
    if (queryObject) {
      let serializedQueryObject = serializeQuery(queryObject)

      url = `${API_BASE_URL}/api/order?${serializedQueryObject}`
    }
    else {
      url = `${API_BASE_URL}/api/order?isActive=true&isDeleted=false`
    }

    const response = await axios.get(
        url,
        config
    )
    result = response

    dispatch({
      type: ORDER_LIST_ALL_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    result = error.response

    dispatch({
      type: ORDER_LIST_ALL_FAIL,
      payload:
          error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
    })
  }

  return result
}

export const listUserOrders = () => async (dispatch, getState) => {
  // //console.log("list user orders calıştı")
  try {
    dispatch({
      type: ORDER_LIST_USER_REQUEST,
    });

    // Get user login to get the bearer token
    const {
      userLogin: { userInfo },
    } = getState();

    const userIP = localStorage.getItem("userIP");
    // Header to send with the request
    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
        'user-ip': userIP,
      },
    };

    // Make request to server and get the response data
    const { data } = await axios.get(
      `${API_BASE_URL}/api/order/user?isDeleted=false`,
      config
    );
    // console.log(data)
    // Dispatch user order pay success after making the request
    dispatch({
      type: ORDER_LIST_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listOrders = (state) => async (dispatch, getState) => {
  // console.log("list all==>", state);
  const { company, email, status, startAt, endAt } = state && state.filteredValue;
  // console.log(
  //     'company: ' + company +
  //     '\nemail: ' + email +
  //     '\nstatus: ' + status +
  //     '\nstartAt: ' + startAt +
  //     '\nendAt: ' + endAt
  // );
  let stateData = {}

  if (status) {
    if (company && email){
      stateData = {
        "filter": {
          "isDeleted": false,
          "user.company": company,
          "user.email": email,
          "status": status
        },
        "skip": 10 * (state.currentPage - 1),
      };
    } else if (company && !email){
      stateData = {
        "filter": {
          "isDeleted": false,
          "user.company": company,
          "status": status
        },
        "skip": 10 * (state.currentPage - 1),
      };
    } else if(!company && email){
      stateData = {
        "filter": {
          "isDeleted": false,
          "user.email": email,
          "status": status
        },
        "skip": 10 * (state.currentPage - 1),
      };
    } else {
      stateData = {
        "filter": {
          "isDeleted": false,
          "status": status
        },
        "skip": 10 * (state.currentPage - 1),
      };
    }
  } else {
    if (company && email){
      stateData = {
        "filter": {
          "isDeleted": false,
          "user.company": company,
          "user.email": email,
          "isActive": true
        },
        "skip": 10 * (state.currentPage - 1),
      };
    } else if (company && !email){
      stateData = {
        "filter": {
          "isDeleted": false,
          "user.company": company,
          "isActive": true
        },
        "skip": 10 * (state.currentPage - 1),
      };
    } else if (!company && email){
      stateData = {
        "filter": {
          "isDeleted": false,
          "user.email": email,
          "isActive": true
        },
        "skip": 10 * (state.currentPage - 1),
      };
    } else {
      stateData = {
        "filter": {
          // "createdAt": { $lte: new Date("2022-06-02T00:00:00.000Z"), $gte: new Date("2021-06-02T00:00:00.000Z") },
          "isDeleted": false,
          "isActive": true
        },
        "skip": 10 * (state.currentPage - 1),
      };
    }

    if (startAt && endAt)
      stateData['filter']['createdAt1'] = { $lte: new Date(`${endAt} 23:59:59`), $gte: new Date(`${startAt} 00:00:00`) }
  }

  //console.log(stateData);
  let result;
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    });

    // Get user login to get the bearer token
    const {
      userLogin: { userInfo },
    } = getState();
    const userIP = localStorage.getItem("userIP");
    // //console.log(userInfo)
    // Header to send with the request
    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
        'user-ip': userIP,
      },
    };

    // Make request to server and get the response data
    const { data } = await axios.post(
      `${API_BASE_URL}/api/order/combine`,
      stateData,
      config
    );
    //console.log(data[0])
    //console.log(data.result.length)
    // Dispatch user order pay success after making the request
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data[0],
    });

    result = data[0];
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
  return result;
};

export const listOrdersCompleted = (state) => async (dispatch, getState) => {
  // console.log("list all completed==>", state);
const {company, email, status} = state && state.filterWord;
//console.log(company, email, status);
let stateData ={}

if(status) {
  if(company && email){
  stateData = {
    "filter": {
      "isDeleted":false,
      "user.company": company,
      "user.email": email,
      "status":status
    },
      "skip":10*(state.currentPage-1),
  };
} else if(company && !email){
  stateData = {
    "filter": {
      "isDeleted":false,
      "user.company": company,
      "status":status
    },
      "skip":10*(state.currentPage-1),
    };
} else if(!company && email){
  stateData = {
    "filter": {
      "isDeleted":false,
      "user.email": email,
      "status":status
    },
      "skip":10*(state.currentPage-1),
  };
} else {
  stateData = {
    "filter": {
      "isDeleted":false,
      "status":status
    },
    "skip":10*(state.currentPage-1),
  };
}
} else {
  if(company && email){
    stateData = {
      "filter": {
        "isDeleted":false,
        "user.company": company,
        "user.email": email
      },
        "skip":10*(state.currentPage-1),
    };
  } else if(company && !email){
    stateData = {
      "filter": {
        "isDeleted":false,
        "user.company": company
      },
        "skip":10*(state.currentPage-1),
      };
  } else if(!company && email){
    stateData = {
      "filter": {
        "isDeleted":false,
        "user.email": email
      },
        "skip":10*(state.currentPage-1),
    };
  } else {
    stateData = {
      "filter": {
        "isDeleted":false,
        "isActive": true
      },
      "skip":10*(state.currentPage-1),
    };
  }
  }

//console.log(stateData);
  let result;
  try {
    dispatch({
      type: ORDER_LIST_COMPLETED_REQUEST,
    });

    // Get user login to get the bearer token
    const {
      userLogin: { userInfo },
    } = getState();
    const userIP = localStorage.getItem("userIP");
    // //console.log(userInfo)
    // Header to send with the request
    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
        'user-ip': userIP,
      },
    };

    // Make request to server and get the response data
    const { data } = await axios.post(
      `${API_BASE_URL}/api/order/combine`,
      stateData,
      config
    );
    //console.log(data[0])
    //console.log(data.result.length)
    // Dispatch user order pay success after making the request
    dispatch({
      type: ORDER_LIST_COMPLETED_SUCCESS,
      payload: data[0],
    });

    result = data[0];
  } catch (error) {
    dispatch({
      type: ORDER_LIST_COMPLETED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
  return result;
};

export const updateOrder = (state) => async (dispatch, getState) => {
  let result;
  const {
    userLogin: { userInfo },
  } = getState();
  const userIP = localStorage.getItem("userIP");
  if (userInfo) {
    try {
      dispatch({
        type: `ORDER_PUT_REQUEST`,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          'user-ip': userIP,
          Authorization: `${userInfo.accesstoken}`,
        },
      };

      //Make request to server and get the response data
      const { data } = await axios.post(
        `${API_BASE_URL}/api/order/${state._id}`,
        state,
        config
      );

      result = data;
      dispatch({
        type: `ORDER_PUT_SUCCESS`,
        payload: data,
      });
    } catch (error) {
      // //console.log(error)
      dispatch({
        type: `ORDER_PUT_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
  return result;
};

export const deleteOrder = (id) => async (dispatch, getState) => {
  let result;
  const {
    userLogin: { userInfo },
  } = getState();
  const userIP = localStorage.getItem("userIP");
  if (userInfo) {
    try {
      dispatch({
        type: `ORDER_DELETE_REQUEST`,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          'user-ip': userIP,
          Authorization: `${userInfo.accesstoken}`,
        },
      };

      //Make request to server and get the response data
      // const { data } = await axios.delete(
      //   `${API_BASE_URL}/api/order/${id}`,
      //   config
      // );
      // Changed DELETE request to a POST request because Mod Security prevents them
      const { data } = await axios.post(
          `${API_BASE_URL}/api/order/delete/${id}`,
          {},
          config
      );

      result = data;
      dispatch({
        type: `ORDER_DELETE_SUCCESS`,
        payload: data,
      });
    } catch (error) {
      // //console.log(error)
      dispatch({
        type: `ORDER_DELETE_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
  return result;
};


export const listCompanyNames = () => async (dispatch, getState) => {
let result;
  try {
    // Get user login to get the bearer token
    const {
      userLogin: { userInfo },
    } = getState();
    const userIP = localStorage.getItem("userIP");
    // Header to send with the request
    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
        'user-ip': userIP,
      },
    };
    // Make request to server and get the response data
    const { data } = await axios.get(
      `${API_BASE_URL}/user/allComp`,
      config
    );
    //console.log(data)
    // Dispatch user order pay success after making the request
    result = data;
  } catch (error) {
    result = error;
  }
  return result;
};
