import React from "react";
import { Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportCSV = ({ content, disabled }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = content.user.company + "_" + content.order_name;
  // const csvData = [content]

  const productionData = (orderItems) => {
    return orderItems.map((item, index) => {
      const drop = (Number(item.drop) / 10).toFixed(1);
      const width = (Number(item.width) / 10).toFixed(1);
      const newFormat = {
        p_windows: item.window,
        p_drop: Number(drop),
        p_last_width: Number(width),
        p_fabric_width: item.headrail === "Open" ? width - 4 : width - 3.1,
        p_pipe_width: item.headrail === "Open" ? width - 3 : width - 2.1,
        p_frame_width: item.headrail === "Open" ? width - 1.6 : width - 0.6,
        p_thin_stick: item.headrail === "Open" ? width - 3.9 : width - 3,
        p_wide_stick: item.headrail === "Open" ? width - 3.8 : width - 2.9,
        p_blind_no: "",
        p_design_code: item.fabric,
        p_control_side: item.control,
        p_frame: item.headrail,
        p_note: item.notes,
        p_assemble: "",
        p_check: "",
      };
      return newFormat;
    });
  };

  // const newOrderData = {
  //   user:"XXX Blinds",
  //   userID: 12345,
  //   orderPrice:500,
  //   orderItems: [
  //     {
  //       windows: "kitchen",
  //       width: 3000,
  //       drop: 2000,
  //       fabric: "101",
  //       frame: "Open",
  //       tassel: null,
  //       stick: "v",
  //       control: "Left",
  //       motor: "r",
  //       note: "",
  //     },
  //   ],
  // };

  const orderData = (orderItems) => {
    return orderItems.map((item, index) => {
      const drop = (Number(item.drop) / 10).toFixed(1);
      const width = (Number(item.width) / 10).toFixed(1);
      const headrailNo =
        item.headrail === "Open"
          ? 1
          : item.headrail === "WhiteCovered"
          ? 2
          : item.headrail === "Pelmeted"
          ? 3
          : 4;
      const tasselNo =
        item.tassel === "Beaded" ? 1 : item.tassel === "No-Beaded" ? 2 : 0;
      const newFormat = {
        o_windows: item.window,
        o_width: Number(width),
        o_drop: Number(drop),
        o_code_number: `${headrailNo}${tasselNo}${item.fabric}`,
        o_motor_type: item.motor === "None" ? 0 : "r",
        o_control_side: item.control === "Left" ? "l" : "r",
        o_note: item.notes,
      };
      return newFormat;
    });
  };

  const csvDataProduction = productionData(content.orderItem);
  const csvDataOrder = orderData(content.orderItem);

  const exportToCSV = (csvDataProduction, csvDataOrder, fileName) => {
    const wsp = XLSX.utils.json_to_sheet(csvDataProduction);
    const wso = XLSX.utils.json_to_sheet(csvDataOrder);
    const wb = {
      Sheets: { production: wsp, order: wso },
      SheetNames: ["production", "order"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Button
        variant="warning"
        size="sm"
        onClick={(e) => exportToCSV(csvDataProduction, csvDataOrder, fileName)}
        disabled={disabled}
      >
        Export
      </Button>
      {/* <Button variant="warning">
            <CSVLink data={csvData} filename={fileName}>Export</CSVLink>
        </Button> */}
    </>
  );
};
