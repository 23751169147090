// ATTENTION! Calculations have changed at 2023-07-04.
// Motor, bracket and additional price have been added the very last.
export const handleCalc = (state) => (dispatch, getState) => {
  let calculatedPrice = 0
  let calculatedDiscountPrice = 0
  let calculatedDiscountPriceWithoutExtraUserDiscount = 0

  const {
    userDetails: { user },
  } = getState()
  const {
    productList: { products },
  } = getState()
  const {
    bracketList: { brackets },
  } = getState()
  const {
    motorList: { motors },
  } = getState()
  const {
    remoteList: { remotes },
  } = getState()
  const {
    tasselList: { tassels },
  } = getState()
  const {
    headrailList: { headrails },
  } = getState()

  const user_discount =
      user && user.user && user.user.user_discount ? user.user.user_discount : 0
  const product =
    products && products.find((item) => item.fabric === state.fabric)

  const bracket = brackets && brackets.find(item => item.blindType === state.blindType && item.name === state.bracket)
  const bracketPrice = state.bracket === "None" ? 0 : state.bracketCount * (bracket && bracket.price)

  const motor = motors && motors.find((item) => item.name === state.motor)
  const chargerPrice = state.charger === "" ? 0 : (12 * parseInt(state.charger))
  const remote = remotes && remotes.find(item => item.blindType === state.blindType && item.name === state.remote)
  const remotePrice = state.remote === "None" ? 0 : remote && remote.price
  const motorPrice = state.motor === "None" ? 0 : motor && (motor.price + chargerPrice + remotePrice)

  const tassel = tassels && tassels.find((item) => item.name === state.tassel)
  const tasselPrice = state.tassel === "None" ? 0 : tassel && tassel.price
  const headrail =
      headrails && headrails.find((item) => item.blindType === state.blindType && item.name === state.headrail)
  const subventionPrice = state.headrail ? headrail?.subvention : 38
  const square =
    state.width && state.drop
      ? (Number(state.width) / 1000) * ((Number(state.drop) + 100) / 1000)
      : 0

  const additionalChainPrice = state.blindType === "DayAndNight" && state.chain === "Silver" ? 1 : (state.blindType === "Venetian" && (state.chain === "Brushed Aluminium" || state.chain === "Chrome" || state.chain === "Brass") ? 4 : 0)

  // console.log(state)
  // console.log("square==>", square)
  // console.log("product==>", product)
  // console.log("motorPrice==>", motorPrice)
  // console.log("bracketPrice==>", bracketPrice)
  // console.log("additionalChainPrice==>", additionalChainPrice)
  // console.log("tasselPrice==>", tasselPrice)
  // console.log("subventionPrice==>", subventionPrice)
  // console.log('user_discount==>', user_discount)

  if (product && motorPrice >= 0 && tasselPrice >= 0) {
    calculatedPrice =
      state.headrail === "Open"
        ? ((product.price_open + tasselPrice) * square) + subventionPrice
        : state.headrail === "WhiteCovered"
            ? ((product.price_covered + tasselPrice) * square) + subventionPrice
            : state.headrail === "Pelmeted"
                ? ((product.price_pelmeted + tasselPrice) * square) + subventionPrice
                : state.headrail === "GreyCovered"
                    ? ((product.price_grey + tasselPrice) * square) + subventionPrice
                    : state.headrail === "AnthraciteCovered"
                        ? ((product.price_anthracitecovered + tasselPrice) * square) + subventionPrice
                        : state.headrail === "White"
                            ? ((product.price_white + 0) * square) + subventionPrice
                            : state.headrail === "Anthracite"
                                ? ((product.price_anthracite + 0) * square) + subventionPrice
                                  : state.headrail === "P-White"
                                      ? ((product.price_p_white + 0) * square) + subventionPrice
                                      : state.headrail === "P-Black"
                                          ? ((product.price_p_black + 0) * square) + subventionPrice
                                          : state.headrail === "FauxWood"
                                              ? ((product.price_faux_wood + 0) * square) + subventionPrice
                                              : state.headrail === "FauxWoodTape"
                                                  ? ((product.price_faux_wood_tape + 0) * square) + subventionPrice
                                                  : state.headrail === "Wood"
                                                      ? ((product.price_wood + 0) * square) + subventionPrice
                                                      : state.headrail === "WoodTape"
                                                          ? ((product.price_wood_tape + 0) * square) + subventionPrice
                                                          : state.headrail === "VER-White"
                                                              ? ((product.price_ver_white + 0) * square) + subventionPrice
                                                              : state.headrail === "VER-Grey"
                                                                  ? ((product.price_ver_grey + 0) * square) + subventionPrice
                                                                  : ((product.price_covered + tasselPrice) * square) + subventionPrice

    /* Calculation of Extra User Discount */
    let extraUserDiscount = 0

    if (user && user.user && user.user.userDiscounts) {
      const userDiscounts = user.user.userDiscounts

      for (let i = 0; i < userDiscounts.length; i++) {
        const userDiscount = userDiscounts[i]

        const type = userDiscount.type
        const condition = userDiscount.condition
        const values = userDiscount.values
        const discount = userDiscount.discount

        if (type === "number") {
          const stateValue = parseFloat(state[userDiscount.id])
          const value = parseFloat(values[0])

          if (condition === "equals") {
            if (stateValue === value)
              extraUserDiscount += discount
          }
          else if (condition === "greaterThan") {
            if (stateValue > value)
              extraUserDiscount += discount
          }
          else if (condition === "greaterThanOrEqual") {
            if (stateValue >= value)
              extraUserDiscount += discount
          }
          else if (condition === "smallerThan") {
            if (stateValue < value)
              extraUserDiscount += discount
          }
          else if (condition === "smallerThanOrEqual") {
            if (stateValue <= value)
              extraUserDiscount += discount
          }
        }
        else if (type === "string") {
          const stateValue = state[userDiscount.id]
          if (condition === "oneOf")
            if (values.includes(stateValue))
              extraUserDiscount += discount
        }
      }
    }

    // console.log('extraUserDiscount for ' + product.fabric, extraUserDiscount)
    /**/

    calculatedDiscountPriceWithoutExtraUserDiscount =
        state.headrail === "Open"
            ? (Math.max(0, 100 - product.price_open_discount) / 100) * calculatedPrice
            : state.headrail === "WhiteCovered"
                ? (Math.max(0, 100 - product.price_covered_discount) / 100) * calculatedPrice
                : state.headrail === "Pelmeted"
                    ? (Math.max(0, 100 - product.price_pelmeted_discount) / 100) * calculatedPrice
                    : state.headrail === "GreyCovered"
                        ? (Math.max(0, 100 - product.price_grey_discount) / 100) * calculatedPrice
                        : state.headrail === "AnthraciteCovered"
                            ? (Math.max(0, 100 - product.price_anthracitecovered_discount) / 100) * calculatedPrice
                            : state.headrail === "White"
                                ? (Math.max(0, 100 - product.price_white_discount) / 100) * calculatedPrice
                                : state.headrail === "Anthracite"
                                    ? (Math.max(0, 100 - product.price_anthracite_discount) / 100) * calculatedPrice
                                    : state.headrail === "P-White"
                                        ? (Math.max(0, 100 - product.price_p_white_discount) / 100) * calculatedPrice
                                        : state.headrail === "P-Black"
                                            ? (Math.max(0, 100 - product.price_p_black_discount) / 100) * calculatedPrice
                                            : state.headrail === "FauxWood"
                                                ? (Math.max(0, 100 - product.price_faux_wood_discount) / 100) * calculatedPrice
                                                : state.headrail === "FauxWoodTape"
                                                    ? (Math.max(0, 100 - product.price_faux_wood_tape_discount) / 100) * calculatedPrice
                                                    : state.headrail === "Wood"
                                                        ? (Math.max(0, 100 - product.price_wood_discount) / 100) * calculatedPrice
                                                        : state.headrail === "WoodTape"
                                                            ? (Math.max(0, 100 - product.price_wood_tape_discount) / 100) * calculatedPrice
                                                            : state.headrail === "VER-White"
                                                                ? (Math.max(0, 100 - product.price_ver_white_discount) / 100) * calculatedPrice
                                                                : state.headrail === "VER-Grey"
                                                                    ? (Math.max(0, 100 - product.price_ver_grey_discount) / 100) * calculatedPrice
                                                                    : (Math.max(0, 100 - product.price_covered_discount) / 100) * calculatedPrice

    calculatedDiscountPrice =
      state.headrail === "Open"
        ? (Math.max(0, 100 - product.price_open_discount - extraUserDiscount) / 100) * calculatedPrice
        : state.headrail === "WhiteCovered"
            ? (Math.max(0, 100 - product.price_covered_discount - extraUserDiscount) / 100) * calculatedPrice
            : state.headrail === "Pelmeted"
                ? (Math.max(0, 100 - product.price_pelmeted_discount - extraUserDiscount) / 100) * calculatedPrice
                : state.headrail === "GreyCovered"
                    ? (Math.max(0, 100 - product.price_grey_discount - extraUserDiscount) / 100) * calculatedPrice
                    : state.headrail === "AnthraciteCovered"
                        ? (Math.max(0, 100 - product.price_anthracitecovered_discount - extraUserDiscount) / 100) * calculatedPrice
                        : state.headrail === "White"
                            ? (Math.max(0, 100 - product.price_white_discount - extraUserDiscount) / 100) * calculatedPrice
                            : state.headrail === "Anthracite"
                                ? (Math.max(0, 100 - product.price_anthracite_discount - extraUserDiscount) / 100) * calculatedPrice
                                : state.headrail === "P-White"
                                    ? (Math.max(0, 100 - product.price_p_white_discount - extraUserDiscount) / 100) * calculatedPrice
                                    : state.headrail === "P-Black"
                                        ? (Math.max(0, 100 - product.price_p_black_discount - extraUserDiscount) / 100) * calculatedPrice
                                        : state.headrail === "FauxWood"
                                              ? (Math.max(0, 100 - product.price_faux_wood_discount - extraUserDiscount) / 100) * calculatedPrice
                                              : state.headrail === "FauxWoodTape"
                                                  ? (Math.max(0, 100 - product.price_faux_wood_tape_discount - extraUserDiscount) / 100) * calculatedPrice
                                                  : state.headrail === "Wood"
                                                      ? (Math.max(0, 100 - product.price_wood_discount - extraUserDiscount) / 100) * calculatedPrice
                                                      : state.headrail === "WoodTape"
                                                          ? (Math.max(0, 100 - product.price_wood_tape_discount - extraUserDiscount) / 100) * calculatedPrice
                                                          : state.headrail === "VER-White"
                                                              ? (Math.max(0, 100 - product.price_ver_white_discount - extraUserDiscount) / 100) * calculatedPrice
                                                              : state.headrail === "VER-Grey"
                                                                  ? (Math.max(0, 100 - product.price_ver_grey_discount - extraUserDiscount) / 100) * calculatedPrice
                                                                  : (Math.max(0, 100 - product.price_covered_discount - extraUserDiscount) / 100) * calculatedPrice

     // console.log("calculatedPrice==>", calculatedPrice)
     // console.log("calculatedDiscountPrice==>", calculatedDiscountPrice)
     // console.log("calculatedDiscountPriceWithoutExtraUserDiscount==>", calculatedDiscountPriceWithoutExtraUserDiscount)

    //**** OLD CALCULATION - CANCELED 10.10.2021 */
    // if (product && motorPrice >= 0 && tasselPrice >= 0) {
    //   product_price =
    //     state.headrail === "Open"
    //       ? (product.price_open * (100 - product.price_open_discount)) / 100
    //       : state.headrail === "Covered"
    //       ? (product.price_covered * (100 - product.price_covered_discount)) / 100
    //       : state.headrail === "Pelmeted"
    //       ? (product.price_pelmeted * (100 - product.price_pelmeted_discount)) /
    //         100
    //       : product.price_covered
    //   calculatedPrice =
    //     (Number(state.width) / 1000) *
    //       ((Number(state.drop) + 100) / 1000) *
    //       product_price +
    //     motorPrice
    //************* */

    return {
      product_id: product._id,
      prices: {
        motorPrice: motorPrice,
        tasselPrice: tasselPrice,
        discountedPrice: (((((calculatedDiscountPrice * (100 - user_discount)) / 100) * 1.2) * (state.blindType === "Pleated" && state.headrailType === "Double" ? 0.75 : (state.blindType === "Vertical" && product.isPremium ? 1.05 : 1))) + additionalChainPrice + bracketPrice + motorPrice).toFixed(2),
        unitPrice: (((calculatedPrice + calculatedPrice * 0.2) * (state.blindType === "Pleated" && state.headrailType === "Double" ? 0.75 : (state.blindType === "Vertical" && product.isPremium ? 1.05 : 1))) + additionalChainPrice + bracketPrice + motorPrice).toFixed(2),
        suggestedPrice: ((((((calculatedDiscountPrice * (100 - user_discount)) / 100) * 1.2) * 2.5) * (state.blindType === "Pleated" && state.headrailType === "Double" ? 0.75 : (state.blindType === "Vertical" && product.isPremium ? 1.05 : 1))) + additionalChainPrice + bracketPrice + motorPrice).toFixed(2),
        extraUserDiscount: extraUserDiscount,
        discountedPriceWithoutExtraUserDiscount: (((((calculatedDiscountPriceWithoutExtraUserDiscount * (100 - user_discount)) / 100) * 1.2) * (state.blindType === "Pleated" && state.headrailType === "Double" ? 0.75 : (state.blindType === "Vertical" && product.isPremium ? 1.05 : 1))) + additionalChainPrice + bracketPrice + motorPrice).toFixed(2),
      },
    }
  }
}
