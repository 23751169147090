import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
// Redux
import { useDispatch, useSelector } from "react-redux";
// UI Components
import Message from "../components/Message";
import Loader from "../components/Loader";
// Redux Actions
import { getUserDetails, updateUserProfile } from "../actions/userActions";

const initialProfile = {
  firstname: "",
  lastname: "",
  company: "",
  email: "",
  phone: "",
  //password:"",
  newPassword: "",
  confirmPassword: "",
};

export default function ProfileDetails() {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [stateProfile, setStateProfile] = useState(initialProfile);

  // Get user details from Redux store
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user } = userDetails;

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.user) {
      setStateProfile({
        firstname: user.user.firstname,
        lastname: user.user.lastname,
        company: user.user.company,
        phone: user.user.phone,
        email: user.user.email,
      });
    }
  }, [user]);

  // Handler that logs in the user
  const submitHandler = (e) => {
    // console.log("click");
    e.preventDefault();
    //Check if passwords are the same
    if (stateProfile.newPassword !== stateProfile.confirmPassword) {
      setError("New Passwords do not match");
      setTimeout(() => {
        setError("");
      }, 5000);
    } else {
      //Dispatch update profile reducer
      dispatch(updateUserProfile(stateProfile)).then((response, error) => {
        if (response.status === 200) {
          setIsVisible(true);
          setMessage(response.msg);
          dispatch(getUserDetails);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        } else {
          setError(response.msg);
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      });
    }
  };
  // console.log(user);

  const handleInputProfile = (e) => {
    // console.log(e.target.name);
    setStateProfile({ ...stateProfile, [e.target.name]: e.target.value });
    // console.log(stateProfile);
  };
  const handleEditActive = () => {
    setIsVisible(false);

    // var inputs, i;
    // inputs = document.getElementsByTagName("Input");
    // for (i = 0; i < inputs.length; ++i) {
    //   inputs[i].disabled = false;
    // }
    // document.getElementById("email").disabled = true;
  };
  return (
    <div>
      <Row>
        <Col className="text-center mx-auto mt-2" xs={12} sm={8} md={6}>
          <div className="text-center mx-auto mt-2" xs={12} sm={8} md={6}>
            {loading && <Loader />}
            <Form onSubmit={submitHandler}>
              <Form.Group>
                <Form.Label className="mt-2">Email Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="email@example.com"
                  value={stateProfile.email || ""}
                  name="email"
                  id="email"
                  disabled
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mt-2">
                  First Name{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        This field required.
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <i
                        {...triggerHandler}
                        ref={ref}
                        className="fa fa-question-circle"
                        aria-hidden="true"
                      ></i>
                    )}
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={stateProfile.firstname || ""}
                  name="firstname"
                  onChange={handleInputProfile}
                  required
                  disabled={isVisible}
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mt-2">
                  Surname{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        This field required.
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <i
                        {...triggerHandler}
                        ref={ref}
                        className="fa fa-question-circle"
                        aria-hidden="true"
                      ></i>
                    )}
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Surname"
                  value={stateProfile.lastname || ""}
                  name="lastname"
                  onChange={handleInputProfile}
                  required
                  disabled={isVisible}
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mt-2">
                  Company Name{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        This field required.
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <i
                        {...triggerHandler}
                        ref={ref}
                        className="fa fa-question-circle"
                        aria-hidden="true"
                      ></i>
                    )}
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="companyname"
                  value={stateProfile.company || ""}
                  name="company"
                  onChange={handleInputProfile}
                  required
                  disabled={isVisible}
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mt-2">
                  Phone{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        This field required.
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <i
                        {...triggerHandler}
                        ref={ref}
                        className="fa fa-question-circle"
                        aria-hidden="true"
                      ></i>
                    )}
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone (Without +44)"
                  value={stateProfile.phone || ""}
                  name="phone"
                  onChange={handleInputProfile}
                  pattern="[0-9]{10,11}"
                  maxLength="11"
                  required
                  disabled={isVisible}
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mt-2">
                  Enter Your Current Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Current Password"
                  value={stateProfile.password || ""}
                  name="password"
                  onChange={handleInputProfile}
                  disabled={isVisible}
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mt-2">
                  Enter Your New Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  value={stateProfile.newPassword || ""}
                  name="newPassword"
                  onChange={handleInputProfile}
                  disabled={isVisible}
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mt-2">
                  Confirm Your New Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm New Password"
                  value={stateProfile.confirmPassword || ""}
                  name="confirmPassword"
                  onChange={handleInputProfile}
                  disabled={isVisible}
                  className="border-zebra"
                ></Form.Control>
              </Form.Group>
              <div className="mt-3">
                {message && <Message variant="success">{message}</Message>}
                {error && <Message variant="danger">{error}</Message>}
              </div>

              <div className="mt-3 mb-3">
                {isVisible && (
                  <Button
                    type="button"
                    onClick={handleEditActive}
                    variant="warning mt-3 mx-auto d-flex"
                    className="bg-zebra"
                  >
                    Edit Profile
                  </Button>
                )}
                {!isVisible && (
                  <Button
                    type="submit"
                    variant="warning mt-3 mx-auto d-flex"
                    className="bg-zebra"
                  >
                    Update
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
