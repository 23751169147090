import React, { memo, useEffect } from "react"
import { usePrevious } from "../hooks/use-previous"
import { useSelector } from "react-redux"
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import { isBlindIncomplete, objectsEqual } from "../utils/common"

const visibleKeys = ['motorPrice', 'tasselPrice', 'discountedPrice', 'unitPrice', 'suggestedPrice']

const HomeList = ({
  headrailType,
  productList,
  product,
  doubleProduct,
  prices,
  doublePrices,
  warnWindow,
  doubleWarnWindow,
  warnWidth,
  doubleWarnWidth,
  warnDrop,
  doubleWarnDrop,
  handleCart,
  handleCalculate,
}) => {
  const prevProps = usePrevious({
    headrailType,
    productList,
    product,
    doubleProduct,
    prices,
    doublePrices,
    warnWidth,
    doubleWarnWidth,
    warnDrop,
    doubleWarnDrop,
    handleCart,
    handleCalculate,
  })

  const isTablet = useMediaQuery({ query: "(min-width: 768px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
  const is425px = useMediaQuery({ query: "(max-width: 425px)" })
  // const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  // const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" })

  const { userCarts } = useSelector((store) => store.userCarts)

  const rotate = {
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
  }

  useEffect(() => {
    const filled = product.fabric !== "Select..." && product.width !== "" && product.drop !== ""

    if (filled) {
      if (!prevProps)
        handleCalculate()
      else {
        const isEqual = objectsEqual(prevProps.product, product)

        if (!isEqual)
          handleCalculate()
      }
    }
  }, [prevProps, product, handleCalculate])

  useEffect(() => {
    const filled = doubleProduct
        .map(d => ({ fabric: d.fabric, width: d.width, drop: d.drop }))
        .every(x => x.fabric !== "Select..." && !!x.width && !!x.drop)

    if (filled) {
      if (!prevProps) {
        handleCalculate()
      }
      else {
        const isEqual = objectsEqual(prevProps.doubleProduct, doubleProduct)

        if (!isEqual) {
          handleCalculate()
        }
      }
    }

  }, [prevProps, doubleProduct, handleCalculate])

  return (
    <div>
      {headrailType === "Double" ? (
          <Table
              className="mb-0"
              responsive="sm"
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "11px", minWidth: is425px ? 290 : 450 }}
          >
            {isMobile && (
                <tbody style={{ verticalAlign: "middle" }}>
                <tr>
                  <th
                      rowSpan="5"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <div className="d-flex flex-column justify-content-between align-items-center">
                      {doubleProduct[0].notes && (
                          <OverlayTrigger
                              notes={doubleProduct[0].notes}
                              overlay={
                                <Tooltip id="tooltip-right">{doubleProduct[0].notes}</Tooltip>
                              }
                          >
                                <span style={{ cursor: "pointer", verticalAlign: "top" }}>
                                  <i className="fas fa-clipboard"/>
                                </span>
                          </OverlayTrigger>
                      )}
                      <div style={{...rotate, paddingBottom: 2}}>
                        <span><strong>{doubleProduct[0].blindType}</strong></span>{" - "}
                        <span>{doubleProduct[0].fabric} , {doubleProduct[1].fabric}</span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  {Object.keys(doubleProduct[0])
                      .slice((is425px ? 6 : 4), 9)
                      .map((item, index) => {
                        return <th key={index} className="text-capitalize">{item}</th>
                      })
                  }
                </tr>
                <tr>
                  {Object.values(doubleProduct[0])
                      .slice((is425px ? 6 : 4), 9)
                      .map((item, index) => {
                        return <td key={index} className="text-capitalize">{item}</td>
                      })
                  }
                </tr>
                <tr>
                  {Object.keys(doublePrices[0]).filter(key => visibleKeys.includes(key)).slice((is425px ? 2 : 0)).map((item, index) => {
                    return <th key={index} className={`text-capitalize ${item === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>
                      {(() => {
                        switch (item) {
                          case 'discountedPrice':
                            return 'Your Price'
                          case 'unitPrice':
                            return 'Original Price'
                          case 'suggestedPrice':
                            return 'Suggested Price'
                          default:
                            return item
                        }
                      })()}
                    </th>
                  })}
                </tr>
                <tr>
                  {Object.entries(doublePrices[0]).filter(item => visibleKeys.includes(item[0])).slice((is425px ? 2 : 0)).map((item, index) => {
                    return <td key={index} className={`text-capitalize ${item[0] === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>£ {(parseFloat(doublePrices[0][item[0]]) + parseFloat(doublePrices[1][item[0]])).toFixed(2)}</td>
                  })}
                </tr>
                </tbody>
            )}
            {isTablet && (
                <tbody style={{ verticalAlign: "middle" }}>
                <tr>
                  <td colSpan="5">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span><strong>{doubleProduct[0].blindType}</strong></span>{" - "}
                        <span>{doubleProduct[0].fabric} , {doubleProduct[1].fabric}</span>
                      </div>
                      {doubleProduct[0].notes && (
                          <OverlayTrigger
                              notes={doubleProduct[0].notes}
                              overlay={
                                <Tooltip id="tooltip-right">{doubleProduct[0].notes}</Tooltip>
                              }
                          >
                                  <span style={{ cursor: "pointer", verticalAlign: "top" }}>
                                    <i className="fas fa-clipboard"/>
                                  </span>
                          </OverlayTrigger>
                      )}
                    </div>
                  </td>
                  <td colSpan="5" className="text-end"></td>
                </tr>
                <tr>
                  {Object.keys(doubleProduct[0])
                      .slice(4, 9)
                      .map((item, index) => {
                        return <th key={index} className="text-capitalize">{item}</th>
                      })
                  }
                  {Object.keys(doublePrices[0]).filter(key => visibleKeys.includes(key)).map((item, index) => {
                    return <th key={index} className={`text-capitalize ${item === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>
                      {(() => {
                        switch (item) {
                          case 'discountedPrice':
                            return 'Your Price'
                          case 'unitPrice':
                            return 'Original Price'
                          case 'suggestedPrice':
                            return 'Suggested Price'
                          default:
                            return item
                        }
                      })()}
                    </th>
                  })}
                </tr>
                <tr>
                  {Object.values(doubleProduct[0])
                      .slice(4, 9)
                      .map((item, index) => {
                        return <td key={index} className="text-capitalize">{item}</td>
                      })
                  }
                  {Object.entries(doublePrices[0]).filter(item => visibleKeys.includes(item[0])).map((item, index) => {
                    return <td key={index} className={`text-capitalize ${item[0] === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>£ {(parseFloat(doublePrices[0][item[0]]) + parseFloat(doublePrices[1][item[0]])).toFixed(2)}</td>
                  })}
                </tr>
                </tbody>
            )}
          </Table>
          /* Old Style Preview */
          // doubleProduct.map((product, productIndex) => (
          //     <Table
          //         key={productIndex}
          //         className="mb-0"
          //         responsive="sm"
          //         striped
          //         bordered
          //         hover
          //         size="sm"
          //         style={{ fontSize: "11px", minWidth: is425px ? 290 : 450 }}
          //     >
          //       {isMobile && (
          //           <tbody style={{ verticalAlign: "middle" }}>
          //             <tr>
          //               <th
          //                   rowSpan="5"
          //                   style={{ textAlign: "center", verticalAlign: "middle" }}
          //               >
          //                 <div className="d-flex flex-column justify-content-between align-items-center">
          //                   {product.notes && (
          //                       <OverlayTrigger
          //                           notes={product.notes}
          //                           overlay={
          //                             <Tooltip id="tooltip-right">{product.notes}</Tooltip>
          //                           }
          //                       >
          //                       <span style={{ cursor: "pointer", verticalAlign: "top" }}>
          //                         <i className="fas fa-clipboard"/>
          //                       </span>
          //                       </OverlayTrigger>
          //                   )}
          //                   <div style={{...rotate, paddingBottom: 2}}>
          //                     <span><strong>{product.blindType}</strong></span>{" - "}
          //                     <span>{product.fabric}</span>
          //                   </div>
          //                 </div>
          //               </th>
          //             </tr>
          //             <tr>
          //               {Object.keys(product)
          //                   .slice((is425px ? 6 : 4), 9)
          //                   .map((item, index) => {
          //                     return <th key={index} className="text-capitalize">{item}</th>
          //                   })
          //               }
          //             </tr>
          //             <tr>
          //               {Object.values(product)
          //                   .slice((is425px ? 6 : 4), 9)
          //                   .map((item, index) => {
          //                     return <td key={index} className="text-capitalize">{item}</td>
          //                   })
          //               }
          //             </tr>
          //             <tr>
          //               {Object.keys(doublePrices[productIndex]).filter(key => visibleKeys.includes(key)).slice((is425px ? 2 : 0)).map((item, index) => {
          //                 return <th key={index} className={`text-capitalize ${item === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>
          //                   {(() => {
          //                     switch (item) {
          //                       case 'discountedPrice':
          //                         return 'Your Price'
          //                       case 'unitPrice':
          //                         return 'Original Price'
          //                       case 'suggestedPrice':
          //                         return 'Suggested Price'
          //                       default:
          //                         return item
          //                     }
          //                   })()}
          //                 </th>
          //               })}
          //             </tr>
          //             <tr>
          //               {Object.entries(doublePrices[productIndex]).filter(item => visibleKeys.includes(item[0])).slice((is425px ? 2 : 0)).map((item, index) => {
          //                 return <td key={index} className={`text-capitalize ${item[0] === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>£ {item[1]}</td>
          //               })}
          //             </tr>
          //           </tbody>
          //       )}
          //       {isTablet && (
          //           <tbody style={{ verticalAlign: "middle" }}>
          //             <tr>
          //               <td colSpan="5">
          //                 <div className="d-flex justify-content-between">
          //                   <div>
          //                     <span><strong>{product.blindType}</strong></span>{" - "}
          //                     <span>{product.fabric}</span>
          //                   </div>
          //                   {product.notes && (
          //                       <OverlayTrigger
          //                           notes={product.notes}
          //                           overlay={
          //                             <Tooltip id="tooltip-right">{product.notes}</Tooltip>
          //                           }
          //                       >
          //                         <span style={{ cursor: "pointer", verticalAlign: "top" }}>
          //                           <i className="fas fa-clipboard"/>
          //                         </span>
          //                       </OverlayTrigger>
          //                   )}
          //                 </div>
          //               </td>
          //               <td colSpan="5" className="text-end"></td>
          //             </tr>
          //             <tr>
          //               {Object.keys(product)
          //                   .slice(4, 9)
          //                   .map((item, index) => {
          //                     return <th key={index} className="text-capitalize">{item}</th>
          //                   })
          //               }
          //               {Object.keys(doublePrices[productIndex]).filter(key => visibleKeys.includes(key)).map((item, index) => {
          //                 return <th key={index} className={`text-capitalize ${item === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>
          //                   {(() => {
          //                     switch (item) {
          //                       case 'discountedPrice':
          //                         return 'Your Price'
          //                       case 'unitPrice':
          //                         return 'Original Price'
          //                       case 'suggestedPrice':
          //                         return 'Suggested Price'
          //                       default:
          //                         return item
          //                     }
          //                   })()}
          //                 </th>
          //               })}
          //             </tr>
          //             <tr>
          //               {Object.values(product)
          //                   .slice(4, 9)
          //                   .map((item, index) => {
          //                     return <td key={index} className="text-capitalize">{item}</td>
          //                   })
          //               }
          //               {Object.entries(doublePrices[productIndex]).filter(item => visibleKeys.includes(item[0])).map((item, index) => {
          //                 return <td key={index} className={`text-capitalize ${item[0] === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>£ {item[1]}</td>
          //               })}
          //             </tr>
          //           </tbody>
          //       )}
          //     </Table>
          // ))
          /* */
      ) : (
          <Table
              className="mb-0"
              responsive="sm"
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "11px", minWidth: is425px ? 290 : 450 }}
          >
            {isMobile && (
                <tbody style={{ verticalAlign: "middle" }}>
                <tr>
                  <th
                      rowSpan="5"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <div className="d-flex flex-column justify-content-between align-items-center">
                      {product.notes && (
                          <OverlayTrigger
                              notes={product.notes}
                              overlay={
                                <Tooltip id="tooltip-right">{product.notes}</Tooltip>
                              }
                          >
                                <span style={{ cursor: "pointer", verticalAlign: "top" }}>
                                  <i className="fas fa-clipboard"/>
                                </span>
                          </OverlayTrigger>
                      )}
                      <div style={{...rotate, paddingBottom: 2}}>
                        <span><strong>{product.blindType}</strong></span>{" , "}
                        <span>{product.fabric}</span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  {Object.keys(product)
                      .slice((is425px ? 6 : 4), 9)
                      .map((item, index) => {
                        return <th key={index} className="text-capitalize">{item}</th>
                      })
                  }
                </tr>
                <tr>
                  {Object.values(product)
                      .slice((is425px ? 6 : 4), 9)
                      .map((item, index) => {
                        return <td key={index} className="text-capitalize">{item}</td>
                      })
                  }
                </tr>
                <tr>
                  {Object.keys(prices).filter(key => visibleKeys.includes(key)).slice((is425px ? 2 : 0)).map((item, index) => {
                    return <th key={index} className={`text-capitalize ${item === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>
                      {(() => {
                        switch (item) {
                          case 'discountedPrice':
                            return 'Your Price'
                          case 'unitPrice':
                            return 'Original Price'
                          case 'suggestedPrice':
                            return 'Suggested Price'
                          default:
                            return item
                        }
                      })()}
                    </th>
                  })}
                </tr>
                <tr>
                  {Object.entries(prices).filter(item => visibleKeys.includes(item[0])).slice((is425px ? 2 : 0)).map((item, index) => {
                    return <td key={index} className={`text-capitalize ${item[0] === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>£ {item[1]}</td>
                  })}
                </tr>
                </tbody>
            )}
            {isTablet && (
                <tbody style={{ verticalAlign: "middle" }}>
                  <tr>
                    <td colSpan="5">
                      <div className="d-flex justify-content-between">
                        <div>
                          <span><strong>{product.blindType}</strong></span>{" , "}
                          <span>{product.fabric}</span>
                        </div>
                        {product.notes && (
                            <OverlayTrigger
                                notes={product.notes}
                                overlay={
                                  <Tooltip id="tooltip-right">{product.notes}</Tooltip>
                                }
                            >
                              <span style={{ cursor: "pointer", verticalAlign: "top" }}>
                                <i className="fas fa-clipboard"/>
                              </span>
                            </OverlayTrigger>
                        )}
                      </div>
                    </td>
                    <td colSpan="5" className="text-end"></td>
                  </tr>
                  <tr>
                    {Object.keys(product)
                        .slice(4, 9)
                        .map((item, index) => {
                          return <th key={index} className="text-capitalize">{item}</th>
                        })
                    }
                    {Object.keys(prices).filter(key => visibleKeys.includes(key)).map((item, index) => {
                      return <th key={index} className={`text-capitalize ${item === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>
                        {(() => {
                          switch (item) {
                            case 'discountedPrice':
                              return 'Your Price'
                            case 'unitPrice':
                              return 'Original Price'
                            case 'suggestedPrice':
                              return 'Suggested Price'
                            default:
                              return item
                          }
                        })()}
                      </th>
                    })}
                  </tr>
                  <tr>
                    {Object.values(product)
                        .slice(4, 9)
                        .map((item, index) => {
                          return <td key={index} className="text-capitalize">{item}</td>
                        })
                    }
                    {Object.entries(prices).filter(item => visibleKeys.includes(item[0])).map((item, index) => {
                      return <td key={index} className={`text-capitalize ${item[0] === 'discountedPrice' ? "fw-bolder fs-6" : ""}`}>£ {item[1]}</td>
                    })}
                  </tr>
                </tbody>
            )}
          </Table>
      )}
      <div className="d-flex justify-content-center mt-2 align-items-center">
        <Button
          variant="outline-zebra"
          type="button"
          style={{ fontSize: "12px" }}
          onClick={handleCart}
          disabled={
            (userCarts && userCarts.length === 0) ||
            isBlindIncomplete(productList, headrailType, product, doubleProduct, warnWindow, doubleWarnWindow, warnWidth, doubleWarnWidth, warnDrop, doubleWarnDrop)
          }
        >
          <i className="fas fa-plus"/> Add to Selected Cart
        </Button>
      </div>
    </div>
  )
}

export default memo(HomeList)
