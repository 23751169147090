import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "react-bs-datatable";
import {
  Button,
  Modal,
  Form,
  Badge,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { updateItems, listItems, deleteItems } from "../actions/sameActions";
import {
  getUserDetailsAdmin,
  updateUser,
  resetPassword,
} from "../actions/userActions";

const getBadge = (status) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "secondary";
    default:
      return "success";
  }
};
const getActive = (status) => {
  switch (status) {
    case true:
      return "Pending";
    case false:
      return "Read";
    default:
      return "Pending";
  }
};
const classes = {
  table: "table-striped table-hover table-responsive table-sm",
  theadCol: `
      .table-datatable__root & {
        &.sortable:hover {
          background: pink;
        }
      }
    `,
  tbodyRow: `
      &:nth-of-type(even) {
        background: #eaeaea;
      }
    `,
};

const initialState = {
  notification_name: "",
  email: "",
  isActive: true,
};

export const NotificationList = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.notificationList);
  const [state, setstate] = useState([]);
  const [selected, setselected] = useState(initialState);
  const [selectedUser, setselectedUser] = useState({});
  const [show, setShow] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [message, setmessage] = useState("");

  const handleClose = () => setShow(false);
  const handleCloseX = () => {
    setmessage("");
  };

  //console.log(notifications)
  useEffect(() => {
    const active =
      notifications && notifications.filter((item) => item.isActive === true);
    setstate(active);
  }, [notifications]);
  useEffect(() => {
    dispatch(listItems("notification"));
  }, [dispatch, isLoading]);

  const header = [
    { title: "Type", prop: "notification_name", sortable: true },
    { title: "Owner", prop: "email", sortable: true },
    { title: "Status", prop: "isActive" },
  ];

  const body =
    state &&
    state.length > 0 &&
    state.map((item, index) => {
      return {
        notification_name: item.notification_name,
        email: item.email,
        isActive: (
          <Badge bg={getBadge(item.isActive)}>{getActive(item.isActive)}</Badge>
        ),
        id: item._id,
      };
    });

  function onRowClick(data) {
    dispatch(getUserDetailsAdmin(data.email)).then((res) => {
      if (res.status === 200) {
        setselectedUser(res.user);
      }
    });
    const selected = state && state.find((item) => item._id === data.id);
    // console.log(selected);
    setselected({ ...selected, isActive: false });
    setShow(true);
  }

  const handleInput = (e) => {
    setselectedUser({ ...selectedUser, [e.target.name]: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    dispatch(updateItems("notification", selected));
    dispatch(updateUser(selectedUser)).then((res) => {
      if (res && res.status === 200) {
        setShow(false);
        setmessage(res.msg);
        setTimeout(() => {
          setselectedUser({});
          setselected(initialState);
          setmessage("");
          setisLoading(false);
        }, 2000);
      }
    });
  };

  const handleResetPassword = () => {
    setisLoading(true);
    dispatch(updateItems("notification", selected));
    dispatch(resetPassword(selectedUser._id)).then((res) => {
      if (res.status === 200) {
        setShow(false);
        setmessage(res.msg);
        setTimeout(() => {
          setselectedUser({});
          setselected(initialState);
          setmessage("");
          setisLoading(false);
        }, 2000);
      }
    });
  };

  //console.log(selected);

  return (
    <div style={{ overflowX: "auto" }}>
      {state && state.length > 0 && (
        <Datatable
          tableHeaders={header}
          tableBody={body}
          classes={classes}
          rowsPerPage={10}
          onRowClick={(item) => onRowClick(item)}
        />
      )}
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selected.notification_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <InputGroup size="sm" className="mb-3">
              <Form.Check
                label={selected.isActive ? " Mark as read" : " Read "}
                type="switch"
                id="isActive"
                name="isActive"
                defaultChecked={selected.isActive}
                onChange={handleInput}
              />
              {!selected.isActive && (
                <span>
                  {"  "}
                  <i className="fa fa-check-circle" aria-hidden="true"></i>
                </span>
              )}
            </InputGroup> */}
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text style={{ width: "100px" }}>
                company
              </InputGroup.Text>
              <FormControl
                type="text"
                name="company"
                defaultValue={selectedUser.company}
                disabled
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text style={{ width: "100px" }}>
                Email
              </InputGroup.Text>
              <FormControl
                type="text"
                name="email"
                defaultValue={selectedUser.email}
                disabled
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text style={{ width: "100px" }}>
                Phone
              </InputGroup.Text>
              <FormControl
                type="text"
                name="phone"
                defaultValue={selectedUser.phone}
                disabled
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text style={{ width: "100px" }}>Name</InputGroup.Text>
              <FormControl
                type="text"
                name="phone"
                defaultValue={`${selectedUser.firstname} ${selectedUser.lastname}`}
                disabled
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text>User Status</InputGroup.Text>
              <Form.Check
                label={selectedUser.isActive ? " Active " : " Passive "}
                style={{ marginLeft: "10px" }}
                type="switch"
                id="isActive"
                name="isActive"
                defaultChecked={selectedUser.isActive}
                onChange={handleInput}
              />
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            {selected.notification_name === "Password reset request" && (
              <Button size="sm" variant="danger" onClick={handleResetPassword}>
                Reset Password
              </Button>
            )}
          </div>
          <div>
            <Button size="sm" variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" onClick={handleSubmit}>
              Update
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={!!message} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-success mt-2" role="alert">
            {message}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const NotificationListP = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.notificationList);
  const [state, setstate] = useState([]);
  const [selected, setselected] = useState(initialState);
  const [show, setShow] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [message, setmessage] = useState("");
  const handleClose = () => setShow(false);
  const handleCloseX = () => {
    setmessage("");
  };

  useEffect(() => {
    const active =
      notifications && notifications.filter((item) => item.isActive === false);
    setstate(active);
  }, [notifications]);
  useEffect(() => {
    dispatch(listItems("notification"));
  }, [dispatch, isLoading]);

  const header = [
    { title: "Type", prop: "notification_name" },
    { title: "Owner", prop: "email" },
    { title: "Status", prop: "isActive" },
  ];

  const body =
    state &&
    state.length > 0 &&
    state.map((item, index) => {
      return {
        notification_name: item.notification_name,
        email: item.email,
        isActive: (
          <Badge bg={getBadge(item.isActive)}>{getActive(item.isActive)}</Badge>
        ),
        id: item._id,
      };
    });

  function onRowClick(data) {
    setisDeleting(false);
    const selected = state && state.find((item) => item._id === data.id);
    setselected(selected);
    setShow(true);
  }

  function handleDeleteItem(data) {
    setisDeleting(true);
  }

  const handleInput = (e) => {
    setselected({ ...selected, [e.target.name]: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    dispatch(updateItems("notification", selected)).then((res) => {
      if (res.status === 200) {
        setShow(false);
        setmessage(res.msg);
        setTimeout(() => {
          setmessage("");
          setisLoading(false);
        }, 2000);
      }
    });
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    setisLoading(true);
    dispatch(deleteItems("notification", selected._id)).then((res) => {
      if (res.status === 200) {
        setShow(false);
        setmessage(res.msg);
        setisDeleting(false);
        setTimeout(() => {
          setmessage("");
          setisLoading(false);
        }, 2000);
      }
    });
  };

  //console.log(selected);

  return (
    <div style={{ overflowX: "auto" }}>
      {state && state.length > 0 && (
        <Datatable
          tableHeaders={header}
          tableBody={body}
          classes={classes}
          rowsPerPage={10}
          onRowClick={(item) => onRowClick(item)}
        />
      )}
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selected.notification_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text
                style={{ width: "120px" }}
                id="inputGroup-notification-price-sm"
              >
                Email
              </InputGroup.Text>
              <FormControl
                type="text"
                name="email"
                defaultValue={selected.email}
                disabled
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <Form.Check
                label={selected.isActive ? " Mark as read" : " Read "}
                type="switch"
                id="isActive"
                name="isActive"
                defaultChecked={selected.isActive}
                onChange={handleInput}
              />
              {!selected.isActive && (
                <span>
                  {"  "}
                  <i className="fa fa-check-circle" aria-hidden="true"></i>
                </span>
              )}
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <Button size="sm" variant="danger" onClick={handleDeleteItem}>
              Delete
            </Button>
          </div>
          <div>
            <Button size="sm" variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" onClick={handleSubmit}>
              Update
            </Button>
          </div>
        </Modal.Footer>
        {isDeleting && (
          <Modal.Footer className="d-flex justify-content-center">
            <div>
              <Button size="sm" variant="danger" onClick={handleSubmitDelete}>
                I'm sure I want to delete the item
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      <Modal show={!!message} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-success mt-2" role="alert">
            {message}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
