import {
  HEADRAIL_LIST_REQUEST,
  HEADRAIL_LIST_SUCCESS,
  HEADRAIL_LIST_FAIL,
  HEADRAIL_DETAILS_REQUEST,
  HEADRAIL_DETAILS_SUCCESS,
  HEADRAIL_DETAILS_FAIL,
} from "../constants/headrailConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting headrails from server
export const headrailListReducer = (state = { headrails: [] }, action) => {
  switch (action.type) {
    case HEADRAIL_LIST_REQUEST:
      return {
        loading: true,
        headrails: [],
      };
    case HEADRAIL_LIST_SUCCESS:
      return {
        loading: false,
        headrails: action.payload,
      };
    case HEADRAIL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
};

// Get headrails details
export const headrailDetailsReducer = (
  state = { headtrail: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case HEADRAIL_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case HEADRAIL_DETAILS_SUCCESS:
      return {
        loading: false,
        headtrail: action.payload,
      };
    case HEADRAIL_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
