export const CART_GET_REQUEST = "CART_GET_REQUEST"
export const CART_GET_SUCCESS = "CART_GET_SUCCESS"
export const CART_GET_FAIL = "CART_GET_FAIL"

export const CART_UPDATE_REQUEST = "CART_UPDATE_REQUEST"
export const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS"
export const CART_UPDATE_FAIL = "CART_UPDATE_FAIL"

export const CART_ADD_SUCCESS = "CART_ADD_SUCCESS"
export const CART_ADD_FAIL = "CART_ADD_FAIL"
export const CART_ADD_ITEM = "CART_ADD_ITEM"

export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM"
export const CART_REMOVE_SUCCESS = "CART_REMOVE_SUCCESS"
export const CART_REMOVE_FAIL = "CART_REMOVE_FAIL"

export const CART_EDIT_ITEM = "CART_EDIT_ITEM"

export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS"
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD"

export const CART_LIST_REQUEST = "CART_LIST_REQUEST"
export const CART_LIST_FAIL = "CART_LIST_FAIL"
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS"
export const CART_LIST_RESET = "CART_LIST_RESET"
