import React, { useEffect, useState } from "react"
import { Badge, Button, Col, Form, FormControl, InputGroup, ListGroup, Modal, Row, Table } from "react-bootstrap"

import { useDispatch, useSelector } from "react-redux"
import { listOrders, listAllOrders, listCompanyNames } from "../actions/orderActions"

import Pagination from "../components/Pagination"

import { useMediaQuery } from "react-responsive"

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

import { formatDate } from "../utils/common"

const getBadge = (status) => {
    switch (status) {
        case true:
            return "success"
        case false:
            return "secondary"
        case "Pending":
            return "warning"
        case "Submited":
            return "info"
        case "Shipped":
            return "success"
        case "Completed":
            return "secondary"
        default:
            return "primary"
    }
}
const getActive = (status) => {
    switch (status) {
        case true:
            return "Active"
        case false:
            return "Inactive"
        case "Pending":
            return "Pending"
        case "Submited":
            return "Submited"
        case "Shipped":
            return "Shipped"
        case "Completed":
            return "Completed"
        default:
            return "Inactive"
    }
}

const Dashboard = () => {
    const dispatch = useDispatch()

    const [state, setState] = useState([])

    const [companyNames, setCompanyNames] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [filteredValue, setFilteredValue] = useState({
        company: "",
        email: "",
        startAt: "",
        endAt: "",
        status: "",
    })
    const [selected, setSelected] = useState({})

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState("")

    const [show, setShow] = useState(false)

    const { orders } = useSelector((store) => store.orderList)

    const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
    const isLargeScreen = useMediaQuery({ query: "(min-width: 1440px)" })
    const isExtraLargeScreen = useMediaQuery({ query: "(min-width: 2560px)" })

    useEffect(() => {
        dispatch(listCompanyNames()).then((res) => {
            if (res.status === 200) {
                setCompanyNames(res.users)
            }
        })
    }, [dispatch])

    useEffect(() => {
        dispatch(listOrders({ currentPage, filteredValue }))
    }, [
        dispatch,
        currentPage,
        filteredValue,
        filteredValue.company,
        filteredValue.email,
        filteredValue.startAt,
        filteredValue.endAt,
        filteredValue.status,
    ])

    useEffect(() => {
        if (orders) {
            setState(orders.data)
            setTotalItems(orders && orders.meta ? orders.meta.total : 0)
        }
    }, [orders])

    const getImageFromUrl = url => {
        const img = new Image()

        img.onError = function() {
            alert('Cannot load image: "' + url + '"')
        }

        img.src = url

        return img
    }

    const printDoc = () => {
        setLoading(true)

        let queryObject = {
            isActive: true,
            isDeleted: false
        }

        if (filteredValue.company) {
            const companyId = companyNames.find(c => c.company === filteredValue.company)._id
            if (companyId)
                queryObject["user_id"] = companyId
        }

        if (filteredValue.startAt && filteredValue.endAt) {
            queryObject['createdAt'] = { lte: formatDate(new Date(`${filteredValue.endAt}`).setDate(new Date(`${filteredValue.endAt}`).getDate() + 1), "yyyy-MM-dd"), gte: formatDate(new Date(`${filteredValue.startAt}`), "yyyy-MM-dd") }
        }

        queryObject['sort'] = '-createdAt'

        dispatch(listAllOrders(queryObject))
            .then(res => {
                if (res.status === 200) {
                    const orders = res.data.orders

                    setError("")

                    const doc = new jsPDF()

                    const pic = getImageFromUrl('zebra.png')

                    doc.addImage(pic, 'PNG', 5, 5, 100, 20, 'zebra')

                    doc.setFont("times", "bold", 600)
                    doc.setFontSize(20)

                    if (filteredValue.company)
                        doc.text(filteredValue.company, 20, 50)

                    if (filteredValue.startAt && filteredValue.endAt)
                        doc.text(new Date(filteredValue.startAt).toLocaleDateString() + " - " + new Date(filteredValue.endAt).toLocaleDateString(), 20, 60)

                    const allOrders = []

                    let orderTotal = 0

                    for (let i = 0; i < orders.length; i++) {
                        orderTotal += orders[i].total_price ? orders[i].total_price : orders[i].order_price
                        allOrders.push([
                            (i + 1).toString(),
                            new Date(orders[i].createdAt).toLocaleDateString(),
                            orders[i].order_name,
                            orders[i].orderItem.length.toString(),
                            '£ ' + (orders[i].total_price ? orders[i].total_price : orders[i].order_price),
                        ])

                        if (!filteredValue.company)
                            allOrders[i].splice(1, 0, orders[i].user_id.company)
                    }

                    const head = [['No', 'Date', 'Reference', 'Number of Blinds', 'Amount £']]

                    if (!filteredValue.company)
                        head[0].splice(1, 0, 'Company')

                    let startY = (!filteredValue.company && (!filteredValue.startAt || !filteredValue.endAt)) ? 50 : 70

                    let finalY = startY

                    autoTable(doc, {
                        theme: 'grid',
                        startY: startY,
                        margin: 20,
                        headStyles: {
                            fillColor: '#bca774',
                            textColor: '#000000',
                        },
                        styles: {
                            lineWidth: 0.5,
                            lineColor: '#000000',
                        },
                        head: head,
                        body: allOrders,
                        didDrawPage: (data) => finalY = data.cursor.y
                    })

                    const pageWidth = doc.internal.pageSize.getWidth()
                    const pageHeight = doc.internal.pageSize.getHeight()

                    if (pageHeight - finalY < 100) {
                        doc.addPage()

                        startY = 10
                    }
                    else {
                        startY = finalY + 10
                    }

                    autoTable(doc, {
                        theme: 'grid',
                        startY: startY,
                        showHead: 'never',
                        margin: 20,
                        columnStyles: { title: { halign: 'left' }, amount: { halign: 'left', fontSize: 15 } },
                        styles: {
                            lineWidth: 0.5,
                            lineColor: '#000000',
                        },
                        body: [
                            { title: 'Total', amount: `£ ${orderTotal.toFixed(2)}` },
                            { title: 'Paid', amount: '' },
                            { title: 'Unpaid', amount: '' },
                        ],
                        columns: [
                            { header: 'Title', dataKey: 'title' },
                            { header: 'Amount', dataKey: 'amount' },
                        ],
                        didDrawPage: () => {
                            const pic = getImageFromUrl('thank-you.png')

                            doc.addImage(pic, 'PNG', 5, pageHeight - 55, pageWidth - 10, 50, 'thank-you')
                        }
                    })

                    doc.save('table.pdf')
                }
                else if (res.status === 500) {
                    setError(res.statusText + ": " + res.data.msg)
                }
                else {
                    setError("Something went wrong, please contact us")
                }
            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => setLoading(false))
    }

    const onChangePage = (pageNum) => setCurrentPage(pageNum)

    const onFilterPageClear = (e, name) => {
        setFilteredValue({
            ...filteredValue,
            [name]: ""
        })
        document.querySelector("#" + name + "SelectControl").value = ""
    }

    function onFilterValue(e) {
        if (e.target.name === "company") {
            setFilteredValue({
                ...filteredValue,
                company: e.target.value,
            })
            document.querySelector("#companySelectControl").value = e.target.value
        } else if (e.target.name === "email") {
            setFilteredValue({
                ...filteredValue,
                email: e.target.value,
            })
            document.querySelector("#emailSelectControl").value = e.target.value
        } else if (e.target.name === "startAt") {
            setFilteredValue({
                ...filteredValue,
                startAt: e.target.value,
            })
            document.querySelector("#startAtSelectControl").value = e.target.value
        } else if (e.target.name === "endAt") {
            setFilteredValue({
                ...filteredValue,
                endAt: e.target.value,
            })
            document.querySelector("#endAtSelectControl").value = e.target.value
        } else {
            setFilteredValue({ ...filteredValue, status: e.target.value })
        }

        setCurrentPage(1)
    }

    function onRowClick(id) {
        const selected =
            orders &&
            orders.data &&
            orders.data.find((item) => item._id === id)

        setSelected(selected)

        setShow(true)
    }

    const handleClose = () => setShow(false)

    return (
        <div style={{ maxWidth: "900px", margin: "0 auto" }}>
            <Row>
                <Col md={3}>
                    <Form.Label>
                        Company
                    </Form.Label>
                    <InputGroup className="mb-2">
                        <FormControl
                            id="companySelectControl"
                            type="text"
                            placeholder="Select Company"
                            name="company"
                            list="companySelect"
                            size="sm"
                            onChange={onFilterValue}
                        />
                        <datalist id="companySelect">
                            {companyNames &&
                                companyNames.map((item, index) => {
                                    return (
                                        <option key={index} value={item.company}>
                                            {item.company}
                                        </option>
                                    )
                                })
                            }
                        </datalist>
                        <InputGroup.Text
                            onClick={(e) => onFilterPageClear(e, "company")}
                            style={{ cursor: "pointer" }}
                            title="Clear"
                        >
                            <i className="fa fa-times" aria-hidden="true"/>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <Form.Label>
                        Email
                    </Form.Label>
                    <InputGroup className="mb-2">
                        <FormControl
                            id="emailSelectControl"
                            type="text"
                            placeholder="Select Email"
                            name="email"
                            list="emailSelect"
                            onChange={onFilterValue}
                            size="sm"
                        />
                        <datalist id="emailSelect">
                            {companyNames &&
                                companyNames.map((item, index) => {
                                    return (
                                        <option key={index} value={item.email}>
                                            {item.email}
                                        </option>
                                    )
                                })
                            }
                        </datalist>
                        <InputGroup.Text
                            onClick={(e) => onFilterPageClear(e, "email")}
                            style={{ cursor: "pointer" }}
                            title="Clear"
                        >
                            <i className="fa fa-times" aria-hidden="true"/>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <Form.Label>
                        Start Date
                    </Form.Label>
                    <InputGroup className="mb-2">
                        <FormControl
                            id="startAtSelectControl"
                            type="date"
                            placeholder="Enter Start Date"
                            name="startAt"
                            onChange={onFilterValue}
                            size="sm"
                        />
                        <InputGroup.Text
                            onClick={(e) => onFilterPageClear(e, "startAt")}
                            style={{ cursor: "pointer" }}
                            title="Clear"
                        >
                            <i className="fa fa-times" aria-hidden="true"/>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <Form.Label>
                        End Date
                    </Form.Label>
                    <InputGroup className="mb-2">
                        <FormControl
                            id="endAtSelectControl"
                            type="date"
                            placeholder="Enter End Date"
                            name="endAt"
                            onChange={onFilterValue}
                            size="sm"
                        />
                        <InputGroup.Text
                            onClick={(e) => onFilterPageClear(e, "endAt")}
                            style={{ cursor: "pointer" }}
                            title="Clear"
                        >
                            <i className="fa fa-times" aria-hidden="true"/>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
            <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                onChangePage={onChangePage}
            />
            <div>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        <th>Company</th>
                        <th>Order Date</th>
                        {!isMobile && (
                            <>
                                <th>Order Name</th>
                                <th>Items</th>
                            </>
                        )}
                        <th>Price</th>
                        <th>
                            <select
                                name="status"
                                style={{ fontWeight: "700", border: "none" }}
                                onChange={onFilterValue}
                            >
                                <option value="">Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Submited">Submited</option>
                                <option value="Shipped">Shipped</option>
                            </select>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {state &&
                        state.map((item, index) => {
                            return (
                                <tr key={index} onClick={() => onRowClick(item._id)}>
                                    <td>{item.user && item.user.company}</td>
                                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                    {!isMobile && (
                                        <>
                                            <td>{item.order_name}</td>
                                            <td>{item.orderItem.length}</td>
                                        </>
                                    )}
                                    <td>{item.total_price ? item.total_price : item.order_price}</td>
                                    <td>
                                        {" "}
                                        <Badge bg={getBadge(item.status)}>
                                            {getActive(item.status)}
                                        </Badge>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
            {state && state.length > 0 && (
                <div className="d-flex justify-content-center mb-2">
                    <button className="btn-print" onClick={printDoc} disabled={loading}>
                        <i className="fas fa-print me-1" />
                        Print
                    </button>
                </div>
            )}
            <Modal
                // size={isMobile ? "sm" : "lg"}
                {
                    ...isLargeScreen && {
                        size: "lg"
                    }
                }
                {
                    ...isExtraLargeScreen && {
                        size: "xl"
                    }
                }
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selected && selected.user && selected.user.company} -{" "}
                        {selected && selected.order_name} Order Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Order Items</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush" style={{ pointerEvents: "none" }}>
                                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                                    {selected.orderItem &&
                                    selected.orderItem.map((elem, index) => {
                                        return (
                                            <ListGroup.Item
                                                key={index}
                                                style={{
                                                    outline: `3px solid ${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : '#bcab79'))))))}`,
                                                    marginBottom: 10,
                                                    padding: '0 1rem',
                                                    borderTop: 0,
                                                    borderBottom: 0,
                                                    minHeight: 125,
                                                    height: 'fit-content'
                                                }}
                                            >
                                                <li
                                                    style={{
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        background: `${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : 'unset'))))))}`,
                                                        marginLeft: -17,
                                                        marginRight: -17,
                                                    }}
                                                >
                                                    {elem.blindType ?? "DayAndNight"} {elem.headrailType === "Double" ? '(Dbl)' : ''}
                                                </li>
                                                <li
                                                    style={{
                                                        color: `${elem.headrailType === "Double" ? 'white': 'unset'}`,
                                                        background: `${elem.headrailType === "Double" ? elem.doubleGroupCode : "unset"}`,
                                                        marginLeft: -17,
                                                        marginRight: -17,
                                                    }}
                                                >
                                                    <div style={{
                                                        marginLeft: 17,
                                                        marginRight: 17,
                                                    }}>
                                                        Window: {elem.window} / Fabric: {elem.fabric}
                                                    </div>
                                                </li>
                                                <li>
                                                    Width: {elem.width} x Drop: {elem.drop}
                                                </li>
                                                {elem.blindType !== "Pleated" && elem.fitting && (
                                                    <li>
                                                        Fitting: {elem.fitting} {elem.fitting !== "Top Fix" && elem.fitting !== "Normal Fix" ? `(${elem.bracket} x ${elem.bracketCount})` : ""}
                                                    </li>
                                                )}
                                                <li>
                                                    Headrail: {elem.headrail}
                                                    {elem.blindType !== "Pleated" ? (
                                                        <>
                                                            {elem.motor === "None" ? (elem.controlOption === "Wand" ? ` - Wand: (${elem.wand})` : ` - Chain: ${elem.chain} ${elem.cord ? `(${elem.cord})` : ``}`) : ``}
                                                            {elem.blindType !== "Venetian" ? ` - Control: ${elem.control}` : ``}
                                                            {elem.blindType === "DayAndNight" ? ` - Tassel: ${elem.tassel}` : ""}
                                                        </>
                                                    ) : null}
                                                </li>
                                                {(elem.blindType === "DayAndNight" || elem.blindType === "Venetian") && (
                                                    <>
                                                        {elem.continued_blind !== "None" ? (
                                                            <li>
                                                                Continued Blind: {elem.continued_blind}
                                                            </li>
                                                        ) : null}
                                                        {elem.motor !== "None" && (
                                                            <li>
                                                                Motor: {elem.motor} {elem.charger !== 0 ? `(${elem.remote} x ${elem.charger})` : ``}
                                                            </li>
                                                        )}
                                                    </>
                                                )}
                                                {elem.notes && (
                                                    <li>Product Note: {elem.notes}</li>
                                                )}
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ul>
                            </ListGroup>
                        </Col>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Order Note</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <h5>{selected.note && selected.note}</h5>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Total Price</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <h5>£ {selected.total_price ? selected.total_price : selected.order_price}</h5>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Shipping Address</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>{selected.selected_address}</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={3}>
                            <ListGroup>
                                <ListGroup.Item action>Status</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <h5>{selected.status}</h5>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={error !== ""}
                onHide={() => setError("")}
                size="sm"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setError("")}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Dashboard
