import {
    REMOTE_LIST_REQUEST,
    REMOTE_LIST_SUCCESS,
    REMOTE_LIST_FAIL,
    REMOTE_DETAILS_REQUEST,
    REMOTE_DETAILS_SUCCESS,
    REMOTE_DETAILS_FAIL,
} from "../constants/remoteConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting remotes from server
export const remoteListReducer = (state = { remotes: [] }, action) => {
    switch (action.type) {
        case REMOTE_LIST_REQUEST:
            return {
                loading: true,
                remotes: [],
            };
        case REMOTE_LIST_SUCCESS:
            return {
                loading: false,
                remotes: action.payload,
            };
        case REMOTE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case USER_LOGOUT:
            return state;
        default:
            return state;
    }
};

// Get remotes details
export const remoteDetailsReducer = (state = { remote: { reviews: [] } }, action) => {
    switch (action.type) {
        case REMOTE_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case REMOTE_DETAILS_SUCCESS:
            return {
                loading: false,
                remote: action.payload,
            };
        case REMOTE_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
