import {
  ADDRESS_LIST_REQUEST,
  ADDRESS_LIST_SUCCESS,
  ADDRESS_LIST_FAIL,
  ADDRESS_DETAILS_REQUEST,
  ADDRESS_DETAILS_SUCCESS,
  ADDRESS_DETAILS_FAIL,
  ADDRESS_GET_REQUEST,
  ADDRESS_GET_SUCCESS,
  ADDRESS_GET_FAIL,
  ADDRESS_POST_REQUEST,
  ADDRESS_POST_SUCCESS,
  ADDRESS_POST_FAIL,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_DELETE_FAIL,
} from "../constants/addressConstants";

// Getting addresses from server
export const addressListReducer = (
  state = { addresses: [], msg: "" },
  action
) => {
  //console.log(action.payload)
  switch (action.type) {
    case ADDRESS_LIST_REQUEST:
      return {
        loading: true,
      };
    case ADDRESS_LIST_SUCCESS:
      return {
        loading: false,
        addresses: action.payload,
        msg: action.payload,
      };
    case ADDRESS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const addressGetReducer = (state = { addresses: [] }, action) => {
  //console.log(action.payload)
  switch (action.type) {
    case ADDRESS_GET_REQUEST:
      return {
        loading: true,
      };
    case ADDRESS_GET_SUCCESS:
      return {
        loading: false,
        addresses: action.payload.addresses,
      };
    case ADDRESS_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Get addresses details
export const addressDetailsReducer = (
  state = { address: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case ADDRESS_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case ADDRESS_DETAILS_SUCCESS:
      return {
        loading: false,
        address: action.payload,
      };
    case ADDRESS_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const addAddressReducer = (state = { userAddresses: [] }, action) => {
  //console.log(action);
  switch (action.type) {
    case ADDRESS_POST_REQUEST:
      return {
        loading: true,
      };
    case ADDRESS_POST_SUCCESS:
      return {
        loading: false,
        userAddresses: action.payload,
      };
    case ADDRESS_POST_FAIL:
      //console.log(action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteAddressReducer = (state = { userAddresses: [] }, action) => {
  //console.log(action);
  switch (action.type) {
    case ADDRESS_DELETE_REQUEST:
      return {
        loading: true,
      };
    case ADDRESS_DELETE_SUCCESS:
      return {
        loading: false,
        userAddresses: action.payload,
      };
    case ADDRESS_DELETE_FAIL:
      //console.log(action.payload);
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
