import React, { useEffect, useState } from "react"
import {
  Table,
  Button,
  Row,
  Col,
  Modal,
  Badge,
  InputGroup,
  FormControl,
  ListGroup,
} from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"
import { listOrdersCompleted, deleteOrder } from "../actions/orderActions"
import Pagination from "./Pagination"
import { formatDate } from "../utils/common"
import { listItems } from "../actions/sameActions"

const getBadge = (status) => {
  switch (status) {
    case true:
      return "success"
    case false:
      return "secondary"
    case "Pending":
      return "warning"
    case "Submited":
      return "info"
    case "Shipped":
      return "success"
    case "Completed":
      return "secondary"
    default:
      return "primary"
  }
}

const getActive = (status) => {
  switch (status) {
    case true:
      return "Active"
    case false:
      return "Inactive"
    case "Pending":
      return "Pending"
    case "Submited":
      return "Submited"
    case "Shipped":
      return "Shipped"
    case "Completed":
      return "Completed"
    default:
      return "Inactive"
  }
}

const OrderAllCompleted = ({ companyNames }) => {
  const dispatch = useDispatch()
  const [state, setstate] = useState([])
  const [selected, setSelected] = useState({})
  const [show, setShow] = useState(false)
  const [showOrderDiscount, setShowOrderDiscount] = useState(false)
  const [orderDiscount, setOrderDiscount] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { ordersCompleted } = useSelector((store) => store.orderListCompleted)
  const inProgress = useSelector((store) => store.orderList.orders)
  const [currentPage, setCurrentPage] = useState(1)
  const [message, setMessage] = useState("")
  const [totalItems, setTotalItems] = useState("")
  const [filterWord, setFilterWord] = useState({
    company: "",
    email: "",
    status: "Completed",
  })
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1440px)" })
  const isExtraLargeScreen = useMediaQuery({ query: "(min-width: 2560px)" })

  const handleClose = () => setShow(false)
  const handleCloseX = () => {
    setMessage("")
  }

  const handleShowOrderDiscount = () => {
    setOrderDiscount(null)

    dispatch(listItems("discount", { code: selected.coupon }))
        .then(res => {
          if (res && res.status === "success") {
            if (res.discounts && res.discounts.length) {
              setOrderDiscount(res.discounts[0])

              setShowOrderDiscount(true)
            }
          }
        })
  }

  useEffect(() => {
    dispatch(listOrdersCompleted({ currentPage, filterWord }))
  }, [
    dispatch,
    currentPage,
    filterWord,
    filterWord.company,
    filterWord.email,
    isLoading,
    inProgress,
  ])

  useEffect(() => {
    if (ordersCompleted) {
      setstate(ordersCompleted.data)
      setTotalItems(ordersCompleted && ordersCompleted.meta ? ordersCompleted.meta.total : 0)
    }
  }, [ordersCompleted])

  const onChangePage = (pageNum) => setCurrentPage(pageNum)

  const onFilterPageClear = () => {
    setFilterWord({ ...filterWord, company: "", email: "" })
    document.querySelector("#emailSelectControl").value = ""
    document.querySelector("#companySelectControl").value = ""
  }

  function onFilterValue(e) {
    if (e.target.name === "company") {
      setFilterWord({
        ...filterWord,
        company: e.target.value,
        email: "",
      })
      document.querySelector("#emailSelectControl").value = ""
    }
    if (e.target.name === "email") {
      setFilterWord({
        ...filterWord,
        email: e.target.value,
        company: "",
      })
      document.querySelector("#companySelectControl").value = ""
    }
    setCurrentPage(1)
  }

  function onRowClick(id) {
    setIsDeleting(false)
    const selected =
      ordersCompleted &&
      ordersCompleted.data &&
      ordersCompleted.data.find((item) => item._id === id)
    setSelected(selected)
    setShow(true)
  }

  function handleDeleteItem() {
    setIsDeleting(true)
  }

  const handleSubmitDelete = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(deleteOrder(selected._id)).then((res) => {
      if (res.status === 200) {
        setShow(false)
        setMessage(res.msg)
        setIsDeleting(false)
        setTimeout(() => {
          setMessage("")
          setIsLoading(false)
        }, 2000)
      }
    })
  }

  return (
    <div style={{ maxWidth: "900px", margin: "0 auto" }}>
      <Col lg={6}>
        <InputGroup className="mb-2">
          <FormControl
            id="companySelectControl"
            type="text"
            placeholder="Select Company"
            name="company"
            list="companySelect"
            size="sm"
            onChange={onFilterValue}
          />
          <datalist id="companySelect">
            {companyNames &&
              companyNames.map((item, index) => {
                return (
                  <option key={index} value={item.company}>
                    {item.company}
                  </option>
                )
              })}
          </datalist>
          <FormControl
            id="emailSelectControl"
            type="text"
            placeholder="Select Email"
            name="email"
            list="emailSelect"
            onChange={onFilterValue}
            size="sm"
          />
          <datalist id="emailSelect">
            {companyNames &&
              companyNames.map((item, index) => {
                return (
                  <option key={index} value={item.email}>
                    {item.email}
                  </option>
                )
              })}
          </datalist>
          <InputGroup.Text
            onClick={onFilterPageClear}
            style={{ cursor: "pointer" }}
            title="Clear"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
      </Col>
      <Pagination
        items={state}
        currentPage={currentPage}
        totalItems={totalItems}
        onChangePage={onChangePage}
      />
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Company</th>
            <th>Order Date</th>
            {!isMobile && (
              <>
                <th>Order Name</th>
                <th>Items</th>
              </>
            )}
            <th>Price</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {state &&
            state.map((item, index) => {
              return (
                <tr key={index} onClick={() => onRowClick(item._id)}>
                  <td>{item.user && item.user.company}</td>
                  <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                  {!isMobile && (
                    <>
                      <td>{item.order_name}</td>
                      <td>{item.orderItem.length}</td>
                    </>
                  )}
                  <td>{item.order_price}</td>
                  <td>
                    {" "}
                    <Badge bg={getBadge(item.status)}>
                      {getActive(item.status)}
                    </Badge>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>

      <Modal
          size="md"
          show={showOrderDiscount}
          onHide={() => setShowOrderDiscount(false)}
          backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {orderDiscount && orderDiscount.code} - Coupon Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount Type</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && orderDiscount.type}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount Figure</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && orderDiscount.figure}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount Start</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && formatDate(new Date(orderDiscount.startAt), "MM/dd/yyyy")}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount End</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && formatDate(new Date(orderDiscount.endAt), "MM/dd/yyyy")}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount One Off</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && orderDiscount.oneOff ? "Yes" : "No"}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        // size={isMobile ? "sm" : "lg"}
        {
          ...isLargeScreen && {
            size: "lg"
          }
        }
        {
          ...isExtraLargeScreen && {
            size: "xl"
          }
        }
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selected && selected.company} - {selected && selected.order_name}{" "}
            Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Order Items</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush" style={{ pointerEvents: "none" }}>
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                  {selected &&
                    selected.orderItem &&
                    selected.orderItem.map((elem, index) => {
                      return (
                        <ListGroup.Item
                            key={index}
                            style={{
                              outline: `3px solid ${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : '#bcab79'))))))}`,
                              marginBottom: 10,
                              padding: '0 1rem',
                              borderTop: 0,
                              borderBottom: 0,
                              minHeight: 125,
                              height: 'fit-content'
                            }}
                        >
                          <li
                              style={{
                                textAlign: 'center',
                                color: 'white',
                                background: `${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : '#bcab79'))))))}`,
                                marginLeft: -17,
                                marginRight: -17,
                              }}
                          >
                            {elem.blindType ?? "DayAndNight"} {elem.headrailType === "Double" ? '(Dbl)' : ''}
                          </li>
                          <li
                              style={{
                                color: `${elem.headrailType === "Double" ? 'white': 'unset'}`,
                                background: `${elem.headrailType === "Double" ? elem.doubleGroupCode : "unset"}`,
                                marginLeft: -17,
                                marginRight: -17,
                              }}
                          >
                            <div style={{
                              marginLeft: 17,
                              marginRight: 17,
                            }}>
                              Window: {elem.window} / Fabric: {elem.fabric}
                            </div>
                          </li>
                          <li>
                            Width: {elem.width} x Drop: {elem.drop}
                          </li>
                          {elem.blindType !== "Pleated" && elem.fitting && (
                              <li>
                                Fitting: {elem.fitting} {elem.fitting !== "Top Fix" && elem.fitting !== "Normal Fix" ? `(${elem.bracket} x ${elem.bracketCount})` : ""}
                              </li>
                          )}
                          <li>
                            Headrail: {elem.headrail}
                            {elem.blindType !== "Pleated" ? (
                                <>
                                  {elem.motor === "None" ? (elem.controlOption === "Wand" ? ` - Wand: (${elem.wand})` : ` - Chain: ${elem.chain} ${elem.cord ? `(${elem.cord})` : ``}`) : ``}
                                  {elem.blindType !== "Venetian" ? ` - Control: ${elem.control}` : ``}
                                  {elem.blindType === "DayAndNight" ? ` - Tassel: ${elem.tassel}` : ""}
                                </>
                            ) : null}
                          </li>
                          {(elem.blindType === "DayAndNight" || elem.blindType === "Venetian") && (
                              <>
                                {elem.continued_blind !== "None" ? (
                                    <li>
                                      Continued Blind: {elem.continued_blind}
                                    </li>
                                ) : null}
                                {elem.motor !== "None" && (
                                    <li>
                                      Motor: {elem.motor} {elem.charger !== 0 ? `(${elem.remote} x ${elem.charger})` : ``}
                                    </li>
                                )}
                              </>
                          )}
                          {elem.notes && (
                              <li>Product Note: {elem.notes}</li>
                          )}
                        </ListGroup.Item>
                      )
                    })}
                </ul>
              </ListGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Order Note</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <span>{selected && selected.note && selected.note}</span>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Total Price</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>£ {selected && selected.order_price && selected.order_price}
                    {selected.coupon
                        ? <small>&nbsp;(Disc. Coupon: <u className="text-primary -i-cursor" style={{ cursor: 'pointer' }} onClick={handleShowOrderDiscount}>{selected.coupon}</u>)</small>
                        : ""
                    }
                  </h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Shipping Address</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <FormControl
                type="text"
                name="selected_address"
                defaultValue={selected && selected.selected_address}
                disabled
              />
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Discount</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <InputGroup>
                <InputGroup.Text id="price_discount-sm">%</InputGroup.Text>
                <FormControl
                  type="number"
                  name="price_discount"
                  defaultValue={
                    selected && (selected.discount ? selected.discount : 0)
                  }
                  disabled
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Status</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{selected && selected.status}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        {isDeleting && (
          <Modal.Footer className="d-flex justify-content-center">
            <div>
              <Button
                size={isMobile && "sm"}
                variant="danger"
                onClick={handleSubmitDelete}
              >
                I'm sure I want to delete the item
              </Button>
            </div>
          </Modal.Footer>
        )}
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <Button
              size={isMobile && "sm"}
              variant="danger"
              onClick={handleDeleteItem}
            >
              Delete
            </Button>
          </div>
          <div>
            <Button
              size={isMobile && "sm"}
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={!!message} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && (
            <div className="alert alert-success mt-2" role="alert">
              {message}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default OrderAllCompleted
