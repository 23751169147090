import {
  ADDRESS_POST_REQUEST,
  ADDRESS_POST_SUCCESS,
  ADDRESS_POST_FAIL,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_DELETE_FAIL,
  ADDRESS_GET_SUCCESS,
  ADDRESS_GET_FAIL
} from "../constants/addressConstants";

import { API_BASE_URL } from "../constants/apiBase";
import axios from "axios";

export const addAddress = (state

) => async (dispatch, getState) => {
  // // console.log(address_name)
  let result;
  try {
    dispatch({
      type: ADDRESS_POST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    // Header to send with the request
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    };

    // Make request to server and get the response data
    // // console.log(address_name)
    const { data } = await axios.post(
      `${API_BASE_URL}/api/address`, state,

      config,
    );
    // //console.log(address_name)
    // Dispatch user login success after making the request
    dispatch({
      type: ADDRESS_POST_SUCCESS,
      payload: data,
    });
    result = data;
    //console.log(data);
  } catch (error) {

    //console.log(error.response.data.msg)
    dispatch({
      type: ADDRESS_POST_FAIL,
      payload: error.response.data.msg

    });
    result=error.response.data
  }
  return result
};

export const getAddresses = () => async (dispatch, getState) => {
  // // console.log(address_name)
  let result;
  try {
    const { userLogin: { userInfo },} = getState();
    // Header to send with the request
    const config = {
      headers: {
        Authorization: `${userInfo.accesstoken}`,
      },
    };
    // Make request to server and get the response data
    const { data } = await axios.get(`${API_BASE_URL}/api/address`, config);
    //console.log(data)
    // Dispatch user login success after making the request
    dispatch({
      type: ADDRESS_GET_SUCCESS,
      payload: data,
    });
    result = data;
    //console.log(data);
  } catch (error) {
    dispatch({
      type: ADDRESS_GET_FAIL,
      payload: error.response.data.msg

    });
    result=error.response.data
  }
  return result
};

export const deleteAddress = (addressId) => async (dispatch, getState) => {
let result;
  try {
    dispatch({
      type: ADDRESS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    // Header to send with the request
    const config = {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `${userInfo.accesstoken}`,
      },
    };

    // Make request to server and get the response data
    // const { data } = await axios.delete(
    //   `${API_BASE_URL}/api/address/user/${addressId}`,
    //   config,
    // );
    // Changed DELETE request to a POST request because Mod Security prevents them
    const { data } = await axios.post(
        `${API_BASE_URL}/api/address/user/delete/${addressId}`,
        {},
        config
    );

    result=data
    // Dispatch user login success after making the request
    dispatch({
      type: ADDRESS_DELETE_SUCCESS,
      payload: data,
    });
    //  console.log(data);
  } catch (error) {
    dispatch({
      type: ADDRESS_DELETE_FAIL,
      payload: error && error.response.data.msg
    });
    result=error.response.data
  }
return result;
};
