import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

import Message from "../components/Message";

//Reset Password
import axios from "axios";
import { API_BASE_URL } from "../constants/apiBase";

const ResetPassword = (props) => {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  const handleReset = (e) => {
    e.preventDefault();
    //     console.log(e.target.value);
    //console.log(email);
    setTimeout(() => {
      axios
        .post(`${API_BASE_URL}/api/notification`, { email })
        .then((res) => {
          if (res.data.status === 200) {
            setError("");
            setMessage(res.data.msg);
            setTimeout(() => {
              setMessage("");
              props.history.push("/");
            }, 3000);
          } else {
            setMessage("");
            setError(res.data.msg);
            setTimeout(() => {
              setError("");
            }, 3000);
          }
        })
        .catch((err) => console.log("error", err));
    }, 500);
  };

  return (
    <Row>
      <Col className="text-center mx-auto mt-2" xs={12} sm={8} md={6} xl={4}>
        <div className="text-center">
          <h1>Forget Password</h1>
          <Form onSubmit={handleReset}>
            <Form.Group controlId="email">
              <Form.Label>Enter Your Email Address:</Form.Label>
              <Form.Control
                type="email"
                placeholder="email@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-zebra"
                required
              ></Form.Control>
            </Form.Group>

            <Button
              type="submit"
              variant="warning mt-3 mx-auto d-flex"
              className="bg-zebra"
            >
              Send Reset Request
            </Button>
          </Form>
          <div className="mt-2 mb-1">
            {message && <Message variant="info">{message}</Message>}
            {error && <Message variant="danger">{error}</Message>}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default ResetPassword;
