import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Datatable from "react-bs-datatable"
import {
  Badge,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal
} from "react-bootstrap"
import {
  updateItems,
  addNewItems,
  listItems,
  deleteItems,
} from "../actions/sameActions"
import { useMediaQuery } from "react-responsive"

const getBadge = (status) => {
  switch (status) {
    case true:
      return "success"
    case false:
      return "secondary"
    default:
      return "primary"
  }
}

const getActive = (status) => {
  switch (status) {
    case true:
      return "Active"
    case false:
      return "Inactive"
    default:
      return "Active"
  }
}

// const getRStick = (isRStick) => {
//   switch (isRStick) {
//     case true:
//       return "Yes"
//     case false:
//       return "No"
//     default:
//       return "No"
//   }
// }

// const getNet = (isNet) => {
//   switch (isNet) {
//     case true:
//       return "Yes"
//     case false:
//       return "No"
//     default:
//       return "No"
//   }
// }

// const getFaux = (isFaux) => {
//   switch (isFaux) {
//     case true:
//       return "Yes"
//     case false:
//       return "No"
//     default:
//       return "No"
//   }
// }

// const getPremium = (isPremium) => {
//   switch (isPremium) {
//     case true:
//       return "Yes"
//     case false:
//       return "No"
//     default:
//       return "No"
//   }
// }

const classes = {
  table: "table-striped table-hover table-responsive table-sm text-nowrap",
  theadCol: `
      .table-datatable__root & {
        &.sortable:hover {
          background: pink;
        }
      }
    `,
  tbodyRow: `
      &:nth-of-type(even) {
        background: #eaeaea;
      }
    `,
  paginationButtonGroup: "float-end",
}

const initialState = {
  blindType: "DayAndNight",
  fabric: "",
  isVStick: false,
  isRStick: false,
  isNet: false,
  isFaux: false,
  isTape: false,
  isPremium: false,
  price_open: "",
  price_covered: "",
  price_pelmeted: "",
  price_grey: "",
  price_anthracitecovered: "",
  price_white: "",
  price_anthracite: "",
  price_p_white: "",
  price_p_black: "",
  price_faux_wood: "",
  price_faux_wood_tape: "",
  price_wood: "",
  price_wood_tape: "",
  price_ver_white: "",
  price_ver_grey: "",
  price_open_discount: "",
  price_covered_discount: "",
  price_pelmeted_discount: "",
  price_grey_discount: "",
  price_anthracitecovered_discount: "",
  price_white_discount: "",
  price_anthracite_discount: "",
  price_p_white_discount: "",
  price_p_black_discount: "",
  price_faux_wood_discount: "",
  price_faux_wood_tape_discount: "",
  price_wood_discount: "",
  price_wood_tape_discount: "",
  price_ver_white_discount: "",
  price_ver_grey_discount: "",
  color: "",
  content: "",
  description: "",
  imgURL: "",
  isActive: true,
}

export const BlindList = () => {
  const dispatch = useDispatch()
  const productList = useSelector((store) => store.productList.products)
  const [state, setState] = useState(productList)
  const [selected, setSelected] = useState(initialState)
  const [show, setShow] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  // const isExtraLargeScreen = useMediaQuery({ query: "(min-width: 2560px)" })
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1440px)" })
  // const isTablet = useMediaQuery({ query: "(min-width: 768px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")

  const handleClose = () => setShow(false)
  const handleCloseX = () => {
    setMessage("")
    setError("")
  }

  useEffect(() => {
    setState(productList)
  }, [productList])

  useEffect(() => {
    dispatch(listItems("product"))
  }, [dispatch, isLoading])

  const header = [
    { title: "Blind Type", prop: "blindType", sortable: true },
    { title: "Fabric", prop: "fabric", sortable: true },
    // isLargeScreen && { title: "R Stick", prop: "isRStick" },
    // isLargeScreen && { title: "Net", prop: "isNet" },
    // isLargeScreen && { title: "Faux", prop: "isFaux" },
    // isExtraLargeScreen && { title: "Premium", prop: "isPremium" },
    isLargeScreen && { title: "Open", prop: "price_open" },
    isLargeScreen && { title: "WhiteCovered", prop: "price_covered" },
    isLargeScreen && { title: "GreyCovered", prop: "price_grey" },
    isLargeScreen && { title: "AnthraciteCovered", prop: "price_anthracitecovered" },
    isLargeScreen && { title: "Pelmeted", prop: "price_pelmeted" },
    isLargeScreen && { title: "White", prop: "price_white" },
    isLargeScreen && { title: "Anthracite", prop: "price_anthracite" },
    isLargeScreen && { title: "P-White", prop: "price_p_white" },
    isLargeScreen && { title: "P-Black", prop: "price_p_black" },
    isLargeScreen && { title: "FauxWood", prop: "price_faux_wood" },
    isLargeScreen && { title: "FauxWoodTape", prop: "price_faux_wood_tape" },
    isLargeScreen && { title: "Wood", prop: "price_wood" },
    isLargeScreen && { title: "WoodTape", prop: "price_wood_tape" },
    isLargeScreen && { title: "VER-White", prop: "price_ver_white" },
    isLargeScreen && { title: "VER-Grey", prop: "price_ver_grey" },
    // isTablet && { title: "Pattern", prop: "color" },
    { title: "Status", prop: "isActive" },
  ]

  const body =
    state &&
    state.length > 0 &&
    state.sort((a,b)=>a.fabric.substring(0,3)-b.fabric.substring(0,3)).map((item) => {
      return {
        blindType: item.blindType.split(/(?=[A-Z])/).join(' '),
        fabric: item.fabric,
        // isRStick: (
        //     getRStick(item.isRStick)
        // ),
        // isNet: (
        //     getNet(item.isNet)
        // ),
        // isFaux: (
        //     getFaux(item.isFaux)
        // ),
        // isPremium: (
        //     getPremium(item.isPremium)
        // ),
        price_open: item.blindType === 'DayAndNight' && item.price_open ? "£" + item.price_open : "",
        price_covered: item.blindType === 'DayAndNight' && item.price_covered ? "£" + item.price_covered : "",
        price_grey: item.blindType === 'DayAndNight' && item.price_grey ? "£" + item.price_grey : "",
        price_anthracitecovered: item.blindType === 'DayAndNight' && item.price_anthracitecovered ? "£" + item.price_anthracitecovered : "",
        price_pelmeted: item.blindType === 'DayAndNight' && item.price_pelmeted ? "£" + item.price_pelmeted : "",
        price_white: item.blindType === 'Vellision' && item.price_white ? "£" + item.price_white : "",
        price_anthracite: item.blindType === 'Vellision' && item.price_anthracite ? "£" + item.price_anthracite : "",
        price_p_white: item.blindType === 'Pleated' && item.price_p_white ? "£" + item.price_p_white : "",
        price_p_black: item.blindType === 'Pleated' && item.price_p_black ? "£" + item.price_p_black : "",
        price_faux_wood: item.blindType === 'Venetian' && item.price_faux_wood ? "£" + item.price_faux_wood : "",
        price_faux_wood_tape: item.blindType === 'Venetian' && item.price_faux_wood_tape ? "£" + item.price_faux_wood_tape : "",
        price_wood: item.blindType === 'Venetian' && item.price_wood ? "£" + item.price_wood : "",
        price_wood_tape: item.blindType === 'Venetian' && item.price_wood_tape ? "£" + item.price_wood_tape : "",
        price_ver_white: item.blindType === 'Vertical' && item.price_ver_white ? "£" + item.price_ver_white : "",
        price_ver_grey: item.blindType === 'Vertical' && item.price_ver_grey ? "£" + item.price_ver_grey : "",
        // price_open_discount: "%" + item.price_open_discount,
        // price_covered_discount: "%" + item.price_covered_discount,
        // price_grey_discount: "%" + item.price_grey_discount,
        // price_anthracitecovered_discount: "%" + item.price_anthracitecovered_discount,
        // price_pelmeted_discount: "%" + item.price_pelmeted_discount,
        // price_white_discount: "%" + item.price_white_discount,
        // price_anthracite_discount: "%" + item.price_anthracite_discount,
        // // price_black_discount: "%" + item.price_black_discount,
        // price_p_white_discount: "%" + item.price_p_white_discount,
        // price_p_black_discount: "%" + item.price_p_black_discount,
        // price_faux_wood_discount: "%" + item.price_faux_wood_discount,
        // price_faux_wood_tape_discount: "%" + item.price_faux_wood_tape_discount,
        // price_wood_discount: "%" + item.price_wood_discount,
        // price_wood_tape_discount: "%" + item.price_wood_tape_discount,
        // price_ver_white_discount: "%" + item.price_ver_white_discount,
        // price_ver_grey_discount: "%" + item.price_ver_grey_discount,
        // color: item.color,
        content: item.content,
        description: item.description,
        imgURL: item.imgURL,
        isActive: (
          <Badge bg={getBadge(item.isActive)}>{getActive(item.isActive)}</Badge>
        ),
        id: item._id,
      }
    })

  function onRowClick(data) {
    setIsAdding(false)
    setIsDeleting(false)
    const selected =
      productList && productList.find((item) => item._id === data.id)
    setSelected(selected)
    setShow(true)
  }

  function addNewItem() {
    setSelected(initialState)
    setIsAdding(true)
    setShow(true)
  }

  function handleDeleteItem() {
    setIsDeleting(true)
  }

  const handleInput = (e) => {
    if (e.target.name === "isActive" || e.target.name === "isVStick" || e.target.name === "isRStick" || e.target.name === "isNet" || e.target.name === "isFaux" || e.target.name === "isTape" || e.target.name === "isPremium") {
      setSelected({ ...selected, [e.target.name]: e.target.checked})
    } else {
      setSelected({
        ...selected,
        ...(e.target.name === "blindType" && e.target.value === "DayAndNight") && { isNet: false, isFaux: false, isTape: false, isPremium: false },
        ...(e.target.name === "blindType" && e.target.value === "Vellision") && { isVStick: false, isRStick: false, isNet: false, isFaux: false, isTape: false, isPremium: false },
        ...(e.target.name === "blindType" && e.target.value === "Pleated") && { isVStick: false, isRStick: false, isFaux: false, isTape: false, isPremium: false },
        ...(e.target.name === "blindType" && e.target.value === "Venetian") && { isVStick: false, isRStick: false, isNet: false, isPremium: false },
        ...(e.target.name === "blindType" && e.target.value === "Vertical") && { isVStick: false, isRStick: false, isNet: false, isFaux: false, isTape: false },
        [e.target.name]: e.target.value
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if ((selected.blindType === 'DayAndNight' && (
        !selected.price_open ||
        !selected.price_covered ||
        !selected.price_anthracitecovered ||
        !selected.price_pelmeted ||
        !selected.price_grey
    )) || (selected.blindType === 'Vellision' && (
        !selected.price_white ||
        !selected.price_anthracite
    )) || (selected.blindType === 'Pleated' && (
        !selected.price_p_white ||
        !selected.price_p_black
    )) || (selected.blindType === 'Venetian' && (
        !selected.price_faux_wood ||
        !selected.price_faux_wood_tape ||
        !selected.price_wood ||
        !selected.price_wood_tape
    )) || (selected.blindType === 'Vertical' && (
        !selected.price_ver_white ||
        !selected.price_ver_grey
    ))) {
      setMessage("")
      setError("Price fields can not be empty")
      setTimeout(() => {
        setError("")
      }, 3000)
    } else {
      setIsLoading(true)
      dispatch(updateItems("product", selected)).then((res) => {
        if (res.status === 200) {
          setError("")
          setMessage(res.msg)
          setShow(false)
          setTimeout(() => {
            setMessage("")
            setIsLoading(false)
          }, 3000)
        }
        else {
          setMessage("")
          setError(res.msg)
          setTimeout(() => {
            setError("")
            setIsLoading(false)
          }, 3000)
        }
      })
    }
  }

  const handleSubmitNew = (e) => {
    e.preventDefault()

    if ((selected.blindType === 'DayAndNight' && (
        !selected.fabric ||
        !selected.price_open ||
        !selected.price_covered ||
        !selected.price_anthracitecovered ||
        !selected.price_pelmeted ||
        !selected.price_grey
    )) || (selected.blindType === 'Vellision' && (
        !selected.fabric ||
        !selected.price_white ||
        !selected.price_anthracite
    )) || (selected.blindType === 'Pleated' && (
        !selected.fabric ||
        !selected.price_p_white ||
        !selected.price_p_black
    )) || (selected.blindType === 'Venetian' && (
        !selected.fabric ||
        !selected.price_faux_wood ||
        !selected.price_faux_wood_tape ||
        !selected.price_wood ||
        !selected.price_wood_tape
    )) || (selected.blindType === 'Vertical' && (
        !selected.fabric ||
        !selected.price_ver_white ||
        !selected.price_ver_grey
    ))) {
      setMessage("")
      setError("Price and fabric name fields can not be empty")
      setTimeout(() => {
        setError("")
      }, 3000)
    } else {
      setIsLoading(true)
      dispatch(addNewItems("product", selected)).then((res) => {
        if (res.status === 200) {
          setError("")
          setMessage(res.msg)
          setShow(false)
          setIsAdding(false)
          setTimeout(() => {
            setMessage("")
            setIsLoading(false)
          }, 3000)
        }
        else {
          setMessage("")
          setError(res.msg)
          setTimeout(() => {
            setError("")
            setIsLoading(false)
          }, 3000)
        }
      })
    }
  }

  const handleSubmitDelete = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(deleteItems("product", selected._id)).then((res) => {
      if (res.status === 200) {
        setError("")
        setMessage(res.msg)
        setShow(false)
        setIsDeleting(false)
        setTimeout(() => {
          setMessage("")
          setIsLoading(false)
        }, 3000)
      }
      else {
        setMessage("")
        setError(res.msg)
        setIsDeleting(false)
        setTimeout(() => {
          setError("")
          setIsLoading(false)
        }, 3000)
      }
    })
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <div className="d-flex text-center mb-2">
        <Button variant="primary" type="button" onClick={addNewItem}>
          Add New Blind
        </Button>
      </div>
      {state && state.length > 0 && (
        <Datatable
          tableHeaders={header}
          tableBody={body}
          classes={classes}
          onRowClick={(item) => onRowClick(item)}
          rowsPerPage={10}
          initialSort={{ prop: "fabric", isAscending: true }}
        />
      )}
      <Modal
        // size={isMobile ? "sm" : ""}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {isAdding ? (
            <Modal.Title>Add New Fabric</Modal.Title>
          ) : (
            <Modal.Title>Fabric Update</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text
                  style={{ width: "180px", overflow: "hidden" }}
                  id="inputGroup-fabric-blindType-sm"
              >
                Blind Type
              </InputGroup.Text>
              <Form.Select
                  className="select"
                  size="sm"
                  value={selected.blindType}
                  name="blindType"
                  id="blindType"
                  onChange={handleInput}
                  style={{ width: "180px" }}
              >
                <option value="DayAndNight">Day And Night</option>
                <option value="Vellision">Vellision</option>
                <option value="Pleated">Pleated</option>
                <option value="Venetian">Venetian</option>
                <option value="Vertical">Vertical</option>
              </Form.Select>
            </InputGroup>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text
                style={{ width: "180px", overflow: "hidden" }}
                id="inputGroup-fabric-sm"
              >
                Fabric
              </InputGroup.Text>
              <FormControl
                type="text"
                placeholder="Enter Name"
                name="fabric"
                value={selected.fabric}
                onChange={handleInput}
                disabled={!isAdding}
                style={{ width: "180px" }}
              />
            </InputGroup>
            {selected.blindType === 'DayAndNight' ? (
                <>
                  <InputGroup size="sm" className="mb-2">
                    <Form.Check
                        type="switch"
                        id="isVStick"
                        label="V Stick"
                        name="isVStick"
                        checked={selected.isVStick}
                        onChange={handleInput}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <Form.Check
                        type="switch"
                        id="isRStick"
                        label="R Stick"
                        name="isRStick"
                        checked={selected.isRStick}
                        onChange={handleInput}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-open-sm"
                    >
                      Price Open
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price Open"
                        name="price_open"
                        min="0.00"
                        max="10000.00"
                        step="0.01"
                        value={selected.price_open || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-open-discount-sm"
                    >
                      Price Open Discount
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price Open Discount"
                        name="price_open_discount"
                        min="0.0"
                        max="100.0"
                        step="0.1"
                        value={selected.price_open_discount || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-covered-sm"
                    >
                      Price WhiteCovered
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price WhiteCovered"
                        name="price_covered"
                        min="0.00"
                        max="10000.00"
                        step="0.01"
                        value={selected.price_covered || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-covered-discount-sm"
                    >
                      Price WhiteCovered Discount
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price WhiteCovered Discount"
                        name="price_covered_discount"
                        min="0.0"
                        max="100.0"
                        step="0.1"
                        value={selected.price_covered_discount || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-grey-sm"
                    >
                      Price GreyCovered
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price GreyCovered"
                        name="price_grey"
                        min="0.00"
                        max="10000.00"
                        step="0.01"
                        value={selected.price_grey || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-grey-discount-sm"
                    >
                      Price GreyCovered Discount
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price GreyCovered Discount"
                        name="price_grey_discount"
                        min="0.0"
                        max="100.0"
                        step="0.1"
                        value={selected.price_grey_discount || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-anthracitecovered-sm"
                    >
                      Price AnthraciteCovered
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price AnthraciteCovered"
                        name="price_anthracitecovered"
                        min="0.00"
                        max="10000.00"
                        step="0.01"
                        value={selected.price_anthracitecovered || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-anthracitecovered-discount-sm"
                    >
                      Price AnthraciteCovered Discount
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price AnthraciteCovered Discount"
                        name="price_anthracitecovered_discount"
                        min="0.0"
                        max="100.0"
                        step="0.1"
                        value={selected.price_anthracitecovered_discount || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-pelmeted-sm"
                    >
                      Price Pelmeted
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price Pelmeted"
                        name="price_pelmeted"
                        min="0.00"
                        max="10000.00"
                        step="0.01"
                        value={selected.price_pelmeted || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text
                        style={{ width: "180px", overflow: "hidden" }}
                        id="inputGroup-price-pelmeted-discount-sm"
                    >
                      Price Pelmeted Discount
                    </InputGroup.Text>
                    <FormControl
                        type="number"
                        placeholder="Enter Price Pelmeted Discount"
                        name="price_pelmeted_discount"
                        min="0.0"
                        max="100.0"
                        step="0.1"
                        value={selected.price_pelmeted_discount || ""}
                        onChange={handleInput}
                        style={{ width: "180px" }}
                    />
                  </InputGroup>
                </>
            ) : (selected.blindType === 'Vellision' ? (
                    <>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-white-sm"
                        >
                          Price White
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price White"
                            name="price_white"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_white || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-white-discount-sm"
                        >
                          Price White Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price White Discount"
                            name="price_white_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_white_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-anthracite-sm"
                        >
                          Price Anthracite
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price Anthracite"
                            name="price_anthracite"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_anthracite || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-anthracite-discount-sm"
                        >
                          Price Anthracite Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price Anthracite Discount"
                            name="price_anthracite_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_anthracite_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                    </>
                ) : (selected.blindType === 'Pleated' ? (
                    <>
                      <InputGroup size="sm" className="mb-2">
                        <Form.Check
                            type="switch"
                            id="isNet"
                            label="Net"
                            name="isNet"
                            checked={selected.isNet}
                            onChange={handleInput}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-p-white-sm"
                        >
                          Price P-White
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price P-White"
                            name="price_p_white"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_p_white || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-p-white-discount-sm"
                        >
                          Price P-White Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price P-White Discount"
                            name="price_p_white_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_p_white_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-p-black-sm"
                        >
                          Price P-Black
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price P-Black"
                            name="price_p_black"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_p_black || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-p-black-discount-sm"
                        >
                          Price P-Black Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price P-Black Discount"
                            name="price_p_black_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_p_black_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                    </>
                // ) : (
                ) : (selected.blindType === 'Venetian' ? (
                    <>
                      <InputGroup size="sm" className="mb-2">
                        <Form.Check
                            type="switch"
                            id="isFaux"
                            label="Faux"
                            name="isFaux"
                            checked={selected.isFaux}
                            onChange={handleInput}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <Form.Check
                            type="switch"
                            id="isTape"
                            label="Tape"
                            name="isTape"
                            checked={selected.isTape}
                            onChange={handleInput}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-faux-wood-sm"
                        >
                          Price FauxWood
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price FauxWood"
                            name="price_faux_wood"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_faux_wood || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-faux-wood-discount-sm"
                        >
                          Price FauxWood Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price FauxWood Discount"
                            name="price_faux_wood_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_faux_wood_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-faux-wood-tape-sm"
                        >
                          Price FauxWoodTape
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price FauxWoodTape"
                            name="price_faux_wood_tape"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_faux_wood_tape || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-faux-wood-tape-discount-sm"
                        >
                          Price FauxWoodTape Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price FauxWoodTape Discount"
                            name="price_faux_wood_tape_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_faux_wood_tape_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-wood-sm"
                        >
                          Price Wood
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price Wood"
                            name="price_wood"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_wood || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-wood-discount-sm"
                        >
                          Price Wood Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price Wood Discount"
                            name="price_wood_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_wood_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-wood-tape-sm"
                        >
                          Price WoodTape
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price WoodTape"
                            name="price_wood_tape"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_wood_tape || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-wood-tape-discount-sm"
                        >
                          Price WoodTape Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price WoodTape Discount"
                            name="price_wood_tape_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_wood_tape_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                    </>
                // ))
                ) : (
                    <>
                      <InputGroup size="sm" className="mb-2">
                        <Form.Check
                            type="switch"
                            id="isPremium"
                            label="Premium"
                            name="isPremium"
                            checked={selected.isPremium}
                            onChange={handleInput}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-ver-white-sm"
                        >
                          Price VER-White
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price VER-White"
                            name="price_ver_white"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_ver_white || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-ver-white-discount-sm"
                        >
                          Price VER-White Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price VER-White Discount"
                            name="price_ver_white_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_ver_white_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-ver-grey-sm"
                        >
                          Price VER-Grey
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price VER-Grey"
                            name="price_ver_grey"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={selected.price_ver_grey || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="mb-2">
                        <InputGroup.Text
                            style={{ width: "180px", overflow: "hidden" }}
                            id="inputGroup-price-ver-grey-discount-sm"
                        >
                          Price VER-Grey Discount
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            placeholder="Enter Price VER-Grey Discount"
                            name="price_ver_grey_discount"
                            min="0.0"
                            max="100.0"
                            step="0.1"
                            value={selected.price_ver_grey_discount || ""}
                            onChange={handleInput}
                            style={{ width: "180px" }}
                        />
                      </InputGroup>
                    </>
                )))
            )}
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text
                style={{ width: "180px", overflow: "hidden" }}
                id="inputGroup-pattern-sm"
              >
                Pattern
              </InputGroup.Text>
              <FormControl
                type="text"
                placeholder="Enter pattern"
                name="color"
                value={selected.color}
                onChange={handleInput}
                style={{ width: "180px" }}
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text
                style={{ width: "180px", overflow: "hidden" }}
                id="inputGroup-content-sm"
              >
                Content
              </InputGroup.Text>
              <FormControl
                as="textarea"
                rows={2}
                name="content"
                value={selected.content}
                onChange={handleInput}
                style={{ width: "180px" }}
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text
                style={{ width: "180px", overflow: "hidden" }}
                id="inputGroup-description-sm"
              >
                Description
              </InputGroup.Text>
              <FormControl
                as="textarea"
                rows={2}
                name="description"
                value={selected.description}
                onChange={handleInput}
                style={{ width: "180px" }}
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-2">
              <InputGroup.Text
                style={{ width: "180px", overflow: "hidden" }}
                id="inputGroup-image-sm"
              >
                Image Url
              </InputGroup.Text>
              <FormControl
                type="text"
                placeholder="Enter image url"
                name="imgURL"
                value={selected.imgURL}
                onChange={handleInput}
                style={{ width: "180px" }}
              />
            </InputGroup>
            <InputGroup size="sm" className="mb-2">
              <Form.Check
                type="switch"
                id="isActive"
                label="Status"
                name="isActive"
                checked={selected.isActive}
                onChange={handleInput}
              />
            </InputGroup>
          </Form>
          {error && (
            <div className="alert alert-warning mt-2" role="alert">
              {error}
            </div>
          )}
        </Modal.Body>
        {!isAdding && isDeleting && (
          <Modal.Footer className="d-flex justify-content-center">
            <div>
              <Button
                size={isMobile && "sm"}
                variant="danger"
                onClick={handleSubmitDelete}
              >
                I'm sure I want to delete the item
              </Button>
            </div>
          </Modal.Footer>
        )}
        <Modal.Footer className="d-flex justify-content-between">
          <div style={{ margin: "auto" }}>
            {!isAdding && (
              <Button
                size={isMobile && "sm"}
                variant="danger"
                onClick={handleDeleteItem}
                disabled={message || error}
                style={{ margin: 2 }}
              >
                Delete
              </Button>
            )}
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-around" style={{ margin: `${!isAdding ? `auto` : `inherit`}` }}>
            <Button
              size={isMobile && "sm"}
              variant="secondary"
              onClick={handleClose}
              style={{ margin: 2 }}
            >
              Cancel
            </Button>
            {isAdding ? (
              <Button
                size={isMobile && "sm"}
                variant="primary"
                onClick={handleSubmitNew}
                disabled={message || error}
                style={{ margin: 2 }}
              >
                Save Fabric
              </Button>
            ) : (
              <Button
                size={isMobile && "sm"}
                variant="primary"
                onClick={handleSubmit}
                disabled={message || error}
                style={{ margin: 2 }}
              >
                Update Fabric
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={!!message} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && (
            <div className="alert alert-success mt-2" role="alert">
              {message}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}
