import {
  TASSEL_LIST_REQUEST,
  TASSEL_LIST_SUCCESS,
  TASSEL_LIST_FAIL,
  TASSEL_DETAILS_REQUEST,
  TASSEL_DETAILS_SUCCESS,
  TASSEL_DETAILS_FAIL,
} from "../constants/tasselConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting tassels from server
export const tasselListReducer = (state = { tassels: [] }, action) => {
  switch (action.type) {
    case TASSEL_LIST_REQUEST:
      return {
        loading: true,
        tassels: [],
      };
    case TASSEL_LIST_SUCCESS:
      return {
        loading: false,
        tassels: action.payload,
      };
    case TASSEL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
};

// Get tassels details
export const tasselDetailsReducer = (
  state = { tassel: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case TASSEL_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case TASSEL_DETAILS_SUCCESS:
      return {
        loading: false,
        tassel: action.payload,
      };
    case TASSEL_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
