import axios from "axios"
import {
    GET_BLIND_REQUEST,
    GET_BLIND_SUCCESS,
    GET_BLIND_FAIL,
    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAIL,
    INTEGRATE_CUSTOMER_REQUEST,
    INTEGRATE_CUSTOMER_SUCCESS,
    INTEGRATE_CUSTOMER_FAIL,
    INTEGRATE_ORDER_REQUEST,
    INTEGRATE_ORDER_SUCCESS,
    INTEGRATE_ORDER_FAIL,
    DISINTEGRATE_ORDER_REQUEST,
    DISINTEGRATE_ORDER_SUCCESS,
    DISINTEGRATE_ORDER_FAIL,
    SEND_ORDER_RECEIVED_MAIL_REQUEST,
    SEND_ORDER_RECEIVED_MAIL_SUCCESS,
    SEND_ORDER_RECEIVED_MAIL_FAIL,
} from "../constants/apiConstants"
import { API_BASE_URL } from "../constants/apiBase"

export const getBlind = (code) => async (dispatch, getState) => {
    let result
    try {
        dispatch({
            type: GET_BLIND_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${userInfo.accesstoken}`,
            },
        }

        const response = await axios.get(
            `${API_BASE_URL}/api/api/blinds?code=${code}`,
            config
        )

        let payload = {
            status: response.status,
            message: response.data.message,
            data: response.data.data,
        }

        dispatch({
            type: GET_BLIND_SUCCESS,
            payload,
        })

        result = payload
    }
    catch (error) {
        let payload = {
            status: error.response.status,
            message: error.response.data.message,
            data: null,
        }

        dispatch({
            type: GET_BLIND_FAIL,
            payload,
        })

        result = payload
    }

    return result
}

export const getCustomer = (email) => async (dispatch, getState) => {
    let result
    try {
        dispatch({
            type: GET_CUSTOMER_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${userInfo.accesstoken}`,
            },
        }

        const response = await axios.get(
            `${API_BASE_URL}/api/api/customers?email=${encodeURIComponent(email)}`,
            config
        )

        let payload = {
            status: response.status,
            message: response.data.message,
            data: response.data.data,
        }

        dispatch({
            type: GET_CUSTOMER_SUCCESS,
            payload,
        })

        result = payload
    }
    catch (error) {
        let payload = {
            status: error.response.status,
            message: error.response.data.message,
            data: null,
        }

        dispatch({
            type: GET_CUSTOMER_FAIL,
            payload,
        })

        result = payload
    }

    return result
}

export const integrateCustomer = (data) => async (dispatch, getState) => {
    let result
    try {
        dispatch({
            type: INTEGRATE_CUSTOMER_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${userInfo.accesstoken}`,
            },
        }

        const response = await axios.post(
            `${API_BASE_URL}/api/api/customers`,
            data,
            config
        )

        let payload = {
            status: response.status,
            message: response.data.message,
            data: response.data.data,
        }

        dispatch({
            type: INTEGRATE_CUSTOMER_SUCCESS,
            payload,
        })

        result = payload
    }
    catch (error) {
        let payload = {
            status: error.response.status,
            message: error.response.data.message,
            data: null,
        }

        dispatch({
            type: INTEGRATE_CUSTOMER_FAIL,
            payload,
        })

        result = payload
    }

    return result
}

export const integrateOrder = (data) => async (dispatch, getState) => {
    let result
    try {
        dispatch({
            type: INTEGRATE_ORDER_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${userInfo.accesstoken}`,
            },
        }

        const response = await axios.post(
            `${API_BASE_URL}/api/api/orders`,
            data,
            config
        )

        let payload = {
            status: response.status,
            message: response.data.message,
            data: response.data.data,
        }

        dispatch({
            type: INTEGRATE_ORDER_SUCCESS,
            payload,
        })

        result = payload
    } catch (error) {
        let payload = {
            status: error.response.status,
            message: error.response.data.message,
            data: null,
        }

        dispatch({
            type: INTEGRATE_ORDER_FAIL,
            payload,
        })

        result = payload
    }

    return result
}

export const disintegrateOrder = (id) => async (dispatch, getState) => {
    let result
    try {
        dispatch({
            type: DISINTEGRATE_ORDER_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${userInfo.accesstoken}`,
            },
        }

        const response = await axios.post(
            `${API_BASE_URL}/api/api/orders/delete/${id}`,
            {},
            config
        )

        let payload = {
            status: response.status,
            message: response.data.message,
        }

        dispatch({
            type: DISINTEGRATE_ORDER_SUCCESS,
            payload,
        })

        result = payload
    }
    catch (error) {
        let payload = {
            status: error.response.status,
            message: error.response.data.message,
        }

        dispatch({
            type: DISINTEGRATE_ORDER_FAIL,
            payload,
        })

        result = payload
    }

    return result
}

export const sendOrderReceivedMail = (state, user) => async (dispatch, getState) => {
    let result
    try {
        dispatch({
            type: SEND_ORDER_RECEIVED_MAIL_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${userInfo.accesstoken}`,
            },
        }

        const response = await axios.post(
            `${API_BASE_URL}/api/api/email/order-received`,
            {
                company: user.company,
                email: user.email,
                address: state.selected_address,
                items: state.orderItem
            },
            config
        )

        let payload = {
            status: response.status,
            message: response.data.message,
        }

        dispatch({
            type: SEND_ORDER_RECEIVED_MAIL_SUCCESS,
            payload,
        })

        result = payload
    } catch (error) {
        let payload = {
            status: error.response.status,
            message: error.response.data.message,
        }

        dispatch({
            type: SEND_ORDER_RECEIVED_MAIL_FAIL,
            payload,
        })

        result = payload
    }

    return result
}
