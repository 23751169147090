import React from "react";
import { Button, Card} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
const Page404 = () => {
  return (
    <div className="container text-center mx-auto my-auto">
     
      <Card className="mx-auto my-auto border-zebra">
        <Card.Body>
          <Card.Title className="textZebra">Oops! You're lost.</Card.Title>
          <Card.Text >
          The page you are looking for was not found.
          </Card.Text>
          <LinkContainer to="/">
          <Button className="bg-zebra">Return Home</Button>
          </LinkContainer>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Page404;
