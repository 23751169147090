import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Tabs, Tab, Button } from "react-bootstrap"
import OrderAllList from "../components/OrderAllList"
import OrderAllCompleted from "../components/OrderAllCompleted"
import DiscountList from "../components/DiscountList"
import { listCompanyNames } from "../actions/orderActions"

const OrderAll = () => {
  const dispatch = useDispatch()
  const [companyNames, setcompanyNames] = useState([])

  const [activeScreen, setActiveScreen] = useState("orders")

  useEffect(() => {
    dispatch(listCompanyNames()).then((res) => {
      if (res.status === 200) {
        setcompanyNames(res.users)
      }
    })
  }, [dispatch])

  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      {activeScreen === "orders" ? (
          <Button
              type="button"
              variant="secondary"
              className="mt-2 mb-3 bg-zebra"
              onClick={() => setActiveScreen("discounts")}
          >
            Show Discounts
          </Button>
      ) : (
          <Button
              type="button"
              variant="secondary"
              className="mt-2 mb-3 bg-zebra"
              onClick={() => setActiveScreen("orders")}
          >
            Show Orders
          </Button>
      )}
      {
        (() => {
          switch (activeScreen) {
            case "orders":
              return (
                  <Tabs
                      defaultActiveKey="order_all"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                  >
                    <Tab eventKey="order_all" title="In Progress">
                      <OrderAllList companyNames={companyNames} />
                    </Tab>
                    <Tab eventKey="order_all_completed" title="Completed">
                      <OrderAllCompleted companyNames={companyNames} />
                    </Tab>
                  </Tabs>
              )
            case "discounts":
              return (
                  <DiscountList />
              )
            default:
              return null
          }
        })()
      }
    </div>
  )
}

export default OrderAll
