import React, { useEffect, useState } from "react"
import { Button, Row, Col, Modal, Badge, ListGroup } from "react-bootstrap"
import Message from "../components/Message"

import { useDispatch, useSelector } from "react-redux"
import { usePrevious } from "../hooks/use-previous"

import Datatable from "react-bs-datatable"

import { Link } from "react-router-dom"

import { useMediaQuery } from "react-responsive"

// Get order details actions
import { listUserOrders } from "../actions/orderActions"
import { arraysEqual } from "../utils/common"

const getBadge = (status) => {
  switch (status) {
    case true:
      return "success"
    case false:
      return "secondary"
    case "Pending":
      return "warning"
    case "Rejected":
      return "danger"
    case "Submited":
      return "info"
    case "Shipped":
      return "success"
    case "Completed":
      return "secondary"
    default:
      return "primary"
  }
}

const getActive = (status) => {
  switch (status) {
    case true:
      return "Active"
    case false:
      return "Inactive"
    case "Pending":
      return "Pending"
    case "Rejected":
      return "Rejected"
    case "Submited":
      return "Waiting Payment"
    case "Shipped":
      return "Shipped"
    case "Completed":
      return "Completed"
    default:
      return "Inactive"
  }
}

const classes = {
  table: "table-striped table-hover",
  theadCol: `
      .table-datatable__root & {
        &.sortable:hover {
          background: pink;
        }
      }
    `,
  tbodyRow: `
      &:nth-of-type(even) {
        background: #ghjghj;
      }
    `,
  tbodyCol: `
       {
        margin-left: 5px;
      }
    `,
}

const Order = ({ match, history }) => {
  const dispatch = useDispatch()
  const [state, setstate] = useState([])
  const [selected, setselected] = useState({})
  const [show, setShow] = useState(false)
  const { orders } = useSelector((store) => store.orderListUser)
  const { addresses } = useSelector((store) => store.userAddresses)
  const handleClose = () => setShow(false)

  const isTablet = useMediaQuery({ query: "(min-width: 768px)" })
  // const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1440px)" })
  const isExtraLargeScreen = useMediaQuery({ query: "(min-width: 2560px)" })

  function findAddress(id) {
    const selectedAddress = addresses.find((item) => item._id === id)
    if (selectedAddress) {
      return `${selectedAddress.address_line1} ${
        selectedAddress.address_line2
      } ${selectedAddress.city} ${
        selectedAddress.postal_code
      } ${selectedAddress.country.toUpperCase()}`
    }
  }

  const header = [
    {
      title: "Order Name",
      prop: "name",
      cellProps: { style: { cursor: "pointer" } },
    },
    {
      title: "Order Date",
      prop: "date",
      cellProps: { style: { cursor: "pointer" } },
    },
    {
      title: "Order Items",
      prop: "items",
      cellProps: { style: { cursor: "pointer" } },
    },
    isTablet && {
      title: "Address",
      prop: "address",
      cellProps: { style: { cursor: "pointer" } },
    },
    { title: "Price", prop: "price" },
    { title: "Status", prop: "status" },
  ]

  const body =
    state &&
    state.length > 0 &&
    state.map((item, index) => {
      return {
        name: item.order_name,
        date: new Date(item.createdAt).toLocaleDateString(),
        items: item.orderItem.length,
        address: item.selected_address || findAddress(item.address_id),
        note: item.note,
        price: "£" + (item.total_price ? item.total_price : item.order_price),
        status: (
          <Badge bg={getBadge(item.status)}>{getActive(item.status)}</Badge>
        ),
        user_id: item.user_id,
        address_id: item.address_id,
        id: item._id,
      }
    })

  const prevStore = usePrevious({
    orders,
  })

  const userOrders =
    orders && orders.filter((item) => item.status !== "Collection")

  useEffect(() => {
    if (userOrders) {
      if (!prevStore || !prevStore.orders) {
        setstate(userOrders)
      }
      else {
        const isEqual = arraysEqual(prevStore.orders, orders)

        if (!isEqual) {
          setstate(userOrders)
        }
      }
    }
  }, [prevStore, orders, userOrders])

  useEffect(() => {
    dispatch(listUserOrders())
  }, [dispatch])

  function onRowClick(data) {
    const selected = orders && orders.find((item) => item._id === data.id)
    setselected(selected)
    setShow(true)
  }

  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <Row>
        <Col xs={12} className="mb-2">
          <h2>My Orders</h2>
          {state && state.length === 0 ? (
            <Message>
              You have no orders <Link to="/">Go back</Link>
            </Message>
          ) : (
            <Datatable
              tableHeaders={header}
              tableBody={body}
              classes={classes}
              onRowClick={(item) => onRowClick(item)}
              rowsPerPage={8}
            />
          )}
        </Col>
      </Row>
      <Modal
        // size={isMobile ? "sm" : "lg"}
        {
          ...isLargeScreen && {
            size: "lg"
          }
        }
        {
          ...isExtraLargeScreen && {
            size: "xl"
          }
        }
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selected && selected.order_name} Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Order Items</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush" style={{ pointerEvents: "none" }}>
                <ListGroup.Item>
                  <h6>
                    You have {selected.orderItem && selected.orderItem.length}{" "}
                    items in your order
                  </h6>
                </ListGroup.Item>
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                  {selected.orderItem &&
                    selected.orderItem.map((elem, index) => {
                      return (
                        <ListGroup.Item
                            key={index}
                            style={{
                              outline: `3px solid ${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : 'unset'))))))}`,
                              marginBottom: 10,
                              padding: '0 1rem',
                              borderTop: 0,
                              borderBottom: 0,
                              minHeight: 125,
                              height: 'fit-content'
                            }}
                        >
                          <li
                              style={{
                                textAlign: 'center',
                                color: 'white',
                                background: `${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : 'unset'))))))}`,
                                marginLeft: -17,
                                marginRight: -17,
                              }}
                          >
                            {elem.blindType ?? "DayAndNight"} {elem.headrailType === "Double" ? '(Dbl)' : ''}
                          </li>
                          <li
                              style={{
                                color: `${elem.headrailType === "Double" ? 'white': 'unset'}`,
                                background: `${elem.headrailType === "Double" ? elem.doubleGroupCode : "unset"}`,
                                marginLeft: -17,
                                marginRight: -17,
                              }}
                          >
                            <div style={{
                              marginLeft: 17,
                              marginRight: 17,
                            }}>
                              Window: {elem.window} / Fabric: {elem.fabric}
                            </div>
                          </li>
                          <li>
                            Width: {elem.width} x Drop: {elem.drop}
                          </li>
                          {elem.blindType !== "Pleated" && elem.fitting && (
                              <li>
                                Fitting: {elem.fitting} {elem.fitting !== "Top Fix" && elem.fitting !== "Normal Fix" ? `(${elem.bracket} x ${elem.bracketCount})` : ""}
                              </li>
                          )}
                          <li>
                            Headrail: {elem.headrail}
                            {elem.blindType !== "Pleated" ? (
                                <>
                                  {elem.motor === "None" ? (elem.controlOption === "Wand" ? ` - Wand: (${elem.wand})` : ` - Chain: ${elem.chain} ${elem.cord ? `(${elem.cord})` : ``}`) : ``}
                                  {elem.blindType !== "Venetian" ? ` - Control: ${elem.control}` : ``}
                                  {elem.blindType === "DayAndNight" ? ` - Tassel: ${elem.tassel}` : ""}
                                </>
                            ) : null}
                          </li>
                          {(elem.blindType === "DayAndNight" || elem.blindType === "Venetian") && (
                              <>
                                {elem.continued_blind !== "None" ? (
                                    <li>
                                      Continued Blind: {elem.continued_blind}
                                    </li>
                                ) : null}
                                {elem.motor !== "None" && (
                                    <li>
                                      Motor: {elem.motor} {elem.charger !== 0 ? `(${elem.remote} x ${elem.charger})` : ``}
                                    </li>
                                )}
                              </>
                          )}
                          {elem.notes && (
                              <li>Product Note: {elem.notes}</li>
                          )}
                        </ListGroup.Item>
                      )
                    })}
                </ul>
              </ListGroup>
            </Col>
            <Col md={3}>
              <ListGroup>
                <ListGroup.Item action>Address</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>{selected.selected_address}</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <ListGroup>
                <ListGroup.Item action>Total Price</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>£ {selected.total_price ? selected.total_price : selected.order_price}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <ListGroup>
                <ListGroup.Item action>Order Note</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{selected.note && selected.note}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <ListGroup>
                <ListGroup.Item action>Order Status</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{selected.status}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Order
