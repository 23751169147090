import { API_BASE_URL } from "../constants/apiBase"
import axios from "axios"

import { serializeQuery } from "../utils/common"

export function getOneItem(item) {
  return (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState()

    if (userInfo) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      return axios
        .get(`${API_BASE_URL}/api/${item}/user?isDeleted=false`, config)
        .then(
          (result) => {
            dispatch({
              type: `${item.toUpperCase()}_GET_SUCCESS`,
              payload: result.data,
            })

            return result.status
          },
          (error) => {
            dispatch({
              type: `${item.toUpperCase()}_GET_FAIL`,
              payload:
                error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
            })

            return error
          }
        )
        .catch((error) => error)
    }
  }
}

// export const listItems = (item) => async (dispatch, getState) => {
export const listItems = (item, queryObject = null) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `${item.toUpperCase()}_LIST_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      let url = ""
      if (queryObject) {
        // let query = ""
        // for (let obj in queryObject)
        //   query += `${query === "" ? "?" : "&"}${obj}=${queryObject[obj]}`
        //
        // url = `${API_BASE_URL}/api/${item}${query}`
        let serializedQueryObject = serializeQuery(queryObject)

        url = `${API_BASE_URL}/api/${item}?${serializedQueryObject}`
      }
      else {
        url = `${API_BASE_URL}/api/${item}?isDeleted=false`
      }

      const { data } = await axios.get(
        // `${API_BASE_URL}/api/${item}?isDeleted=false`,
          url,
        config
      )
      result = data

      dispatch({
        type: `${item.toUpperCase()}_LIST_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: `${item.toUpperCase()}_LIST_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  return result
}

export const updateItems = (item, state) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `${item.toUpperCase()}_PUT_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      const { data } = await axios.post(
        `${API_BASE_URL}/api/${item}/${state._id}`,
        state,
        config
      )
      result = data

      dispatch({
        type: `${item.toUpperCase()}_PUT_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      result = error.response && error.response.data ? error.response.data : { status: 500, msg: "Something went wrong!" }

      dispatch({
        type: `${item.toUpperCase()}_PUT_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  return result
}

export const deleteItems = (item, id) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `${item.toUpperCase()}_DELETE_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      // const { data } = await axios.delete(
      //   `${API_BASE_URL}/api/${item}/${id}`,
      //   config
      // )
      // Changed DELETE request to a POST request because Mod Security prevents them
      const { data } = await axios.post(
          `${API_BASE_URL}/api/${item}/delete/${id}`,
          {},
          config
      )
      result = data

      dispatch({
        type: `${item.toUpperCase()}_DELETE_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      result = error.response && error.response.data ? error.response.data : { status: 500, msg: "Something went wrong!" }

      dispatch({
        type: `${item.toUpperCase()}_DELETE_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  return result
}

export const addNewItems = (item, state) => async (dispatch, getState) => {
  let result

  const {
    userLogin: { userInfo },
  } = getState()

  if (userInfo) {
    try {
      dispatch({
        type: `${item.toUpperCase()}_ADD_REQUEST`,
      })

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.accesstoken}`,
        },
      }

      const { data } = await axios.post(
        `${API_BASE_URL}/api/${item}`,
        state,
        config
      )
      result = data

      dispatch({
        type: `${item.toUpperCase()}_ADD_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      result = error.response && error.response.data ? error.response.data : { status: 500, msg: "Something went wrong!" }

      dispatch({
        type: `${item.toUpperCase()}_ADD_FAIL`,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  return result
}

export const listItemDetails = (item, id) => async (dispatch) => {
  try {
    dispatch({
      type: `${item.toUpperCase()}_DETAILS_REQUEST`,
    })

    const { data } = await axios.get(`${API_BASE_URL}/api/${item}/${id}`)

    dispatch({
      type: `${item.toUpperCase()}_DETAILS_SUCCESS`,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: `${item.toUpperCase()}_DETAILS_FAIL`,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
