import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

function getPager(totalItems, currentPage) {
  currentPage = currentPage || 1;
  let pageSize = 10;
  var totalPages = Math.ceil(totalItems / pageSize);
  var startPage, endPage;
  if (totalPages <= 3) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 2) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage + 1 >= totalPages) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }
  }
  // calculate start and end item indexes
  var startIndex = (currentPage - 1) * pageSize;
  var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  // create an array of pages to ng-repeat in the pager control
  var pages = [...Array(endPage + 1 - startPage).keys()].map(
    (i) => startPage + i
  );
  // return an object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
}


const initialState = {
  totalItems: 0,
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  startPage: 1,
  endPage: 1,
  startIndex: 1,
  endIndex: 10,
  pages: [],
};

const Pagination = ({ currentPage, totalItems, onChangePage }) => {
  const [pager, setpager] = useState(initialState);

 // const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  
  useEffect(() => {
    setpager(getPager(totalItems, currentPage, 10));
  }, [currentPage, totalItems]);
  
  const setPage = (page) => {
    if (page < 1 || page > pager.totalPages) {
      return;
    }
    
    // call change page function in parent component
    onChangePage(page);
  };
  //console.log(pager);
  return (
    <div className="container d-flex justify-content-between align-items-center m-2">
      {!isMobile && pager && (
        <div>
          {pager && pager.totalItems !== 0 ?
          <span style={{ border: "none" }} className="align-middle">
          Showing <b>{pager ? (pager.startIndex + 1) : 1}</b> to <b>{pager ? (pager && pager.endIndex) + 1 : 1}</b>{" "}
          of <b>{pager && pager.totalItems}</b> results
        </span>
        :
          <span style={{ border: "none" }} className="align-middle">
            <b>{pager && pager.totalItems}</b> results
          </span>
          }
        </div>
      )}
      {pager && (
        <div className="d-flex ">
          <button
            type="button"
            className={`btn btn-primary ${
              pager.currentPage === 1 ? "disabled" : ""
            }`}
            style={{ boxShadow: "none" }}
            onClick={() => setPage(1)}
          >
            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            className={`btn btn-primary ${
              pager.currentPage === 1 ? "disabled" : ""
            }`}
            onClick={() => setPage(pager.currentPage - 1)}
          >
            <i className="fa fa-caret-left" aria-hidden="true"></i>{!isMobile && " Prev" }
          </button>

          <ul className="pagination mb-0">
            {/* <li>
            <button
              type="button "
              className={`${
                pager.currentPage <= 2 || pager.totalPages <= 3
                  ? "d-none"
                  : "btn btn-primary  m-1"
              }`}
              onClick={() => setPage(pager.currentPage - 1)}
            >
              {" "}
              . . .
            </button>
          </li> */}
            {pager &&
              pager.pages &&
              pager.pages.map((page, index) => (
                <li onClick={() => setPage(page)}
                  key={index}
                  className={`btn btn-primary ${
                    pager.currentPage === page ? "active" : ""
                  }`}
                >
                  <span >{page}</span>
                </li>
              ))}
            {/* <li>
            <button
              type="button "
              className={`${
                pager.currentPage >= pager.totalPages - 1
                  ? "d-none"
                  : "btn btn-primary  m-1"
              }`}
              onClick={() => setPage(pager.currentPage + 1)}
            >
              {" "}
              . . .
            </button>
          </li> */}
          </ul>
          <button
            type="button"
            className={`btn btn-primary ${
              pager.currentPage === pager.totalPages ? "disabled" : ""
            }`}
            onClick={() => setPage(pager.currentPage + 1)}
          >
            {!isMobile && "Next " }<i className="fa fa-caret-right" aria-hidden="true"></i>
          </button>
          <button
            type="button "
            className={`btn btn-primary ${
              pager.currentPage === pager.totalPages ? "disabled" : ""
            }`}
            style={{ boxShadow: "none" }}
            onClick={() => setPage(pager.totalPages)}
          >
            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
