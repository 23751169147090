import { Tabs, Tab } from "react-bootstrap"

import UserDetails from "../components/UserDetails"
import UserDiscounts from "../components/UserDiscounts"

const UserScreen = () => {
  return (
      <div style={{ maxWidth: "850px", margin: "0 auto" }}>
        <Tabs
            defaultActiveKey="details"
            id="tab-example"
            className="mb-3"
        >
          <Tab eventKey="details" title="User Details">
            <UserDetails />
          </Tab>
          <Tab eventKey="discounts" title="User Discounts">
            <UserDiscounts />
          </Tab>
        </Tabs>
      </div>
  )
}

export default UserScreen
