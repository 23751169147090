import React, { memo, useCallback, useEffect, useState } from "react"
import { usePrevious } from "../hooks/use-previous"
import { Row, Col, ListGroup, Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { handleCalc } from "../actions/calculateAction"
import { objectsEqual } from "../utils/common"

// const CartItem = ({ cartItem, removeFromCartHandler, handleTotal }) => {
const CartItem = ({ cartItem, removeFromCartHandler, editCartItemHandler, handleTotal }) => {
  const [prices, setPrices] = useState({})
  const dispatch = useDispatch()

  const prevProps = usePrevious({
    cartItem,
    removeFromCartHandler,
    //
    editCartItemHandler,
    //
    handleTotal,
  })

  const handleCalculate = useCallback(() => {
    if (cartItem) {
      const result = dispatch(handleCalc(cartItem))

      if (result)
        setPrices(result.prices)
    }
  }, [dispatch, cartItem])

  useEffect(() => {
    if (!prevProps) {
      handleCalculate()
    }
    else {
      const isEqual = objectsEqual(prevProps.cartItem, cartItem)

      if (!isEqual)
        handleCalculate()
    }
  }, [prevProps, cartItem, handleCalculate])

  const deleteItem = () => removeFromCartHandler(cartItem)

  const editItem = () => editCartItemHandler(cartItem)

  return (
    <Col xs={12} >
      <ListGroup >
        <ListGroup.Item key={cartItem._id} variant="light" className="border-zebra" style={{ background: `${cartItem.headrailType === "Double" ? cartItem.doubleGroupCode : "unset"}`, color: `${cartItem.headrailType === "Double" ? "white" : "unset"}` }}>
          <Row>
            <Col xs={8}>
              <Row>
                <Col xs={6} lg={4}>
                  {cartItem.window}
                </Col>
                <Col xs={6} lg={4}>
                  Fabric: {cartItem.fabric} ({cartItem.blindType === "DayAndNight" ? (cartItem.headrailType === "Double" ? "DN-Double" : "DN") : (cartItem.blindType === "Pleated" ? (cartItem.headrailType === "Double" ? "P-Double" : "P") : (cartItem.blindType === "Vellision" ? "V" : (cartItem.blindType === "Venetian" ? "VEN" : "VER")))})
                </Col>
                <Col xs={12} lg={4}>
                  {cartItem.width} mm x {cartItem.drop} mm
                </Col>
                <Col xs={12} lg={6}>
                  {cartItem.headrail}{" "}
                  {cartItem.blindType !== "Pleated" ? (
                      <>
                        {cartItem.blindType !== "Venetian" ? `- ${cartItem.control} side` : ``}
                        {cartItem.motor === "None" && (cartItem.blindType === "DayAndNight" || cartItem.blindType === "Venetian" || ((cartItem.blindType === "Vellision" || cartItem.blindType === "Vertical") && cartItem.controlOption === "Chain")) ? ` - ${cartItem.chain} Chain (${cartItem.cord})` : ""}
                        {(cartItem.blindType === "Vellision" || cartItem.blindType === "Vertical") && cartItem.controlOption === "Wand" ? ` - Wand (${cartItem.wand})` : ""}
                      </>
                  ) : null}
                </Col>
                {cartItem.blindType !== "Pleated" && cartItem.blindType !== "Venetian" && cartItem.tassel !== "None" && (
                  <Col xs={6} lg={3}>
                    {cartItem.tassel}
                  </Col>
                )}
                {cartItem.blindType === "DayAndNight" && cartItem.continued_blind !== "None" && (
                  <Col xs={6} lg={3}>
                    {cartItem.continued_blind}
                  </Col>
                )}
              </Row>
              {cartItem.blindType !== "Pleated" && (
                  <Row>
                    <Col>
                      {cartItem.fitting} {cartItem.fitting !== "Top Fix" && cartItem.fitting !== "Normal Fix" ? `(${cartItem.bracket} x ${cartItem.bracketCount})` : ""}
                    </Col>
                  </Row>
              )}
              {(cartItem.blindType === "DayAndNight" || cartItem.blindType === "Venetian") && cartItem.motor !== "None" && (
                  <Row>
                    <Col>
                      {cartItem.motor} {cartItem.remote !== "None" ? `(${cartItem.remote} x ${cartItem.charger})` : ""}
                    </Col>
                  </Row>
              )}
              {cartItem.notes && (
                <Row>
                  <span>
                    <b>Notes :</b> {cartItem.notes}
                  </span>
                </Row>
              )}
            </Col>
            <Col xs={3}>
              <Row>
                <span>
                  {" "}
                  Price : £ {prices.unitPrice}
                </span>
              </Row>
              <Row>
                <span>
                  {" "}
                  Suggested : £ {prices.suggestedPrice}
                </span>
              </Row>
                <Row>
                  <span>
                    {" "}
                    <h6>Yours : £ {prices.extraUserDiscount > 0 ? (<>{prices.discountedPrice} <del className="text-muted">{prices.discountedPriceWithoutExtraUserDiscount}</del></>) : prices.discountedPrice}</h6>
                  </span>
                  {prices.extraUserDiscount > 0 ? (
                      <small style={{ color: `${cartItem.headrailType === "Double" ? `white` : `limeGreen`}`, fontSize: 13, fontWeight: "bold" }}><u>with additional discount</u></small>
                  ) : null}
                </Row>
            </Col>
            <Col xs={1}>
              <Row className="p-0">
                <Col md={6} className="p-0">
                  <Button
                    className="p-0 text-danger"
                    type="button"
                    variant="light"
                    onClick={deleteItem}
                  >
                    <i className="fas fa-trash"/>
                  </Button>
                </Col>
                <Col md={6} className="p-0">
                  <Button
                      className="p-0"
                      type="button"
                      variant="light"
                      onClick={editItem}
                  >
                    <i className="fas fa-edit"/>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </Col>
  )
}

export default memo(CartItem)
