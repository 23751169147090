import {
  FITTING_LIST_REQUEST,
  FITTING_LIST_SUCCESS,
  FITTING_LIST_FAIL,
  FITTING_DETAILS_REQUEST,
  FITTING_DETAILS_SUCCESS,
  FITTING_DETAILS_FAIL,
} from "../constants/fittingConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting fittings from server
export const fittingListReducer = (state = { fittings: [] }, action) => {
  switch (action.type) {
    case FITTING_LIST_REQUEST:
      return {
        loading: true,
        fittings: [],
      };
    case FITTING_LIST_SUCCESS:
      return {
        loading: false,
        fittings: action.payload,
      };
    case FITTING_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
};

// Get fittings details
export const fittingDetailsReducer = (
  state = { fitting: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case FITTING_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case FITTING_DETAILS_SUCCESS:
      return {
        loading: false,
        fitting: action.payload,
      };
    case FITTING_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
