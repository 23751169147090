export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
]

export const twoDigitPad = (num) => num < 10 ? "0" + num : num

export const formatDate = (date, formatStr) => {
    date = new Date(date)

    if (!formatStr) {
        formatStr = "dd/mm/yyyy"
    }

    let day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        milliseconds = date.getMilliseconds(),
        hh = twoDigitPad(hour),
        mm = twoDigitPad(minute),
        ss = twoDigitPad(second),
        EEEE = days[date.getDay()],
        EEE = EEEE.substr(0, 3),
        dd = twoDigitPad(day),
        M = month + 1,
        MM = twoDigitPad(M),
        MMMM = months[month],
        MMM = MMMM.substr(0, 3),
        yyyy = year + "",
        yy = yyyy.substr(2, 2)

    return formatStr
        .replace('hh', hh).replace('h', hour.toString())
        .replace('mm', mm).replace('m', minute.toString())
        .replace('ss', ss).replace('s', second.toString())
        .replace('S', milliseconds.toString())
        .replace('dd', dd).replace('d', day.toString())
        .replace('MMMM', MMMM).replace('MMM', MMM).replace('MM', MM).replace('M', M.toString())
        .replace('EEEE', EEEE).replace('EEE', EEE)
        .replace('yyyy', yyyy)
        .replace('yy', yy)
}

export const isValidEmail = (email) => {
    try {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
    catch (error) {
        return false
    }
}

export const serializeQuery = (params, prefix) => {
    const query = Object.keys(params).map((key) => {
        const value  = params[key]

        if (params.constructor === Array)
            key = `${prefix}[]`
        else if (params.constructor === Object)
            key = (prefix ? `${prefix}[${key}]` : key)

        if (typeof value === 'object')
            return serializeQuery(value, key)
        else
            return `${key}=${encodeURIComponent(value)}`
    })

    return [].concat.apply([], query).join('&')
}

export const removeDuplicatesFromArrayOfObjects = (array, key) => {
    return [...new Map(array.map(item => [item[key], item])).values()]
}

export function addDays(date, days) {
    let result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

export const capitalizeFirstLettersInString = str => {
    var splitStr = str.split(' ')
    for (let i = 0; i < splitStr.length; i++) {
        if (!splitStr[i].includes('&') && !splitStr[i].includes('.'))
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].toLowerCase().substring(1)

    }

    return splitStr.join(' ')
}

export var DecimalPrecision2 = (function() {
    if (Number.EPSILON === undefined) {
        Number.EPSILON = Math.pow(2, -52);
    }
    if (Math.trunc === undefined) {
        Math.trunc = function(v) {
            return v < 0 ? Math.ceil(v) : Math.floor(v);
        };
    }
    var powers = [
        1e0,  1e1,  1e2,  1e3,  1e4,  1e5,  1e6,  1e7,
        1e8,  1e9,  1e10, 1e11, 1e12, 1e13, 1e14, 1e15,
        1e16, 1e17, 1e18, 1e19, 1e20, 1e21, 1e22
    ];
    var intpow10 = function(power) {
        if (power < 0 || power > 22) {
            return Math.pow(10, power);
        }
        return powers[power];
    };
    var isRound = function(num, decimalPlaces) {
        var p = intpow10(decimalPlaces);
        return Math.round(num * p) / p === num;
    };
    var decimalAdjust = function(type, num, decimalPlaces) {
        if (type !== 'round' && isRound(num, decimalPlaces || 0))
            return num;
        var p = intpow10(decimalPlaces || 0);
        var n = (num * p) * (1 + Number.EPSILON);
        return Math[type](n) / p;
    };
    return {
        // Decimal round (half away from zero)
        round: function(num, decimalPlaces) {
            return decimalAdjust('round', num, decimalPlaces);
        },
        // Decimal ceil
        ceil: function(num, decimalPlaces) {
            return decimalAdjust('ceil', num, decimalPlaces);
        },
        // Decimal floor
        floor: function(num, decimalPlaces) {
            return decimalAdjust('floor', num, decimalPlaces);
        },
        // Decimal trunc
        trunc: function(num, decimalPlaces) {
            return decimalAdjust('trunc', num, decimalPlaces);
        },
        // Format using fixed-point notation
        toFixed: function(num, decimalPlaces) {
            return decimalAdjust('round', num, decimalPlaces).toFixed(decimalPlaces);
        }
    };
})();

export const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && (o1 && o2)
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2

export const arraysEqual = (a1, a2) =>
    Array.isArray(a1) && Array.isArray(a2) && a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))

export const getRandomColor = () => {
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

export function objectMap(object, mapFn) {
    return Object.keys(object).reduce(function(result, key) {
        result[key] = mapFn(object[key])
        return result
    }, {})
}

export const isBlindIncomplete = (productList, headrailType, state, doubleState, warnWindow, doubleWarnWindow, warnWidth, doubleWarnWidth, warnDrop, doubleWarnDrop) =>
    (headrailType === "Single" && state.window === "") || (headrailType === "Double" && doubleState.map(state => state.window).some(i => i === "")) ||
    (headrailType === "Single" && warnWindow !== "") || (headrailType === "Double" && doubleWarnWindow.some(i => i !== "")) ||
    (headrailType === "Single" && !state.width) || (headrailType === "Double" && doubleState.map(state => state.width).some(i => !i)) ||
    (headrailType === "Single" && !state.drop) || (headrailType === "Double" && doubleState.map(state => state.drop).some(i => !i)) ||
    (headrailType === "Single" && warnWidth !== "") || (headrailType === "Double" && doubleWarnWidth.some(i => i !== "")) ||
    (headrailType === "Single" && warnDrop !== "") || (headrailType === "Double" && doubleWarnDrop.some(i => i !== "")) ||
    (headrailType === "Single" && state.fabric === "Select...") || (headrailType === "Double" && (doubleState.map(state => state.fabric).some(i => i === "Select...") || (state.blindType === "DayAndNight" && doubleState.map(state => state.fabric).every(i => !productList.filter(p => p.isRStick === true).map(product => product.fabric).includes(i))) || (state.blindType === "Pleated" && (doubleState.map(state => state.fabric).every(i => !productList.filter(p => p.isNet === true).map(product => product.fabric).includes(i)) || doubleState.map(state => state.fabric).every(i => productList.filter(p => p.isNet === true).map(product => product.fabric).includes(i)))))) ||
    (headrailType === "Single" && (((state.blindType === "DayAndNight" || state.blindType === "Venetian") && ((state.motor === "None" && state.cord === "None") || (state.motor !== "None" && state.cord !== "None"))) || ((state.blindType === "Vellision" || state.blindType === "Vertical") && ((state.controlOption === "Chain" && state.cord === "None") || (state.controlOption === "Wand" && state.wand === "None"))))) || (headrailType === "Double" && state.blindType === "DayAndNight" && doubleState.map(state => state.cord).some(i => i === "None")) ||
    (headrailType === "Single" && state.fitting !== "Top Fix" && state.fitting !== "Normal Fix" && (state.bracketCount === 0 || state.bracket === "None")) || (headrailType === "Double" && doubleState.map(state => ({ fitting: state.fitting, bracketCount: state.bracketCount, bracket: state.bracket })).some(i => i.fitting !== "Top Fix" && (i.bracketCount === 0 || i.bracket === "None"))) ||
    (headrailType === "Single" && (state.blindType === "DayAndNight" || state.blindType === "Venetian") && state.motor !== "None" && ((state.charger !== 0 && state.remote === "None") || (state.charger === 0 && state.remote !== "None"))) || (headrailType === "Double" && doubleState.map(state => state.motor).some(i => i !== "None"))

export const buildTreeFromArrayOfObjects = (array, idField, parentIdField) => {
    const hashTable = Object.create(null)

    array.forEach(aData => hashTable[aData[idField]] = { ...aData, childNodes: [] })

    const tree = []
    array.forEach(aData => {
        if (aData[parentIdField]) {
            if (hashTable[aData[parentIdField]])
                hashTable[aData[parentIdField]].childNodes.push(hashTable[aData[idField]])
        }
        else
            tree.push(hashTable[aData[idField]])
    })

    return tree
}

export const buildGroupedArrayOfObjectsFromTree = (array, tree, groupField, color = null) => {
    let finalArray = array
    for (let i = 0; i < tree.length; i++) {
        let group_code

        if (color)
            group_code = color
        else {
            do {
                group_code = getRandomColor()
            } while (finalArray && finalArray.length > 0 && finalArray.filter(s => {
                if (s.hasOwnProperty('group_code')) {
                    if (s.group_code === group_code) {
                        return true
                    }
                }

                return false
            }).length > 0)
        }

        if (finalArray.filter(s => s.group_code === group_code).length > 0) {
            if (finalArray.find(s => s.group_code === group_code).groups.length > 0) {
                finalArray.find(s => s.group_code === group_code).groups.push(tree[i][groupField])
            }
            else {
                finalArray.find(s => s.group_code === group_code).groups = tree[i][groupField]
            }
        }
        else {
            if (tree[i].childNodes.length > 0)
                finalArray.push({
                    group_code,
                    groups: [tree[i][groupField]]
                })
        }

        if (tree[i].childNodes.length > 0) {
            finalArray = buildGroupedArrayOfObjectsFromTree(finalArray, tree[i].childNodes, groupField, group_code)
        }
    }

    return finalArray
}

export const getSideBySideBlinds = (blinds) => {
    try {
        const array = removeDuplicatesFromArrayOfObjects(blinds.map(b => ({ window: b.window, continued_blind: b.continued_blind === "None" ? "" : b.continued_blind })), 'window')

        const tree = buildTreeFromArrayOfObjects(array, 'window', 'continued_blind')

        return buildGroupedArrayOfObjectsFromTree([], tree, 'window')
    }
    catch (error) {
        console.log(error)

        return []
    }
}

