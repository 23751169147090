import {
    CORD_LIST_REQUEST,
    CORD_LIST_SUCCESS,
    CORD_LIST_FAIL,
    CORD_DETAILS_REQUEST,
    CORD_DETAILS_SUCCESS,
    CORD_DETAILS_FAIL,
} from "../constants/cordConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting cords from server
export const cordListReducer = (state = { cords: [] }, action) => {
    //console.log(action.payload)
    switch (action.type) {
        case CORD_LIST_REQUEST:
            return {
                loading: true,
                cords: [],
            };
        case CORD_LIST_SUCCESS:
            return {
                loading: false,
                cords: action.payload,
            };
        case CORD_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case USER_LOGOUT:
            return state;
        default:
            return state;
    }
};

// Get cords details
export const cordDetailsReducer = (
    state = { cord: { reviews: [] } },
    action
) => {
    switch (action.type) {
        case CORD_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case CORD_DETAILS_SUCCESS:
            return {
                loading: false,
                cord: action.payload,
            };
        case CORD_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
