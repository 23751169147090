import React, { useCallback, useEffect, useState } from "react"
import { usePrevious } from "../hooks/use-previous"
import { Link } from "react-router-dom"
// React Bootstrap
import {
  InputGroup,
  Row,
  Col,
  ListGroup,
  FloatingLabel,
  Form,
  Button,
  Card,
  Modal,
  Alert,
  OverlayTrigger,
  Tooltip, Image,
} from "react-bootstrap"
// Redux state
import { useDispatch, useSelector } from "react-redux"
// Components
import Message from "../components/Message"
import CartItem from "../components/CartItem"
// Redux Actions
import {
  getUserCarts,
  removeCart,
  removeFromCart,
  updateCart,
} from "../actions/cartActions"
import { getOneItem, listItems } from "../actions/sameActions"
import { handleCalc } from "../actions/calculateAction"
import { createOrder } from "../actions/orderActions"
import { deactivateDiscount } from "../actions/discountActions"
import {
  arraysEqual,
  formatDate,
  isBlindIncomplete,
  objectsEqual,
  removeDuplicatesFromArrayOfObjects
} from "../utils/common"
import { sendOrderReceivedMail } from "../actions/apiActions"
import { getUserDetails } from "../actions/userActions"
import Swal from "sweetalert2";

const initialState = {
  address_id: "None",
  order_price: 0,
  delivery_price: 0,
  total_price: 0,
  orderItem: [],
  note: "",
  selected_address: "",
  order_name: "",
  coupon: "",
}

const tempState = {
  address_name: "",
  address_line1: "",
  city: "",
  country: "",
  mobile: "",
  telephone: "",
  postal_code: "",
}

const Cart = (props) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showAddress, setShowAddress] = useState(false)
  const [showCartItem, setShowCartItem] = useState(false)
  const [newState, setnewState] = useState(initialState)
  const [totalPrice, settotalPrice] = useState("")
  const [orderPrice, setorderPrice] = useState("")
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [selectedCartItem, setSelectedCartItem] = useState(null)
  const [selectedCartItemDoubleEquivalent, setSelectedCartItemDoubleEquivalent] = useState(null)
  const [selectedCartItemWarnWindow, setSelectedCartItemWarnWindow] = useState("")
  const [selectedCartItemWarnWidth, setSelectedCartItemWarnWidth] = useState("")
  const [selectedCartItemWarnDrop, setSelectedCartItemWarnDrop] = useState("")
  const [tempAddress, settempAddress] = useState(tempState)
  const { userInfo } = useSelector((store) => store.userLogin)
  const { userCarts } = useSelector((store) => store.userCarts)
  const [userCart, setuserCart] = useState({})
  const { deliveries } = useSelector((store) => store?.deliveryList)
  const productList = useSelector((store) => store.productList.products)
  const headrailList = useSelector((store) => store.headrailList.headrails)
  const tasselList = useSelector((store) => store.tasselList.tassels)
  const chainList = useSelector((store) => store.chainList.chains)
  const cordList = useSelector((store) => store.cordList.cords)
  const wandList = useSelector((store) => store.wandList.wands)
  const fittingList = useSelector((store) => store.fittingList.fittings)
  const bracketList = useSelector((store) => store.bracketList.brackets)
  const motorList = useSelector((store) => store.motorList.motors)
  const remoteList = useSelector((store) => store.remoteList.remotes)
  const [error, setError] = useState("")

  const { addresses } = useSelector((store) => store.userAddresses)
  const handleClose = () => setShow(false)
  const handleCloseX = () => setError("")
  const handleShow = () => {
    setSelectedCoupon(null)
    setShow(true)
  }
  const handleCloseAddress = () => setShowAddress(false)
  const handleCloseCartItem = () => setShowCartItem(false)

  useEffect(() => {
    if (addresses.length === 0 && userCart?.cartItems?.length > 0) {
      setShowAddress(true)
      setTimeout(() => {
        setShowAddress(false)
      }, 10000)
    }
  }, [addresses, userCart])

  const activeDelivery = deliveries.find(item => item.isActive)
  const deliveryPrice = activeDelivery ? activeDelivery?.price : 0

  const handleGetPrices = useCallback(() => {
    let response = []
    if (userCart && userCart.cartItems && userCart.cartItems.length > 0) {
      for (let i = 0; i < userCart.cartItems.length; i++) {
        const result = dispatch(handleCalc(userCart.cartItems[i]))
        if (result) {
          let newState = {...userCart.cartItems[i], prices: result.prices}
          response.push(newState)
        }
      }
    }
    setnewState({
      ...newState,
      orderItem: response,
      order_name: userCart && userCart.cart_name ? userCart.cart_name : "",
    })
  }, [dispatch, newState, userCart])

  const handleTotal = useCallback(() => {
    let totalPr = 0
    let finalDeliveryPrice = deliveryPrice
    if (newState.orderItem.length > 0) {
      totalPr = newState.orderItem.reduce(
          (a, b) => a + Number(b.prices.discountedPrice),
          0
      )
      for (let i = 0; i < newState.orderItem.length; i++) {
        if (newState.orderItem[i].width >= 3000) {
          finalDeliveryPrice += 30
          break
        }
      }
    }
    setorderPrice(totalPr.toFixed(2))
    settotalPrice((Number(totalPr) + Number(finalDeliveryPrice)).toFixed(2))
    setnewState({
      ...newState,
      order_price: totalPr.toFixed(2),
      delivery_price: finalDeliveryPrice,
      total_price: (Number(totalPr) + Number(finalDeliveryPrice)).toFixed(2),
    })
  }, [deliveryPrice, newState])

  const handleFilteredCarts = useCallback(() => {
    setTimeout(() => {
      const selected_cart = JSON.parse(localStorage.getItem("selectedCart"))
      if (selected_cart) {
        const selectedCart =
            userCarts &&
            userCarts.length > 0 &&
            userCarts.find((item) => item._id === selected_cart._id)
        if (selectedCart) {
          setuserCart(selectedCart)
          localStorage.setItem("selectedCart", JSON.stringify(selectedCart))
        } else {
          if (userCarts && userCarts.length > 0) {
            setuserCart(userCarts[0])
            localStorage.setItem("selectedCart", JSON.stringify(userCarts[0]))
          } else {
            setuserCart({})
          }
        }
      } else {
        if (userCarts && userCarts.length > 0) {
          setuserCart(userCarts[0])
          localStorage.setItem("selectedCart", JSON.stringify(userCarts[0]))
        } else {
          setuserCart({})
        }
      }
    }, 250)
  }, [userCarts])

  const prevStore = usePrevious({
    userCarts,
    addresses,
    userCart,
  })

  const prevState = usePrevious({
    newState,
  })

  useEffect(() => {
    dispatch(getUserCarts())
    dispatch(getUserDetails())
    dispatch(getOneItem("address"))
    // handleGetPrices()
    // handleTotal()
  }, [dispatch])

  useEffect(() => {
    if (!prevState) {
      handleTotal()
    }
    else {
      const isEqual = objectsEqual(prevState.newState, newState)

      if (!isEqual) {
        handleTotal()
      }
    }
  }, [prevState, newState, handleTotal])

  useEffect(() => {
    if (!prevStore) {
      handleGetPrices()
    }
    else {
      const isEqual = objectsEqual(prevStore.userCart, userCart)

      if (!isEqual) {
        handleGetPrices()
      }
    }
  }, [prevStore, userCart, handleGetPrices])

  useEffect(() => {
    if (!prevStore) {
      handleFilteredCarts()
    }
    else {
      const isEqual = arraysEqual(prevStore.userCarts, userCarts)

      if (!isEqual) {
        handleFilteredCarts()
      }
    }
  }, [prevStore, userCarts, handleFilteredCarts])

  useEffect(() => {
    if (userCart && userCart.cartItems && userCart.cartItems.length > 0) {
      if (newState && newState.orderItem && newState.orderItem.length === 0) {
        if (productList.length > 0) {
          handleGetPrices()
        }
      }
    }
  }, [userCart, newState, productList, productList, handleGetPrices])

  const handleSelectCart = (e) => {
    const selectedCart =
      userCarts && userCarts.length > 0 &&
      userCarts.find((item) => item._id === e.target.value)
    localStorage.setItem("selectedCart", JSON.stringify(selectedCart))
    setuserCart(selectedCart)
  }

  const removeFromCartHandler = useCallback((cartProduct) => {
    const Swal = require('sweetalert2')

    Swal.fire({
      title: "WARNING",
      text: "Are you sure to delete?",
      showDenyButton: true,
      confirmButtonText: `<i class="fa fa-thumbs-up"></i> Yes`,
      denyButtonText: `<i class="fa fa-thumbs-down"></i> No`,
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#777",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          cart_id: userCart._id,
        }

        if (cartProduct.headrailType === "Double") {
          obj['cartType'] = "Double"

          const item_id_array = [cartProduct._id]

          const otherCartProduct = userCart.cartItems.filter(i => i._id !== cartProduct._id && i.doubleGroupCode === cartProduct.doubleGroupCode)

          if (otherCartProduct[0])
            item_id_array.push(otherCartProduct[0])

          obj['item_id'] = item_id_array
        }
        else {
          obj['cartType'] = "Single"
          obj['item_id'] = cartProduct._id
        }

        dispatch(removeFromCart(obj)).then(
            (response) => {
              if (response.status === 200) {
                dispatch(getUserCarts())
                handleGetPrices()
                // handleTotal()
              }
            }
        )
      }
    })
  }, [dispatch, userCart, handleGetPrices])

  const editCartItemHandler = useCallback((cartProduct) => {
    setSelectedCartItem(cartProduct)

    setSelectedCartItemWarnWindow("")
    setSelectedCartItemWarnWidth("")
    setSelectedCartItemWarnDrop("")

    if (cartProduct.headrailType === "Double") {
      const otherCartProductIndex = userCart.cartItems.findIndex(i => i._id !== cartProduct._id && i.doubleGroupCode === cartProduct.doubleGroupCode)

      if (otherCartProductIndex > -1) {
        const otherProduct = userCart.cartItems[otherCartProductIndex]

        setSelectedCartItemDoubleEquivalent(otherProduct)
      }
      else {
        setSelectedCartItemDoubleEquivalent(null)
      }
    }
    else {
      setSelectedCartItemDoubleEquivalent(null)
    }

    setShowCartItem(true)
  }, [userCart])

  const handleNumberPaste = e => e.preventDefault()

  const handleNumberKeyPress = e => {
    if (!/[0-9.]/.test(e.key))
      e.preventDefault()

    if (e.target.value.toString() === "" && e.key === '.')
      e.preventDefault()
  }

  const handleSelectedCartItemWidth = (e) => {
    const input = Number(e.target.value)

    const blindType = selectedCartItem.blindType
    const headrailType = selectedCartItem.headrailType

    if (blindType === "DayAndNight") {
      if (!input || input < 1 || input > 2500) {
        setSelectedCartItemWarnWidth('Enter value "1" to "2500" mm.')
      } else if (selectedCartItem.motor !== "None" && input < 470) {
        setSelectedCartItemWarnWidth('Enter value "470" to "2500" mm.')
      } else {
        setSelectedCartItemWarnWidth("")
      }
    }
    else if (blindType === "Vellision") {
      if (!input || input < 1 || input > 5000) {
        setSelectedCartItemWarnWidth('Enter value "1" to "5000" mm.')
      } else {
        setSelectedCartItemWarnWidth("")
      }
    }
    else if (blindType === "Pleated") {
      if (!input || input < 200 || input > 1400) {
        setSelectedCartItemWarnWidth('Enter value "200" to "1400" mm.')
      } else {
        setSelectedCartItemWarnWidth("")
      }
    }
    else if (blindType === "Venetian") {
      if (selectedCartItem.headrail === "FauxWood" || selectedCartItem.headrail === "FauxWoodTape") {
        if (!input || input < 250 || input > 2700) {
          setSelectedCartItemWarnWidth('Enter value "250" to "2700" mm.')
        } else {
          setSelectedCartItemWarnWidth("")
        }
      }
      else if (selectedCartItem.headrail === "Wood" || selectedCartItem.headrail === "WoodTape") {
        if (!input || input < 250 || input > 2400) {
          setSelectedCartItemWarnWidth('Enter value "250" to "2400" mm.')
        } else {
          setSelectedCartItemWarnWidth("")
        }
      }
    }
    else if (blindType === "Vertical") {
      if (!input || input < 100 || input > 4000) {
        setSelectedCartItemWarnWidth('Enter value "100" to "4000" mm.')
      } else {
        setSelectedCartItemWarnWidth("")
      }
    }

    const clonedSelectedCartItem = {...selectedCartItem}
    clonedSelectedCartItem[e.target.name] = input
    setSelectedCartItem(clonedSelectedCartItem)

    if (headrailType === "Double") {
      const clonedSelectedCartItemDoubleEquivalent = {...selectedCartItemDoubleEquivalent}
      clonedSelectedCartItemDoubleEquivalent[e.target.name] = input
      setSelectedCartItemDoubleEquivalent(clonedSelectedCartItemDoubleEquivalent)
    }
  }

  const handleSelectedCartItemDrop = (e) => {
    const input = Number(e.target.value)

    const blindType = selectedCartItem.blindType
    const headrailType = selectedCartItem.headrailType

    if (blindType === "DayAndNight") {
      if (!input || input < 1 || input > 3000) {
        setSelectedCartItemWarnDrop('Enter value "1" to "3000" mm.')
      } else {
        setSelectedCartItemWarnDrop("")
      }
    }
    else if (blindType === "Vellision") {
      if (!input || input < 1 || input > 4000) {
        setSelectedCartItemWarnDrop('Enter value "1" to "4000" mm.')
      } else {
        setSelectedCartItemWarnDrop("")
      }
    }
    else if (blindType === "Pleated") {
      if (!input || input < 200 || input > 2000) {
        setSelectedCartItemWarnDrop('Enter value "200" to "2000" mm.')
      } else {
        setSelectedCartItemWarnDrop("")
      }
    }
    else if (blindType === "Venetian") {
      if (!input || input < 250 || input > 2200) {
        setSelectedCartItemWarnDrop('Enter value "250" to "2200" mm.')
      } else {
        setSelectedCartItemWarnDrop("")
      }
    }
    else if (blindType === "Vertical") {
      if (!input || input < 100 || input > 3000) {
        setSelectedCartItemWarnDrop('Enter value "100" to "3000" mm.')
      } else {
        setSelectedCartItemWarnDrop("")
      }
    }

    const clonedSelectedCartItem = {...selectedCartItem}
    clonedSelectedCartItem[e.target.name] = input
    setSelectedCartItem(clonedSelectedCartItem)

    if (headrailType === "Double") {
      const clonedSelectedCartItemDoubleEquivalent = {...selectedCartItemDoubleEquivalent}
      clonedSelectedCartItemDoubleEquivalent[e.target.name] = input
      setSelectedCartItemDoubleEquivalent(clonedSelectedCartItemDoubleEquivalent)
    }
  }

  const handleSelectedCartItemInput = (e) => {
    const blindType = selectedCartItem.blindType
    const headrailType = selectedCartItem.headrailType

    if (e.target.name === "window") {
      if (!e.target.value.trim() ||
          (
              (headrailType === "Single" && (userCart.cartItems && userCart.cartItems.filter(i => i.window === e.target.value.trim() && i._id !== selectedCartItem._id).length > 0)) ||
              (headrailType === "Double" && (userCart.cartItems && userCart.cartItems.filter(i => i.window === e.target.value.trim() && i.doubleGroupCode !== selectedCartItem.doubleGroupCode && i._id !== selectedCartItem._id).length > 0))
          )
      ) {
        setSelectedCartItemWarnWindow("Enter an unused window name.")
      }
      else {
        setSelectedCartItemWarnWindow("")
      }
    }

    if (e.target.name === "motor" && e.target.value !== "None") {
      if (blindType === "DayAndNight") {
        if (selectedCartItem.width < 470 || selectedCartItem.width > 2500) {
          setSelectedCartItemWarnWidth('Enter value "470" to "2500" mm.')
        } else {
          setSelectedCartItemWarnWidth("")
        }
      }
    }

    if (e.target.name === "motor" && e.target.value === "None") {
      if (blindType === "DayAndNight") {
        if (!selectedCartItem.width || selectedCartItem.width < 1 || selectedCartItem.width > 2500) {
          setSelectedCartItemWarnWidth('Enter value "1" to "2500" mm.')
        } else {
          setSelectedCartItemWarnWidth("")
        }
      }
    }

    let headrail
    if (blindType === "DayAndNight") {
      if (e.target.name === "fabric") {
        if (productList) {
          const productIndex = productList.findIndex(p => p.blindType === blindType && p.fabric === e.target.value)
          if (productIndex > -1) {

            if (selectedCartItem.headrail === "AnthraciteCovered") {
              const isVStick = productList[productIndex].isVStick
              if (isVStick === false) {
                headrail = "WhiteCovered"
              }
            }
            else if (selectedCartItem.headrail === "Pelmeted") {
              const fabric = productList[productIndex].fabric
              if (fabric.startsWith('4')) {
                headrail = "WhiteCovered"
              }
            }

          }
        }
      }
    }
    if (blindType === "Venetian") {
      if (e.target.name === "fabric") {
        if (productList) {
          const productIndex = productList.findIndex(p => p.blindType === blindType && p.fabric === e.target.value)
          if (productIndex > -1) {
            const isFaux = productList[productIndex].isFaux
            const isTape = productList[productIndex].isTape
            if (isFaux) {
              if ((selectedCartItem.headrail === "Wood" || selectedCartItem.headrail === "WoodTape") || (!isTape && selectedCartItem.headrail === "FauxWoodTape")) {
                headrail = "FauxWood"
              }
            }
            else {
              if ((selectedCartItem.headrail === "FauxWood" || selectedCartItem.headrail === "FauxWoodTape") || (!isTape && selectedCartItem.headrail === "WoodTape")) {
                headrail = "Wood"
              }
            }
          }
        }
      }
    }

    if (e.target.name === "headrail" || headrail) {
      if (e.target.value === "FauxWood" || e.target.value === "FauxWoodTape" || headrail === "FauxWood") {
        if (!selectedCartItem.width || selectedCartItem.width < 250 || selectedCartItem.width > 2700) {
          setSelectedCartItemWarnWidth('Enter value "250" to "2700" mm.')
        }
        else {
          setSelectedCartItemWarnWidth("")
        }
      }
      else if (e.target.value === "Wood" || e.target.value === "WoodTape" || headrail === "Wood") {
        if (!selectedCartItem.width || selectedCartItem.width < 250 || selectedCartItem.width > 2400) {
          setSelectedCartItemWarnWidth('Enter value "250" to "2400" mm.')
        }
        else {
          setSelectedCartItemWarnWidth("")
        }
      }
    }

    let tassel
    if (blindType === "DayAndNight") {
      if (e.target.name === "headrail") {
        if (e.target.value === "AnthraciteCovered") {
          if (selectedCartItem.tassel === "No-Beaded" || selectedCartItem.tassel === "Beaded") {
            tassel = "None"
          }
        }
      }
    }

    //
    let product_id
    if (e.target.name === "fabric") {
      product_id = e.target.options[e.target.selectedIndex].id
    }
    //

    const value = e.target.name === "charger" || e.target.name === "bracketCount" ? Number(e.target.value) : e.target.value
    setSelectedCartItem({
      ...selectedCartItem,
      ...(e.target.name === "motor" && e.target.value === "None") && { charger: 0, remote: "None" },

      ...(e.target.name === "motor" && e.target.value === "Rechargeable") && { chain: blindType === "DayAndNight" ? "White" : (chainList.find(c => c.blindType === "Venetian")?.name ?? ""), cord: "None" },

      ...(e.target.name === "fitting" && e.target.value === "Top Fix") && { bracket: "None", bracketCount: 0 },

      ...(e.target.name === "fitting" && e.target.value === "Normal Fix") && { bracket: "None", bracketCount: 0 },

      ...(e.target.name === "controlOption" && e.target.value === "Chain") && { wand: "None" },

      ...(e.target.name === "controlOption" && e.target.value === "Wand") && { chain: "White", cord: "None" },

      ...(headrail) && { headrail },

      ...(tassel) && { tassel },

      //
      ...(product_id) && { product_id },
      //

      [e.target.name]: value
    })

    if (headrailType === "Double") {
      if (e.target.name !== "fabric" && e.target.name !== "tassel") {
        setSelectedCartItemDoubleEquivalent({
          ...selectedCartItemDoubleEquivalent,

          ...(e.target.name === "fitting" && e.target.value === "Top Fix") && { bracket: "None", bracketCount: 0 },

          [e.target.name]: value
        })
      }
    }
  }

  const handleUpdateCart = () => {
    const selectedCartItemIndex = userCart.cartItems.findIndex(i => i._id === selectedCartItem._id)

    // Update User Cart
    if (selectedCartItemIndex > -1) {
      const clonedUserCartCartItems = [...userCart.cartItems]

      let updatedUserCartCartItems = clonedUserCartCartItems.map((cartItem, index) => {
        if (index !== selectedCartItemIndex) return cartItem

        return {
          ...cartItem,
          ...selectedCartItem
        }
      })

      if (selectedCartItem.headrailType === "Double") {
        const selectedCartItemDoubleEquivalentIndex = userCart.cartItems.findIndex(i => i._id === selectedCartItemDoubleEquivalent._id)

        if (selectedCartItemDoubleEquivalentIndex > -1) {
          updatedUserCartCartItems = updatedUserCartCartItems.map((cartItem, index) => {
            if (index !== selectedCartItemDoubleEquivalentIndex) return cartItem

            return {
              ...cartItem,
              ...selectedCartItemDoubleEquivalent
            }
          })
        }
      }

      let obj = {
        cart_id: userCart._id,
        cartItems: updatedUserCartCartItems
      }

      dispatch(updateCart(obj))
          .then((response) => {
            if (response.status === 200) {
              dispatch(getUserCarts())
              handleGetPrices()

              setShowCartItem(false)
            }
          })
    }
    else {
      const Swal = require('sweetalert2')

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      })
    }
  }

  const handleCoupon = () => {
    setSelectedCoupon(null)

    const code = document.getElementById('coupon').value

    dispatch(listItems("discount", { code, startAt: { lte: formatDate(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z" }, endAt: { gte: formatDate(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z" }, isActive: true, isDeleted: false }))
        .then(res => {
          if (res && res.status === "success") {
            if (res.discounts && res.discounts.length) {
              const discount = res.discounts[0]

              let discounted_order_price
              if (discount.type === "Percentage")
                discounted_order_price = (newState.order_price * (100 - discount.figure)) / 100
              else
                discounted_order_price = newState.order_price - discount.figure

              if (discounted_order_price < 0)
                discounted_order_price = 0

              document.getElementsByClassName('summary-order-price')[0].innerHTML = '<span>£ ' + discounted_order_price.toFixed(2) + '</span><span class="text-muted">&nbsp;<del>' + Number(newState.order_price).toFixed(2) + '</del></span>'
              document.getElementsByClassName('summary-total-price')[0].innerText = '£ ' + (Number(discounted_order_price) + Number(newState.delivery_price)).toFixed(2)

              setSelectedCoupon(discount)
            }
            else {
              document.getElementsByClassName('summary-order-price')[0].innerText = '£ ' + newState.order_price
              document.getElementsByClassName('summary-total-price')[0].innerText = '£ ' + newState.total_price

              const Swal = require('sweetalert2')

              Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "The code you entered is not valid!",
              })
            }
          }
        })
  }

  const handleInput = (e) => {
    if (e.target.name === "address_id" && e.target.value !== "None") {
      const selectedAddress = addresses.find(
        (item) => item._id === e.target.value
      )
      settempAddress(tempState)
      setnewState({
        ...newState,
        [e.target.name]: e.target.value,
        selected_address: `Name:${selectedAddress.address_name} - Postal-code:${selectedAddress.postal_code
          }, ${selectedAddress.address_line1} ${selectedAddress.address_line2}, ${selectedAddress.city
          }, ${selectedAddress.country.toUpperCase()}, mobile:${selectedAddress.mobile
          }, telephone:${selectedAddress.telephone}`,
      })
    } else if (e.target.name === "address_id" && e.target.value === "None") {
      setnewState({
        ...newState,
        [e.target.name]: e.target.value,
        selected_address: "",
      })
    } else {
      setnewState({
        ...newState,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleTempAddress = (e) => {
    if (e.target.name === "postal_code") {
      settempAddress({ ...tempAddress, [e.target.name]: e.target.value.toUpperCase() })
    } else {
      settempAddress({ ...tempAddress, [e.target.name]: e.target.value })
    }
  }

  const submitAddressHandler = (e) => {
    e.preventDefault()

    setnewState({
      ...newState,
      selected_address: `address_name: ${tempAddress.address_name} - ${tempAddress.postal_code
        }, ${tempAddress.address_line1}, ${tempAddress.city
        }, ${tempAddress.country.toUpperCase()}, mobile:${tempAddress.mobile
        }, telephone:${tempAddress.telephone}`,
    })
  }

  const handleSubmit = () => {
    const clonedNewState = {...newState}
    const clonedOrderItem = [...clonedNewState.orderItem]
    const filteredClonedOrderItem = clonedOrderItem.filter(i => i.doubleGroupCode !== "")
    const doubleGroupCodes = removeDuplicatesFromArrayOfObjects(filteredClonedOrderItem, 'doubleGroupCode').map(i => i.doubleGroupCode)

    let stop = false
    for (let i = 0; i < doubleGroupCodes.length && !stop; i++) {
      const dgc = doubleGroupCodes[i]
      if (clonedOrderItem.filter(i => i.doubleGroupCode === dgc).length !== 2)
        stop = true
    }

    if (stop) {
      alert('There should be 2 blinds for each double frame! Please check your blinds')
      return
    }

    setLoading(true)

    let obj = { ...newState, status: "Pending" }

    if (selectedCoupon) {
      let discounted_order_price
      if (selectedCoupon.type === "Percentage")
        discounted_order_price = (newState.order_price * (100 - selectedCoupon.figure)) / 100
      else
        discounted_order_price = newState.order_price - selectedCoupon.figure

      if (discounted_order_price < 0)
        discounted_order_price = 0

      obj["coupon"] = selectedCoupon.code
      obj["order_price"] = discounted_order_price.toFixed(2)
      obj["total_price"] = (Number(discounted_order_price) + Number(newState.delivery_price)).toFixed(2)
    }

    dispatch(createOrder(obj)).then((res) => {
      if (res.status === 200) {

        if (selectedCoupon && selectedCoupon.oneOff) dispatch(deactivateDiscount({ ...selectedCoupon, isActive: false }))

        handleClose()

        setnewState({ ...initialState })

        setLoading(false)

        dispatch(sendOrderReceivedMail(newState, userInfo.user))
            .then(() => {
              props.history.push("/orders")
            })
      } else if (res.status === 400) {
        handleClose()

        setLoading(false)

        setError(res.msg)

        setTimeout(() => setError(""), 4000)
      } else {
        handleClose()

        setLoading(false)

        setError("Something went wrong! Please contact us through <b>i.sen@zebrablind.co.uk</b>.")

        setTimeout(() => setError(""), 4000)
      }
    })
  }

  const deleteCart = () => {
    dispatch(removeCart(userCart._id))
      .then(res => {
        if (res.status === 200) {
          setnewState({ ...newState, order_name: "" })
          dispatch(getUserCarts())
          handleGetPrices()
          // handleTotal()
          document.getElementById("cart_id").value = userCart && userCart.cart_name
        }
      })
  }

  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <Modal size="sm" animation={true} show={!!error} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
              <div
                className="alert alert-danger mt-2" role="alert"
                dangerouslySetInnerHTML={{__html: error}}
              />
          )}
        </Modal.Body>
      </Modal>
      {/**/}
      {selectedCartItem && (
          <Modal
              show={showCartItem}
              onHide={handleCloseCartItem}
              backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Cart Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="row">
                <Form.Group className="mb-1 col-sm-12">
                  <Form.Label className="mb-0 text-muted">Window Name</Form.Label>
                  <Form.Control
                      placeholder="Enter window name."
                      size="sm"
                      name="window"
                      value={selectedCartItem.window}
                      onChange={handleSelectedCartItemInput}
                      style={{ borderColor: `${selectedCartItemWarnWindow && "red"}` }}
                  />
                  {selectedCartItemWarnWindow && (
                      <Form.Text type="invalid" style={{ color: "red" }}>
                        {selectedCartItemWarnWindow}
                      </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-1 col-sm-6">
                  <Form.Label
                      className="mb-0 text-muted"
                  >
                    Fabric{" "}
                    <span
                        style={{
                          color: `${selectedCartItem.fabric === "Select..." ? "red" : ""}`,
                          display: `${selectedCartItem.fabric === "Select..." ? "" : "none"}`,
                        }}
                    >
                      *
                    </span>
                  </Form.Label>
                  <Form.Select
                      style={{
                        borderColor: `${selectedCartItem.fabric === "Select..." ? "red" : ""}`,
                      }}
                      className="select"
                      value={selectedCartItem.fabric}
                      size="sm"
                      name="fabric"
                      id="fabric"
                      onChange={handleSelectedCartItemInput}
                  >
                    <option value="Select...">Select...</option>
                    {
                      productList && productList.length > 0 &&
                      productList
                          .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                          .sort((a, b) => a.fabric.substring(0, 3) - b.fabric.substring(0, 3))
                          .map((item, index) => (
                              <option id={item._id} key={index} value={item.fabric}>
                                {item.fabric}
                              </option>
                          ))
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-1 col-sm-6">
                  <Image
                      src={`${selectedCartItem.fabric !== "Select..." ? (selectedCartItem.blindType === "DayAndNight" ? `/blinds/${selectedCartItem.fabric.toLowerCase().substring(0,3)}.jpg` : `/blinds/${selectedCartItem.fabric.toLowerCase()}.jpg`) : `no-image.png`}`}
                      onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }}
                      rounded
                      fluid
                  />
                </Form.Group>
                <Form.Group className="mb-1 col-sm-6">
                  <Form.Label className="mb-0 text-muted">Width (mm)</Form.Label>
                  <Form.Control
                      className="no-arrows"
                      placeholder="Enter width in 'mm'."
                      size="sm"
                      type="number"
                      min={selectedCartItem.headrailType === "Single" ? (selectedCartItem.blindType === "Venetian" ? 250 : (selectedCartItem.blindType === "Pleated" ? 200 : (selectedCartItem.blindType === "Vertical" ? 100 : 1))) : (selectedCartItem.blindType === "DayAndNight" ? 1 : 200)}
                      max={selectedCartItem.headrailType === "Single" ? (selectedCartItem.blindType === "Venetian" ? (selectedCartItem.headrail === "FauxWood" || selectedCartItem.headrail === "FauxWoodTape" ? 2700 : 2400) : (selectedCartItem.blindType === "Pleated" ? 1400 : (selectedCartItem.blindType === "Vellision" ? 5000 : (selectedCartItem.blindType === "Vertical" ? 4000 : 2500)))) : (selectedCartItem.blindType === "DayAndNight" ? 2500 : 1400)}
                      name="width"
                      onChange={handleSelectedCartItemWidth}
                      onPaste={handleNumberPaste}
                      value={selectedCartItem.width || ""}
                      onKeyPress={handleNumberKeyPress}
                      pattern="[0-9]+"
                      style={{ borderColor: `${selectedCartItemWarnWidth && "red"}` }}
                  />
                  {selectedCartItemWarnWidth && (
                      <Form.Text type="invalid" style={{ color: "red" }}>
                        {selectedCartItemWarnWidth}
                      </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-1 col-sm-6">
                  <Form.Label className="mb-0 text-muted">Drop (mm)</Form.Label>
                  <Form.Control
                      className="no-arrows"
                      placeholder="Enter drop in 'mm'."
                      size="sm"
                      type="number"
                      min={selectedCartItem.headrailType === "Single" ? (selectedCartItem.blindType === "Venetian" ? 250 : (selectedCartItem.blindType === "Pleated" ? 200 : (selectedCartItem.blindType === "Vertical" ? 100 : 1))) : (selectedCartItem.blindType === "DayAndNight" ? 1 : 200)}
                      max={selectedCartItem.headrailType === "Single" ? (selectedCartItem.blindType === "Venetian" ? 2200 : (selectedCartItem.blindType === "Pleated" ? 2000 : (selectedCartItem.blindType === "Vellision" ? 4000 : 3000))) : (selectedCartItem.blindType === "DayAndNight" ? 3000 : 2000)}
                      name="drop"
                      onChange={handleSelectedCartItemDrop}
                      onPaste={handleNumberPaste}
                      value={selectedCartItem.drop || ""}
                      onKeyPress={handleNumberKeyPress}
                      pattern="[0-9]+"
                      style={{ borderColor: `${selectedCartItemWarnDrop && "red"}` }}
                  />
                  {selectedCartItemWarnDrop && (
                      <Form.Text type="invalid" style={{ color: "red" }}>
                        {selectedCartItemWarnDrop}
                      </Form.Text>
                  )}
                </Form.Group>
                {selectedCartItem.headrailType === "Single" ? (
                    selectedCartItem.blindType === "Venetian" ? (
                          <Form.Group className="mb-1 col-sm-6">
                            <Form.Label className="mb-0 text-muted">Headrail</Form.Label>
                            <Form.Select
                                className="select"
                                value={selectedCartItem.headrail}
                                size="sm"
                                name="headrail"
                                id="headrail"
                                onChange={handleSelectedCartItemInput}
                            >
                              {
                                headrailList &&
                                headrailList.length > 0 &&
                                headrailList
                                    .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                                    .map((item, index) => {

                                      let disabled = false
                                      if (productList) {
                                        const productIndex = productList.findIndex(p => p.blindType === selectedCartItem.blindType && p.fabric === selectedCartItem.fabric)
                                        if (productIndex > -1) {
                                          const isFaux = productList[productIndex].isFaux
                                          const isTape = productList[productIndex].isTape
                                          if (isFaux) {
                                            if ((item.name === "Wood" || item.name === "WoodTape") || (!isTape && item.name === "FauxWoodTape")) {
                                              disabled = true
                                            }
                                          }
                                          else {
                                            if ((item.name === "FauxWood" || item.name === "FauxWoodTape") || (!isTape && item.name === "WoodTape")) {
                                              disabled = true
                                            }
                                          }
                                        }
                                      }

                                      return (
                                          <option key={index} value={item.name} disabled={disabled}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                      ) : (
                        <Form.Group className="mb-1 col-sm-6">
                          <Form.Label className="mb-0 text-muted">Headrail</Form.Label>
                          <Form.Select
                              className="select"
                              value={selectedCartItem.headrail}
                              size="sm"
                              name="headrail"
                              id="headrail"
                              onChange={handleSelectedCartItemInput}
                          >
                            {
                              headrailList &&
                              headrailList.length > 0 &&
                              headrailList
                                  .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                                  .map((item, index) => {
                                    let isVStick = true
                                    if (selectedCartItem.blindType === "DayAndNight") {
                                      if (productList) {
                                        const productIndex = productList.findIndex(p => p.blindType === selectedCartItem.blindType && p.fabric === selectedCartItem.fabric)
                                        if (productIndex > -1) {
                                          isVStick = productList[productIndex].isVStick
                                        }
                                      }
                                    }

                                    let is400 = false
                                    if (selectedCartItem.blindType === "DayAndNight") {
                                      if (selectedCartItem.fabric.startsWith('4')) {
                                        is400 = true
                                      }
                                    }

                                    return (
                                        <option key={index} value={item.name} disabled={(selectedCartItem.blindType === "DayAndNight" && item.name === "AnthraciteCovered" && isVStick === false) || (selectedCartItem.blindType === "DayAndNight" && item.name === "Pelmeted" && is400 === true)}>
                                          {item.name}
                                        </option>
                                    )
                                  })
                            }
                          </Form.Select>
                        </Form.Group>
                    )
                ) : (
                    <Form.Group className="mb-1 col-sm-6">
                      <Form.Label className="mb-0 text-muted">Headrail</Form.Label>
                      <Form.Select
                          className="select"
                          value={selectedCartItem.headrail}
                          size="sm"
                          name="headrail"
                          id="headrail"
                          onChange={handleSelectedCartItemInput}
                      >
                        {
                          headrailList &&
                          headrailList.length > 0 &&
                          headrailList
                              .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                              .map((item, index) => {
                                return (
                                    selectedCartItem.blindType !== "DayAndNight" || (selectedCartItem.blindType === "DayAndNight" && item.name === "Open") ? (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                    ) : null
                                )
                              })
                        }
                      </Form.Select>
                    </Form.Group>
                )}
                <Form.Group className="mb-1 col-sm-6">
                  <Image src={`/headrails/${selectedCartItem.headrailType === "Double" ? `double/` : ``}${selectedCartItem.headrail.toLowerCase()}.png`} onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }} rounded fluid />
                </Form.Group>

                {selectedCartItem.blindType === "DayAndNight" ? (
                    <>
                      <Form.Group className="mb-1 col-sm-6">
                        <Form.Label className="mb-0 text-muted">Tassel</Form.Label>
                        <Form.Select
                            className="select"
                            value={selectedCartItem.tassel}
                            size="sm"
                            name="tassel"
                            id="tassel"
                            onChange={handleSelectedCartItemInput}
                        >
                          <option value="None">None</option>
                          {
                            tasselList &&
                            tasselList.length > 0 &&
                            tasselList
                                .filter((item) => item.isActive === true)
                                .map((item, index) => {
                                  let isAnthraciteCovered = false
                                  if (selectedCartItem.blindType === "DayAndNight") {
                                    if (selectedCartItem.headrail === "AnthraciteCovered") {
                                      isAnthraciteCovered = true
                                    }
                                  }

                                  return (
                                      <option key={index} value={item.name} disabled={selectedCartItem.headrailType === "Single" && (item.name === "No-Beaded" || item.name === "Beaded") && isAnthraciteCovered}>
                                        {item.name}
                                      </option>
                                  )
                                })
                          }
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-1 col-sm-6">
                        <Image src={`/tassels/${selectedCartItem.tassel.toLowerCase()}.jpg`} onError={(e)=>{ e.target.onError = null; e.target.src = `no-image.png`; }} rounded fluid />
                      </Form.Group>
                    </>
                ) : null}
                {selectedCartItem.headrailType === "Single" ? (
                    <>
                      {(selectedCartItem.blindType === "DayAndNight" || selectedCartItem.blindType === "Venetian") && selectedCartItem.motor === "None" ? (
                          <Form.Group className="mb-1 col-sm-6">
                            <Form.Label className="mb-0 text-muted">Chains</Form.Label>
                            <Form.Select
                                className="select"
                                value={selectedCartItem.chain}
                                size="sm"
                                name="chain"
                                id="chain"
                                onChange={handleSelectedCartItemInput}
                            >
                              {
                                chainList &&
                                chainList.length > 0 &&
                                chainList
                                    .filter((item) => item.blindType === selectedCartItem.blindType && item.isActive === true)
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name} {item.blindType === "DayAndNight" && item.name === "Silver" ? ' / Metal (+£1)' : (item.blindType === "Venetian" && (item.name === "Brushed Aluminium" || item.name === "Chrome" || item.name === "Brass" ? ` (+£4)` : ''))}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                      ) : null}
                      {(selectedCartItem.blindType === "Vellision" || selectedCartItem.blindType === "Vertical") ? (
                          <Form.Group className="mb-1 col-sm-6">
                            <Form.Label className="mb-0 text-muted">Control Option</Form.Label>
                            <Form.Select
                                className="select"
                                value={selectedCartItem.controlOption}
                                size="sm"
                                name="controlOption"
                                id="controlOption"
                                onChange={handleSelectedCartItemInput}
                            >
                              <option value="Chain">Chain</option>
                              <option value="Wand">Wand</option>
                            </Form.Select>
                          </Form.Group>
                      ) : null}
                      {((selectedCartItem.blindType === "DayAndNight" || selectedCartItem.blindType === "Venetian") && selectedCartItem.motor === "None") || ((selectedCartItem.blindType === "Vellision" || selectedCartItem.blindType === "Vertical") && selectedCartItem.controlOption === "Chain") ? (
                          <Form.Group className="mb-1 col-sm-6">
                            <Form.Label className="mb-0 text-muted">Chain Length</Form.Label>
                            <Form.Select
                                className="select"
                                value={selectedCartItem.cord}
                                size="sm"
                                name="cord"
                                id="cord"
                                onChange={handleSelectedCartItemInput}
                                style={{ borderColor: `${selectedCartItem.cord === "None" ? "red" : ""}` }}
                            >
                              <option value="None">None</option>
                              {
                                cordList &&
                                cordList.length > 0 &&
                                cordList
                                    .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                      ) : null}
                      {(selectedCartItem.blindType === "Vellision" || selectedCartItem.blindType === "Vertical") && selectedCartItem.controlOption === "Wand" ? (
                          <Form.Group className="mb-1 col-sm-6">
                            <Form.Label className="mb-0 text-muted">Wand Length</Form.Label>
                            <Form.Select
                                className="select"
                                value={selectedCartItem.wand}
                                size="sm"
                                name="wand"
                                id="wand"
                                onChange={handleSelectedCartItemInput}
                                style={{ borderColor: `${selectedCartItem.wand === "None" ? "red" : ""}` }}
                            >
                              <option value="None">None</option>
                              {
                                wandList &&
                                wandList.length > 0 &&
                                wandList
                                    .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                                    .map((item, index) => {
                                      return (
                                          <option key={index} value={item.name}>
                                            {item.name}
                                          </option>
                                      )
                                    })
                              }
                            </Form.Select>
                          </Form.Group>
                      ) : null}

                      {selectedCartItem.blindType !== "Pleated" ? (
                          <Row className="mx-0 px-0">
                            <Form.Group className="mb-1 col-12 col-sm-4">
                              <Form.Label className="mb-0 text-muted">Fitting</Form.Label>
                              <Form.Select
                                  className="select"
                                  size="sm"
                                  value={selectedCartItem.fitting}
                                  name="fitting"
                                  id="fitting"
                                  onChange={handleSelectedCartItemInput}
                              >
                                {
                                  fittingList &&
                                  fittingList.length > 0 &&
                                  fittingList
                                      .filter((item) => item.isActive === true && ((selectedCartItem.blindType !== "Venetian" && item.name !== "Normal Fix") || (selectedCartItem.blindType === "Venetian" && item.name !== "Top Fix")))
                                      .map((item, index) => {
                                        return (
                                            <option key={index} value={item.name}>
                                              {item.name}
                                            </option>
                                        )
                                      })
                                }
                              </Form.Select>
                            </Form.Group>
                            {selectedCartItem.fitting === "Face Fix" ? (
                                <Form.Group className="mb-1 col-6 col-sm-4">
                                  <Form.Label className="mb-0 text-muted">Ext. Bracket Quantity</Form.Label>
                                  <Form.Control
                                      size="sm"
                                      placeholder="Enter bracket quantity."
                                      type="number"
                                      min="0"
                                      name="bracketCount"
                                      value={selectedCartItem.bracketCount}
                                      onChange={handleSelectedCartItemInput}
                                      onPaste={(event) => event.preventDefault()}
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key))
                                          event.preventDefault()

                                        if (event.target.value.toString() === "0")
                                          event.target.value = ""
                                      }}
                                      pattern="[0-9]+"
                                      style={{ borderColor: `${(selectedCartItem.fitting !== "Top Fix" && selectedCartItem.fitting !== "Normal Fix" && selectedCartItem.bracketCount === 0) ? "red" : ""}` }}
                                  />
                                </Form.Group>
                            ) : null}
                            {selectedCartItem.fitting === "Face Fix" ? (
                                <Form.Group className="mb-1 col-6 col-sm-4">
                                  <Form.Label className="mb-0 text-muted">Ext. Bracket</Form.Label>
                                  <Form.Select
                                      className="select"
                                      size="sm"
                                      value={selectedCartItem.bracket}
                                      name="bracket"
                                      id="bracket"
                                      onChange={handleSelectedCartItemInput}
                                      style={{ borderColor: `${(selectedCartItem.fitting !== "Top Fix" && selectedCartItem.fitting !== "Normal Fix" && selectedCartItem.bracket === "None") ? "red" : ""}` }}
                                  >
                                    <option value="None">None</option>
                                    {
                                      bracketList &&
                                      bracketList.length > 0 &&
                                      bracketList
                                          .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                                          .map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>
                                                  {item.name} (£ {item.price})
                                                </option>
                                            )
                                          })
                                    }
                                  </Form.Select>
                                </Form.Group>
                            ) : null}
                          </Row>
                      ) : null}

                      {(selectedCartItem.blindType === "DayAndNight" || selectedCartItem.blindType === "Venetian") ? (
                          <Row className="mx-0 px-0">
                            <Form.Group className="mb-1 col-12 col-sm-4">
                              <Form.Label className="mb-0 text-muted">Motor</Form.Label>
                              <Form.Select
                                  className="select"
                                  size="sm"
                                  value={selectedCartItem.motor}
                                  defaultChecked="None"
                                  name="motor"
                                  id="motor"
                                  onChange={handleSelectedCartItemInput}
                              >
                                <option value="None">None</option>
                                {
                                  motorList &&
                                  motorList.length > 0 &&
                                  motorList
                                      .filter((item) => item.isActive === true)
                                      .map((item, index) => {
                                        return (
                                            <option key={index} value={item.name}>
                                              {item.name} {selectedCartItem.blindType === "Venetian" && item.name === "Rechargeable" ? "(Tilt Only)" : ""}
                                            </option>
                                        )
                                      })
                                }
                              </Form.Select>
                            </Form.Group>
                            {selectedCartItem.motor !== "None" ? (
                                <>
                                  <Form.Group className="mb-1 col-6 col-sm-4">
                                    <Form.Label className="mb-0 text-muted">Charger Count</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        placeholder="Enter charger count."
                                        type="number"
                                        min="0"
                                        name="charger"
                                        value={selectedCartItem.charger}
                                        onChange={handleSelectedCartItemInput}
                                        onPaste={(event) => event.preventDefault()}
                                        onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key))
                                            event.preventDefault()

                                          if (event.target.value.toString() === "0")
                                            event.target.value = ""
                                        }}
                                        pattern="[0-9]+"
                                        style={{ borderColor: `${(selectedCartItem.charger === 0 && selectedCartItem.remote !== "None") ? "red" : ""}` }}
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-1 col-6 col-sm-4">
                                    <Form.Label className="mb-0 text-muted">Remote</Form.Label>
                                    <Form.Select
                                        className="select"
                                        size="sm"
                                        value={selectedCartItem.remote}
                                        name="remote"
                                        id="remote"
                                        onChange={handleSelectedCartItemInput}
                                        style={{ borderColor: `${(selectedCartItem.charger !== 0 && selectedCartItem.remote === "None") ? "red" : ""}` }}
                                    >
                                      <option value="None">None</option>
                                      {
                                        remoteList &&
                                        remoteList.length > 0 &&
                                        remoteList
                                            .filter((item) => item.blindType === selectedCartItem.blindType && item.isActive === true)
                                            .map((item, index) => {
                                              return (
                                                  <option key={index} value={item.name}>
                                                    {item.name}
                                                  </option>
                                              )
                                            })
                                      }
                                    </Form.Select>
                                  </Form.Group>
                                </>
                            ) : null}
                          </Row>
                      ) : null}
                    </>
                ) : (
                    <>
                      {selectedCartItem.blindType === "DayAndNight" && selectedCartItem.motor === "None" ? (
                          <>
                            <Form.Group className="mb-1 col-sm-6">
                              <Form.Label className="mb-0 text-muted">Chains</Form.Label>
                              <Form.Select
                                  className="select"
                                  value={selectedCartItem.chain}
                                  size="sm"
                                  name="chain"
                                  id="chain"
                                  onChange={handleSelectedCartItemInput}
                              >
                                {
                                  chainList &&
                                  chainList.length > 0 &&
                                  chainList
                                      .filter((item) => item.blindType === selectedCartItem.blindType && item.isActive === true)
                                      .map((item, index) => {
                                        return (
                                            <option key={index} value={item.name}>
                                              {item.name} {item.blindType === "DayAndNight" && item.name === "Silver" ? ' / Metal (+£1)' : ''}
                                            </option>
                                        )
                                      })
                                }
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-1 col-sm-6">
                              <Form.Label className="mb-0 text-muted">Chain Length</Form.Label>
                              <Form.Select
                                  className="select"
                                  value={selectedCartItem.cord}
                                  size="sm"
                                  name="cord"
                                  id="cord"
                                  onChange={handleSelectedCartItemInput}
                                  style={{ borderColor: `${selectedCartItem.cord === "None" ? "red" : ""}` }}
                              >
                                <option value="None">None</option>
                                {
                                  cordList &&
                                  cordList.length > 0 &&
                                  cordList
                                      .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                                      .map((item, index) => {
                                        return (
                                            <option key={index} value={item.name}>
                                              {item.name}
                                            </option>
                                        )
                                      })
                                }
                              </Form.Select>
                            </Form.Group>
                          </>
                      ) : (
                          <Form.Group className="mb-1 col-sm-12">
                          </Form.Group>
                      )}

                      {selectedCartItem.blindType === "DayAndNight" ? (
                          <>
                            <Form.Group className="mb-1 col-12 col-sm-4">
                              <Form.Label className="mb-0 text-muted">Fitting</Form.Label>
                              <Form.Select
                                  className="select"
                                  size="sm"
                                  value={selectedCartItem.fitting}
                                  name="fitting"
                                  id="fitting"
                                  onChange={handleSelectedCartItemInput}
                              >
                                {
                                  fittingList &&
                                  fittingList.length > 0 &&
                                  fittingList
                                      .filter((item) => item.isActive === true && ((selectedCartItem.blindType !== "Venetian" && item.name !== "Normal Fix") || (selectedCartItem.blindType === "Venetian" && item.name !== "Top Fix")))
                                      .map((item, index) => {
                                        return (
                                            <option key={index} value={item.name}>
                                              {item.name}
                                            </option>
                                        )
                                      })
                                }
                              </Form.Select>
                            </Form.Group>
                            {selectedCartItem.fitting === "Face Fix" ? (
                                <Form.Group className="mb-1 col-6 col-sm-4">
                                  <Form.Label className="mb-0 text-muted">Ext. Bracket Quantity</Form.Label>
                                  <Form.Control
                                      size="sm"
                                      placeholder="Enter bracket quantity."
                                      type="number"
                                      min="0"
                                      name="bracketCount"
                                      value={selectedCartItem.bracketCount}
                                      onChange={handleSelectedCartItemInput}
                                      onPaste={(event) => event.preventDefault()}
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key))
                                          event.preventDefault()

                                        if (event.target.value.toString() === "0")
                                          event.target.value = ""
                                      }}
                                      pattern="[0-9]+"
                                      style={{ borderColor: `${(selectedCartItem.fitting !== "Top Fix" && selectedCartItem.bracketCount === 0) ? "red" : ""}` }}
                                  />
                                </Form.Group>
                            ) : null}
                            {selectedCartItem.fitting === "Face Fix" ? (
                                <Form.Group className="mb-1 col-6 col-sm-4">
                                  <Form.Label className="mb-0 text-muted">Ext. Bracket</Form.Label>
                                  <Form.Select
                                      className="select"
                                      size="sm"
                                      value={selectedCartItem.bracket}
                                      name="bracket"
                                      id="bracket"
                                      onChange={handleSelectedCartItemInput}
                                      style={{ borderColor: `${(selectedCartItem.fitting !== "Top Fix" && selectedCartItem.bracket === "None") ? "red" : ""}` }}
                                  >
                                    <option value="None">None</option>
                                    {
                                      bracketList &&
                                      bracketList.length > 0 &&
                                      bracketList
                                          .filter((item) => item.isActive === true && item.blindType === selectedCartItem.blindType)
                                          .map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>
                                                  {item.name} (£ {item.price})
                                                </option>
                                            )
                                          })
                                    }
                                  </Form.Select>
                                </Form.Group>
                            ) : null}
                          </>
                      ) : null}
                    </>
                )}

                <Row className="mx-0 px-0">
                  {(selectedCartItem.blindType !== "Pleated" && selectedCartItem.blindType !== "Venetian") ? (
                      <Form.Group
                          className="mb-1 col-12 col-sm-6"
                          controlId="formGridControl"
                      >
                        <Form.Label className="mb-0 text-muted">Control Side</Form.Label>
                        <Form.Select
                            className="select"
                            size="sm"
                            value={selectedCartItem.control}
                            name="control"
                            id="control"
                            onChange={handleSelectedCartItemInput}
                        >
                          <option value="Left">Left</option>
                          <option value="Right">Right</option>
                        </Form.Select>
                      </Form.Group>
                  ) : null}
                  <Form.Group className="mb-1 col-12 col-sm-6 d-flex flex-column">
                    <Form.Label className="mb-0 text-muted">Comments</Form.Label>
                    <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Comments"
                        size="sm"
                    >
                      <Form.Control
                          size="sm"
                          as="textarea"
                          placeholder="Leave your comment here"
                          name="notes"
                          value={selectedCartItem.notes}
                          style={{ height: "75px" }}
                          onChange={handleSelectedCartItemInput}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseCartItem}>
                Cancel
              </Button>
              <Button
                  variant="primary"
                  onClick={handleUpdateCart}
                  // disabled={loading}
                  disabled={
                    isBlindIncomplete(productList, selectedCartItem.headrailType, selectedCartItem, [selectedCartItem, selectedCartItemDoubleEquivalent], selectedCartItemWarnWindow, [selectedCartItemWarnWindow, ""], selectedCartItemWarnWidth, [selectedCartItemWarnWidth, ""], selectedCartItemWarnDrop, [selectedCartItemWarnDrop, ""])
                  }
              >
                Update
              </Button>
              {
                isBlindIncomplete(productList, selectedCartItem.headrailType, selectedCartItem, [selectedCartItem, selectedCartItemDoubleEquivalent], selectedCartItemWarnWindow, [selectedCartItemWarnWindow, ""], selectedCartItemWarnWidth, [selectedCartItemWarnWidth, ""], selectedCartItemWarnDrop, [selectedCartItemWarnDrop, ""]) && (
                    <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-right">
                            {
                              selectedCartItem.window === ""
                                  ? "Please enter window name"
                                  : selectedCartItemWarnWindow !== ""
                                        ? "Please enter an unused window name"
                                        : selectedCartItemWarnWindow.width === ""
                                              ? "Please enter width"
                                              : selectedCartItemWarnWindow.drop === ""
                                                    ? "Please enter drop"
                                                    : (selectedCartItemWarnWidth !== "" || selectedCartItemWarnDrop !== "")
                                                          ? "Please check your dimensions"
                                                          :  selectedCartItem.fabric === "Select..."
                                                                ? "Please select fabric"
                                                                : (selectedCartItem.headrailType === "Double" && selectedCartItem.blindType === "DayAndNight" && [selectedCartItem, selectedCartItemDoubleEquivalent].map(state => state.fabric).every(i => !productList.filter(p => p.isRStick === true).map(product => product.fabric).includes(i)))
                                                                      ? "Please select one roller fabric"
                                                                      : (selectedCartItem.headrailType === "Double" && selectedCartItem.blindType === "Pleated" && [selectedCartItem, selectedCartItemDoubleEquivalent].map(state => state.fabric).every(i => !productList.filter(p => p.isNet === true).map(product => product.fabric).includes(i)))
                                                                            ? "Please select one fabric with net"
                                                                            : (selectedCartItem.headrailType === "Double" && selectedCartItem.blindType === "Pleated" && [selectedCartItem, selectedCartItemDoubleEquivalent].map(state => state.fabric).every(i => productList.filter(p => p.isNet === true).map(product => product.fabric).includes(i)))
                                                                                  ? "Please select one fabric without net"
                                                                                  : (selectedCartItem.headrailType === "Single" && (((selectedCartItem.blindType === "DayAndNight" || selectedCartItem.blindType === "Venetian") && (selectedCartItem.motor === "None" && selectedCartItem.cord === "None")) || ((selectedCartItem.blindType === "Vellision" || selectedCartItem.blindType === "Vertical") && selectedCartItem.controlOption === "Chain" && selectedCartItem.cord === "None"))) || (selectedCartItem.headrailType === "Double" && selectedCartItem.blindType === "DayAndNight" && [selectedCartItem, selectedCartItemDoubleEquivalent].map(state => state.cord).some(i => i === "None"))
                                                                                        ? "Please select cord"
                                                                                        : (selectedCartItem.headrailType === "Single" && (selectedCartItem.blindType === "Vellision" || selectedCartItem.blindType === "Vertical") && selectedCartItem.controlOption === "Wand" && selectedCartItem.wand === "None")
                                                                                              ? "Please select wand"
                                                                                              : (selectedCartItem.headrailType === "Single" && selectedCartItem.fitting !== "Top Fix" && selectedCartItem.fitting !== "Normal Fix" && (selectedCartItem.bracketCount === 0 || selectedCartItem.bracket === "None")) || (selectedCartItem.headrailType === "Double" && [selectedCartItem, selectedCartItemDoubleEquivalent].map(state => ({ fitting: state.fitting, bracketCount: state.bracketCount, bracket: state.bracket })).some(i => i.fitting !== "Top Fix" && (i.bracketCount === 0 || i.bracket === "None")))
                                                                                                    ? "Please select a bracket and enter bracket count"
                                                                                                    : (selectedCartItem.headrailType === "Single" && (selectedCartItem.blindType === "DayAndNight" || selectedCartItem.blindType === "Venetian") && selectedCartItem.motor !== "None" && ((selectedCartItem.charger !== 0 && selectedCartItem.remote === "None") || (selectedCartItem.charger === 0 && selectedCartItem.remote !== "None")))
                                                                                                          ? "Please select a remote and enter charger count"
                                                                                                          : "Please fill in the form correctly"

                            }
                          </Tooltip>
                        }>
                        <i className="fa fa-lg fa-question-circle text-danger"/>
                    </OverlayTrigger>
                )}
            </Modal.Footer>
          </Modal>
      )}
      {/**/}
      <Modal
        //size={isMobile ? "sm" : "lg"}
        show={showAddress}
        onHide={handleCloseAddress}
        keyboard={false}
        centered
      >
        <Alert className="m-0" variant="info">We recommend you to add a constant address for this and further orders. <br></br>You can add an address from the "Addresses" tab on the "Profile" page.<br></br>If It is one time order, you can go on with temporary address.</Alert>
      </Modal>
      <div className="mb-1 row">
        {userCarts && userCarts.length > 0 && (
          <>
            <Form.Group
              className="mb-1 col-sm-6 col-lg-5"
              controlId="formGridCarts"
              style={{ alignSelf: "center" }}
            >
              <InputGroup className="mb-1 border-zebra">
                <InputGroup.Text id="basic-addon3" className="border-zebra">
                  <strong>Selected Cart</strong>
                </InputGroup.Text>
                <Form.Select
                  className="border-zebra"
                  defaultValue="none"
                  name="cart_id"
                  id="cart_id"
                  onChange={handleSelectCart}
                >
                  {userCart && (
                    <option value={userCart._id}>{userCart.cart_name}</option>
                  )}
                  {userCarts &&
                    userCarts.length > 0 &&
                    userCarts.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item._id}
                          style={{
                            display: `${userCart._id === item._id ? "none" : ""
                              }`,
                          }}
                        >
                          {item.cart_name}
                        </option>
                      )
                    })}
                </Form.Select>
              </InputGroup>
            </Form.Group>
            <Form.Group
              className="mb-1 col-sm-6 col-lg-3 border-zebra"
              controlId="formGridCarts"
              style={{ alignSelf: "center" }}
            >
              <Button
                type="button"
                className="mb-2 btn-block"
                disabled={userCart.cart_name === "MyCart"}
                onClick={deleteCart}
              >
                Delete Cart
              </Button>
            </Form.Group>
          </>
        )}
      </div>
      {userCarts && userCarts.length === 0 ? (
        <Message>
          You have no carts and products, Please <Link to="/">Go back</Link>
        </Message>
      ) : (
        <Row>
          <Col xs={12} className="mb-2">
            {/*{userCart.cartItems &&*/}
            {
              productList.length &&
              userCart.cartItems &&
              userCarts && userCarts.length > 0 &&
              userCart.cartItems.map((item, index) => {
                return (
                  <CartItem
                    key={item._id}
                    cartItem={item}
                    removeFromCartHandler={removeFromCartHandler}
                    editCartItemHandler={editCartItemHandler}
                    handleTotal={handleTotal}
                  />
                )
              })}
          </Col>
          {userCart.cartItems && userCart.cartItems.length === 0 ? (
            <Message>
              Your cart is empty, To add items please <Link to="/">Go back</Link>
            </Message>
          ) : (
            userCart.cartItems &&
            userCart.cartItems.length > 0 && (
              <>
                <hr></hr>
                <h5>Proceed Order</h5>
                <Col lg={6} className="mb-2">
                  <Row>
                    <Form size="sm" className="mb-3 row">
                      <Form.Group className="col-sm-6">
                        <Form.Label>Order Name</Form.Label>
                        <Form.Control
                          className="border-zebra"
                          size="sm"
                          placeholder="Enter order name."
                          name="order_name"
                          value={newState.order_name}
                          onChange={handleInput}
                        />
                      </Form.Group>
                      <Form.Group className="col-sm-6">
                        <Form.Label>Selected Address</Form.Label>
                        <Form.Select
                          className="border-zebra"
                          size="sm"
                          defaultValue="None"
                          name="address_id"
                          id="address_id"
                          onChange={handleInput}
                        >
                          <option value="None">None</option>
                          {addresses &&
                            addresses.length > 0 &&
                            addresses.map((item) => {
                              return (
                                <option
                                  key={item._id}
                                  value={item._id}
                                >
                                  {item.address_name} - {item.postal_code}
                                </option>
                              )
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                    {newState.address_id === "None" && (
                      <Form size="sm" onSubmit={submitAddressHandler}>
                        <Form.Label className="mt-2">
                          Add Temporary Adrress{" "}
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                You can select from your addresses (up) or add
                                an address just for this order.
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <i
                                {...triggerHandler}
                                ref={ref}
                                className="fa fa-question-circle"
                                aria-hidden="true"
                              ></i>
                            )}
                          </OverlayTrigger>
                        </Form.Label>
                        <Card className="border-zebra">
                          <Form.Group controlId="address_name">
                            <Form.Control
                              size="sm"
                              style={{ border: "none" }}
                              type="text"
                              placeholder="Enter Adress Name - required"
                              name="address_name"
                              required
                              value={tempAddress.address_name}
                              onChange={handleTempAddress}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId="address_line1">
                            <Form.Control
                              size="sm"
                              style={{ border: "none" }}
                              type="text"
                              placeholder="Enter your address... - required"
                              name="address_line1"
                              required
                              value={tempAddress.address_line1}
                              onChange={handleTempAddress}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId="city">
                            <Form.Control
                              size="sm"
                              style={{ border: "none" }}
                              type="text"
                              placeholder="Enter City - required"
                              name="city"
                              value={tempAddress.city}
                              onChange={handleTempAddress}
                              required
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId="country">
                            <Form.Control
                              size="sm"
                              style={{ border: "none" }}
                              type="text"
                              name="country"
                              placeholder="Enter Country - required"
                              value={tempAddress.country}
                              onChange={handleTempAddress}
                              required
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId="mobile">
                            <Form.Control
                              size="sm"
                              style={{ border: "none" }}
                              type="text"
                              placeholder="Enter Mobile Phone (without +44) - required"
                              name="mobile"
                              pattern="[0-9]{9,13}"
                              required
                              value={tempAddress.mobile}
                              onChange={handleTempAddress}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId="phone">
                            <Form.Control
                              size="sm"
                              style={{ border: "none" }}
                              type="text"
                              placeholder="Enter Phone Number (without +44) - optional"
                              name="telephone"
                              pattern="[0-9]{9,13}"
                              value={tempAddress.telephone}
                              onChange={handleTempAddress}
                              maxLength="13"
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group controlId="postal_code">
                            <Form.Control
                              style={{ border: "none" }}
                              size="sm"
                              type="text"
                              placeholder="Enter Your Postal Code - required"
                              name="postal_code"
                              value={tempAddress.postal_code}
                              onChange={handleTempAddress}
                              pattern="^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$"
                              required
                            ></Form.Control>
                          </Form.Group>
                        </Card>
                        <Button
                          type="submit"
                          className="btn btn-sm mb-3 mt-2 btn-zebra"
                          disabled={
                            tempAddress.address_line1 &&
                              tempAddress.city &&
                              tempAddress.country &&
                              tempAddress.postal_code &&
                              tempAddress.mobile
                              ? false
                              : true
                          }
                        >
                          Save Address
                        </Button>
                        {newState.address_id === "None" &&
                          newState.selected_address && (
                            <span>
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                      </Form>
                    )}
                  </Row>
                </Col>
                <Col lg={3} className="mb-2">
                  <Form>
                    <Form.Group className="mb-1 d-flex flex-column ">
                      <FloatingLabel
                        controlId="floatingTextarea"
                        label="Order Notes"
                      >
                        <Form.Control
                          className="border-zebra"
                          as="textarea"
                          placeholder="Add your notes here"
                          name="note"
                          value={newState.note}
                          style={{ height: "100px" }}
                          onChange={handleInput}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Form>
                  <div className="d-flex justify-content-evenly align-items-center">
                    <img
                        className="accredited-logo"
                        src="blinds_make_better.png"
                        alt="blinds_make_better"
                    />
                    <img
                        className="accredited-logo"
                        src="make_it_safe.png"
                        alt="blinds_make_better"
                    />
                  </div>
                </Col>
                <Col lg={3} className="mb-2">
                  <Card className="border-zebra">
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                          <h6>Order Price: &nbsp; £ {orderPrice}</h6>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <h6>Delivery : &nbsp; £ {newState.delivery_price}</h6>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <h5>Total Price</h5>
                        <h4> £ {totalPrice}</h4>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {(userCart && userCart.length === 0) || newState.selected_address === "" || newState.order_name === "" ?
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-right" >
                                {userCart && userCart.length === 0
                                  ? "Your cart is empty"
                                  : newState.selected_address === ""
                                    ? "Please add an address"
                                    : newState.order_name === ""
                                      ? "Please enter your order name"
                                      : "Proceed"}
                              </Tooltip>
                            }
                          ><i className="fa fa-question-circle" style={{ position: "relative", top: "20px", left: "10px", zIndex: 5 }}></i>
                          </OverlayTrigger>
                          :
                          ""
                        }
                        <Button
                          type="button"
                          className="mb-2 btn-block"
                          disabled={
                            (userCart && userCart.length === 0) ||
                            !newState.address_id ||
                            (newState.address_id === "None" &&
                              !newState.selected_address) ||
                            !newState.order_name
                          }
                          onClick={handleShow}
                        >
                          Proceed to checkout
                        </Button>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Order Summary</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action>Order Items</ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={8}>
                        <ListGroup
                          variant="flush"
                          style={{ pointerEvents: "none" }}
                        >
                          <ListGroup.Item>
                            <h6>
                              You have{" "}
                              {newState.orderItem && newState.orderItem.length}{" "}
                              items in your cart
                            </h6>
                            <ul>
                              {newState.orderItem &&
                                newState.orderItem.map((elem, index) => {
                                  return (
                                    <li key={index} style={{color: `${elem.doubleGroupCode ? elem.doubleGroupCode : "unset"}`}}>
                                      {elem.window} / {elem.fabric} {elem.headrailType === "Double" ? "(Double)" : ""}
                                    </li>
                                  )
                                })}
                            </ul>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action>Address</ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={8}>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            {newState.selected_address}
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>

                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action>Order Price</ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={8}>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <h5 className="summary-order-price">
                              £ {newState.order_price && newState.order_price}
                            </h5>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action>Total Price</ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={8}>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <h5  className="summary-total-price">
                              £ {newState.total_price && newState.total_price}
                            </h5>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action>Coupon</ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={8}>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <InputGroup>
                              <Form.Control
                                  className="border-zebra"
                                  size="sm"
                                  placeholder="Enter coupon"
                                  name="coupon"
                                  id="coupon"
                                  // value={newState.coupon}
                                  // onChange={handleInput}
                              />
                              <Button
                                  size={"sm"}
                                  variant="danger"
                                  onClick={handleCoupon}
                              >
                                Apply
                              </Button>
                            </InputGroup>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action>Order Note</ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={8}>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <h5>{newState.note && newState.note}</h5>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                      Place Order
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )
          )}
        </Row>
      )}
    </div>
  )
}

export default Cart
