import {
  MOTOR_LIST_REQUEST,
  MOTOR_LIST_SUCCESS,
  MOTOR_LIST_FAIL,
  MOTOR_DETAILS_REQUEST,
  MOTOR_DETAILS_SUCCESS,
  MOTOR_DETAILS_FAIL,
} from "../constants/motorConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting motors from server
export const motorListReducer = (state = { motors: [] }, action) => {
  switch (action.type) {
    case MOTOR_LIST_REQUEST:
      return {
        loading: true,
        motors: [],
      };
    case MOTOR_LIST_SUCCESS:
      return {
        loading: false,
        motors: action.payload,
      };
    case MOTOR_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
};

// Get motors details
export const motorDetailsReducer = (
  state = { motor: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case MOTOR_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case MOTOR_DETAILS_SUCCESS:
      return {
        loading: false,
        motor: action.payload,
      };
    case MOTOR_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
