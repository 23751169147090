import {
  CHAIN_LIST_REQUEST,
  CHAIN_LIST_SUCCESS,
  CHAIN_LIST_FAIL,
  CHAIN_DETAILS_REQUEST,
  CHAIN_DETAILS_SUCCESS,
  CHAIN_DETAILS_FAIL,
} from "../constants/chainConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting chains from server
export const chainListReducer = (state = { chains: [] }, action) => {
  //console.log(action.payload)
  switch (action.type) {
    case CHAIN_LIST_REQUEST:
      return {
        loading: true,
        chains: [],
      };
    case CHAIN_LIST_SUCCESS:
      return {
        loading: false,
        chains: action.payload,
      };
    case CHAIN_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
};

// Get chains details
export const chainDetailsReducer = (
  state = { chain: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case CHAIN_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case CHAIN_DETAILS_SUCCESS:
      return {
        loading: false,
        chain: action.payload,
      };
    case CHAIN_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
