import React, { useEffect, useState } from "react"
import {
    Badge,
    Button,
    Form,
    FormControl,
    InputGroup,
    Modal,
    Table
} from "react-bootstrap"
import Pagination from "../components/Pagination"
import { useMediaQuery } from "react-responsive"
import { useDispatch, useSelector } from "react-redux"
import { listAllDiscounts } from "../actions/discountActions"
import { addNewItems, deleteItems, updateItems } from "../actions/sameActions"
import { formatDate } from "../utils/common"

const getBadge = (status) => {
    switch (status) {
        case true:
            return "success"
        case false:
            return "secondary"
        default:
            return "primary"
    }
}

const getActive = (status) => {
    switch (status) {
        case true:
            return "Active"
        case false:
            return "Inactive"
        default:
            return "Active"
    }
}

const initialState = {
    type: "Percentage",
    code: "",
    figure:"",
    startAt: formatDate(new Date(), "yyyy-MM-dd"),
    endAt: formatDate(new Date(), "yyyy-MM-dd"),
    oneOff: false,
    isActive: true,
    isDeleted: false,
}

const DiscountList = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState([])
    const [selected, setSelected] = useState(initialState)
    const [show, setShow] = useState(false)
    const [isAdding, setIsAdding] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const { discounts } = useSelector((store) => store.discountList)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [filteredValue,] = useState("")
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
        dispatch(listAllDiscounts({ currentPage, filteredValue }))
    }, [
        dispatch,
        currentPage,
        filteredValue,
        isLoading
    ])

    useEffect(() => {
        if (discounts) {
            setState(discounts.discounts)
            setTotalItems(discounts && discounts.total ? discounts.total : 0)
        }
    }, [discounts])

    const handleClose = () => setShow(false)

    const handleCloseX = () => {
        setMessage("")
        setError("")
    }

    const onChangePage = pageNum =>
        setCurrentPage(pageNum)

    const onRowClick = id => {
        const selected =
            discounts &&
            discounts.discounts &&
            discounts.discounts.find((item) => item._id === id)

        setSelected({
            ...selected,
            startAt: formatDate(new Date(selected.startAt), "yyyy-MM-dd"),
            endAt: formatDate(new Date(selected.endAt), "yyyy-MM-dd"),
        })
        setIsAdding(false)
        setShow(true)
    }

    const addNewItem = () => {
        setSelected(initialState)
        setIsAdding(true)
        setShow(true)
    }

    const handleDeleteItem = () =>
        setIsDeleting(true)

    const handleInput = e => {
        if (e.target.name === "oneOff") {
            setSelected({ ...selected, [e.target.name]: e.target.checked })
        } else {
            setSelected({...selected, [e.target.name]: e.target.value})
        }
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (!selected.figure || !selected.startAt || !selected.endAt) {
            setMessage("")
            setError("Figure, start and end date fields can not be empty")
            setTimeout(() => {
                setError("")
            }, 3000)
        } else {
            setIsLoading(true)
            dispatch(updateItems("discount", selected)).then(res => {
                if (res.status === 200) {
                    setError("")
                    setMessage(res.msg)
                    setShow(false)
                    setTimeout(() => {
                        setMessage("")
                        setIsLoading(false)
                    }, 3000)
                }
                else {
                    setMessage("")
                    setError(res.msg)
                    setTimeout(() => {
                        setError("")
                        setIsLoading(false)
                    }, 3000)
                }
            })
        }
    }

    const handleSubmitNew = e => {
        e.preventDefault()
        if (!selected.code || !selected.figure || !selected.startAt || !selected.endAt) {
            setMessage("")
            setError("Code, figure, start and end date fields can not be empty")
            setTimeout(() => {
                setError("")
            }, 3000)
        } else {
            setIsLoading(true)
            dispatch(addNewItems("discount", selected)).then(res => {
                if (res.status === 200) {
                    setError("")
                    setMessage(res.msg)
                    setShow(false)
                    setIsAdding(false)
                    setTimeout(() => {
                        setMessage("")
                        setIsLoading(false)
                    }, 3000)
                }
                else {
                    setMessage("")
                    setError(res.msg)
                    setTimeout(() => {
                        setError("")
                        setIsLoading(false)
                    }, 3000)
                }
            })
        }
    }

    const handleSubmitDelete = e => {
        e.preventDefault()
        setIsLoading(true)
        dispatch(deleteItems("discount", selected._id)).then(res => {
            if (res.status === 200) {
                setError("")
                setMessage(res.msg)
                setShow(false)
                setIsDeleting(false)
                setTimeout(() => {
                    setMessage("")
                    setIsLoading(false)
                }, 3000)
            }
            else {
                setMessage("")
                setError(res.msg)
                setIsDeleting(false)
                setTimeout(() => {
                    setError("")
                    setIsLoading(false)
                }, 3000)
            }
        })
    }

    return (
        <div style={{ maxWidth: "850px", margin: "0 auto" }}>
            <div className="d-flex text-center">
                <Button variant="primary" type="button" onClick={addNewItem}>
                    Add New Discount
                </Button>
            </div>
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    {isAdding ? (
                        <Modal.Title>Add New Discount</Modal.Title>
                    ) : (
                        <Modal.Title>Discount Update</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-discount-code-sm"
                            >
                                Code
                            </InputGroup.Text>
                            <FormControl
                                type="text"
                                placeholder="Enter Code"
                                name="code"
                                defaultValue={selected.code}
                                onChange={handleInput}
                                disabled={!isAdding}
                            />
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-discount-type-sm"
                            >
                                Type
                            </InputGroup.Text>
                            <Form.Select
                                className="select"
                                size="sm"
                                defaultValue={selected.type}
                                name="type"
                                id="type"
                                onChange={handleInput}
                            >
                                <option value="Percentage">Percentage</option>
                                <option value="Amount">Amount</option>
                            </Form.Select>
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-discount-figure-sm"
                            >
                                Figure
                            </InputGroup.Text>
                            <FormControl
                                type="number"
                                placeholder="Enter Figure"
                                name="figure"
                                min="0.00"
                                max="10000.00"
                                step="0.01"
                                defaultValue={selected.figure}
                                onChange={handleInput}
                            />
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-discount-startAt-sm"
                            >
                                Start Date
                            </InputGroup.Text>
                            <FormControl
                                type="date"
                                placeholder="Enter Start Date"
                                name="startAt"
                                defaultValue={selected.startAt}
                                onChange={handleInput}
                            />
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text
                                style={{ width: "120px" }}
                                id="inputGroup-discount-endAt-sm"
                            >
                                End Date
                            </InputGroup.Text>
                            <FormControl
                                type="date"
                                placeholder="Enter End Date"
                                name="endAt"
                                defaultValue={selected.endAt}
                                onChange={handleInput}
                            />
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                            <Form.Check
                                type="switch"
                                id="oneOff"
                                label="One Off"
                                name="oneOff"
                                defaultChecked={selected.oneOff}
                                onChange={handleInput}
                            />
                        </InputGroup>
                    </Form>
                    {error && (
                        <div className="alert alert-warning mt-2" role="alert">
                            {error}
                        </div>
                    )}
                </Modal.Body>
                {!isAdding && isDeleting && (
                    <Modal.Footer className="d-flex justify-content-center">
                        <div>
                            <Button size="sm" variant="danger" onClick={handleSubmitDelete}>
                                I'm sure I want to delete the item
                            </Button>
                        </div>
                    </Modal.Footer>
                )}
                <Modal.Footer className="d-flex justify-content-between">
                    <div>
                        {!isAdding && (
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={handleDeleteItem}
                                disabled={message || error}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button size="sm" variant="secondary" onClick={handleClose} disabled={message || error}>
                            Cancel
                        </Button>
                        {isAdding ? (
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={handleSubmitNew}
                                disabled={message || error}
                            >
                                Save Discount
                            </Button>
                        ) : (
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={message || error}
                            >
                                Update Discount
                            </Button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={!!message} onHide={handleCloseX} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message && (
                        <div className="alert alert-success mt-2" role="alert">
                            {message}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                onChangePage={onChangePage}
            />
            <Table striped hover responsive size="sm">
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Type</th>
                    <th>Figure</th>
                    {!isMobile && (
                        <>
                            <th>Start At</th>
                            <th>End At</th>
                        </>
                    )}
                    <th>One Off</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {state &&
                state.map((item, index) => {
                    return (
                        <tr key={index} onClick={() => onRowClick(item._id)}>
                            <td>{item.code}</td>
                            <td>{item.type}</td>
                            <td>{item.figure}</td>
                            {!isMobile && (
                                <>
                                    <td>{formatDate(new Date(item.startAt), "MM/dd/yyyy")}</td>
                                    <td>{formatDate(new Date(item.endAt), "MM/dd/yyyy")}</td>
                                </>
                            )}
                            <td>{item.oneOff ? 'Yes' : 'No'}</td>
                            <td><Badge bg={getBadge(item.isActive)}>{getActive(item.isActive)}</Badge></td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    )
}

export default DiscountList
