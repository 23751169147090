import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Bootstrap Components
import { Form, Button } from "react-bootstrap";
// Redux
import { useDispatch, useSelector } from "react-redux";
// UI Components
import ParticlesBg from 'particles-bg'
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
// Redux Actions
import { login } from "../actions/userActions";

const Login = ({ location, history }, props) => {
  // State to hold email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //message set
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  // Get user login info from Redux state
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, userInfo } = userLogin;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    // If there is user info then redirect
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  // Handler that logs in the user
  const submitHandler = (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setMessage("Check your username or password.");
    } else {
      // Dispatch login
      dispatch(login(email, password)).then((res) => {
        //console.log("login res ==>", res);
        if (res.status === 400) {
          setMessage(res.msg);
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      });
    }
  };

  return (
    <FormContainer>
      <ParticlesBg color="#f7b32e" num={50} type="cobweb" bg={true} />
      <div>
        <h1>Sign In</h1>
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="email@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-zebra"
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label className="mt-2 mb-1">Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-zebra"
            ></Form.Control>
          </Form.Group>
          <div className="mt-2 mb-1">
            {message && <Message variant="danger">{message}</Message>}
          </div>
          <Button
            type="submit"
            variant="warning mt-3 mx-auto d-flex"
            className="bg-zebra mt-3 mx-auto d-flex"
          >
            Sign In
          </Button>
        </Form>
        <div className="py-3 text-center">
          <div>
            <Link
              variant="warning mt-3 mx-auto d-flex"
              className="btn btn-outline-zebra btn-sm mt-1 mb-1"
              //to={redirect ? `/register?redirect=${redirect}` : "/register"}
              to="/register" // try 1
            >
              Register
            </Link>
          </div>
          <div>
            <Link
              variant="warning mt-3 mx-auto d-flex"
              className="btn btn-outline-zebra btn-sm mt-1 mb-1"
              to="/resetpassword"
            >
              Forgot Password
            </Link>
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default Login;
