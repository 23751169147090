import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { handleCalc } from "../actions/calculateAction"

const initialPrices = {
  unitPrice: "",
  discountedPrice: "",
  suggestedPrice: "",
  extraUserDiscount: "",
  discountedPriceWithoutExtraUserDiscount: "",
}

const HomeCart = ({ productList, cartProduct, deleteFromCart }) => {
  const dispatch = useDispatch()

  const [prices, setPrices] = useState(initialPrices)

  const handleCalculate = useCallback(() => {
    const result = dispatch(handleCalc(cartProduct))

    if (result)
      setPrices(result.prices)
  }, [dispatch, cartProduct])

  useEffect(() => {
    handleCalculate()
  }, [productList, cartProduct, handleCalculate])

  return (
    <Card className="w-100 shadow bg-white my-2 rounded" size="sm" style={{ borderColor: `${cartProduct.blindType === "DayAndNight" && cartProduct.headrailType === "Single" ? '#bcab79' : (cartProduct.blindType === "DayAndNight" && cartProduct.headrailType === "Double" ? '#292929' : (cartProduct.blindType === "Vellision" ? '#39afd1' : (cartProduct.blindType === "Pleated" && cartProduct.headrailType === "Single" ? '#57ae5b' : (cartProduct.blindType === "Pleated" && cartProduct.headrailType === "Double" ? '#84717d' : (cartProduct.blindType === "Venetian" ? '#f6c756' : (cartProduct.blindType === "Vertical" ? '#d64545' : 'unset'))))))}` }}>
      <Card.Header className="d-flex justify-content-between" style={{ minHeight: 75, background: `${cartProduct.headrailType === "Double" ? cartProduct.doubleGroupCode : "unset"}`, color: `${cartProduct.headrailType === "Double" ? "white" : "unset"}` }}>
        <Card.Text as="small">
          <span>
            Window: {cartProduct.window}
            <br></br>
            Fabric: {cartProduct.fabric}
          </span>
        </Card.Text>
        {cartProduct.notes && (
          <OverlayTrigger
            notes={cartProduct.notes}
            overlay={<Tooltip id="tooltip-right">{cartProduct.notes}</Tooltip>}
          >
            <span style={{ cursor: "pointer", verticalAlign: "top" }}>
              <i className="fas fa-clipboard"/>
            </span>
          </OverlayTrigger>
        )}
        <span
          onClick={() => deleteFromCart(cartProduct)}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-trash-alt"/>
        </span>
      </Card.Header>
      <Card.Body className="p-3 pt-0 d-flex flex-column justify-content-between">
        {/*<Card.Text as="small">*/}
        {/*  {cartProduct.continued_blind !== "None" &&*/}
        {/*    `Continued of ${cartProduct.continued_blind}, `}*/}
        {/*</Card.Text>*/}
        <div>
          <Card.Text
              style={{
                background: `${cartProduct.blindType === "DayAndNight" && cartProduct.headrailType === "Single" ? '#bcab79' : (cartProduct.blindType === "DayAndNight" && cartProduct.headrailType === "Double" ? '#292929' : (cartProduct.blindType === "Vellision" ? '#39afd1' : (cartProduct.blindType === "Pleated" && cartProduct.headrailType === "Single" ? '#57ae5b' : (cartProduct.blindType === "Pleated" && cartProduct.headrailType === "Double" ? '#84717d' : (cartProduct.blindType === "Venetian" ? '#f6c756' : (cartProduct.blindType === "Vertical" ? '#d64545' : 'unset'))))))}`,
                marginLeft: -16,
                marginRight: -16,
                marginBottom: 0,
                padding: 5,
                textAlign: 'center'
              }}
          >
            <strong style={{color: 'white' }}>{cartProduct.blindType} {cartProduct.headrailType === "Double" ? '(Dbl)' : ''}</strong><br></br>
          </Card.Text>
          {cartProduct.continued_blind !== "None" &&
          `Continued of ${cartProduct.continued_blind}, `}
          {cartProduct.width}mm x {cartProduct.drop}mm<br></br>
          {cartProduct.headrail}
          {cartProduct.blindType !== "Pleated" ? (
              <>
                {cartProduct.blindType !== "Venetian" ? `, ${cartProduct.control} side` : ``}
                {`, ${cartProduct.fitting}${cartProduct.fitting !== "Top Fix" && cartProduct.fitting !== "Normal Fix" ? ` (${cartProduct.bracket} x ${cartProduct.bracketCount})` : ``}`}
                {cartProduct.motor !== "None" &&
                `, ${cartProduct.motor}${cartProduct.charger !== 0 ? ` (${cartProduct.remote} x ${cartProduct.charger})` : ``}`}
                {cartProduct.tassel !== "None" &&
                `, ${cartProduct.tassel}`}
                {cartProduct.motor === "None" ? (cartProduct.controlOption ? `, ${cartProduct.controlOption}` : ", Chain") : ""}
                {cartProduct.motor === "None" && (cartProduct.blindType === "DayAndNight" || cartProduct.blindType === "Venetian" || ((cartProduct.blindType === "Vellision" || cartProduct.blindType === "Vertical") && cartProduct.controlOption === "Chain")) ? `, ${cartProduct.chain}` : ""}
                {cartProduct.motor === "None" ? (cartProduct.blindType === "DayAndNight" || cartProduct.blindType === "Venetian" || ((cartProduct.blindType === "Vellision" || cartProduct.blindType === "Vertical") && cartProduct.controlOption === "Chain") ? `, ${cartProduct.cord}.` : `, ${cartProduct.wand}.`) : "."}
              </>
          ) : '.'}
        </div>
        <div>
        <Card.Text as="h6" className="text-end" >
          <small>Original Price :</small><br></br> £ {prices.unitPrice}
        </Card.Text>
        <hr className="my-1" />
        <Card.Text as="h6" className="text-end">
          <small>Suggested Sale Price :</small><br></br> £ {prices.suggestedPrice}
        </Card.Text>
        <hr className="my-1" />
        <Card.Text as="h5" className="text-end" style={{ minHeight: 75 }}>
          <small>Your Price :</small><br></br> £ {prices.extraUserDiscount > 0 ? (<>{prices.discountedPrice} <del className="text-muted">{prices.discountedPriceWithoutExtraUserDiscount}</del></>) : prices.discountedPrice}
          {prices.extraUserDiscount > 0 ? (
              <>
                <br></br>
                <small style={{ color: 'limeGreen', fontSize: 13, fontWeight: "bold" }}><u>with additional discount</u></small>
              </>
          ) : null}
        </Card.Text>
        </div>
      </Card.Body>
    </Card>
  )
}

export default HomeCart
