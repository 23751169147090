import React, { useEffect, useState } from "react"
import {
  Table,
  Button,
  Row,
  Col,
  Modal,
  ListGroup,
} from "react-bootstrap"

import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"

// Get cart details actions
import {
  listAllCarts,
} from "../actions/cartActions"
import Pagination from "../components/Pagination"

const CartAll = () => {
  const dispatch = useDispatch()
  const [state, setstate] = useState([])
  const [selected, setselected] = useState({})
  const [show, setShow] = useState(false)
  const { cartsAll } = useSelector((store) => store.cartList)
  const [currentPage, setcurrentPage] = useState(1)
  const [totalItems, settotalItems] = useState(0)
  // const [filteredValue, setfilteredValue] = useState("")
  const [filteredValue,] = useState("")
  //const isTablet = useMediaQuery({ query: "(min-width: 768px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
  const handleClose = () => setShow(false)

  useEffect(() => {
    dispatch(listAllCarts({ currentPage, filteredValue }))
  }, [
    dispatch,
    currentPage,
    filteredValue,
  ])

  useEffect(() => {
    if (cartsAll) {
      setstate(cartsAll.carts)
      settotalItems(cartsAll && cartsAll.total ? cartsAll.total : 0)
    }
  }, [cartsAll])

  const onChangePage = (pageNum) => {
    setcurrentPage(pageNum)
  }

  function onRowClick(id) {
    const selected =
      cartsAll &&
      cartsAll.carts &&
      cartsAll.carts.find((item) => item._id === id)
    setselected(selected)
    setShow(true)
  }

  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <Pagination
        // items={state}
        currentPage={currentPage}
        totalItems={totalItems}
        onChangePage={onChangePage}
      />
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th>User</th>
            <th>Items</th>
            {!isMobile && (
              <>
              <th>Cart Name</th>
              <th>Cart Date</th>
              </>
            )}
            <th>Update Date</th>
          </tr>
        </thead>
        <tbody>
          {state &&
            state.map((item, index) => {
              return (
                <tr key={index} onClick={() => onRowClick(item._id)}>
                  <td>{item && item.user_id && item.user_id.company}</td>
                  <td>{item.cartItems.length}</td>
                  {!isMobile && (
                    <>
                    <td>{item.cart_name}</td>
                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    </>
                  )}
                  <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      <Modal
        size={isMobile ? "sm" : "lg"}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selected && selected.cart_name} Cart Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Cart Items</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush" style={{ pointerEvents: "none" }}>
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                  {selected.cartItems &&
                    selected.cartItems.map((elem, index) => {
                      return (
                        <ListGroup.Item key={index} style={{ background: `${elem.headrailType === "Double" ? elem.doubleGroupCode : "unset"}`, color: `${elem.headrailType === "Double" ? "white" : "unset"}` }}>
                          <li>
                            Window: {elem.window} / Fabric: {elem.fabric} ({elem.blindType === "DayAndNight" ? (elem.headrailType === "Double" ? "DN-Double" : "DN") : (elem.blindType === "Pleated" ? (elem.headrailType === "Double" ? "P-Double" : "P") : (elem.blindType === "Vellision" ? "V" : (elem.blindType === "Venetian" ? "VEN" : "VER")))})
                          </li>
                          <li>
                            Width: {elem.width} x Drop: {elem.drop}
                          </li>
                          {elem.blindType !== "Pleated" && (
                              <li>
                                Fitting: {elem.fitting} {elem.fitting !== "Top Fix" && elem.fitting !== "Normal Fix" ? `(${elem.bracket} x ${elem.bracketCount})` : ""}
                              </li>
                          )}
                          <li>
                            Headrail: {elem.headrail}
                            {elem.blindType !== "Pleated" ? (
                                <>
                                  {elem.motor === "None" ? (elem.controlOption === "Wand" ? ` - Wand: (${elem.wand})` : ` - Chain: ${elem.chain} (${elem.cord})`) : ``}
                                  {elem.blindType !== "Venetian" ? ` - Control: ${elem.control}` : ``}
                                  {elem.blindType === "DayAndNight" ? ` - Tassel: ${elem.tassel}` : ""}
                                </>
                            ) : null}
                          </li>
                          {(elem.blindType === "DayAndNight" || elem.blindType === "Venetian") && (
                              <>
                                {elem.continued_blind !== "None" ? (
                                    <li>
                                      Continued Blind: {elem.continued_blind}
                                    </li>
                                ) : null}
                                {elem.motor !== "None" && (
                                    <li>
                                      Motor: {elem.motor} {elem.charger !== 0 ? `(${elem.remote} x ${elem.charger})` : ``}
                                    </li>
                                )}
                              </>
                          )}
                          {elem.notes && (
                              <li>Product Note: {elem.notes}</li>
                          )}
                        </ListGroup.Item>
                      )
                    })}
                </ul>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
           <div>
            <Button
              size={isMobile && "sm"}
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CartAll
