import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Bootstrap Components
import {
  Form,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
// Redux
import { useDispatch, useSelector } from "react-redux";
// UI Components
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";

// Redux Actions
import { register } from "../actions/userActions";

const Register = ({ location, history }) => {
  // State to hold email and password
  const dispatch = useDispatch();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [messageFront, setMessageFront] = useState(null);
  const [success, setSuccess] = useState(null);
  const [serverError, setServerError] = useState(null);

  // Get user login info from Redux state
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo } = userRegister;

  const redirect = location.search ? location.search.split("=")[0] : "/";
  //Modal Set
  const handleClose = () => {
    setServerError("");
    setMessageFront("");
    if (success) {
      history.push("/");
    }
    setSuccess("");
  };

  useEffect(() => {
    // If there is user info then redirect
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  // Handler that logs in the user
  const submitHandler = (e) => {
    e.preventDefault();
    // Check if passwords are the same
    if (password !== confirmPassword) {
      setServerError("");
      setMessageFront("Passwords do not match.");
    } else if (password.length < 6) {
      setServerError("");
      setMessageFront("Password must be at least 6 characters.");
    } else {
      // Dispatch Register
      dispatch(
        register(firstname, lastname, email, company, phone, password)
      ).then((res) => {
        //console.log("ress ==> ", res);
        if (res.status === 200) {
          setServerError("");
          setMessageFront("");
          setSuccess(res.msg);
          setFirstname("");
          setLastname("");
          setCompany("");
          setPhone("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
        } else if (res.status === 400 || res.status === 500) {
          setSuccess("");
          setMessageFront("");
          setServerError(res.msg);
        }
      });
    }
  };

  //console.log(serverError);
  return (
    <FormContainer>
      <h1>Sign Up</h1>

      {loading && <Loader />}
      {/* {isVisible ? ( */}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label className="mt-2 text-secondary">
            Name {"  "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2">This field required.</Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i
                  {...triggerHandler}
                  ref={ref}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              )}
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Name"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            required
            className="border-zebra"
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="surname">
          <Form.Label className="mt-2 text-secondary">
            Surname{"  "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2">This field required.</Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i
                  {...triggerHandler}
                  ref={ref}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              )}
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Surname"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            required
            className="border-zebra"
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="Company Name">
          <Form.Label className="mt-2 text-secondary">
            Company Name{"  "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2">This field required.</Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i
                  {...triggerHandler}
                  ref={ref}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              )}
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="companyname"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
            className="border-zebra"
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label className="mt-2 text-secondary">
            Phone{"  "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2">
                  Phone number should be 11 numbers, please enter without (+44).
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i
                  {...triggerHandler}
                  ref={ref}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              )}
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Phone (Without +44)"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            pattern="[0-9]{10,11}"
            maxLength="11"
            required
            className="border-zebra"
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label className="mt-2 text-secondary">
            Email Address{"  "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2">This field required.</Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i
                  {...triggerHandler}
                  ref={ref}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              )}
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="email@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="border-zebra"
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label className="mt-2 text-secondary">
            Password{"  "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2">
                  Password required and set minimum 6 characters.
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i
                  {...triggerHandler}
                  ref={ref}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              )}
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border-zebra"
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label className="mt-2 text-secondary">
            Confirm Password
            {"  "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2">
                  Password required and set minimum 6 characters.
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <i
                  {...triggerHandler}
                  ref={ref}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              )}
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            //min="6"
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="border-zebra"
          ></Form.Control>
        </Form.Group>
        <Button
          type="submit"
          //onClick={handleShow}
          variant="outline-zebra mt-3 mx-auto d-flex"
        >
          Register
        </Button>
        <Modal
          size="sm"
          show={messageFront || serverError || success ? true : false}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header className="bg-light" closeButton>
            {/* <Modal.Title>Attention Please!</Modal.Title> */}
          </Modal.Header>
          <Modal.Body className="bg-light border border-2 border-zebra">
            {messageFront && (
              <div className="text-danger text-center">
                <h6>{messageFront}</h6>
              </div>
            )}
            {serverError && (
              <div className="text-danger text-center">
                <h6>{serverError}</h6>
              </div>
            )}
            {success && (
              <div className="textZebra text-center">
                <h6>{success}</h6>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-zebra mx-auto" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
      <Row className="py-3">
        <Col>
          Have an Account? <Link to={"/"}>Login</Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default Register;
