import { NOTIFICATION_LIST_SUCCESS, NOTIFICATION_LIST_FAIL } from "../constants/notificationConstants";


export const notificationReducer = (state =  [], action) => {
  // const { type, payload } = action;
//console.log(action.payload)
  switch (action.type) {
    case NOTIFICATION_LIST_SUCCESS:
      return action.payload.notifications;

    case NOTIFICATION_LIST_FAIL:
      return state;

    default:
      return state;
  }
};
