import React, { useCallback, useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { connect, useDispatch, useSelector } from "react-redux"
import AutVerify from "./components/AutVerify"
import { autVerify } from "./actions/userActions"

// Routing
import { Redirect, Route, Switch } from "react-router-dom"

// Components
import Header from "./components/Header"
import Footer from "./components/Footer"
import { listItems, getOneItem } from "./actions/sameActions"
import { getUserCarts } from "./actions/cartActions"
import { listUserOrders } from "./actions/orderActions"

// Screens
import HomeScreen from "./screens/Home"
import ProductScreen from "./screens/Product"
import NotificationScreen from "./screens/Notification"
import UserScreen from "./screens/Users"
import CartScreen from "./screens/Cart"
import LoginScreen from "./screens/Login"
import RegisterScreen from "./screens/Register"
import ProfileScreen from "./screens/Profile"
import ShippingScreen from "./screens/Shipping"
import PaymentScreen from "./screens/Payment"
import PlaceOrderScreen from "./screens/PlaceOrder"
import DashboardScreen from "./screens/Dashboard"
import OrderScreen from "./screens/Order"
import OrdersAllScreen from "./screens/OrderAll"
import CartsAllScreen from "./screens/CartAll"
import ResetPassword from "./screens/ResetPassword"

//Pages
import Page404 from "./pages/Page404"

const App = () => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const isLogin = localStorage.getItem("firstLogin")
  const userInfoLocal = JSON.parse(localStorage.getItem("userInfo"))
  const [authUser, setAuthUser] = useState(false)
  const isAdmin = userInfoLocal && userInfoLocal.user.role

  useEffect(() => {
    dispatch(listItems("product"))
    dispatch(listItems("motor"))
    dispatch(listItems("delivery"))
    dispatch(listItems("tassel"))
    dispatch(listItems("fitting"))
    dispatch(listItems("chain"))
    dispatch(listItems("cord"))
    dispatch(listItems("wand"))
    dispatch(listItems("headrail"))
    dispatch(listItems("bracket"))
    dispatch(listItems("remote"))
    dispatch(getUserCarts())
    dispatch(getOneItem("address"))
    dispatch(listUserOrders())
  }, [dispatch, userInfo])

  useEffect(() => {
    if (isAdmin === 1) {
      dispatch(listItems("notification"))
    }
  }, [isAdmin, dispatch])

  const handleBeforeUnload = useCallback(() => {
    setAuthUser(prevAuthUser => !prevAuthUser)
  }, [])

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [handleBeforeUnload])

  useEffect(() => {
    dispatch(autVerify())
  }, [authUser, dispatch])

  return (
    <div>
      <Header />
      <main className="py-3">
        <Container>
          <Switch>
            <Route path="/register" component={RegisterScreen} exact />
            <Route path="/resetpassword" component={ResetPassword} exact />
            {isLogin ? (
              <Switch>
                {isAdmin === 0 ? (
                  <Switch>
                    <Route
                      path="/"
                      render={(props) => <HomeScreen {...props} />}
                      exact
                    />
                    <Route
                      path="/cart"
                      render={(props) => <CartScreen {...props} />}
                      exact
                    />
                    <Route path="/orders" component={OrderScreen} exact />
                    <Route
                      path="/place-order"
                      component={PlaceOrderScreen}
                      exact
                    />
                    <Route
                      path="/payment"
                      component={PaymentScreen}
                      exact
                    />
                    <Route
                      path="/shipping"
                      component={ShippingScreen}
                      exact
                    />
                    <Route
                      path="/profile"
                      strict
                      component={ProfileScreen}
                      exact
                    />
                    <Route component={Page404} exact />
                  </Switch>
                ) : (
                  <Switch>
                    <Route
                      path="/"
                      component={DashboardScreen}
                      exact
                    />
                    <Route
                      path="/ordersAll"
                      component={OrdersAllScreen}
                      exact
                    />
                    <Route
                      path="/cartsAll"
                      component={CartsAllScreen}
                      exact
                    />
                    <Route
                      path="/products"
                      component={ProductScreen}
                      exact
                    />
                    <Route
                      path="/notifications"
                      component={NotificationScreen}
                      exact
                    />
                    <Route path="/users" component={UserScreen} exact />
                    <Route
                      path="/profile"
                      strict
                      component={ProfileScreen}
                      exact
                    />
                    <Route component={Page404} exact />
                  </Switch>
                )}
              </Switch>
            ) : (
              <Switch>
                <Route path="/" component={LoginScreen} exact strict />
                <Redirect to="/" from="*" />
              </Switch>
            )}
          </Switch>
          <AutVerify />
        </Container>
      </main>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => state
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(App)
