import {
  DELIVERY_LIST_REQUEST,
  DELIVERY_LIST_SUCCESS,
  DELIVERY_LIST_FAIL,
  DELIVERY_DETAILS_REQUEST,
  DELIVERY_DETAILS_SUCCESS,
  DELIVERY_DETAILS_FAIL,
} from "../constants/deliveryConstants";
import { USER_LOGOUT } from "../constants/userConstants";

// Getting deliveries from server
export const deliveryListReducer = (state = { deliveries: [] }, action) => {
  switch (action.type) {
    case DELIVERY_LIST_REQUEST:
      return {
        loading: true,
        deliveries: [],
      };
    case DELIVERY_LIST_SUCCESS:
      return {
        loading: false,
        deliveries: action.payload,
      };
    case DELIVERY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
};

// Get deliveries details
export const deliveryDetailsReducer = (
  state = { delivery: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case DELIVERY_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case DELIVERY_DETAILS_SUCCESS:
      return {
        loading: false,
        delivery: action.payload,
      };
    case DELIVERY_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
