export const ADDRESS_LIST_REQUEST = 'ADDRESS_LIST_REQUEST'
export const ADDRESS_LIST_SUCCESS = 'ADDRESS_LIST_SUCCESS'
export const ADDRESS_LIST_FAIL = 'ADDRESS_LIST_FAIL'

export const ADDRESS_GET_REQUEST = 'ADDRESS_GET_REQUEST'
export const ADDRESS_GET_SUCCESS = 'ADDRESS_GET_SUCCESS'
export const ADDRESS_GET_FAIL = 'ADDRESS_GET_FAIL'

export const ADDRESS_DETAILS_REQUEST = 'ADDRESS_DETAILS_REQUEST'
export const ADDRESS_DETAILS_SUCCESS = 'ADDRESS_DETAILS_SUCCESS'
export const ADDRESS_DETAILS_FAIL = 'ADDRESS_DETAILS_FAIL'

export const ADDRESS_POST_REQUEST = 'ADDRESS_POST_REQUEST'
export const ADDRESS_POST_SUCCESS = 'ADDRESS_POST_SUCCESS'
export const ADDRESS_POST_FAIL = 'ADDRESS_POST_FAIL'


export const ADDRESS_DELETE_REQUEST = 'ADDRESS_DELETE_REQUEST'
export const ADDRESS_DELETE_SUCCESS = 'ADDRESS_DELETE_SUCCESS'
export const ADDRESS_DELETE_FAIL = 'ADDRESS_DELETE_FAIL'